import { useTranslation } from 'react-i18next';

import { useMeQuery } from 'store/api/profile';
import s from './HeaderProfile.module.scss';
import { Loader } from 'components/loader/loader';

const attributesWhitelist = ['snils', 'inn', 'kpp'];

export const HeaderProfile = () => {
  const { data: userInfo, isFetching } = useMeQuery();
  const { t } = useTranslation();

  if (isFetching) {
    return <Loader size='big'/>;
  }

  return (
    <div className={s['continer']}>
      <div className={s['wrapper-name']}>
        <span>{userInfo?.firstName}</span>
        <span>{userInfo?.lastName}</span>
      </div>

      <div className={s['wrapper-attributes']}>
        {userInfo?.id && (
          <div className={s['attribute-item']}>
            <span>ID:</span>
            <span>{userInfo?.id ?? ''}</span>
          </div>
        )}

        {userInfo?.attributes
          ?.filter((item) => attributesWhitelist?.includes(item?.name))
          ?.filter((item) => item.value)
          ?.map((item) => (
            <div className={s['attribute-item']} key={item?.name}>
              <span>{t(item.name)}:</span>
              <span>{item.value}</span>
            </div>
          ))}
      </div>
    </div>
  );
};
