import { selectIsLoggedIn } from 'modules/auth';
import { DashboardPage } from 'modules/dashboard';
import { Navigate, Route, Routes } from 'react-router-dom';
import s from './App.module.scss';

import LayoutDashboard from 'components/layout/dashboard';

import { ProtectedRoute } from 'components/routes';
import { useSelector } from 'react-redux';

import LayoutAuth from 'components/layout/main';
import { InviteModal } from 'components/modal/invite/Invite';
import { AppRoutes } from 'config/routes';
import { useInvitation } from 'hooks/use-invitation';
import { AuthPage } from 'modules/auth/Auth';
import useCertClass from 'hooks/useCertClass';

export function App() {
  const isLoggedIn = useSelector(selectIsLoggedIn());

  const { Home: AuthHome, Login } = AppRoutes.Auth;

  useCertClass();
  useInvitation();

  return (
    <div className={s.root}>
      <Routes>
        <Route path="/" element={<Navigate to={AuthHome + Login} />} />

        <Route
          path={AuthHome + '/*'}
          element={
            <LayoutAuth>
              <AuthPage />
            </LayoutAuth>
          }
        />

        <Route
          path={'/*'}
          element={
            <ProtectedRoute allowed={isLoggedIn} redirect={AuthHome + Login}>
              <LayoutDashboard>
                <DashboardPage />
              </LayoutDashboard>
            </ProtectedRoute>
          }
        />
      </Routes>

      <InviteModal />
    </div>
  );
}
