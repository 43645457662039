import React from 'react';

import { Header } from './header';
import { Footer } from './footer';

interface LayoutMainProps {
  children: React.ReactNode;
}

function LayoutAuth({ children }: LayoutMainProps) {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default LayoutAuth;
