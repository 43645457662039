import cx from 'classnames';
import { Formik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePickerField, InputField } from 'components/common';
import { Button } from 'components/ui/Button';
import { ModalMain } from '../main/main';

import { ModelType, initialValues, model, validationSchema } from './config';

import { ReactComponent as InfoIcon } from 'assets/images/information.svg';

import { useParams } from 'react-router-dom';
import { usePoaPreviewRevocationMutation } from 'store/api/poa';
import { useAppDispatch } from 'store/hooks';
import { setDocumentContent } from 'store/reducers/attorney.revoke';
import { prepareDate } from 'utils/date';
import { useHandleMutation } from 'utils/handleMutation';
import s from './revocation-power-attorney.module.scss';

interface RevocationPowerAttorneyModalProps {
  isVisible: boolean;
  onClick: () => void;
  onClose?: () => void;
}

export const RevocationPowerAttorneyModal = ({
  onClose,
  onClick,
  isVisible,
}: RevocationPowerAttorneyModalProps) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [previewRevocation, mutation] = usePoaPreviewRevocationMutation();
  const isLoading = mutation.isLoading;

  const classesCancel = cx(s['button'], s['cancel']);
  const classesConfirm = cx(s['button'], s['confirm']);

  const onSuccess = useCallback(
    (content: string) => {
      if (isVisible && content) {
        dispatch(setDocumentContent(content));
      }
    },
    [isVisible],
  );

  useHandleMutation({ ...mutation, onSuccess });

  const onSubmit = async (values: ModelType) => {
    const { date, reason } = values;
    await previewRevocation({
      poaId: id ?? '',
      revokeDate: prepareDate(date),
      revokeReason: reason,
    });
    onClick();
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, submitForm, values, setFieldValue }) => (
        <ModalMain
          title="Revocation of the power of attorney"
          isVisible={isVisible}
          closeOnClick={onClose}
          footer={
            <div className={s['button-wrapper']}>
              <Button
                displayType="outlined-secondary"
                text={t('Cancel')}
                className={classesCancel}
                onClick={onClose}
              />
              <Button
                text={t(isLoading ? 'Loading...' : 'Confirm')}
                className={classesConfirm}
                onClick={submitForm}
              />
            </div>
          }
        >
          <div className={s['body']}>
            <div className={s['wrapper']}>
              <div className={s['wrapper-info']}>
                <div>
                  <InfoIcon />
                </div>
                <span className={s['text-info']}>
                  {t(
                    'To revoke the power of attorney, it is necessary to form an order for revocation.',
                  )}
                </span>
              </div>

              <div className={s['wrapper-date']}>
                <DatePickerField {...model.date} />
              </div>
            </div>

            <div className={s['input-wrapper']}>
              <InputField {...model.reason} />
            </div>
          </div>
        </ModalMain>
      )}
    </Formik>
  );
};
