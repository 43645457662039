import { Certificate, TemplateData } from 'client/models';
import TemplateSignerBase from './template-signer-base';

interface VipnetApi {
  checkConnection: () => Promise<any>;
  getCertificates: () => Promise<any>;
  sign: (options: any) => Promise<{ SignedData: string }>;
  signXml: (options: any) => Promise<{ Base64SignedXml: string }>;
}

export default class VipnetSigner extends TemplateSignerBase {
  private client;

  constructor(client: VipnetApi) {
    super();
    this.client = client;
  }

  async getCertificates(): Promise<Certificate[]> {
    return await this.client.getCertificates();
  }

  async sign({
    certificate,
    dataForSign,
  }: {
    certificate: Certificate;
    dataForSign: string;
  }): Promise<string> {
    return (
      await this.client.sign({
        certificate,
        dataForSign,
      })
    ).SignedData;
  }

  async signXml({
    props,
    dataForSign,
    certificate,
  }: {
    dataForSign: string;
    certificate: Certificate;
    props?: Partial<TemplateData>;
  }): Promise<{ signature: string; detachedSignature: string }> {
    const xmlToSign = await this.addSignatureTemplate(dataForSign, certificate, props);

    const options = {
      dataForSign,
      fileExtension: 'xml',
      base64Xml: xmlToSign,
      certificate: certificate,
      signatureType: 'wsse-security',
      description: 'Тестовое описание',
      documentName: 'Тестовый документ',
      disableCertificateVerification: true,
      base64Certificate: certificate.Base64RawData,
    };

    const signature = (await this.client.signXml(options)).Base64SignedXml;

    return { signature, detachedSignature: '' };
  }
}
