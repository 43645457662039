import { DocumentCounterparty } from 'components/list-items/document-counterpary';
import { Loader } from 'components/loader/loader';
import { NotFound, NotFoundContainer } from 'components/not-found';
import { useParams } from 'react-router-dom';
import { useContractorDocumentsQuery } from 'store/api/organizationDocuments';
import s from './documents.module.scss';
import { DocumentOrganization } from 'components/list-items/document-organization';

export const Documents = () => {
  const { id } = useParams();

  const { data, isFetching } = useContractorDocumentsQuery(
    { contractorId: id ?? '' },
    { skip: !id },
  );

  if (isFetching) {
    return <Loader size="medium" />;
  }

  if (!isFetching && !data?.length) {
    return (
      <NotFoundContainer>
        <NotFound
          hasSearch={false}
          title="Here will be displayed"
          subtitle="documents"
          notFoundTitle="No documents found"
        />
      </NotFoundContainer>
    );
  }

  return (
    <div className={s.container}>
      <div className={s.grid}>
        {data?.map((item) => (
          <DocumentOrganization hasDelete={false} key={item.id.toString()} data={item} />
        ))}
      </div>
    </div>
  );
};
