import { gql } from 'graphql-request';

export const START_REGISTRATION_BY_REPRESENTATIVE = gql`
  mutation CoreOrganizations($poaId: ID!, $inviteId: ID!) {
    coreOrganizations {
      startRegistrationByRepresentative(input: { inviteId: $inviteId, poaId: $poaId }) {
        joinDocumentContent
      }
    }
  }
`;
