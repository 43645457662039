import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { refreshToken } from 'api';
import { AuthState } from 'modules/auth';
import { RootState } from 'store/store';

const initialState: AuthState = {
  authData: {},
  mode: 'default',
  isLoggedIn: false,
};

export const refreshAccessToken = createAsyncThunk(
  'auth/refreshAccessToken',
  async ({ refresh_token }: { refresh_token: string }) => {
    const response = await refreshToken(refresh_token);
    return response;
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleAuth(state, action) {
      const { access_token } = action.payload;
      const isLoggedIn = access_token !== undefined;
      state.authData = action.payload;
      state.isLoggedIn = isLoggedIn;
    },
    setMode(state, action) {
      state.mode = action.payload;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(refreshAccessToken.fulfilled, (state, action) => {
      const { access_token, refresh_token } = action.payload;
      const isLoggedIn = access_token !== undefined;
      state.isLoggedIn = isLoggedIn;
      state.authData = { ...state.authData, access_token, refresh_token };
    });

    builder.addCase(refreshAccessToken.rejected, (state, action) => {
      if (action.error) {
        state.isLoggedIn = false;
        state.authData = {};
      }
    });
  },
});

export const { setMode, handleAuth, resetState } = authSlice.actions;

export default authSlice.reducer;

const authStore = (store: RootState) => store.auth;

export const selectIsLoggedIn = () =>
  createSelector([authStore], (store) => store?.isLoggedIn ?? false);

export const selectToken = () =>
  createSelector([authStore], (store) => store?.authData?.access_token ?? null);
