import { useTranslation } from 'react-i18next';

import { model } from '../config';

import { InputField, DatePickerField } from 'components/common';
import { SelectField } from 'components/fields/select-fields/select-filed';
import { RadioGroupField } from 'components/fields/radio-group-field/select-filed';

import s from './editing.module.scss';

export const ContactInformation = () => {
  const { t } = useTranslation();

  return (
    <div className={s['container']}>
      <span className={s['title']}>{t('Contact information')}</span>
      <div className={s['wrapper-fields']}>
        <InputField {...model.phone} />
        <InputField {...model.email} />
      </div>
    </div>
  );
};

export const EditingProfileForm = () => {
  const { t } = useTranslation();

  return (
    <div className={s['container']}>
      <span className={s['title']}>{t('Information about an individual')}</span>
      <div className={s['wrapper-fields']}>
        <InputField {...model.surname} />
        <InputField {...model.name} />
        <InputField {...model.patronymic} />
        <DatePickerField {...model.birthdate} />
        <RadioGroupField {...model.citizenship} />
        <SelectField {...model.typeIdentityDocument} />
        <InputField {...model.documentSeriesAndNumber} />
        <DatePickerField {...model.dateIssueDocument} />
        <SelectField {...model.nameAuthorityIssuedDocument} />
      </div>
    </div>
  );
};
