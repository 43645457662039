import { TokenPayload } from '../models';

export function isTokenExpired(token: string) {
  const tokenPayload = getTokenPayload(token);
  return tokenPayload ? Math.floor(new Date().getTime() / 1000) > tokenPayload.exp : false;
}

export function getTokenPayload(token: string) {
  if (!token) {
    return null;
  }

  const [, payload] = token.split('.');

  return JSON.parse(atob(payload)) as TokenPayload;
}
