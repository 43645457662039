import { ReactElement } from 'react';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';

import { SelectProps } from './select-types';
import { useStyles } from './helpers';

import { ReactComponent as RequiredIcon } from 'assets/images/required.svg';

import s from './select.module.scss';
import { ErrorLabel } from 'components/error-label';

export const Select = ({
  name,
  large,
  label,
  error,
  options,
  country,
  priority,
  required,
  onChange,
  isLoading,
  className,
  isDisabled,
  components,
  isCreatable,
  placeholder,
  isClearable,
  requiredText,
  defaultValue,
  isSearchable,
  defaultControl,
  containerStyle,
  errorOnlyBorder,
  isCloseMenuOnSelect = true,
  ...props
}: SelectProps): ReactElement => {
  const { t } = useTranslation();

  const styles = useStyles(large);
  return (
    <div className={s['container']}>
      {label && (
        <div className={s['wrapper-label']}>
          <span className={s['label']}>{t(label ?? '')}</span>
          {required && <RequiredIcon />}
        </div>
      )}

      <ReactSelect
        styles={styles}
        options={options}
        onChange={onChange}
        isLoading={isLoading}
        className={className}
        isDisabled={isDisabled}
        inputId={name ?? label}
        closeMenuOnSelect={isCloseMenuOnSelect}
        placeholder={placeholder ? t(placeholder) : ''}
        isClearable={isClearable}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        components={{
          ...components,
        }}
        {...props}
      />

      {error && <ErrorLabel error={error} />}
    </div>
  );
};

Select.defaultProps = {
  error: '',
  label: '',
  large: false,
  components: {},
  disabled: false,
  priority: false,
  requiredText: '',
  placeholder: 'Select',
  defaultValue: undefined,
  isSearchable: false,
};
