export const EditDocument = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 15.5L9.5 19.2875L6.5 15.5V3C6.5 1.89375 7.39375 1 8.5 1H10.5C11.6063 1 12.5 1.89375 12.5 3V15.5Z"
        stroke="#BECADC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M18 6.5H12.5" stroke="#BECADC" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M18 9.5H12.5" stroke="#BECADC" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M18 12.5H12.5" stroke="#BECADC" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M18 15.5H6.5" stroke="#BECADC" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M20.5 3.5H3.5V23H20.5V3.5Z"
        stroke="#BECADC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
