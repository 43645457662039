import React, { useState, useEffect, useRef } from 'react';
import s from './Dropdown.module.scss';

interface DropdownProps extends React.PropsWithChildren {
  button: React.ReactNode;
  footer?: React.ReactNode;
}

const Dropdown = ({ children, button, footer }: DropdownProps) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      toggleDropdown();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={s.dropdownContainer} ref={dropdownRef}>
      <div
        className={`${s.dropdown} ${isDropdownOpen ? s.open : ''}`}
        onClick={toggleDropdown}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        {button}
      </div>
      {isDropdownOpen && (
        <div className={s.dropdownContent}>
          {children}

          <div className={s.footer}>{footer}</div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
