import jsonApi from './json-api';

const API_SECTION = 'cert';

interface CertClassRequest {
  cert_body: string;
}
interface CertClassResponse {
  cert_class: string;
}

function certClass(cert_body: string): Promise<CertClassResponse> {
  if (!cert_body?.length) {
    return new Promise((resolve) => resolve({ cert_class: '' }));
  }
  return jsonApi.post<CertClassRequest, CertClassResponse>(`${API_SECTION}/class`, {
    cert_body,
  });
}

export { certClass };
