import { DatePickerMode } from 'components/common/date-picker/date-picker';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { DefaultDateFormat } from 'utils/date';
import * as Yup from 'yup';

export type ModelType = {
  date: string;
  reason: string;
};

export const model = {
  date: {
    type: 'date',
    required: true,
    mode: DatePickerMode.SINGLE,
    name: 'date',
    label: 'Date of recall',
    disabled: true,
    placeholder: dayjs().format(DefaultDateFormat),
  },
  reason: {
    name: 'reason',
    allowClear: true,
    type: 'textarea',
    label: 'Reason for the recall',
    placeholder: 'Reason for the recall',
  },
};

export const validationSchema = (t: TFunction) => Yup.object().shape({});

export const initialValues = {
  [model.date.name]: dayjs().add(1, 'day').format(DefaultDateFormat),
  [model.reason.name]: '',
} as ModelType;
