import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button';
import { ModalMain } from '../main/main';

import { ReactComponent as InfoIcon } from 'assets/images/information.svg';

import useSigner from 'client/hooks/use-signer';
import useProcessXslt from 'hooks/use-process-xslt';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { usePoaRevokeMutation } from 'store/api/poa';
import { selectDocumentContent } from 'store/reducers/attorney.revoke';
import { useHandleMutation } from 'utils/handleMutation';
import s from './revocation-xslt.module.scss';

interface Props {
  isVisible: boolean;
  onClose?: () => void;
}

export const RevocationXsltModal = ({ isVisible, onClose }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const documentContent = useSelector(selectDocumentContent());

  const { handleSign, isLoading: isSignerLoading } = useSigner();
  const { data: xsltPreview } = useProcessXslt({ base64: documentContent ?? '' });

  const [previewRevoke, mutation] = usePoaRevokeMutation();
  const isLoading = mutation.isLoading;

  const onSuccess = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  useHandleMutation({ ...mutation, onSuccess });

  const onSubmit = async () => {
    await handleSign({
      signData: documentContent ?? '',
      onSubmit: async ({ signature, detachedSignature }) => {
        await previewRevoke({ id: id ?? '', signature, detachedSignature });
      },
    });
  };

  const classesCancel = cx(s['button'], s['cancel']);
  const classesConfirm = cx(s['button'], s['confirm']);

  return (
    <ModalMain
      title="Revocation of the power of attorney"
      isVisible={isVisible}
      closeOnClick={onClose}
      footer={
        <div className={s['button-wrapper']}>
          <Button
            displayType="outlined-secondary"
            text={t('Cancel')}
            className={classesCancel}
            onClick={onClose}
          />
          <Button
            disabled={isSignerLoading}
            text={t(isLoading ? 'Loading...' : 'Sign')}
            className={classesConfirm}
            onClick={onSubmit}
          />
        </div>
      }
    >
      <div className={s['body']}>
        <div className={s['wrapper']}>
          <div className={s['wrapper-info']}>
            <div>
              <InfoIcon />
            </div>
            <span className={s['text-info']}>{t('Please sign the recall order')}</span>
          </div>

          <div>
            {documentContent && xsltPreview && (
              <span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: new XMLSerializer().serializeToString(xsltPreview as any),
                  }}
                />
              </span>
            )}
          </div>
        </div>
      </div>
    </ModalMain>
  );
};
