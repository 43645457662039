import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BreadcrumbsProps, ItemRender } from 'constants/routers-breadcrumbs';

import s from './styles.module.scss';

const Breadcrumbs = ({ items }: { items: BreadcrumbsProps[] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const itemRender: ItemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;

    const handleClick = () => {
      if (!last) {
        navigate(paths.join('/'));
      }
    };

    return last ? (
      <div className={s['link']}>
        <span>{t(route.title)}</span>
      </div>
    ) : (
      <button
        className={s['link']}
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleClick();
          }
        }}
      >
        <span>{t(route.title)}</span>
      </button>
    );
  };

  return (
    <nav className={s['breadcrumbs']}>
      {items?.map((item) => (
        <span key={item.id.toString()} className={s['breadcrumbs-item']}>
          {itemRender(item, {}, items, item.path.split('/'))}
        </span>
      ))}
    </nav>
  );
};

export default Breadcrumbs;
