import { gql } from 'graphql-request';

export const GET_CORE_DICTIONARIES_BANKS = gql`
  query CoreOrganizations {
    coreOrganizations {
      queryBanks {
        organizations {
          id
          name
          status
          type
          countryCode
          createdAt
          updatedAt
        }
      }
    }
  }
`;
