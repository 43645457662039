import * as Yup from 'yup';

const handleLanguage = (value, lang) => {
  if ((typeof value === 'object' && value?.en) || value?.ru) {
    return value[lang];
  }
  return value;
};

export function generateModelFromScheme(scheme, lang = 'ru') {
  const model = {};

  scheme?.forEach((item) => {
    const key = item.name;
    model[key] = Object.entries(item).reduce((obj, [k, v]) => {
      obj[k] = handleLanguage(v, lang);

      return obj;
    }, {});
  });

  return model;
}

export const applyValidation = (validator, rule) => {
  const [method, value] = rule.split('-');

  switch (method) {
    case 'string':
      validator = validator.string();
      break;
    case 'number':
      validator = validator.number();
      break;
    case 'required':
      validator = validator.required('This field is required');
      break;
    case 'max':
      validator = validator.max(Number(value), `Maximum ${value} characters`);
      break;
    case 'min':
      validator = validator.min(Number(value), `Minimum ${value} characters`);
      break;
    case 'onlyNumbers':
      validator = validator.matches(/^[0-9]+$/, 'Contract field must contain only numbers');
      break;
    case 'onlyLetters':
      validator = validator.matches(
        /^[A-Za-zА-Яа-я\s]+$/,
        'Place of conclusion must contain only letters',
      );
      break;
    default:
      break;
  }

  return validator;
};

export const generateValidationSchema = (scheme, step) => {
  const validationObj = {};

  const filtered = scheme?.filter((item) => item.step === step);

  filtered?.forEach((field) => {
    if (field.validation && field.name) {
      const rules = field.validation.split('.');

      const validator = rules.reduce((acc, rule) => applyValidation(acc, rule), Yup);

      validationObj[field.name] = validator;
    }
  });

  return Yup.object().shape(validationObj);
};

function removeDuplicates(arr) {
  let uniqueArr = [];
  for (let i = 0; i < arr?.length; i++) {
    if (uniqueArr.indexOf(arr[i]) === -1) {
      uniqueArr.push(arr[i]);
    }
  }

  return uniqueArr;
}

export const parseSteps = (scheme) => {
  const stepsArray = scheme?.map((field) => {
    return field.step;
  });

  const stepsLabels = scheme?.map((field) => {
    return field.stepLabel;
  });

  return {
    steps: stepsArray ? removeDuplicates(stepsArray) : [],
    stepsLabels: stepsLabels ? removeDuplicates(['', ...stepsLabels]) : [],
  };
};
