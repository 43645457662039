import cx from 'classnames';
import { selectInvitationsType, setInvitationType } from 'store/reducers/invite.list';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/ui/Button';

import { AppRoutes } from 'config/routes';
import { useNavigate } from 'react-router-dom';
import s from './Tabs.module.scss';

const varinatMessage = {
  incoming: 'incoming',
  outgoing: 'outgoing',
};

const defaultTabs = [
  {
    id: 0,
    name: 'Incoming',
    type: 'incoming',
    variant: varinatMessage.incoming,
    url:
      AppRoutes.Dashboard.Home +
      AppRoutes.Dashboard.Counterparties.Home +
      AppRoutes.Dashboard.Counterparties.Invitations,
  },
  {
    id: 1,
    name: 'Outgoing',
    type: 'outgoing',
    variant: varinatMessage.outgoing,
    url:
      AppRoutes.Dashboard.Home +
      AppRoutes.Dashboard.Counterparties.Home +
      AppRoutes.Dashboard.Counterparties.Invitations,
  },
];

type Props = {
  tabs?: typeof defaultTabs;
  leftCount: number;
  rightCount: number;
};

export const Tabs = ({ tabs = defaultTabs, leftCount = 0, rightCount = 0 }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const type = useSelector(selectInvitationsType);

  const incomingClasses = cx(s['button'], {
    [s['incoming']]: type === varinatMessage.incoming,
  });
  const incomingCountClasses = cx(s['count'], {
    [s['count-incoming']]: type === varinatMessage.incoming,
  });

  const outgoingClasses = cx(s['button'], {
    [s['outgoing']]: type === varinatMessage.outgoing,
  });
  const outgoingCountClasses = cx(s['count'], {
    [s['count-outgoing']]: type === varinatMessage.outgoing,
  });

  return (
    <div className={s['container']}>
      {tabs?.map((item) => (
        <Button
          removeRoot
          type="null"
          className={item.type === varinatMessage.incoming ? incomingClasses : outgoingClasses}
          onClick={() => {
            dispatch(setInvitationType(item.type));
            navigate(item.url);
          }}
          key={item.id}
        >
          <div className={s['wrapper-content']}>
            <span>{t(item.name)}</span>
            <span
              className={
                item.type === varinatMessage.incoming
                  ? incomingCountClasses
                  : outgoingCountClasses
              }
            >
              {item.type === varinatMessage.incoming ? leftCount : rightCount}
            </span>
          </div>
        </Button>
      ))}
    </div>
  );
};
