import JCWebclientApi from 'client/api/jc-webclient-api';
import { VipnetApi } from 'client/api/vipnet-api';
import { Certificate, TemplateData } from 'client/models';
import CryptoProSigner from 'client/providers/cryptopro-client';
import JCWebClient from 'client/providers/jc-webclient';
import MockSigner from 'client/providers/mock-client';
import VipnetSigner from 'client/providers/vipnet-client';
import { useEffect, useState } from 'react';
import { getMockClientStatus } from 'utils/mock-client';

type Constructable<T> = new (...args: any[]) => T;
type LssCLientType = ReturnType<typeof VipnetApi>;

export const useClient = () => {
  const [client, setClient] = useState<any>();
  const [clientType, setClientType] = useState('none');

  const checkMockClient = () => {
    return getMockClientStatus();
  };
  const checkVPIConnection = async () => {
    const client = new (VipnetApi as unknown as Constructable<LssCLientType>)();
    const response = await client.checkConnection();
    return !!response?.Version || response.ErrorMessage === 'Service is busy.';
  };

  const checkJCWebClient = () => {
    try {
      const jcWebClientApi = new JCWebclientApi();
      const version = jcWebClientApi.getJCWebClientVersion();

      return version;
    } catch (error) {
      return false;
    }
  };

  const checkCryptoProConnection = async () => {
    try {
      await (window as any)?.cadesplugin;
      return true;
    } catch (error) {
      return false;
    }
  };

  const isMockClientAvailable = () => {
    try {
      const mockClient = new MockSigner();
      return [mockClient, getMockClientStatus()];
    } catch (error) {
      return [null, false];
    }
  };

  const isJCWebClientAvailable = async () => {
    try {
      const jcWebClientApi = new JCWebclientApi();
      const jcWebClient = new JCWebClient(jcWebClientApi);

      await new Promise((resolve) => setTimeout(resolve, 5000));

      const isConnected = jcWebClientApi.getSlots()?.length;

      return [jcWebClient, !!isConnected];
    } catch (error) {
      return [null, false];
    }
  };

  const isVipNetAvailable = async () => {
    try {
      const vipnetApi = new (VipnetApi as unknown as Constructable<LssCLientType>)();
      const response = await vipnetApi.checkConnection();
      const client = new VipnetSigner(vipnetApi);
      return response && response.Version !== null ? [client, true] : [null, false];
    } catch (error) {
      return [null, false];
    }
  };
  const isCryptoProAvailable = () => {
    try {
      return (window as any)?.cadesplugin
        ? [(window as any)?.cadesplugin, true]
        : [null, false];
    } catch (error) {
      return [null, false];
    }
  };

  const checkClient = async () => {
    const [mockClient, isAvailableMock] = isMockClientAvailable();
    if (isAvailableMock) {
      setClient(mockClient);
      setClientType('mockclient');
      return;
    }

    const [jcWebClient, isAvailableJcWebClient] = await isJCWebClientAvailable();
    if (isAvailableJcWebClient && jcWebClient) {
      setClient(jcWebClient);
      setClientType('jcwebclient');
      return;
    }

    const [vipNetClient, isAvailableVPI] = await isVipNetAvailable();
    if (isAvailableVPI) {
      setClient(vipNetClient);
      setClientType('vipnetclient');
      return;
    }

    const [isAvailableCryptoPro] = await isCryptoProAvailable();

    if (isAvailableCryptoPro) {
      setClient(new CryptoProSigner());
      setClientType('cryptoproclient');
      return;
    }
  };

  useEffect(() => {
    checkClient();
  }, []);

  const signXml = async ({
    props,
    certificate,
    dataForSign,
  }: {
    certificate: Certificate;
    dataForSign: string;
    props?: Partial<TemplateData>;
  }): Promise<{ signature: string; detachedSignature: string }> => {
    return await client.signXml({ certificate, dataForSign, props });
  };

  const sign = async ({
    certificate,
    dataForSign,
  }: {
    certificate: Certificate;
    dataForSign: string;
  }): Promise<string> => {
    const response = await client.sign({
      certificate,
      dataForSign,
    });
    return response;
  };

  const getCertificates = async () => {
    const data = (await client?.getCertificates()) as Certificate[];

    return data;
  };

  return {
    sign,
    client,
    clientType,
    signXml,
    checkClient,
    getCertificates,
    checkMockClient,
    checkJCWebClient,
    checkVPIConnection,
    checkCryptoProConnection,
  };
};
