export const Report = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="98"
      height="98"
      viewBox="0 0 98 98"
      fill="none"
      {...props}
    >
      <path
        d="M26.5416 53.083C33.3071 53.083 38.7916 47.5985 38.7916 40.833C38.7916 34.0675 33.3071 28.583 26.5416 28.583C19.7761 28.583 14.2916 34.0675 14.2916 40.833C14.2916 47.5985 19.7761 53.083 26.5416 53.083Z"
        stroke="#C4CED7"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M46.9588 68.3958C46.9588 64.4401 43.7687 61.25 39.8129 61.25H13.2713C9.31553 61.25 6.12543 64.4401 6.12543 68.3958V91.875H46.9588V68.3958Z"
        stroke="#C4CED7"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M55.1252 75.5412H89.8335V26.5412L69.4169 6.12451H30.6252V20.4162"
        stroke="#C4CED7"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M69.4171 6.12451V26.5412H89.8337"
        stroke="#C4CED7"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M81.6665 36.7505H46.9581"
        stroke="#C4CED7"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M81.6665 46.958H46.9581"
        stroke="#C4CED7"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
      <path
        d="M81.6669 57.1655H51.0419"
        stroke="#C4CED7"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
