import cx from 'classnames';
import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { controlError } from 'utils/controlError';

import { ReactComponent as UploadIcon } from 'assets/images/upload.svg';

import s from './upload-image-field.module.scss';

import { Upload, UploadProps } from 'antd';
import { ErrorLabel } from 'components/error-label';
import { FieldLabel } from 'components/field-label';
import { Button } from 'components/ui/Button';

export interface UploadImageFieldProps {
  name: string;
  label?: string;
  isModal?: boolean;
  placeholder?: string;
}

// Define a type for the file object
interface FileListItem {
  uid: string;
  name: string;
  status: 'done' | 'uploading' | 'error';
  originFileObj: File;
}

export const UploadImageField = ({
  name,
  label,
  isModal,
}: UploadImageFieldProps): JSX.Element => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const [fileList, setFileList] = useState<FileListItem[]>([]);

  useEffect(() => {
    if (field.value) {
      const fileListItem: FileListItem = {
        uid: field.value.uid,
        name: field.value.name,
        status: 'done',
        originFileObj: field.value,
      };
      setFileList([fileListItem]);
    } else {
      setFileList([]);
    }
  }, [field.value]);

  const error = t(controlError(meta, name) as string);

  const settingStyles = {
    borderRadius: '0px',
    background: 'transparent',
  };

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    fileList: fileList as unknown as UploadProps['fileList'],
    beforeUpload: (file) => {
      const fileExt = (file?.name.split('.').pop() || '').toLowerCase();
      const dangerousExtensions = ['exe', 'bat', 'cmd', 'sh'];
      if (dangerousExtensions.includes(fileExt)) {
        return Upload.LIST_IGNORE;
      }
      return false;
    },
    onChange(info) {
      if (
        info.file.status !== 'done' &&
        info.file.status !== 'uploading' &&
        info.file.status !== 'removed'
      ) {
        helpers.setValue(info.file);
      }
    },
    onRemove: () => {
      helpers.setValue(null);
      setFileList([]);
    },
  };

  return (
    <div>
      <FieldLabel label={label} />
      <Upload.Dragger
        {...props}
        style={isModal ? { ...settingStyles } : {}}
        className={cx(s['wrapper-upload'], {
          [s['wrapper-modal']]: isModal,
        })}
      >
        <p className={s['wrapper-upload-icon']}>
          <UploadIcon />
        </p>
        <p className={s['upload-text']}>{t('Drag and drop files or select it')}</p>
        {/* <p className={s['upload-hint']}>{t('Supporting file types: xml')}</p> */}
        <div className={s['wrapper-button']}>
          <Button text={t('Select file')} className={s['button']} />
        </div>
      </Upload.Dragger>
      <ErrorLabel error={error} />
    </div>
  );
};
