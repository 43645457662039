import { UsersList } from 'components/sidenav-message/invitations/UsersList';

import { AppRoutes } from 'config/routes';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useGetStaffIncomingInvitationsListQuery,
  useGetStaffOutgoingInvitationsListQuery,
} from 'store/api/staff';
import { StaffInvite } from 'store/api/types/staff';
import { useAppDispatch } from 'store/hooks';
import { selectInvitationsType, setInvitationType } from 'store/reducers/invite.list';
import s from './Invitations.module.scss';
import { InviteInfo } from './InviteInfo/InviteInfo';
import { CounterpartyType } from 'constants/attributes';

export const Invitations = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const type = useSelector(selectInvitationsType);

  const [selected, setSelected] = useState<StaffInvite | undefined>();

  const { data: outgoing = [] } = useGetStaffOutgoingInvitationsListQuery();
  const { data: incoming = [] } = useGetStaffIncomingInvitationsListQuery();

  const parseInvite = (item: StaffInvite | undefined) => {
    return {
      ...item,
      name: item?.inviteeEmployee?.firstName,
      partyOrganization: {
        ...item?.inviteeEmployee,
        type: CounterpartyType.Entrepreneur,
        name: `${item?.inviteeEmployee?.lastName} ${item?.inviteeEmployee?.firstName} ${item?.inviteeEmployee?.middleName}`,
        attributes: item?.inviteeEmployee?.attributes ?? [],
      },
    };
  };

  useEffect(() => {
    let currentType;
    let current;

    if (incoming?.find((item) => item.id === id)) {
      current = incoming?.find((item) => item.id === id);
      currentType = 'incoming';
    }

    if (outgoing?.find((item) => item.id === id)) {
      current = outgoing?.find((item) => item.id === id);
      currentType = 'outgoing';
    }

    if (currentType) {
      dispatch(setInvitationType(currentType));
    }

    if (type === 'incoming' && incoming.length > 0 && !id) {
      current = incoming[incoming.length - 1];
      setSelected(current);
    }
    if (type === 'outgoing' && outgoing.length > 0 && !id) {
      current = outgoing[outgoing.length - 1];
      setSelected(current);
    }

    setSelected(current);
  }, [id, outgoing, incoming, type, dispatch]);

  const currentData = useMemo(() => {
    return type === 'incoming' ? incoming : outgoing;
  }, [type, outgoing, incoming]);

  return (
    <div className={s['container']}>
      <UsersList
        type={'invites'}
        customAttributes={['snils']}
        selected={selected as any}
        leftCount={incoming.length}
        rightCount={outgoing.length}
        onItemClick={(item: any) => {
          setSelected(item);
          return void 0;
        }}
        data={currentData?.map((item) => parseInvite(item)) as any}
        tabs={[
          {
            id: 0,
            name: 'Incoming',
            type: 'incoming',
            variant: 'incoming',
            url:
              AppRoutes.Dashboard.Home +
              AppRoutes.Dashboard.Organizations.Home +
              AppRoutes.Dashboard.Organizations.Staff +
              AppRoutes.Dashboard.Organizations.Invites,
          },
          {
            id: 1,
            name: 'Outgoing',
            type: 'outgoing',
            variant: 'outgoing',
            url:
              AppRoutes.Dashboard.Home +
              AppRoutes.Dashboard.Organizations.Home +
              AppRoutes.Dashboard.Organizations.Staff +
              AppRoutes.Dashboard.Organizations.Invites,
          },
        ]}
      />
      <InviteInfo currentInvite={parseInvite(selected) as any} />
    </div>
  );
};
