import { useTranslation } from 'react-i18next';
import { model } from 'components/attorney/forms/config';

import { InputField } from 'components/common';
import { SelectField } from 'components/fields/select-fields/select-filed';

import s from './payment-procedure.module.scss';

export const PaymentProcedureGroup = () => {
  const { t } = useTranslation();

  return (
    <div className={s['container']}>
      <span className={s['title']}>{t('Information about the organization')}</span>
      <div className={s['wrapper-content']}>
        {/* <SelectField {...model.paymentProcedure} /> */}
        <InputField {...model.nameCompanyAddingRepresentative} label="Наименование Организации"/>
        <InputField {...model.inn} />
        <InputField {...model.kppAddingRepresentative} />
        <InputField {...model.ogrn} />
      </div>
    </div>
  );
};
