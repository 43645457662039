import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button';
import { ModalMain } from '../main/main';

import s from './styles.module.scss';
import { useAlert } from 'react-alert';
import { useDeleteDocumentMutation } from 'store/api/organizationDocuments';
import { useCallback } from 'react';
import { useHandleMutation } from 'utils/handleMutation';

interface Props {
  documentId?: string;
  isVisible: boolean;
  setVisible: (value: boolean) => void;
}

export const DeleteDocumentModal = ({ documentId, isVisible, setVisible }: Props) => {
  const { t } = useTranslation();
  const alert = useAlert();

  const [deleteDocument, mutation] = useDeleteDocumentMutation();

  const onSuccess = useCallback(() => {
    alert.success('Документ успешно удален');
    setVisible(false);
  }, []);

  const onError = useCallback(() => {
    alert.error('Произошла ошибка при удалении документа');
  }, []);

  useHandleMutation({ ...mutation, onSuccess, onError });

  const onSubmit = () => {
    if (!documentId) return;

    deleteDocument({ id: documentId });
    setVisible(false);
  };

  return (
    <ModalMain
      title="Удаление документа"
      isVisible={isVisible}
      closeOnClick={() => setVisible(false)}
      footer={
        <div className={s['button-wrapper']}>
          <Button
            text={t('Cancel')}
            className={cx(s['button'])}
            displayType="outlined-primary"
            onClick={() => setVisible(false)}
          />
          <Button type="cancel" onClick={onSubmit} text={t('Delete')} className={s['button']} />
        </div>
      }
    >
      <span>Подтвердите удаление документа</span>
    </ModalMain>
  );
};
