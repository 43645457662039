import { fromBase64, removeExtraSymbolsFromBase64, toBase64 } from 'helpers';
import MockSigner from './mock-client';
import TemplateSignerBase from './template-signer-base';

import { Certificate, TemplateData } from 'client/models';

export default class JCWebClient extends TemplateSignerBase {
  private client;

  constructor(client: any) {
    super();
    this.client = client;
  }

  parseCertificateSubject(subject: any) {
    const subjectParts = subject.split(/,\s*/);
    const subjectObj = {};

    subjectParts.forEach((part: any) => {
      const [key, value] = part.split('=');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      subjectObj[key.trim()] = value.trim();
    });

    return subjectObj;
  }

  arrayBufferToBase64(buffer: any) {
    return new Promise((resolve, reject) => {
      const blob = new Blob([buffer]);
      const reader = new FileReader();

      reader.onloadend = function () {
        const base64data = (reader?.result ?? '')?.toString().split(',')[1];
        resolve(removeExtraSymbolsFromBase64(base64data?.toString()));
      };

      reader.onerror = reject;

      reader.readAsDataURL(blob);
    });
  }

  async getCertificates() {
    try {
      const certList: any[] = this.client.getCertificatesList();

      const certificates = await Promise.all(
        certList.map(async (item, index) => {
          const cert = item.certificate;
          const Subject: any = this.parseCertificateSubject(cert.subject);
          const rawData = await this.arrayBufferToBase64(cert.tbs);

          return {
            FriendlyName: Subject.CN,
            Thumbprint: cert.serialNumber,
            Base64RawData: item.base64Body,
            Subject: {
              ИНН: Subject['2.5.4.5'] ?? '',
              Владелец: Subject.CN,
              Город: Subject.L ?? '-',
              Регион: Subject.C,
              Компания: Subject.O,
              ОГРН: '1027739057356',
              ИННЮЛ: Subject['2.5.4.5'] ?? '',
              СНИЛС: '77090932551',
              Улица: '',
              Фамилия: Subject.CN,
              'Имя/Отчество': Subject.CN ?? '',
              Должность: Subject.T,
            },
          };
        }),
      );

      return certificates;
    } catch (error) {
      console.error(error);
    }
  }

  async sign({
    certificate,
    dataForSign,
  }: {
    certificate: Certificate;
    dataForSign: string;
    signDate?: string;
  }): Promise<string> {
    const itemsList = this.client.getCertificatesListWithContainers();

    const currentContainer = itemsList.find(
      (item: any) => item?.certificate.serialNumber === certificate.Thumbprint,
    )?.container;

    if (!currentContainer) {
      throw new Error('Certificate not found');
    }

    const result = this.client.sign({
      dataForSign: fromBase64(dataForSign),
      contID: currentContainer?.id,
    });

    return toBase64(result);
  }

  async signXml({
    props,
    dataForSign,
    certificate,
  }: {
    dataForSign: string;
    certificate: Certificate;
    props?: Partial<TemplateData>;
  }): Promise<{ signature: string; detachedSignature: string }> {
    const itemsList = this.client.getCertificatesListWithContainers();

    const currentContainer = itemsList.find(
      (item: any) => item?.certificate.serialNumber === certificate.Thumbprint,
    )?.container;

    if (!currentContainer) {
      throw new Error('Certificate not found');
    }

    const replcaementLabels = new Map([
      ['ИНН', 'INN'],
      ['Владелец', 'NONE'],
      ['Город', 'L'],
      ['Регион', 'S'],
      ['Компания', 'CN'],
      ['ОГРН', 'OGRN'],
      ['ИННЮЛ', 'INNLE'],
      ['Улица', 'STREET'],
      ['Фамилия', 'NONE'],
      ['Имя/Отчество', 'G'],
      ['Должность', 'T'],
      ['SN', 'NONE'],
    ]);

    const xmlToSign = await this.addSignatureTemplate(
      dataForSign,
      certificate,
      props,
      replcaementLabels,
    );

    const { signature, data } = this.client.signBase64EncodedData({
      dataForSignBase64: xmlToSign,
      contID: currentContainer?.id,
    });

    return { signature: data, detachedSignature: signature };
  }
}
