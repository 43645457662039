import cx from 'classnames';
import { AlertTemplateProps } from 'react-alert';
import { Provider as AlertProvider, positions, transitions } from 'react-alert';

import { Icon } from './config';
import { ReactComponent as CloseIcon } from 'assets/images/alert-close.svg';

import s from './alert.module.scss';

const alertOptions = {
  timeout: 5000,
  offset: '30px',
  transition: transitions.FADE,
  position: positions.BOTTOM_CENTER,
};

const Alert = ({ close, message, options }: AlertTemplateProps): JSX.Element => {
  const { type } = options;

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      close();
    }
  };

  const containerClasses = cx(s['alert-container'], {
    [s['error']]: type === 'error',
    [s['success']]: type === 'success',
  });

  return (
    <div className={containerClasses}>
      <div className={s['content-container']}>
        {type && <div className={s['icon-container']}>{Icon[type]}</div>}
        <div className={s['text-container']}>{message}</div>
      </div>

      <div
        role="button"
        tabIndex={0}
        onClick={close}
        className={s['icon-close']}
        onKeyDown={handleKeyPress}
      >
        <CloseIcon />
      </div>
    </div>
  );
};

export const GlobalAlertProvider = ({ children }: { children: any }) => (
  <AlertProvider template={Alert} {...alertOptions}>
    {children}
  </AlertProvider>
);

export { Alert };
