import { CERTIFICATE_STATE_KEY } from 'modules/auth';
import { CertificatesInitialState } from 'store/reducers/certificates';
import { getCertClass } from './certClass';

export const saveCertificateState = (item: any) => {
  localStorage.setItem(CERTIFICATE_STATE_KEY, JSON.stringify(item));
};

export const getCertificateState = (): CertificatesInitialState => {
  return JSON.parse(localStorage.getItem(CERTIFICATE_STATE_KEY) ?? '{}');
};

export const isCertificatePerson = () => {
  const certClass = getCertClass();

  return certClass === 'person';
};

export const generateGuid = () => {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
  return uuid;
};
