import { NotFound } from 'components/not-found';
import dayjs from 'dayjs';
import { DefaultDateFormat } from 'utils/date';
import s from './styles.module.scss';
import cx from 'classnames';
import { AppDocument } from 'store/api/types/documents';

export const DocumentHeaderInfo = ({
  document,
  isLongTitle,
}: {
  document: AppDocument;
  isLongTitle?: boolean;
}) => {
  if (!document) {
    return <NotFound title="Document not found" notFoundTitle="" hasSearch={false} />;
  }

  return (
    <div className={s['wrapper-title']}>
      <span className={cx(s['title'], { [s['title-long']]: isLongTitle })}>
        {document?.title}
        <span className={s['date']}></span>
        <span className={s['number']}>{` №${document?.number}`}</span>
        <span className={s['date']}>
          {` от ${dayjs(document?.createdAt).format(DefaultDateFormat)}`}
        </span>
      </span>
    </div>
  );
};
