import { gql } from 'graphql-request';

export const CREATE_DOCUMENT = gql`
  mutation App70e9b8a9_Document($input: app70e9b8a9_DocumentMutationNS_createInputInput!) {
    app70e9b8a9_Document {
      create(input: $input) {
        id
      }
    }
  }
`;
