import { Icons } from 'components/icons';
import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';
import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { usePoaGetLinkForAuthorityQuery } from 'store/api/poa';
import { selectInviteId } from 'store/reducers/invite.conteragent';
import { useCopyToClipboard } from 'usehooks-ts';
import s from './styles.module.scss';

export function PoaRequest() {
  const navigate = useNavigate();
  const { id: draftId } = useParams<{ id: string }>();

  const { Auth } = AppRoutes;

  const inviteId = useSelector(selectInviteId());

  const { t } = useTranslation();
  const alert = useAlert();
  const [, copy] = useCopyToClipboard();

  const { data: url, isFetching } = usePoaGetLinkForAuthorityQuery(
    { inviteId, poaId: draftId ?? '' },
    { skip: !draftId || !inviteId },
  );

  useEffect(() => {
    if (!inviteId) {
      navigate(Auth.Home + Auth.Login);
      alert.error('Приглашение не найдено');
    }
  }, [inviteId]);

  const handleClick = () => {
    const params = new URLSearchParams(url?.split('?')?.[1]);
    const accessToken = params.get('access_token');
    const link = `${window.location.origin}?poaId=${draftId}&access_token=${accessToken}`;
    copy(link);
    alert.success(t('Copied'));
  };

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.firstBlock}>
          <div className={s.title}>
            {t(
              'Contracteum ensures the confidentiality of transmitted information and guarantees the legal significance of transactions',
            )}
          </div>
        </div>

        <div className={s.secondBlock}>
          <div className={s.info}>
            <div className={s.infoHeader}>
              <Icons.SuccessMark />
              <span className={s.description}>
                {t(
                  'Thank you, the machine-readable power of attorney (MPOA) draft has been successfully generated.',
                )}
              </span>
            </div>

            <span className={s.description}>
              {t(
                'The final step is to sign the MPOA. Please send the link below to your organizations CEO',
              )}
            </span>
          </div>

          <div className={s.buttonContainer}>
            <Button
              type="primary"
              disabled={isFetching}
              className={s.button}
              onClick={handleClick}
            >
              {t('Copy link to clipboard')}
            </Button>
          </div>
        </div>
      </div>

      <div className={s.bottom}></div>
    </div>
  );
}
