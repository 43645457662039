import { Route, Routes } from 'react-router-dom';

import { InvitationSearch } from '../invitation-search/invitation-search';
import { InvitationOrganization } from './invitation-organization';

import { NdaContractPreview } from 'components/contracts/nda-preview/NdaContractPreview';
import { AppRoutes } from 'config/routes';
import { counterpartiesInvitatRoutes } from 'constants/routers-breadcrumbs';
import { useSelector } from 'react-redux';
import { selectCounterpartyId } from 'store/reducers/invite.conteragent';

export const InvitationContent = () => {
  const { Invitation } = AppRoutes.Dashboard.Counterparties;

  const currentCounterpartyId = useSelector(selectCounterpartyId());

  return (
    <Routes>
      <Route path="/*" element={<InvitationSearch />} />
      <Route path="/:id" element={<InvitationOrganization />} />
      <Route
        path={Invitation.Agreement + '/:id'}
        element={
          <NdaContractPreview
            counterpartyId={currentCounterpartyId}
            title="Поиск и приглашение контрагента"
            breadItems={counterpartiesInvitatRoutes}
          />
        }
      />
    </Routes>
  );
};
