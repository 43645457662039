import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { AppRoutes } from 'config/routes';
import { useNavigate } from 'react-router-dom';
import { PoaDraft } from 'store/api/types';

import s from './AttorneyCard.module.scss';
import { useSelector } from 'react-redux';
import { selectAttorneysType, setAttorneysType } from 'store/reducers/attorney.list';
import { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import dayjs from 'dayjs';
import { DefaultDateFormat } from 'utils/date';
import { useNavigateWithCurrentParams } from 'hooks';

export const AttorneyCard = ({
  data,
  current,
}: {
  data: PoaDraft;
  current?: string | PoaDraft | null | undefined;
}) => {
  const urlParams = new URLSearchParams(location.search);
  const representativeEmployeeID = urlParams.get('representativeEmployeeID');

  const classesUser = cx(s['wrapper-user'], {
    [s['recalled-user']]: false,
  });

  const classesWrapper = cx(s['wrapper-card'], {
    [s['current']]: current === data?.id,
  });

  const dispatch = useAppDispatch();

  const currentType = useSelector(selectAttorneysType);

  const { Home, PowersAttorney } = AppRoutes.Dashboard;

  const { t } = useTranslation();
  const navigate = useNavigateWithCurrentParams();

  const handleClick = (id: string) => {
    if (currentType === 'drafts') {
      dispatch(setAttorneysType('active'));
      navigate(Home + PowersAttorney.Home + PowersAttorney.NewAttorney + `/${id}`);
      return;
    }
    navigate(Home + PowersAttorney.Home + `/${currentType}` + `/${id}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, id: string) => {
    if (event.key === 'Enter') {
      handleClick(id);
    }
  };

  const authorityInfo = useMemo(() => {
    try {
      return JSON.parse(data?.authority?.rawUserInfo);
    } catch (error) {
      return null;
    }
  }, [data?.authority?.rawUserInfo]);

  const representativeInfo = useMemo(() => {
    try {
      return JSON.parse(data?.representative?.rawUserInfo);
    } catch (error) {
      return null;
    }
  }, [data?.representative?.rawUserInfo]);

  const formatDate = (date: string) => {
    return dayjs(date)?.format(DefaultDateFormat);
  };

  const isActive = data?.status === 'active';
  const isDraft = data?.status === 'draft';
  const isArchived = data?.status === 'archived';
  const isRevoked = data?.status === 'revoked';

  return (
    <div
      tabIndex={0}
      role="button"
      className={s['container']}
      onClick={() => handleClick(data.id)}
      onKeyDown={(e) => handleKeyDown(e, data.id)}
    >
      <div className={classesWrapper}>
        <span className={s['date']}>
          {t('от')} {formatDate(data.createdAt?.replaceAll('-', '.'))}
        </span>
        <div className={classesUser}>
          {(isActive || isRevoked) && representativeInfo && (
            <span className={s['name']}>
              {representativeInfo?.organization_name?.length
                ? representativeInfo?.organization_name
                : `${representativeInfo?.name}  ${representativeInfo?.surname}`}
            </span>
          )}
          {!isActive && !isRevoked && authorityInfo && (
            <span className={s['name']}>
              {authorityInfo?.organization_name ??
                `${authorityInfo?.name}  ${authorityInfo?.surname}`}
            </span>
          )}
          {!isDraft && !isArchived && data.expirationDate && (
            <span className={s['range']}>{`${formatDate(
              data.issueDate?.replaceAll('-', '.'),
            )} - ${formatDate(data.expirationDate.replaceAll('-', '.'))}`}</span>
          )}
        </div>

        {data?.recipientOrganization && (
          <>
            <span className={s['recipient']}>{t('Получатель')}</span>
            <span className={s['company']}>
              {data?.recipientOrganization?.name?.length
                ? data?.recipientOrganization?.name
                : data?.recipientOrganization?.id}
            </span>
          </>
        )}

        <>
          <span className={s['recipient']}>{t('Номер доверенности')}</span>
          {<span className={s['id']}>{data.number}</span>}
        </>

        {isRevoked && (
          <>
            <span className={s['recipient']}>{t('Дата отзыва')}</span>
            <span className={s['id']}>
              {formatDate(data?.revocationDate?.replaceAll('-', '.') ?? 'Неопределено')}
            </span>
          </>
        )}
      </div>
    </div>
  );
};
