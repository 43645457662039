import { useTranslation } from 'react-i18next';

import s from './field-label.module.scss';

import { ReactComponent as RequiredIcon } from 'assets/images/required.svg';

type FieldLabelProps = {
  label?: string;
  isRequired?: boolean;
};

export const FieldLabel = ({ label, isRequired }: FieldLabelProps) => {
  const { t } = useTranslation();

  return (
    <div className={s['wrapper-label']}>
      <span className={s['label']}>{t(label ?? '')}</span>
      {isRequired && <RequiredIcon />}
    </div>
  );
};
