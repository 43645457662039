import { gql } from 'graphql-request';

export const CREATE_LICENSE_DOCUMENT = gql`
  mutation App5705572d_Document($input: app5705572d_DocumentMutationNS_createInputInput!) {
    app5705572d_Document {
      create(input: $input) {
        id
      }
    }
  }
`;
