import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: '',
  type: 'incoming',
};

const inviteListSlice = createSlice({
  name: 'inviteList',
  initialState,
  reducers: {
    setInvitationType(state, action) {
      state.type = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { setInvitationType, setStatus } = inviteListSlice.actions;

export default inviteListSlice.reducer;

const selectStore = (store: any) => store.inviteList;

export const selectStatus = createSelector([selectStore], (store) => store.status);
export const selectInvitationsType = createSelector([selectStore], (store) => store.type);
