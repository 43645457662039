import { gql } from 'graphql-request';

export const INVITE_STAFF = gql`
  mutation CoreEmployeeInvitations(
    $participantData: core_EmployeeInvitationMutationNS_inviteUnregisteredEmployeeInputInput!
    $role: core_EmployeeRole!
    $type: core_EmployeeType!
    $position: String!
  ) {
    coreEmployeeInvitations {
      invite(
        input: {
          participantData: $participantData
          role: $role
          type: $type
          position: $position
        }
      )
    }
  }
`;
