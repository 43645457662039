import { createSlice } from '@reduxjs/toolkit';
import { sublicenseContract } from '../default-store-values/sublicense-contract-default-state';

const sublicenseContractSlice = createSlice({
  name: 'contract/sublicense',
  initialState: sublicenseContract,
  reducers: {
    setGeneral: (state, action) => {
      state.general = action.payload;
    },
    setSpecification: (state, action) => {
      state.specification = action.payload;
    },
    setFirstParty: (state, action) => {
      state.firstParty = action.payload;
    },
    setSecondParty: (state, action) => {
      state.secondParty = action.payload;
    },
    resetSublicenseState: () => sublicenseContract,
  },
});

export default sublicenseContractSlice.reducer;
export const { resetSublicenseState, setFirstParty, setGeneral, setSecondParty, setSpecification } =
  sublicenseContractSlice.actions;
