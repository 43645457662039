import cx from 'classnames';
import { Formik, FormikHelpers } from 'formik';

import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { UploadImageField } from 'components/fields/upload-image-field/select-filed';
import { Button } from 'components/ui/Button';

import { gql } from '@apollo/client';
import { uploadClient } from 'api/apollo-upload-client';
import { selectToken } from 'modules/auth';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { useCreateDocumentMutation } from 'store/api/organizationDocuments';
import { getToken } from 'utils/token';
import s from './aside-document.module.scss';
import {
  OrganizationUploadDocumentFormValues,
  initialValues,
  model,
  validationSchema,
} from './config';

export const AsideAddDocument = ({
  isOpen,
  onClick,
}: {
  isOpen: boolean;
  onClick: () => void;
}) => {
  const alert = useAlert();

  const token = useSelector(selectToken());

  const [uploadDocument, mutation] = useCreateDocumentMutation();
  const { t } = useTranslation();

  const [isFileLoading, setFileLoading] = useState(false);
  const isLoading = mutation.isLoading;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (onClick) {
        onClick();
      }
    }
  };

  const onSubmit = async (
    values: OrganizationUploadDocumentFormValues,
    { resetForm }: FormikHelpers<OrganizationUploadDocumentFormValues>,
  ) => {
    const { document } = values;

    if (document) {
      try {
        setFileLoading(true);
        await uploadClient.mutate({
          mutation: gql`
            mutation CoreOrganizationDocuments($file: Upload!) {
              coreOrganizationDocuments {
                create(file: $file)
              }
            }
          `,
          variables: { file: document },
          context: {
            headers: {
              authorization: `Bearer ${getToken() ?? token}`,
            },
          },
        });
        uploadDocument({ file: document }); //  refetch list
        onClick();
      } catch (error) {
        alert.error('Something went wrong');
      } finally {
        resetForm();
        setFileLoading(false);
      }
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={() => validationSchema(t)}
    >
      {({ dirty, values, isSubmitting, submitForm }) => (
        <div
          className={cx(s['container'], {
            [s['open']]: isOpen,
          })}
        >
          <div
            className={cx(s['wrapper'], {
              [s['open']]: isOpen,
            })}
          >
            <div className={s['header']}>
              <span className={s['title']}>{t('Add document')}</span>

              <div
                role="button"
                tabIndex={0}
                className={s['close']}
                onClick={onClick}
                onKeyDown={handleKeyDown}
              >
                <CloseIcon />
              </div>
            </div>

            <div className={s['main']}>
              <div className={s['scroll']}>
                <UploadImageField {...model.document} />
              </div>
            </div>

            <div className={s['footer']}>
              <Button className={cx(s['button'], s['button-back'])} onClick={onClick}>
                {t('Back')}
              </Button>
              <Button className={cx(s['button'], s['button-save'])} onClick={submitForm}>
                {t(isLoading || isFileLoading ? 'Loading...' : 'Save')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
