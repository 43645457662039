import { gql } from 'graphql-request';

export const CREATE_OPERATIONAL_REQUEST_DOCUMENT = gql`
  mutation App5182dd71_Document($input: app5182dd71_DocumentMutationOperationalRequest_createInputInput!) {
    app5182dd71_Document {
      create(input: $input) {
        id
      }
    }
  }
`;
