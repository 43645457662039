import { setAuthState } from 'utils/token';

export default function ({ getState }: { getState: any }) {
  return (next: any) => (action: any) => {
    const result = next(action);

    if (action.type?.startsWith('auth/')) {
      const authState = getState().auth;
      setAuthState(authState);
    }

    return result;
  };
}
