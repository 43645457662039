import { gql } from 'graphql-request';

export const POA_PREVIEW_REVOCATION = gql`
  query CorePOA($poaId: ID!, $revokeDate: Date!, $revokeReason: String!) {
    corePOA {
      previewRevocation(
        poaId: $poaId
        input: { revokeDate: $revokeDate, revokeReason: $revokeReason }
      ) {
        content
      }
    }
  }
`;
