import cx from 'classnames';
import { Field, FieldArray, Form, Formik } from 'formik';

import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { Button } from 'components/ui/Button';

import { Checkbox, InputField } from 'components/common';
import { SelectField } from 'components/fields/select-fields/select-filed';
import styles from './aside-modal.module.scss';
import { model } from './config';

export const AsideEditStaff = ({
  title,
  isOpen,
  onClick,
  initialValues,
  validationSchema,
}: {
  title: string;
  isOpen: boolean;
  initialValues: any;
  onClick: () => void;
  validationSchema: any;
}) => {
  const { t } = useTranslation();

  const containerClasses = cx(styles['container'], {
    [styles['open']]: isOpen,
  });
  const wrapperClasses = cx(styles['wrapper'], {
    [styles['open']]: isOpen,
  });
  const classesButtonBack = cx(styles['button'], styles['button-back']);
  const classesButtonSave = cx(styles['button'], styles['button-save']);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (onClick) {
        onClick();
      }
    }
  };

  const onSubmit = () => {
    //
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, submitForm, values }) => (
        <div className={containerClasses}>
          <div className={wrapperClasses}>
            <div className={styles['header']}>
              <span className={styles['title']}>{t(title)}</span>

              <div
                role="button"
                tabIndex={0}
                className={styles['close']}
                onClick={onClick}
                onKeyDown={handleKeyDown}
              >
                <CloseIcon />
              </div>
            </div>

            <div className={styles['main']}>
              <div className={styles['scroll']}>
                <div className={styles['content-modal']}>
                  <div className={styles['wrapper-filed']}>
                    <InputField {...model.email} />

                    <div className={styles['wrapper-settings']}>
                      <SelectField {...model.role} />
                    </div>
                  </div>

                  <div className={styles['wrapper-group']}>
                    {/* <span className={styles['title-modal']}>{t('Authority')}</span>
                    <Checkbox
                      checked={false}
                      label={t('Select all')}
                      onClick={() => {
                        //
                      }}
                    /> */}

                    {/* <FieldArray name={model.permissions.name}>
                      {({ form, push, remove }) => {
                        return form?.values?.permissions?.map((option: any, index: number) => (
                          <div key={option.id} className={styles['modal-wrapper-checkbox']}>
                            <Field name={`permissions.${index}.checked`}>
                              {({ field }: any) => (
                                <Checkbox
                                  label={option.label}
                                  checked={field.value}
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  name={field.name}
                                  onChange={field.onChange}
                                />
                              )}
                            </Field>
                            {option.poaRequired && (
                              <div className={styles['wrapper-desc']}>
                                <span className={styles['info']}>{t('POA required')}</span>
                              </div>
                            )}
                          </div>
                        ));
                      }}
                    </FieldArray> */}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles['footer']}>
              <Button className={classesButtonBack} onClick={onClick}>
                {t('Back')}
              </Button>
              <Button className={classesButtonSave} onClick={onClick}>
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
