import { AttorneysList } from 'components/sidenav-message/attorneys/AttorneysList';

import { AttorneyInfo } from 'components/card/attorney/attorney-info/AttorneyInfo';
import { Loader } from 'components/loader/loader';
import { NotFound, NotFoundContainer } from 'components/not-found';
import { AppRoutes } from 'config/routes';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPoaRevokedListQuery } from 'store/api/poa';
import { PoaDraft } from 'store/api/types';
import { useAppDispatch } from 'store/hooks';
import { setAttorneysType } from 'store/reducers/attorney.list';
import s from './Revoked.module.scss';

export const Revoked = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data = [], isFetching } = useGetPoaRevokedListQuery();
  const [currentItem, setCurrentItem] = useState<PoaDraft | null>(null);

  const { Home, PowersAttorney } = AppRoutes.Dashboard;

  useEffect(() => {
    if (!id && data?.length) {
      navigate(Home + PowersAttorney.Home + PowersAttorney.Revoked + '/' + data[0]?.id);
    }

    dispatch(setAttorneysType('revoked'));
    setCurrentItem(data?.find((item) => item.id === id) ?? data?.[0]);
  }, [id, data]);

  if (!isFetching && !data?.length) {
    return (
      <NotFoundContainer>
        <NotFound
          hasSearch={false}
          title="Here will be displayed"
          subtitle="your powers of attorney"
          notFoundTitle="No power of attorney found"
        />
      </NotFoundContainer>
    );
  }

  if (isFetching) {
    return <Loader size="medium" />;
  }

  return (
    <div className={s['container']}>
      <AttorneysList data={data} hasSearch />
      <AttorneyInfo currentItem={currentItem} />
    </div>
  );
};
