import { useMutation } from '@tanstack/react-query';

import { login } from '../api';

export default function useLogin() {
  return useMutation({
    mutationFn: (data: { token: string; signature: string; detachedSignature: string }) =>
      login(data.token, data.signature, data?.detachedSignature),
  });
}
