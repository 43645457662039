import cx from 'classnames';
import { Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'config/routes';

import { Button } from 'components/ui/Button';

import { ReactComponent as FilterIcon } from 'assets/images/filter.svg';
import { ReactComponent as PlusIcon } from 'assets/images/second-plus.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search-dashboard.svg';

import s from './Header.module.scss';

interface HeaderProps {
  setFilter: (value: string) => void;
  isFilter?: boolean;
  onClick?: () => void;
}

export const Header = ({ setFilter, isFilter, onClick }: HeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Home, Organizations } = AppRoutes.Dashboard;

  const classesButtonFilter = cx(s['button'], s['button-filter']);
  const classesButtonContract = cx(s['button'], s['button-contract']);

  const handleFilter = () => {
    if (onClick) {
      onClick();
    }
  };

  const handleNewContract = () => {
    navigate(Home + Organizations.Home + Organizations.Staff + Organizations.AddStaff);
  };

  return (
    <div className={s['container']}>
      <div className={s['wrapper-search']}>
        <div className={s['search']}>
          <Input
            allowClear
            placeholder={t('Search...')}
            prefix={<SearchIcon />}
            style={{ borderRadius: '0px', height: '40px' }}
            onChange={(e) => setFilter(e?.target?.value ?? '')}
          />
        </div>
        <Button className={classesButtonFilter} onClick={handleFilter}>
          {t('Filter')}
        </Button>
      </div>

      <div className={s['wrapper-button']}>
        <Button
          icon={<PlusIcon />}
          className={classesButtonContract}
          onClick={handleNewContract}
        >
          {t('Add Staff')}
        </Button>
      </div>
    </div>
  );
};
