export default `<wsse:Security soapenv:actor="[{#@COMPANY_NAME@#}]">
      <ds:Signature xmlns:ds="http://www.w3.org/2000/09/xmldsig#">
        <ds:SignedInfo>
          <ds:CanonicalizationMethod Algorithm="http://www.w3.org/2001/10/xml-exc-c14n#"/>
          <ds:SignatureMethod Algorithm="{#@SIGNATURE_METHOD_ALGORITHM@#}"/>
          <ds:Reference URI="#{#@ID_FILE@#}">
            <ds:Transforms>
              <ds:Transform Algorithm="http://www.w3.org/2001/10/xml-exc-c14n#"/>
            </ds:Transforms>
            <ds:DigestMethod Algorithm="{#@DIGEST_METHOD_ALGORITHM@#}"/>
            <ds:DigestValue>{#@DIGEST_METHOD_ALGORITHM@#}</ds:DigestValue>
          </ds:Reference>
          <ds:Reference URI="#keyinfo">
						<ds:Transforms>
							<ds:Transform Algorithm="http://www.w3.org/2001/10/xml-exc-c14n#" />
						</ds:Transforms>
						<ds:DigestMethod Algorithm="{#@DIGEST_METHOD_ALGORITHM@#}" />
						<ds:DigestValue />
					</ds:Reference>
					<ds:Reference URI="#visualization">
						<ds:Transforms>
							<ds:Transform Algorithm="http://www.w3.org/2001/10/xml-exc-c14n#" />
						</ds:Transforms>
						<ds:DigestMethod Algorithm="{#@DIGEST_METHOD_ALGORITHM@#}" />
						<ds:DigestValue />
					</ds:Reference>
					<ds:Reference URI="#signature-properties">
						<ds:Transforms>
							<ds:Transform Algorithm="http://www.w3.org/2001/10/xml-exc-c14n#" />
						</ds:Transforms>
						<ds:DigestMethod Algorithm="{#@DIGEST_METHOD_ALGORITHM@#}" />
						<ds:DigestValue />
					</ds:Reference>
        </ds:SignedInfo>
        <ds:SignatureValue>{#@SIGNATURE_VALUE@#}</ds:SignatureValue>
        <ds:KeyInfo Id="keyinfo">
          <wsse:SecurityTokenReference>
            <wsse:Reference URI="#[{#@COMPANY_NAME@#}]" ValueType="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-x509-token-profile-1.0#X509v3" />
            <ds:X509Data>
              <ds:X509SubjectName>{#@CERTIFICATE_INFO@#}</ds:X509SubjectName>
              <ds:X509SerialNumber>{#@CERTIFICATE_SERIAL_NUMBER@#}</ds:X509SerialNumber>
            </ds:X509Data>
          </wsse:SecurityTokenReference>
        </ds:KeyInfo>
        <ds:Object Id="signature-properties">
          <ds:SignatureProperties>
            <ds:SignatureProperty>
              <SigningTime>{#@CURRENT_ISO_DATE_TIME@#}</SigningTime>
            </ds:SignatureProperty>
            <ds:SignatureProperty>
              <X509ValidFrom>{#@VALID_FROM@#}</X509ValidFrom>
            </ds:SignatureProperty>
            <ds:SignatureProperty>
              <X509ValidUntil>{#@VALID_UNTIL@#}</X509ValidUntil>
            </ds:SignatureProperty>
          </ds:SignatureProperties>
        </ds:Object>
      </ds:Signature>
</wsse:Security>`;
