import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Certificate } from 'client/models';
import { RootState } from 'store/store';

export interface CertificatesInitialState {
  isSignLoading?: boolean;
  currentCertificate?: Certificate | null;
  currentCertificateInfo: { [key: string]: string };
}

const initialState: CertificatesInitialState = {
  isSignLoading: false,
  currentCertificate: null,
  currentCertificateInfo: {},
};

const certificateSlice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    setSignLoading(state, action) {
      state.isSignLoading = action.payload;
    },
    setCertificate(state, action) {
      state.currentCertificate = action.payload;
    },
    setCertificateInfo(state, action) {
      state.currentCertificateInfo = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setCertificate, setCertificateInfo, setSignLoading, resetState } =
  certificateSlice.actions;

export default certificateSlice.reducer;

const certificateStore = (store: RootState) => store.certificate;

export const selectIsSignLoading = () =>
  createSelector([certificateStore], (store) => store?.isSignLoading ?? false);

export const selectCurrentCertificate = () =>
  createSelector([certificateStore], (store) => store?.currentCertificate);

export const selectCurrentCertificateInfo = () =>
  createSelector([certificateStore], (store) => store?.currentCertificateInfo);
