import { gql } from 'graphql-request';

export const GET_CORE_DICTIONARIES_COUNTRIES = gql`
  query CoreDictionaries {
    coreDictionaries {
      get(ids: "74db90ed-70e7-4a03-86ae-96969b21c9af") {
        id
        appId
        alias
        title
        description
        createdAt
        updatedAt
        enums {
          id
          title
          values {
            key
            value
          }
        }
      }
    }
  }
`;
