import { gql } from 'graphql-request';

export const UPDATE_MY_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $input: core_OrganizationMutationNS_updateOrganizationInputInput!
  ) {
    coreOrganizations {
      update(input: $input) {
        attributes {
          name
          value
        }
        createdAt
        updatedAt
        countryCode
        type
        status
        id
        name
      }
    }
  }
`;
