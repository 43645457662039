import { createApi } from '@reduxjs/toolkit/query/react';

import { UPDATE_MY_ORGANIZATION } from './mutations/organizations/update.my.organizations';
import { ACCEPT_STAFF_INVITE } from './mutations/staff/accept.staff.invite';
import { INVITE_STAFF } from './mutations/staff/invite.staff';
import { REJECT_STAFF_INVITE } from './mutations/staff/reject.staff.invite';
import { InviteStaffInput, InviteStaffResponse } from './mutations/staff/types';
import { GET_STAFF_BY_ID } from './queries/staff/staff.by.id';
import { GET_STAFF_INCOMING_INVITES } from './queries/staff/staff.incoming.invites';
import { GET_STAFF_INCOMING_INVITES_COUNT } from './queries/staff/staff.incoming.invites.count';
import { GET_STAFF_LIST } from './queries/staff/staff.list';
import { GET_STAFF_OUTGOING_INVITES } from './queries/staff/staff.outgoing.invites';
import { GET_STAFF_OUTGOING_INVITES_COUNT } from './queries/staff/staff.outgoing.invites.count';
import { GET_STAFF_POAS_LIST } from './queries/staff/staff.poas.list';
import {
  StaffByIdResponse,
  StaffInvitesCountResponse,
  StaffInvitesListResponse,
  StaffListResponse,
  StaffPoasListResponse,
} from './queries/staff/types';
import { baseQuery } from './query';
import { PoaDraft } from './types';
import { Employee, StaffInvite } from './types/staff';

export const StaffTags = {
  Staff: 'Staff',
  Invites: 'Invites',
  List: 'List',
};
const tagTypes = [StaffTags.Invites, StaffTags.List];

export const staffApi = createApi({
  tagTypes,
  baseQuery: baseQuery,
  reducerPath: 'staffApi',
  endpoints: (builder) => ({
    getStaffList: builder.query<Employee[], { search: string; role?: string | undefined }>({
      query: ({ search, role }) => ({
        document: GET_STAFF_LIST,
        variables: {
          roles: role ? [role] : null,
          searchText: search?.length ? search : null,
        },
      }),
      transformResponse: (rawResponse: StaffListResponse) =>
        rawResponse?.coreEmployees?.query.employees,
      providesTags: [StaffTags.List],
    }),

    getStaffById: builder.query<Employee, { id: string }>({
      query: ({ id }) => ({
        document: GET_STAFF_BY_ID,
        variables: { id: id },
      }),
      transformResponse: (rawResponse: StaffByIdResponse) =>
        rawResponse?.coreEmployees?.get?.[0],
      providesTags: [StaffTags.Staff],
    }),

    updateStaff: builder.mutation<any, any>({
      query: ({ input }) => ({
        document: UPDATE_MY_ORGANIZATION,
        variables: { input },
      }),
      transformResponse: (rawResponse: any) => rawResponse?.coreOrganizations?.update,
      invalidatesTags: [StaffTags.List],
    }),

    getStaffIncomingInvitationsList: builder.query<StaffInvite[], void>({
      query: () => ({
        document: GET_STAFF_INCOMING_INVITES,
      }),
      transformResponse: (rawResponse: StaffInvitesListResponse) =>
        rawResponse?.coreEmployeeInvitations?.query.invites,
      providesTags: [StaffTags.Invites],
    }),

    getStaffOutgoingInvitationsList: builder.query<StaffInvite[], void>({
      query: () => ({
        document: GET_STAFF_OUTGOING_INVITES,
      }),
      transformResponse: (rawResponse: StaffInvitesListResponse) =>
        rawResponse?.coreEmployeeInvitations?.query.invites,
      providesTags: [StaffTags.Invites],
    }),

    getStaffOutgoingInvitationsCount: builder.query<number, void>({
      query: () => ({
        document: GET_STAFF_OUTGOING_INVITES_COUNT,
      }),
      transformResponse: (rawResponse: StaffInvitesCountResponse) =>
        rawResponse?.coreEmployeeInvitations?.count,
      providesTags: [StaffTags.Invites],
    }),

    getStaffIncomingInvitationsCount: builder.query<number, void>({
      query: () => ({
        document: GET_STAFF_INCOMING_INVITES_COUNT,
      }),
      transformResponse: (rawResponse: StaffInvitesCountResponse) =>
        rawResponse?.coreEmployeeInvitations?.count,
      providesTags: [StaffTags.Invites],
    }),

    staffInvite: builder.mutation<any, InviteStaffInput>({
      query: ({ participantData, role, type, position }) => ({
        document: INVITE_STAFF,
        variables: { participantData, role, type, position },
      }),
      transformResponse: (rawResponse: InviteStaffResponse) =>
        rawResponse?.coreEmployeeInvitations.invite,
      invalidatesTags: [StaffTags.Invites, StaffTags.List],
    }),

    confirmStaffInvite: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        document: ACCEPT_STAFF_INVITE,
        variables: { invitationId: id },
      }),
      invalidatesTags: [StaffTags.Invites, StaffTags.List],
    }),

    rejectStaffInvite: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        document: REJECT_STAFF_INVITE,
        variables: { invitationId: id },
      }),
      invalidatesTags: [StaffTags.Invites, StaffTags.List],
    }),

    staffPoasList: builder.query<PoaDraft[], { representativeEmployeeID: string }>({
      query: ({ representativeEmployeeID }) => ({
        document: GET_STAFF_POAS_LIST,
        variables: { representativeEmployeeID },
      }),
      transformResponse: (rawResponse: StaffPoasListResponse) =>
        rawResponse?.corePOA?.query.poas,
    }),
  }),
});

export const {
  useGetStaffListQuery,
  useGetStaffByIdQuery,
  useStaffPoasListQuery,
  useUpdateStaffMutation,
  useStaffInviteMutation,
  useRejectStaffInviteMutation,
  useConfirmStaffInviteMutation,
  useGetStaffIncomingInvitationsListQuery,
  useGetStaffOutgoingInvitationsListQuery,
  useGetStaffOutgoingInvitationsCountQuery,
  useGetStaffIncomingInvitationsCountQuery,
} = staffApi;
