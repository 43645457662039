import { gql } from 'graphql-request';

export const GET_POA_DRAFT_AND_XML = gql`
  query CorePOA($id: ID!, $format: core_POAQueryNS_poaFormatEnum) {
    corePOA {
      getDraft(id: $id, format: $format) {
        id
        number
        issueDate
        expirationDate
        revocationDate
        createdAt
        updatedAt
        status
        content
        authority {
          id
          userType
          email
          rawUserInfo
        }
        recipientOrganization {
          id
          name
          status
          type
          countryCode
          createdAt
          updatedAt
        }
        representative {
          id
          userType
          email
          rawUserInfo
        }
        powerDomain {
          code
          textDescription
          content
        }
        content
      }
    }
  }
`;
