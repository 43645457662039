import useSigner from 'client/hooks/use-signer';
import { Loader } from 'components/loader/loader';
import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';
import dayjs from 'dayjs';
import useProcessXslt from 'hooks/use-process-xslt';
import { useCallback, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPoaDraftQuery, usePoaSignMutation } from 'store/api/poa';
import { selectCurrentCertificate } from 'store/reducers/certificates';
import { useHandleMutation } from 'utils/handleMutation';
import s from './styles.module.scss';

export function PoaPreview() {
  const alert = useAlert();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Auth } = AppRoutes;

  const { handleSign, isLoading: isSignerLoading } = useSigner();
  const [signDraft, signDraftMutation] = usePoaSignMutation();
  const {
    data: draft,
    refetch,
    isFetching,
  } = useGetPoaDraftQuery({ id: id ?? '' }, { skip: !id });
  const documentContent = draft?.content ?? '';
  const { data: xsltPreview } = useProcessXslt({ base64: documentContent });
  const currentCertificate = useSelector(selectCurrentCertificate());

  const isLoading = signDraftMutation?.isLoading;

  useEffect(() => {
    if (draft) {
      refetch();
    }
  }, []);

  const onSuccess = useCallback(() => {
    navigate(`${Auth.Home}${Auth.PoaSigned}/${id}`);
  }, [navigate]);

  const onError = useCallback(() => {
    alert.error(t('Error'));
  }, [alert, t]);

  useHandleMutation({ ...signDraftMutation, onSuccess, onError });

  const handleBack = () => {
    navigate(-1);
  };

  const handleClick = async () => {
    await handleSign({
      addTemplate: true,
      validFrom: dayjs(draft?.issueDate).toISOString(),
      validUntil: dayjs(draft?.expirationDate).toISOString(),
      signData: documentContent ?? '',
      onSubmit: async ({ signature, detachedSignature }) => {
        await signDraft({
          id: id ?? '',
          signature,
          detachedSignature,
          cert: currentCertificate?.Base64RawData ?? '',
        });
      },
    });
  };

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.firstBlock}>
          <div className={s.subtitle}>
            {t(
              'Welcome to Contracteum, a platform for secure digital transactions under your full control',
            )}
          </div>
          <div className={s.subtitle}>
            {t(
              'The solution ensures the confidentiality of transmitted information and guarantees the legal significance of transactions',
            )}
          </div>
        </div>

        <div className={s.secondBlock}>
          {isFetching && <Loader size="big" />}
          {xsltPreview && (
            <span>
              <div
                className={s['preview-container']}
                dangerouslySetInnerHTML={{
                  __html: new XMLSerializer().serializeToString(xsltPreview as any),
                }}
              />
            </span>
          )}

          <div className={s.buttonContainer}>
            <div className={s.buttonWrapper}>
              <Button displayType="outlined-primary" className={s.button} onClick={handleBack}>
                {t('Назад')}
              </Button>
              <Button
                disabled={isSignerLoading}
                type="primary"
                className={s.buttonSign}
                onClick={handleClick}
              >
                {t(isLoading ? 'Loading...' : 'Sign')}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={s.bottom}></div>
    </div>
  );
}
