import { createSlice } from '@reduxjs/toolkit';

const contractorSearchModal = createSlice({
  name: 'contract/sublicense',
  initialState: { isSearchModalOpened: false },
  reducers: {
    setIsSearchModalOpened: (state, action) => {
      state.isSearchModalOpened = action.payload;
    },
  },
});

export default contractorSearchModal.reducer;
export const { setIsSearchModalOpened } = contractorSearchModal.actions;
