import { gql } from 'graphql-request';

export const GET_MY_ORGANIZATIONS = gql`
  query CoreOrganizations {
    coreOrganizations {
      my {
        id
        name
        status
        type
        countryCode
        createdAt
        updatedAt
        attributes(names: null) {
          name
          value
        }
      }
    }
  }
`;

export const coreOrganizationsQuery = {
  query: GET_MY_ORGANIZATIONS,
};
