import { TFunction } from 'i18next';
import * as Yup from 'yup';

export interface EmployeeInformationFormValues {
  surname: string;
  name: string;
  patronymic: string;
  inn: string;
  snils: string;
  email: string;
  role: string;
  position: string;
  settingAccess: string;
}

export const model = {
  surname: {
    label: 'Surname',
    name: 'surname',
    type: 'text',
    required: true,
  },
  name: {
    label: 'Name',
    name: 'name',
    type: 'text',
    required: true,
  },
  patronymic: {
    label: 'Patronymic',
    name: 'patronymic',
    type: 'text',
    required: true,
  },
  inn: {
    label: 'INN',
    name: 'inn',
    required: true,
  },
  snils: {
    label: 'SNILS',
    name: 'snils',
    required: true,
  },
  email: {
    type: 'email',
    label: 'E-mail',
    name: 'email',
    placeholder: 'Enter email',
    required: true,
  },
  role: {
    label: 'Role',
    name: 'role',
    placeholder: 'Select from the list',
    options: [
      {
        label: 'Сотрудник',
        value: 'employee',
      },
      {
        label: 'Администратор',
        value: 'administrator',
      },
    ],
    required: true,
  },
  posistion: {
    type: 'text',
    label: 'Должность',
    name: 'position',
    placeholder: '',
    required: true,
  },
  settingAccess: {
    type: 'text',
    label: 'Setting up access',
    name: 'settingAccess',
    placeholder: 'Ко всем документам и разделам',
    required: true,
  },
};

export const validationSchema = ({ t, step }: { t: TFunction; step: number }) => {
  if (step === 1) {
    return Yup.object().shape({
      [model.inn.name]: Yup.string()
        .max(12, 'ИНН должен состоять из 12 цифр')
        .required(t('ИНН обязателен для заполнения')),
      [model.name.name]: Yup.string().required(t('Имя обязательно для заполнения')),
      [model.email.name]: Yup.string().required(t('Email обязателен для заполнения')),
      [model.snils.name]: Yup.string()
        .max(11, 'CНИЛС должен состоять из 11 цифр')
        .required(t('CНИЛС обязателен для заполнения')),
      [model.surname.name]: Yup.string().required(t('Фамилия обязательна для заполнения')),
      [model.patronymic.name]: Yup.string().required(t('Отчество обязательно для заполнения')),
      [model.role.name]: Yup.string().required(t('Роль обязательна для заполнения')),
      [model.posistion.name]: Yup.string().required(t('Должность обязательна для заполнения')),
    });
  }
  if (step === 2) {
    return Yup.object().shape({
      [model.role.name]: Yup.string().required(t('Роль обязательна для заполнения')),
    });
  }
  return Yup.object().shape({});
};

export const initialValues = {
  [model.inn.name]: '',
  [model.role.name]: '',
  [model.name.name]: '',
  [model.email.name]: '',
  [model.snils.name]: '',
  [model.surname.name]: '',
  [model.patronymic.name]: '',
  [model.settingAccess.name]: '',
} as unknown as EmployeeInformationFormValues;
