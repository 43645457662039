import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from 'config/routes';

import { ReactComponent as PlusIcon } from 'assets/images/second-plus.svg';
import { HeaderTabs, Tab } from 'components/header-tabs/header-tabs';
import { createButtonSettings } from '../counterparties/counterparty-detail/CounterpatyDetail';

import { NewAttorney } from './new-attorney/NewAttorney';

import { NewAttorneyPreview } from 'components/attorney/new-attorney-preview/NewAttorneyPreview';
import s from './PowersAttorney.module.scss';
import { Active } from './active/Active';
import { Drafts } from './drafts/Drafts';
import { Revoked } from './recalled/Revoked';
import { Archived } from './archived/Archived';

const { Home, PowersAttorney: PowersAttorneyRoute } = AppRoutes.Dashboard;

const tabsContent: Tab[] = [
  {
    id: 0,
    label: 'Current',
    path: Home + PowersAttorneyRoute.Home + PowersAttorneyRoute.Active,
  },
  {
    id: 1,
    label: 'Recalled',
    path: Home + PowersAttorneyRoute.Home + PowersAttorneyRoute.Revoked,
  },
  {
    id: 2,
    label: 'Archive',
    path: Home + PowersAttorneyRoute.Home + PowersAttorneyRoute.Archived,
  },
  {
    id: 3,
    label: 'Drafts',
    path: Home + PowersAttorneyRoute.Home + PowersAttorneyRoute.Drafts,
  },
];

function PowersAttorney() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const isButtonVisible = location.pathname?.includes(PowersAttorneyRoute.Active);

  const headerBlacklist = [PowersAttorneyRoute.NewAttorney, PowersAttorneyRoute.Preview];
  const isHeaderVisible = !headerBlacklist.some((route) => location.pathname?.includes(route));

  const classesButton = cx(s['button']);

  const handleNewAttornet = () => {
    navigate(Home + PowersAttorneyRoute.Home + PowersAttorneyRoute.NewAttorney);
  };

  const buttonSettings = createButtonSettings({
    text: 'Add power of attorney',
    icon: <PlusIcon width={16} height={16} />,
    type: 'primary',
    disabled: false,
    className: classesButton,
    onClick: handleNewAttornet,
  });

  const optionHeader = {
    isButton: isButtonVisible,
  };

  return (
    <div className={s['container']}>
      {isHeaderVisible && (
        <>
          <span className={s['title']}>{t('Powers of attorney')}</span>
          <HeaderTabs
            type="detail"
            tabs={tabsContent}
            optionButton={buttonSettings}
            {...optionHeader}
          />
        </>
      )}

      <Routes>
        <Route path="/" element={<></>} />
        <Route path={PowersAttorneyRoute.Active} element={<Active />} />
        <Route path={PowersAttorneyRoute.Active + '/:id'} element={<Active />} />

        <Route path={PowersAttorneyRoute.Revoked} element={<Revoked />} />
        <Route path={PowersAttorneyRoute.Revoked + '/:id'} element={<Revoked />} />

        <Route path={PowersAttorneyRoute.Archived} element={<Archived />} />
        <Route path={PowersAttorneyRoute.Archived + '/:id'} element={<Archived />} />

        <Route path={PowersAttorneyRoute.Drafts} element={<Drafts />} />
        <Route path={PowersAttorneyRoute.Drafts + '/:id'} element={<Drafts />} />

        <Route path={PowersAttorneyRoute.NewAttorney} element={<NewAttorney />} />
        <Route path={PowersAttorneyRoute.Drafts + '/:id'} element={<NewAttorney />} />
        <Route path={PowersAttorneyRoute.NewAttorney + '/:id'} element={<NewAttorney />} />

        <Route path={PowersAttorneyRoute.Preview + '/:id'} element={<NewAttorneyPreview />} />
      </Routes>
    </div>
  );
}

export default PowersAttorney;
