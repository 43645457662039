import Input from 'antd/es/input/Input';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SearchIcon } from 'assets/images/search-dashboard.svg';

import { AttorneyCard } from 'components/card/attorney/AttorneyCard';
import { PoaDraft, RawUserInfo } from 'store/api/types';
import s from './AttorneysList.module.scss';

export interface ComapnyCardProps {
  id: number;
  date: string;
  name: string;
  range: string;
  recipient: string;
  company: string;
}

type SidenavCompanyProps = {
  data?: PoaDraft[];
  hasSearch?: boolean;
  current?: string | null | undefined | PoaDraft;
};

export const AttorneysList = ({ data, current, hasSearch }: SidenavCompanyProps) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState<string>('');

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilter(value);
  };

  const filterData = useMemo(() => {
    if (!filter) {
      return data;
    }

    return data?.filter((user: PoaDraft) => {
      if (typeof user?.representative?.rawUserInfo === 'string') {
        try {
          const userInfo = JSON.parse(user?.representative?.rawUserInfo) as RawUserInfo;
          const name = userInfo?.name || '';
          const surname = userInfo?.surname || '';
          const fullName = (name + ' ' + surname).toLowerCase();

          return fullName.includes(filter.toLowerCase());
        } catch (error) {
          console.error('Ошибка разбора JSON:', error);
        }
      }
    });
  }, [filter, data]);

  return (
    <div className={s['container']}>
      {hasSearch && (
        <div>
          <Input
            allowClear
            value={filter ?? ''}
            placeholder={t('Search...')}
            prefix={<SearchIcon />}
            className={s['input']}
            onChange={handleFilter}
          />
        </div>
      )}
      <div className={s['message-wrapper']}>
        <div className={s['scroll-container']}>
          <div className={s['scroll']}>
            {filterData?.map((company: any) => (
              <AttorneyCard data={company} key={company.id.toString()} current={current} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
