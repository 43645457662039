import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { InputMasks } from 'components/common/input-field/input-field';
import { DefaultPaymentOption, Organization } from 'store/api/types';

export interface OrganizationFormValues {
  okpo?: string;
  kpp?: string | undefined;
  country?: string | undefined;
  address?: string | undefined;
  postalCountry?: string | undefined;
  postalAddress?: string | undefined;
  phone?: string | undefined;
  email?: string | undefined;
  bankId?: string | undefined;
  bankDepartment?: string | undefined;
  paymentAccount?: string | undefined;
  correspondentAccount?: string | undefined;
  bic?: string | undefined;
}

export const model = {
  okpo: {
    name: 'okpo',
    label: 'OKPO',
    required: true,
    mask: InputMasks.okpo,
    placeholder: '',
  },
  kpp: {
    name: 'kpp',
    label: 'KPP',
    required: true,
    mask: InputMasks.kpp,
    placeholder: '',
  },
  country: {
    required: true,
    name: 'country',
    label: 'Country',
    placeholder: 'Select from the list',
  },
  address: {
    type: 'text',
    required: true,
    name: 'address',
    label: 'Address',
    placeholder: 'legal address',
  },
  postalCountry: {
    required: true,
    label: 'Country',
    name: 'postalCountry',
    placeholder: 'Select from the list',
  },
  postalAddress: {
    type: 'text',
    required: true,
    label: 'Address',
    name: 'postalAddress',
    placeholder: 'postal address',
  },
  phone: {
    label: '',
    type: 'text',
    name: 'phone',
    placeholder: 'phone',
  },
  email: {
    type: 'text',
    name: 'email',
    required: true,
    label: 'E-mail',
    placeholder: 'willie.jennings@example.com',
  },
  bankId: {
    type: 'text',
    required: true,
    name: 'bankId',
    label: 'Name bank',
    placeholder: 'Select from the list',
  },
  bankDepartment: {
    type: 'text',
    required: true,
    name: 'bankDepartment',
    label: 'Name bank department',
    placeholder: 'Enter bank department name',
  },
  paymentAccount: {
    required: true,
    name: 'paymentAccount',
    mask: InputMasks.org20,
    label: 'Payment account',
    placeholder: '',
  },
  correspondentAccount: {
    required: true,
    mask: InputMasks.org20,
    name: 'correspondentAccount',
    label: 'Correspondent account',
    placeholder: '',
  },
  bic: {
    name: 'bic',
    label: 'BIC',
    required: true,
    mask: InputMasks.org9,
    placeholder: '',
  },
};

export const validationSchema = ({
  t,
  organization,
}: {
  t: TFunction;
  organization: Organization | undefined;
}) =>
  Yup.object().shape({
    kpp: organization?.attributes?.find((item) => item.name === 'kpp')
      ? Yup.number().required(t('Kpp is required'))
      : Yup.string().optional(),

    bic: Yup.number().required(t('BIC is required')),
    country: Yup.string().required(t('Country is required')),
    address: Yup.string().required(t('Legal address is required')),
    phone: Yup.string().required(t('Phone is required')),
    bankDepartment: Yup.string().required(t('Name bank is required')),
    postalCountry: Yup.string().required(t('Postal country is required')),
    postalAddress: Yup.string().required('Postal address is required'),
    bankId: Yup.string().required(t('Name bank department is required')),
    paymentAccount: Yup.number().required(t('Payment account is required')),
    email: Yup.string().email(t('Invalid email address')).required(t('Email is required')),
    correspondentAccount: Yup.number().required(t('Correspondent account is required')),
  });

const parseCountry = (organization: Organization | undefined, attribute: string) => {
  const { attributes } = organization ?? {};

  if (organization?.countryCode === 'kg') {
    return 'KGZ';
  }
  if (organization?.countryCode === 'ru') {
    return 'RUS';
  }

  return attributes?.find((item) => item.name === attribute)?.value;
};

export const initialValues = ({
  organization,
  payment,
}: {
  organization: Organization | undefined;
  payment: DefaultPaymentOption | undefined;
}) => {
  const { attributes } = organization ?? {};

  return {
    [model.okpo.name]: attributes?.find((item) => item.name === 'okpo')?.value || '',
    [model.kpp.name]: attributes?.find((item) => item.name === 'kpp')?.value || '',
    [model.email.name]: attributes?.find((item) => item.name === 'email')?.value || '',
    [model.phone.name]: attributes?.find((item) => item.name === 'phone')?.value || '',
    [model.country.name]: parseCountry(organization, 'country'),
    [model.address.name]: attributes?.find((item) => item.name === 'address')?.value || '',
    [model.postalCountry.name]: parseCountry(organization, 'postal_country'),
    [model.postalAddress.name]:
      attributes?.find((item) => item.name === 'postal_address')?.value || '',

    [model.bic.name]: payment?.bic ?? '',
    [model.bankId.name]: payment?.bank?.id ?? '',
    [model.bankDepartment.name]: payment?.name ?? '',
    [model.paymentAccount.name]: payment?.accountNumber || '',
    [model.correspondentAccount.name]: payment?.corrNumber ?? '',
  };
};
