import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectField } from 'components/fields/select-fields/select-filed';
import { ListItem } from 'components/list';
import { AsideModal } from 'components/modal/aside/aside-modal';
import { Header } from './header/Header';

import { initialValues, model, validationSchema } from './config';

import { HeaderTabs, Tab } from 'components/header-tabs/header-tabs';
import { NotFound } from 'components/not-found';
import { AppRoutes } from 'config/routes';
import { Route, Routes } from 'react-router-dom';
import {
  useGetStaffIncomingInvitationsCountQuery,
  useGetStaffListQuery,
} from 'store/api/staff';
import styles from './Staff.module.scss';
import AddStaff from './add-staff/AddStaff';
import { Invitations } from './invitations/Invitations';
import { StaffDetail } from './staff-detail/StaffDetail';
import { Loader } from 'components/loader/loader';
import { useDebounce } from 'usehooks-ts';
const { Home, Organizations: OrganizationsRoute } = AppRoutes.Dashboard;

export type AttrParams = {
  id: number;
  attr: string;
  value: string;
};

export interface StaffsParams {
  id: number;
  name: string;
  position: string;
  status: string;
  textStatus: string;
  attrs: AttrParams[];
}

const tabsContent: Tab[] = [
  {
    id: 0,
    label: 'Staff',
    path: Home + OrganizationsRoute.Home + OrganizationsRoute?.Staff + OrganizationsRoute.List,
  },
  {
    id: 1,
    label: 'Invite/Request',
    path:
      Home + OrganizationsRoute.Home + OrganizationsRoute?.Staff + OrganizationsRoute?.Invites,
  },
];

function ListStaff() {
  const { t } = useTranslation();
  const [filterRole, setFilterRole] = useState(undefined);
  const [searchText, setSearchText] = useState('');
  const filterDebounced = useDebounce(searchText, 1000);
  const { data = [], isFetching } = useGetStaffListQuery({
    search: filterDebounced,
    role: filterRole,
  });

  const [isFilter, setIsFilter] = useState<boolean>(false);

  const handleOpen = () => {
    setIsFilter(true);
  };

  const handleClose = () => {
    setIsFilter(false);
  };

  const { data: incomingCount = 0 } = useGetStaffIncomingInvitationsCountQuery();

  return (
    <div className={styles['container']}>
      <span className={styles['title']}>{t('Staff')}</span>
      <div className={styles.headerContainer}>
        <HeaderTabs tabs={tabsContent} invitesCount={incomingCount} />
      </div>
      <div className={styles['wrapper-content']}>
        <Routes>
          <Route
            path={OrganizationsRoute.List}
            element={
              <div>
                <Header setFilter={setSearchText} isFilter={isFilter} onClick={handleOpen} />
                <div className={styles['wrapper-main']}>
                  {data?.length === 0 && !isFetching && (
                    <div className={styles.notFoundContainer}>
                      <NotFound
                        title="Сотрудники&nbsp;"
                        subtitle="не найдены"
                        notFoundTitle="Сотрудники не найдены"
                        hasSearch={!!filterRole || !!searchText?.length}
                      />
                    </div>
                  )}

                  {data?.map((item) => (
                    <ListItem key={item.id.toString()} data={item} />
                  ))}
                  {isFetching && <Loader size="medium" />}
                </div>
              </div>
            }
          />
          <Route path={OrganizationsRoute.Invites} element={<Invitations />} />
          <Route path={OrganizationsRoute.Invites + '/:id'} element={<Invitations />} />
        </Routes>
      </div>

      <AsideModal
        title={t('Search filtering')}
        isOpen={isFilter}
        onClick={handleClose}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        <div className={styles['content-modal']}>
          <SelectField
            {...model.role}
            onChange={(item: any) => {
              setFilterRole(item?.value);
            }}
          />
        </div>
      </AsideModal>
    </div>
  );
}

export const Staff = () => {
  return (
    <Routes>
      <Route path={'/*'} element={<ListStaff />} />
      <Route path={OrganizationsRoute.Details + '/:id' + '/*'} element={<StaffDetail />} />
      <Route path={OrganizationsRoute.AddStaff + '/*'} element={<AddStaff />} />
    </Routes>
  );
};
