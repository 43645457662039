import Input from 'antd/es/input/Input';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SearchIcon } from 'assets/images/search-dashboard.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings.svg';

import { UserCard } from 'components/card';
import { Tabs } from './tabs/Tabs';

import { PartyInfo } from 'store/api/types/organizations-invitations';
import s from './UsersList.module.scss';

interface DescriptionItem {
  id: number;
  text: string;
  value: string;
}

export interface UsersCounterpartParams {
  id: number;
  name: string;
  variant: 'active' | 'blocked' | 'expectation';
  date: string;
  time: string;
  description: DescriptionItem[];
}

type Props = {
  tabs?: any;
  type: string;
  leftCount: number;
  rightCount: number;
  data: PartyInfo[];
  customAttributes?: string[];
  selected: PartyInfo | undefined;
  onItemClick?: (item: PartyInfo) => void;
};

export const UserCardEmpty = ({ text }: { text: string }) => {
  const { t } = useTranslation();

  return (
    <div className={s['container-empty']}>
      <span className={s['text-empty']}>{t('Empty invites')}</span>
    </div>
  );
};

export const UsersList = ({
  type,
  tabs,
  data,
  selected,
  leftCount,
  rightCount,
  onItemClick,
  customAttributes,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={s['container']}>
      {false && (
        <div>
          <Input
            placeholder={t('Search...')}
            prefix={<SearchIcon />}
            suffix={<SettingsIcon />}
            className={s['input']}
          />
        </div>
      )}
      <div className={s['message-wrapper']}>
        <Tabs tabs={tabs} leftCount={leftCount} rightCount={rightCount} />
        <div className={s['scroll-container']}>
          <div className={s['scroll']}>
            {data.length > 0 ? (
              [...data]
                .sort(
                  (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
                )
                .map((item: PartyInfo) => (
                  <UserCard
                    item={item}
                    onClick={onItemClick}
                    key={item.id.toString()}
                    customAttributes={customAttributes}
                    isActive={item.id === selected?.id}
                  />
                ))
            ) : (
              <UserCardEmpty text={type} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
