import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  initialValues,
  PoaAcceptFromValues,
  validationSchema,
} from 'components/forms/poa/accept/config';
import { PoaAcceptForm } from 'components/forms/poa/accept/PoaAcceptForm';

import { Loader } from 'components/loader/loader';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyPoaGetDraftForAuthorityQuery, useUpdatePoaDraftMutation } from 'store/api/poa';
import { useLazyCheckCertificatesQuery } from 'store/api/poaUsers';
import { AuthorityInfo, PowerDomain, RepresetativeInfo, Retrust } from 'store/api/types';
import { useAppDispatch } from 'store/hooks';
import {
  selectCurrentCertificate,
  setCertificate,
  setCertificateInfo,
} from 'store/reducers/certificates';
import { prepareDate } from 'utils/date';
import { useHandleMutation } from 'utils/handleMutation';
import s from './styles.module.scss';
import { NotFound } from 'components/not-found';
import { useCertificates } from 'client/hooks/useCertificates';

export function PoaAccept() {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Auth } = AppRoutes;

  const { data: certificates } = useCertificates();

  const [updateDraft, updateDraftMutation] = useUpdatePoaDraftMutation();
  const [checkCertificates, { data: fingerprint, isFetching: isCertificateFetching }] =
    useLazyCheckCertificatesQuery();
  const [getPoaDraft, { data: draft, isFetching: isPoaFetching }] =
    useLazyPoaGetDraftForAuthorityQuery();

  const [hasError, setHasError] = useState(false);

  const representative: RepresetativeInfo = JSON.parse(
    draft?.representative?.rawUserInfo ?? '{}',
  );
  const authority: AuthorityInfo = JSON.parse(draft?.authority?.rawUserInfo ?? '{}');

  const currentCertificate = useSelector(selectCurrentCertificate());

  const onSuccessUpdate = useCallback(() => {
    navigate(`${Auth.Home}${Auth.PoaPreview}/${id}`);
  }, [navigate]);

  useHandleMutation({ ...updateDraftMutation, onSuccess: onSuccessUpdate });

  useEffect(() => {
    if (certificates) {
      const certificatesData = certificates?.map((item) => item?.Base64RawData);

      checkCertificates({
        poaId: id ?? '',
        certificates: certificatesData,
      });
    }
  }, [certificates]);

  useEffect(() => {
    if (fingerprint && fingerprint?.length > 0) {
      const certificate = certificates?.find((item) => item?.Base64RawData === fingerprint);
      if (certificate) {
        setHasError(false);
        dispatch(setCertificate(certificate));
        dispatch(setCertificateInfo(certificate.Subject));
      } else {
        setHasError(true);
      }
    } else {
      setHasError(true);
    }
  }, [fingerprint]);

  useEffect(() => {
    if (currentCertificate) {
      getPoaDraft({
        id: id ?? '',
        cert: currentCertificate?.Base64RawData ?? '',
      });
    }
  }, [currentCertificate]);

  const onSubmit = async (values: PoaAcceptFromValues) => {
    const {
      dateOfBirth,
      citizenship,
      lossOfAuthority,
      managingEmployes,
      powerOfAttorneyPeriod,
      possibilityTransferTrust,
    } = values;

    const [issueDate, expirationDate] = JSON.parse(powerOfAttorneyPeriod);

    await updateDraft({
      input: {
        authorityCitizenship: citizenship,
        cert: currentCertificate?.Base64RawData,
        authorityBirthday: prepareDate(dateOfBirth),
        representativeId: draft?.representative?.id ?? '',
        authorityInvitation: {
          inn: 'null',
          name: 'null',
          snils: 'null',
          surname: 'null',
          position: 'null',
          parentalName: 'null',
        },
        poaInfo: {
          id: draft?.number ?? '',
          issueDate: prepareDate(issueDate),
          expirationDate: prepareDate(expirationDate),
          retrust: possibilityTransferTrust as Retrust,
          authorityLossFlag: lossOfAuthority,
        },
        legalEntity: {
          kpp: authority?.kpp?.length ? authority?.kpp : '000',
          foundation: 'Устав',
        },
        powerDomain: [
          {
            code: '1',
            textDescription: 'Управление профилем компании в Контрактиум',
            content: 'Управление профилем компании в Контрактиум',
          },
          {
            code: '2',
            textDescription: 'Подписание соглашения о неразглашении и порядке обработки данных',
            content: 'Подписание соглашения о неразглашении и порядке обработки данных',
          },
          managingEmployes && {
            code: '3',
            textDescription: 'Управление сотрудниками в Контрактиум',
            content: 'Управление сотрудниками в Контрактиум',
          },
        ]?.filter((item) => item !== false) as Array<PowerDomain>,
      },
    });
  };

  if (isPoaFetching || isCertificateFetching) {
    return <Loader size="big" />;
  }

  if (hasError) {
    return (
      <div className={s.notFound}>
        <NotFound
          notFoundTitle=""
          hasSearch={false}
          title="Требуемый сертификат"
          subtitle="не найден"
        />
      </div>
    );
  }

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.firstBlock}>
          <div className={s.subtitle}>
            {t(
              'Welcome to Contracteum, a platform for secure digital transactions under your full control',
            )}
          </div>
          <div className={s.subtitle}>
            {t(
              'The solution ensures the confidentiality of transmitted information and guarantees the legal significance of transactions',
            )}
          </div>
        </div>

        <div className={s.secondBlock}>
          <div className={s.wrapperDesc}>
            <span className={s.description}>
              {`${representative?.surname ?? ''} ${representative?.name ?? ''} ${
                representative?.parental_name ?? ''
              } ${t('requests authority from you to register')} ${
                authority?.organization_name
              } ${t('in Contracteum')}`}
            </span>
            {/* <div className={s.wrapperIcon}>
              <InfoIcon />

              <p className={s.labelInfo}>
                {t('Important! You can revoke the power of attorney at any time')}
              </p>
            </div> */}
          </div>

          <Formik
            validateOnMount
            onSubmit={onSubmit}
            initialValues={initialValues({ draft })}
            validationSchema={validationSchema}
          >
            {({ errors, isValid, values, isSubmitting, submitForm }) => (
              <>
                <div className={s.wrapperForm}>
                  <PoaAcceptForm values={values} />
                </div>

                <div className={s.buttonContainer}>
                  <Button
                    type="primary"
                    className={s.button}
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid || !!Object.values(errors)?.length}
                  >
                    {t('Create a Power of Attorney')}
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>

      <div className={s.bottom}></div>
    </div>
  );
}
