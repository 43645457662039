import dayjs from 'dayjs';
import SIGNATURE_TEMPLATE from '../templates/signature-template';
import { Certificate, TemplateData } from 'client/models';
import { unicodeSafeFromBase64, unicodeSafeToBase64 } from 'helpers';

export default class TemplateSignerBase {
  defaultProps = {
    signatureMethodAlgorithm: '',
    digestMethodAlgorithm: '',
    digestValue: '',
    signatureValue: '',
    x509SerialNumber: '',
    validFrom: '2023-12-09T20:59:59.000Z',
    validUntil: '2023-07-19T15:45:00.000Z',
  };

  parseInfo(str: string, replcaementLabelsOverride?: Map<string, string>) {
    const replcaementLabels = new Map([
      ['ИНН', 'INN'],
      ['Владелец', 'CN'],
      ['Город', 'L'],
      ['Регион', 'S'],
      ['Компания', 'O'],
      ['ОГРН', 'OGRN'],
      ['ИННЮЛ', 'INNLE'],
      ['Улица', 'STREET'],
      ['Фамилия', 'SN'],
      ['Имя/Отчество', 'G'],
      ['Должность', 'T'],
    ]);

    return str.split(',').map((item) => {
      const [key, value] = item.split(':');

      return {
        value: (replcaementLabelsOverride ?? replcaementLabels).get(key) ?? key,
        text: value,
      };
    });
  }

  objectKeysValuesToString(obj: any) {
    let result = '';
    for (const key in obj) {
      result += `${key}: ${obj[key]}, `;
    }
    return result.slice(0, -2);
  }

  formatCompanyName(title: string) {
    return title
      .replace(/""(.*?)""/g, '«$1»')
      .replace(/^"|"$/g, '')
      .replaceAll('\\', '')
      .replaceAll('"', '');
  }

  formatValue(title: string) {
    return title.replaceAll('\\', '').replaceAll('"', '');
  }

  getCompanyName(certificate: Certificate) {
    const subject = certificate.Subject;

    const CN = subject?.['Компания'] ?? subject?.['CN'] ?? subject?.['Имя/Отчество'];

    return this.formatCompanyName(CN);
  }

  getCertificateInfo(certificate: Certificate, replcaementLabels?: Map<string, string>) {
    const subject = certificate.Subject;

    let value = this.objectKeysValuesToString(subject);
    const parsed = this.parseInfo(
      JSON.stringify(subject)?.replaceAll(`"`, '')?.slice(1, -1),
      replcaementLabels,
    );
    parsed.forEach((item, index) => {
      if (item?.text) {
        value += `${index === 1 ? ', ' : ''}${item?.value}=${this.formatValue(item?.text)},`;
      }
    });

    return value;
  }

  getCertificateSerialNumber(certificate: Certificate) {
    return certificate?.Thumbprint;
  }

  getIdFile(inputString: string) {
    const pattern = /ИдФайл="([^"]+)"/;
    const match = pattern.exec(inputString);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }

  async addSignatureTemplate(
    content: string,
    certificate: Certificate,
    props?: Partial<TemplateData>,
    replcaementLabels?: Map<string, string>,
  ) {
    const xmlDocument = unicodeSafeFromBase64(content);

    const data = { ...this.defaultProps, ...props };

    const substitutes: Array<any> = [
      { key: '{#@COMPANY_NAME@#}', value: this.getCompanyName(certificate) },
      { key: '{#@SIGNATURE_METHOD_ALGORITHM@#}', value: data.signatureMethodAlgorithm },
      { key: '{#@DIGEST_METHOD_ALGORITHM@#}', value: data.digestMethodAlgorithm },
      { key: '{#@DIGEST_VALUE@#}', value: data.digestValue },
      { key: '{#@SIGNATURE_VALUE@#}', value: data.signatureValue },
      {
        key: '{#@CERTIFICATE_INFO@#}',
        value: this.getCertificateInfo(certificate, replcaementLabels),
      },
      {
        key: '{#@CERTIFICATE_SERIAL_NUMBER@#}',
        value: this.getCertificateSerialNumber(certificate),
      },
      { key: '{#@ID_FILE@#}', value: this.getIdFile(xmlDocument) },
      { key: '{#@CURRENT_ISO_DATE_TIME@#}', value: dayjs().toISOString() },
      { key: '{#@VALID_FROM@#}', value: data.validFrom },
      { key: '{#@VALID_UNTIL@#}', value: data.validUntil },
    ];

    const signTemplate = substitutes
      .reduce(
        (template, substitute) => template.replaceAll(substitute.key, substitute.value),
        SIGNATURE_TEMPLATE,
      )
      ?.trim();

    if (xmlDocument.indexOf('<soapenv:Header>') === -1) {
      throw new Error('The provided XML document does not contain a soapenv:Header element.');
    }

    const xmlOutput = xmlDocument.replace(
      '<soapenv:Header>',
      `<soapenv:Header>${signTemplate}`,
    );

    return unicodeSafeToBase64(xmlOutput);
  }
}
