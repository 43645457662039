import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { DatePickerMode } from 'components/common/date-picker/date-picker';
import { AuthorityInfo, PoaDraft, Retrust } from 'store/api/types';
import { DefaultDateFormat } from 'utils/date';

export interface PoaAcceptFromValues {
  dateOfBirth: string;
  citizenship: string;
  managingEmployes: boolean;
  possibilityTransferTrust: string;
  lossOfAuthority: boolean;
  powerOfAttorneyPeriod: string;
}

export const model = {
  dateOfBirth: {
    type: 'date',
    required: true,
    mode: DatePickerMode.SINGLE,
    name: 'dateOfBirth',
    label: 'Date of birth',
  },

  citizenship: {
    type: 'radio',
    required: true,
    name: 'citizenship',
    label: 'Citizenship',
    options: [
      { label: 'Citizen of the Russian Federation', value: 'ru' },
      { label: 'Foreign citizen', value: 'foreign' },
    ],
  },

  possibilityTransferTrust: {
    label: 'Possibility of transfer of trust',
    name: 'possibilityTransferTrust',
    placeholder: 'Select from the list',
    options: [
      { label: 'Without the right of transfer', value: 'no_retrust' },
      { label: 'One-time transfer', value: 'single_retrust' },
      {
        label: 'Transfer of confidence followed by transfer of confidence',
        value: 'multi_retrust',
      },
    ] as Array<{ value: Retrust; label: string }>,
    required: true,
  },

  lossOfAuthority: {
    type: 'radio',
    name: 'lossOfAuthority',
    label: 'Loss of authority in case of transfer of trust',
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  powerOfAttorneyPeriod: {
    type: 'date',
    required: true,
    mode: DatePickerMode.RANGE,
    name: 'powerOfAttorneyPeriod',
    label: 'The period of validity of the power of attorney',
    tomorrow: true,
  },

  managingEmployes: {
    name: 'managingEmployes',
    label: `Managing employees in the Contracteum`,
  },
  country: {
    required: true,
    name: 'country',
    label: 'Country',
    placeholder: 'Select from the list',
  },
};

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    [model.dateOfBirth.name]: Yup.string().required('This field is required'),
    [model.possibilityTransferTrust.name]: Yup.string().required('This field is required'),
    [model.powerOfAttorneyPeriod.name]: Yup.string().required('This field is required'),
  });

export const initialValues = ({ draft }: { draft: PoaDraft | undefined }) => {
  const authority: AuthorityInfo = JSON.parse(draft?.authority?.rawUserInfo ?? '{}');

  const today = dayjs();
  const expirationDate = today.add(1, 'year').add(1, 'day');
  const issueDate =
    draft?.issueDate && dayjs(draft.issueDate).isAfter(today) ? dayjs(draft.issueDate) : today;

  return {
    [model.managingEmployes.name]: true,
    [model.citizenship.name]: authority?.citizenship_flag ?? 'ru',
    [model.possibilityTransferTrust.name]: draft?.retrust === 'unset' ? null : draft?.retrust,
    [model.lossOfAuthority.name]: draft?.authorityLossFlag ?? false,
    [model.dateOfBirth.name]: authority?.birth_date
      ? dayjs(authority?.birth_date)?.format(DefaultDateFormat)
      : null,
    [model.powerOfAttorneyPeriod.name]: JSON.stringify([
      issueDate.format(DefaultDateFormat),
      expirationDate.format(DefaultDateFormat),
    ]),
  } as unknown as PoaAcceptFromValues;
};
