import cx from 'classnames';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { Button } from 'components/ui/Button';

import s from './aside-modal.module.scss';

export const AsideModal = ({
  title,
  isOpen,
  onClick,
  children,
  initialValues,
  validationSchema,
}: {
  title: string;
  isOpen: boolean;
  initialValues: any;
  onClick: () => void;
  validationSchema: any;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();

  const containerClasses = cx(s['container'], {
    [s['open']]: isOpen,
  });
  const wrapperClasses = cx(s['wrapper'], {
    [s['open']]: isOpen,
  });
  const classesButtonBack = cx(s['button'], s['button-back']);
  const classesButtonSave = cx(s['button'], s['button-save']);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (onClick) {
        onClick();
      }
    }
  };

  const onSubmit = () => {
    //
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, submitForm }) => (
        <div className={containerClasses}>
          <div className={wrapperClasses}>
            <div className={s['header']}>
              <span className={s['title']}>{t(title)}</span>

              <div
                role="button"
                tabIndex={0}
                className={s['close']}
                onClick={onClick}
                onKeyDown={handleKeyDown}
              >
                <CloseIcon />
              </div>
            </div>

            <div className={s['main']}>
              <div className={s['scroll']}>{children}</div>
            </div>

            <div className={s['footer']}>
              <Button className={classesButtonBack} onClick={onClick}>
                {t('Back')}
              </Button>
              <Button className={classesButtonSave} onClick={onClick}>
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
