import cx from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ListAttr } from 'components/list';
import { Status, variantStatus } from 'components/status/status';
import { Button } from 'components/ui/Button';

import { ReactComponent as EditIcon } from 'assets/images/edit.svg';

import {
  initialValues,
  validationSchema,
} from '../../../../components/modal/aside-edit-staff/config';

import Dropdown from 'components/dropdown';
import { DropdownItemDefault } from 'components/dropdown/items/default';
import { Icons } from 'components/icons';
import { Loader } from 'components/loader/loader';
import { AsideEditStaff } from 'components/modal/aside-edit-staff/aside-edit-staff';
import { StaffPermissionsForm } from 'components/staff/staff-permissions-form';
import { AppRoutes } from 'config/routes';
import { AttributesWhitelistStaff } from 'constants/attributes';
import { useGetEmployeePowersQuery } from 'store/api/employePowers';
import { useGetStaffByIdQuery, useStaffPoasListQuery } from 'store/api/staff';
import { StaffPower } from 'store/api/types/staff';
import styles from './StaffDetail.module.scss';
import { StaffPermission } from './staff-permission';

interface CheckboxListParams {
  id: number;
  label: string;
  checked: boolean;
  disabled?: boolean;
  status?: string;
  poaRequired?: boolean;
  statusText?: string;
}

const varinatBlockedButtons = ['delete', 'expectation'];

export const StaffDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { data: staff } = useGetStaffByIdQuery({ id: id ?? '' }, { skip: !id });
  const { data: permissions = [] } = useGetEmployeePowersQuery();

  const isAdministrator = staff?.organization_context?.[0]?.role === 'administrator';

  const activePowers = !isAdministrator
    ? staff?.organization_context?.[0]?.powers?.reduce((acc: any, item: StaffPower) => {
        acc.push(item.code);

        return acc;
      }, [])
    : permissions?.map((item) => item.code) ?? [];

  const classesButton = cx(styles['button']);
  const classesButtonBack = cx(styles['button'], styles['button-back']);

  const handleOpenEditing = () => {
    setIsEditing(true);
  };

  const handleCloseEditing = () => {
    setIsEditing(false);
  };

  if (!staff) {
    return <Loader size="big" />;
  }

  const getButtonSettings = (item: CheckboxListParams) => {
    switch (staff?.status) {
      case variantStatus.expectation:
      case variantStatus.delete:
        return {
          disabled: true,
          text: 'Previously issued, but not valid',
        };
      case variantStatus.secondaryExpectation:
        return {
          disabled: false,
          text: item.label,
        };
      default:
        return {
          disabled: item.disabled,
          text: item.label,
        };
    }
  };

  const renderGroupButtons = () => {
    if (staff?.status && varinatBlockedButtons.includes(staff?.status)) {
      return null;
    } else if (staff?.status && staff?.status === variantStatus.secondaryExpectation) {
      return (
        <div>
          <Button
            className={classesButtonBack}
            onClick={() => {
              //
            }}
          >
            {t('Reject')}
          </Button>

          <Button
            icon={<EditIcon />}
            className={classesButton}
            onClick={() => {
              //
            }}
          >
            {t('Confirm request')}
          </Button>
        </div>
      );
    }

    return (
      <div className={styles['wrapper-action']}>
        <Dropdown
          button={
            <div className={styles.moreButton}>
              <Icons.More />
            </div>
          }
        >
          <DropdownItemDefault onClick={handleOpenEditing}> {t('Edit')}</DropdownItemDefault>
        </Dropdown>
      </div>
    );
  };

  if (!staff) {
    return <span>Not found staff</span>;
  }

  return (
    <div className={styles['container']}>
      <div className={styles['container-main']}>
        <div className={styles['content']}>
          <div className={styles['header']}>
            <div className={styles['info-user']}>
              <div>
                <Status text={staff.status} variant={staff.status} />
              </div>
              <div className={styles['container-attr']}>
                <span className={styles['name']}>
                  {staff.lastName} {staff.firstName} {staff.middleName}
                </span>
                <div className={styles['wrapper-attr']}>
                  <span className={styles['attr']}>ID:</span>
                  <span className={styles['value']}>{staff.id}</span>
                </div>
                <ListAttr
                  attrs={staff.attributes}
                  whitelist={[...AttributesWhitelistStaff, 'email']}
                  additionally={['birthday']}
                />
              </div>
            </div>

            {renderGroupButtons()}
          </div>

          <div className={styles['wrapper-main']}>
            <div className={styles['header-main']}>
              <div className={styles['wrapper-main-attr']}>
                <span className={styles['main-attr']}>{`${t('Должность')}:`}</span>
                <span className={styles['main-value']}>
                  {staff?.organization_context?.[0]?.position?.length
                    ? staff?.organization_context?.[0]?.position
                    : staff?.organization_context?.[0]?.role}
                </span>
              </div>
            </div>

            <div className={styles['container-checkbox']}>
              {!isEditMode && (
                <div className={styles.permissionsActions}>
                  <span className={styles['main-attr']}>{t('Authority')}</span>

                  {!isAdministrator && (
                    <Button
                      className={styles.button}
                      icon={<Icons.Plus width={16} height={16} />}
                      text="Выдать полномочия"
                      onClick={() => {
                        setIsEditMode(true);
                      }}
                    />
                  )}
                </div>
              )}

              <div className={styles['lists-checkbox']}>
                {isEditMode && (
                  <StaffPermissionsForm
                    activePowers={activePowers}
                    onCancel={() => {
                      setIsEditMode(false);
                    }}
                  />
                )}
                {!isEditMode &&
                  permissions.map((item) => (
                    <StaffPermission
                      key={item.code}
                      title={item.name}
                      disabled={!activePowers.includes(item.code)}
                    />
                  ))}
              </div>

              <div className={styles.divider} />

              {!isAdministrator && (
                <a
                  className={styles.linkContainer}
                  href={
                    AppRoutes.Dashboard.Home +
                    AppRoutes.Dashboard.PowersAttorney.Home +
                    AppRoutes.Dashboard.PowersAttorney.Active +
                    `?representativeEmployeeID=${staff?.id}`
                  }
                >
                  <span>Перейти к доверенностям сотрудника</span>
                  <Icons.ArrowRight height={16} width={16} />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      {!varinatBlockedButtons.includes(staff.status) && (
        <AsideEditStaff
          title="Editing"
          isOpen={isEditing}
          onClick={handleCloseEditing}
          initialValues={initialValues}
          validationSchema={validationSchema}
        />
      )}
    </div>
  );
};
