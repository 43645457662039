import { DatePickerField, InputField } from 'components/common';
import { CheckboxField } from 'components/fields/checkbox-field';
import { SelectField } from 'components/fields/select-fields/select-filed';
import dayjs from 'dayjs';
import { DefaultDateFormat } from 'utils/date';

export const SchemeModificators = {
  required: 'required',
  disabled: 'disabled',
};

export const generateForm = (model, step, modificators = []) => {
  const filtered = Object.entries(model).filter((values) => {
    const [, item] = values;

    if (step) {
      return item?.step === step;
    }

    return true;
  });

  return filtered?.map((values) => {
    const [, item] = values;
    const modifications = modificators.reduce((acc, modificator) => {
      if (modificator) {
        acc[modificator] = true;
      }

      return acc;
    }, {});

    switch (item.fieldType) {
      case 'input':
        return <InputField {...item} {...modifications} />;
      case 'select':
        return <SelectField {...item} {...modifications} />;
      case 'datepicker':
        return <DatePickerField {...item} {...modifications} />;
      case 'checkbox':
        return <CheckboxField {...item} {...modifications} />;
      default:
        return null;
    }
  });
};

export const generateInitialValues = (model) => {
  return Object.entries(model).reduce((acc, values) => {
    const [key, item] = values;

    if (item?.value) {
      acc[key] = item.value;

      if (item?.fieldType === 'datepicker') {
        acc[key] = dayjs(item?.value, 'YYYY-MM-DD').format(DefaultDateFormat);
      }
    }

    return acc;
  }, {});
};
