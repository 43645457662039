import cx from 'classnames';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';

import { initialValues, validationSchema } from 'components/forms/profile/config';

import {
  ContactInformation,
  EditingProfileForm,
} from 'components/forms/profile/editing/editing';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { Button } from 'components/ui/Button';

import s from './aside-profile.module.scss';

export const AsideProfile = ({ isOpen, onClick }: { isOpen: boolean; onClick: () => void }) => {
  const { t } = useTranslation();

  const containerClasses = cx(s['container'], {
    [s['open']]: isOpen,
  });
  const wrapperClasses = cx(s['wrapper'], {
    [s['open']]: isOpen,
  });
  const classesButtonBack = cx(s['button'], s['button-back']);
  const classesButtonSave = cx(s['button'], s['button-save']);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (onClick) {
        onClick();
      }
    }
  };

  const onSubmit = () => {
    //
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, submitForm }) => (
        <div className={containerClasses}>
          <div className={wrapperClasses}>
            <div className={s['header']}>
              <span className={s['title']}>{t('Add authority')}</span>

              <div
                role="button"
                tabIndex={0}
                className={s['close']}
                onClick={onClick}
                onKeyDown={handleKeyDown}
              >
                <CloseIcon />
              </div>
            </div>

            <div className={s['main']}>
              <div className={s['scroll']}>
                <ContactInformation />
                <EditingProfileForm />
              </div>
            </div>

            <div className={s['footer']}>
              <Button
                displayType="outlined-secondary"
                className={classesButtonBack}
                onClick={onClick}
              >
                {t('Cancel')}
              </Button>
              <Button className={classesButtonSave} onClick={onClick}>
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
