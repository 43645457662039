import { fromUrlSafeBase64 } from 'helpers';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StaffPower } from 'store/api/types/staffPowers';

export const useStaffPermissionsData = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const staffPermissionsParams = urlParams.get('staffPermissionsData');

  const [staffPermissionsData, setStaffPermissionsData] = useState<{
    permissions: StaffPower[];
    staffId: string;
  } | null>(null);

  useEffect(() => {
    if (staffPermissionsParams) {
      try {
        const value = JSON.parse(fromUrlSafeBase64(staffPermissionsParams));

        setStaffPermissionsData({ ...value, staffId: value.staffId.replaceAll('+', '-') });
      } catch (error) {
        console.error(error);
      }
    }
  }, [staffPermissionsParams]);

  return staffPermissionsData;
};
