import { useTranslation } from 'react-i18next';

import s from './requisites.module.scss';
import { useGetCoreDictionariesCountriesQuery } from 'store/api/dictionaries';
import { Organization } from 'store/api/types';

interface AddressColumnData {
  id: number;
  label: string;
  description: string;
}

interface DescriptionAbbreviationProps {
  name: string;
  value: string;
}

const TableHeader = ({ title }: { title: string }) => {
  const { t } = useTranslation();

  return (
    <div className={s.wrapperHeader}>
      <span className={s.title}>{t(title)}</span>
    </div>
  );
};

const TableBody = ({
  organization,
  columnData,
}: {
  organization: Organization | undefined;
  columnData: DescriptionAbbreviationProps[];
}) => {
  const { t } = useTranslation();
  const { data: [countriesDictionary] = [], isFetching } =
    useGetCoreDictionariesCountriesQuery();

  const transformCountryCode = (code: string) => {
    const country = countriesDictionary?.enums?.find(
      (item) => item?.title?.toLowerCase().trim() === code?.trim()?.toLowerCase(),
    )?.values;

    if (code?.trim()?.toLocaleLowerCase() === 'ru') {
      return 'Россия';
    }

    if (code?.trim()?.toLocaleLowerCase() === 'kg') {
      return 'Киргизия';
    }

    const countryFullName =
      country?.find((item) => item?.key === 'fullname')?.value ??
      country?.find((item) => item?.key === 'shortname')?.value ??
      code;

    return countryFullName;
  };

  return (
    <div className={s.wrapperBody}>
      {columnData
        ?.map((item) => {
          if (item?.name === 'address') {
            return {
              name: item.name,
              value: `${transformCountryCode(
                organization?.attributes?.find((it) => it.name === 'country')?.value ??
                  organization?.countryCode ??
                  '',
              )}, ${item.value}`,
            };
          }
          if (item.name === 'postal_address') {
            return {
              name: item.name,
              value: `${transformCountryCode(
                organization?.attributes?.find((it) => it.name === 'postal_country')?.value ??
                  organization?.countryCode ??
                  '',
              )}, ${item.value}`,
            };
          }
          return item;
        })
        .map((item, index) => (
          <div key={index.toString()} className={s.contentBody}>
            <span className={s.label}>{t(item.name)}: </span>
            <span className={s.desc}>{item.value}</span>
          </div>
        ))}
    </div>
  );
};

export const TableColumn = ({
  max,
  min,
  data,
  title,
  organization,
}: {
  max?: number;
  min?: number;
  title: string;
  data: DescriptionAbbreviationProps[];
  organization?: Organization | undefined;
}) => {
  return (
    <div className={s.wrapperColumn}>
      <div className={s.column} style={{ minWidth: `${min}px`, maxWidth: `${max}px` }}>
        <TableHeader title={title} />
        <TableBody organization={organization} columnData={data} />
      </div>
    </div>
  );
};

export const TableRequisites = ({
  id,
  max,
  title,
  data,
  organization,
}: {
  id: number;
  max?: number;
  min?: number;
  title: string;
  data: DescriptionAbbreviationProps[];
  organization?: Organization | undefined;
}) => {
  return (
    <div className={s[`column-${id + 1}`]}>
      <TableColumn organization={organization} max={max} title={title} data={data} />
    </div>
  );
};
