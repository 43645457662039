import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: 'active',
};

const attorneyListSlice = createSlice({
  name: 'attorneyList',
  initialState,
  reducers: {
    setAttorneysType(state, action) {
      state.type = action.payload;
    },
  },
});

export const { setAttorneysType } = attorneyListSlice.actions;

export default attorneyListSlice.reducer;

const selectStore = (store: any) => store.attorneyList;

export const selectAttorneysType = createSelector([selectStore], (store) => store.type);
