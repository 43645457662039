import { DatePickerField, InputField } from 'components/common';
import { FormikHelpers } from 'formik';
import { AttorneyFormValues, model } from '../config';

import { RadioGroupField } from 'components/fields/radio-group-field/select-filed';
import { SelectField } from 'components/fields/select-fields/select-filed';
import { Loader } from 'components/loader/loader';

import { CounterpartyType } from 'constants/attributes';
import { useCountriesOptions } from 'hooks/use-countires-options';
import { t } from 'i18next';
import s from './information-principal.module.scss';

type Props = {
  values: AttorneyFormValues;
};

export const InformationAboutSignatory = ({ values }: { values: AttorneyFormValues }) => {
  const { defaultCountry, isFetching, options } = useCountriesOptions();

  if (isFetching) {
    return <Loader size="medium" />;
  }

  return (
    <div className={s['container-signatory']}>
      <span className={s['title']}>{t('Information about the signatory')}</span>
      <DatePickerField {...model.dateOfBirth} />
      <RadioGroupField variant="column" {...model.citizenship} />
      {values.citizenship === 'foreign' && (
        <SelectField {...model.country} options={options} defaultValue={defaultCountry} />
      )}
    </div>
  );
};

export const InformationPrincipal = ({ values }: Props) => {
  const isLegalEntity =
    values?.type === CounterpartyType.LegalEntity || values?.type === CounterpartyType.General;

  return (
    <div className={s['container']}>
      <InputField {...model.nameCompany} />
      {isLegalEntity && (
        <>
          <InputField {...model.kpp} disabled={values.isKppDisabled} />
          <InputField {...model.nameDocumentConfirmingAuthority} />
        </>
      )}
      <InformationAboutSignatory values={values} />
    </div>
  );
};
