import { AttributesWhitelistStaff } from 'constants/attributes';
import { useTranslation } from 'react-i18next';
import { sortCustomAttributes } from 'utils/attributes-order';
import s from './ListAttr.module.scss';

interface ListAttrProps {
  attrs: Attribute[];
  disableFilter?: boolean;
  additionally?: string[];
  whitelist?: string[];
}

interface Attribute {
  name: string;
  value: string;
}

export const ListAttr = ({
  attrs,
  whitelist = [],
  additionally = [],
  disableFilter,
}: ListAttrProps) => {
  const { t } = useTranslation();

  return (
    <div className={s['container']}>
      {sortCustomAttributes(attrs, whitelist)
        ?.filter((item) => {
          if (disableFilter) {
            return item?.value?.length;
          } else {
            return (
              [...whitelist, ...additionally]?.includes(item?.name?.toString()) &&
              item?.value?.length
            );
          }
        })
        ?.map((item: Attribute) => (
          <div key={item.name.toString()} className={s['wrapper-attr']}>
            <span className={s['attr']}>{`${t(item?.name)}:`}</span>
            <span className={s['value']}>{item.value}</span>
          </div>
        ))}
    </div>
  );
};
