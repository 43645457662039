import { gql } from 'graphql-request';

export const GET_CORE_DOCUMENT_STATUSES = gql`
  query CoreDocuments {
    coreDocuments {
      getStatuses {
        id
        alias
        appId {
          id
        }
      }
    }
  }
`;
