import { getToken } from 'utils/token';
import mockApiConfig from './mock-api-config';

export default async function api(path: string, init?: RequestInit): Promise<Response> {
  const url = mockApiConfig.get(path) ?? `${process.env.REACT_APP_API_BASE_PATH}/${path}`;

  const token = getToken();
  init = { ...init, headers: { ...init?.headers, Authorization: `Bearer ${token}` } };

  const response = await fetch(url, init);

  if (!response.ok) {
    let errorResponse;
    try {
      errorResponse = await response.json();
    } catch (error) {
      throw { message: 'Unexpected error' };
    }
    throw errorResponse;
  }

  return response;
}
