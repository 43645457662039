import { TFunction } from 'i18next';
import { PoaDraft } from 'store/api/types';
import * as Yup from 'yup';

export interface AttorneyRepresentiveFormValues {
  authority: string;
  powerDomains: Array<string>;
  representative: string;
}

export const model = {
  //information representative
  authority: {
    disabled: true,
    type: 'radio',
    required: true,
    name: 'authority',
    label: 'Authority',
    options: [
      { label: 'Organization Classifier', value: 'organization' },
      { label: 'Recipient Classifier', value: 'recipient' },
      { label: 'Set manually', value: 'manual' },
    ],
  },
  powerDomains: {
    type: 'text',
    required: true,
    name: 'powerDomains',
    fieldArrayName: 'powerDomains',
    placeholder: 'Enter the permissions',
  },
  representative: {
    label: 'Representative',
    name: 'representative',
    placeholder: 'Select a representative from the list',
    options: ['Loading...'].map((label) => ({
      label,
      value: label,
    })),
    required: true,
  },
};

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    [model.authority.name]: Yup.string().required('Please select option'),
    [model.powerDomains.name]: Yup.array()
      .of(Yup.string().required('Please enter a description of the power'))
      .required(),
    [model.representative.name]: Yup.string().required(t('Please specify a representative')),
  });

export const validationSchemaHasPrePower = (t: TFunction) =>
  Yup.object().shape({
    [model.authority.name]: Yup.string().required('Please select option'),
    [model.representative.name]: Yup.string().required(t('Please specify a representative')),
  });

export const initialValues = (draft: PoaDraft | undefined) =>
  ({
    [model.authority.name]: 'manual',
    [model.representative.name]: '',
    [model.powerDomains.name]: draft?.powerDomain?.map((item) => item.textDescription) ?? [],
  } as unknown as AttorneyRepresentiveFormValues);
