import { Main } from './main/Main';
import { EventsInfo } from './events-info/EventsInfo';
import { useTranslation } from 'react-i18next';

import s from './Infopanel.module.scss';

function Infopanel() {
  const { t } = useTranslation();
  return (
    <div className={s.container}>
      <div className={s.title}>{t('Infopanel')}</div>
      <div className={s.wrapper}>
        <div className={s.main}>
          <Main />
        </div>
        <div className={s.aside}>
          <EventsInfo />
        </div>
      </div>
    </div>
  );
}

export default Infopanel;
