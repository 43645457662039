import { useTranslation } from 'react-i18next';

import { model } from 'components/attorney/forms/config';

import { FormikValues } from 'formik';

import { Loader } from 'components/loader/loader';

import { DatePickerField, InputField } from 'components/common';
import { RadioGroupField } from 'components/fields/radio-group-field/select-filed';
import { SelectField } from 'components/fields/select-fields/select-filed';

import { useCountriesOptions } from 'hooks/use-countires-options';
import s from './representative-information.module.scss';

export const RepresentativeInformation = ({ values }: { values: FormikValues }) => {
  const { t } = useTranslation();

  const { isFetching, options } = useCountriesOptions();
  const firstOption = model.citizenshipRepresentative.options[0];

  if (isFetching) {
    return <Loader size="medium" />;
  }

  return (
    <div className={s['container']}>
      <span className={s['title']}>{t('Information about an individual')}</span>
      <div className={s['wrapper-content']}>
        <InputField {...model.surname} />
        <InputField {...model.name} />
        <InputField {...model.middleName} />
        <InputField {...model.innFl} />
        <InputField {...model.snils} />
        <DatePickerField {...model.birthdateRepresentative} />
        <RadioGroupField
          variant="column"
          {...model.citizenship}
          value={{
            label: t(firstOption.label),
            value: firstOption.value,
          }}
        />
        {values.citizenship === 'foreign' && (
          <SelectField {...model.country} options={options} />
        )}
        <SelectField
          {...model.typeIdentityDocument}
          options={model.typeIdentityDocument?.options.map((item) => ({
            label: t(item.label),
            value: item.value,
          }))}
        />
        <InputField {...model.documentSeriesAndNumber} />
        <DatePickerField {...model.dateIssueDocument} />
        <InputField {...model.nameAuthorityIssuedDocument} />
      </div>
    </div>
  );
};
