import { AttorneysList } from 'components/sidenav-message/attorneys/AttorneysList';

import { AttorneyInfo } from 'components/card/attorney/attorney-info/AttorneyInfo';
import { NotFound, NotFoundContainer } from 'components/not-found';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPoaDraftsListQuery } from 'store/api/poa';
import { PoaDraft } from 'store/api/types';
import s from './Drafts.module.scss';
import { Loader } from 'components/loader/loader';

export const Drafts = () => {
  const { id } = useParams();
  const { data = [], isFetching } = useGetPoaDraftsListQuery();
  const [currentItem, setCurrentItem] = useState<PoaDraft | null>(null);

  useEffect(() => {
    setCurrentItem(data?.find((item) => item.id === id) ?? data?.[0]);
  }, [id, data]);

  if (!isFetching && !data?.length) {
    return (
      <NotFoundContainer>
        <NotFound
          hasSearch={false}
          title="Here will be displayed"
          subtitle="your powers of attorney"
          notFoundTitle="No power of attorney found"
        />
      </NotFoundContainer>
    );
  }

  if (isFetching) {
    return <Loader size="medium" />;
  }

  return (
    <div className={s['container']}>
      <AttorneysList data={data} />
      <AttorneyInfo currentItem={currentItem} />
    </div>
  );
};
