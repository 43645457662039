import { createApi } from '@reduxjs/toolkit/query/react';

import { CREATE_ORGANIZATION_DOCUMENT } from './mutations/organization.documents/create.organization.document';
import { DELETE_ORGANIZATION_DOCUMENT } from './mutations/organization.documents/delete.organization.document';
import {
  CreateOrganizationDocumentInput,
  DeleteOrganizationDocumentInput,
} from './mutations/organization.documents/types';
import { GET_CONTRACTOR_ORGANIZATION_DOCUMENTS } from './queries/organization.documents/get.contractor.organization.documents';
import { GET_MY_DOCUMENTS } from './queries/organization.documents/get.my.organization.documents';
import {
  GetContractorOrganizationDocumentsInput,
  GetContractorOrganizationDocumentsResponse,
  GetMyOrganizationDocumentsResponse,
} from './queries/organization.documents/types';
import { baseQuery } from './query';
import { OrganizationDocument } from './types';

const OrganizationDocumentsTags = {
  List: 'List',
};
const tagTypes = [OrganizationDocumentsTags.List];

export const organizationDocumentsApi = createApi({
  tagTypes,
  baseQuery: baseQuery,
  reducerPath: 'organizationDocumentsApis',
  endpoints: (builder) => ({
    myDocuments: builder.query<OrganizationDocument[], void>({
      query: () => ({
        document: GET_MY_DOCUMENTS,
      }),
      transformResponse: (rawResponse: GetMyOrganizationDocumentsResponse) => {
        return rawResponse?.coreOrganizationDocuments?.myDocuments?.documents;
      },
      providesTags: [OrganizationDocumentsTags.List],
    }),
    contractorDocuments: builder.query<
      OrganizationDocument[],
      GetContractorOrganizationDocumentsInput
    >({
      query: ({ contractorId }) => ({
        document: GET_CONTRACTOR_ORGANIZATION_DOCUMENTS,
        variables: {
          contractorId,
        },
      }),
      transformResponse: (rawResponse: GetContractorOrganizationDocumentsResponse) => {
        return rawResponse?.coreOrganizationDocuments?.contractorDocuments?.documents;
      },
      providesTags: [OrganizationDocumentsTags.List],
    }),

    createDocument: builder.mutation<void, CreateOrganizationDocumentInput>({
      query: ({ file }) => ({
        document: CREATE_ORGANIZATION_DOCUMENT,
        variables: { file },
      }),
      invalidatesTags: [OrganizationDocumentsTags.List],
    }),

    deleteDocument: builder.mutation<void, DeleteOrganizationDocumentInput>({
      query: ({ id }) => ({
        document: DELETE_ORGANIZATION_DOCUMENT,
        variables: { id },
      }),
      invalidatesTags: [OrganizationDocumentsTags.List],
    }),
  }),
});

export const {
  useMyDocumentsQuery,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useContractorDocumentsQuery,
} = organizationDocumentsApi;
