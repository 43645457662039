import { InputField } from 'components/common';

import { model } from '../../config';

import s from './EmployeeInformation.module.scss';
import { RolesPowers } from '../roles-powers/RolesPowers';

export const EmployeeInformation = () => {
  return (
    <div className={s['container']}>
      <InputField {...model.surname} />
      <InputField {...model.name} />
      <InputField {...model.patronymic} />
      <InputField {...model.snils} />
      <InputField {...model.inn} />
      <InputField {...model.email} />
      <RolesPowers />
    </div>
  );
};
