import { gql } from 'graphql-request';

export const REJECT_INVITATION = gql`
  mutation CoreOrganizations($inviteId: ID!, $rejectReason: String) {
    coreOrganizationInvitations {
      confirmInvitation(
        input: { inviteId: $inviteId, partnershipAgreement: ${false}, rejectReason: $rejectReason }
      )
    }
  }
`;
