import cx from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderPage } from 'components/header-page/header-page';
import { Button } from 'components/ui/Button';
import { AddStaffContent } from './content/Content';

import { SidenavSteps } from 'components/sidenav-message/steps/steps';

import s from './AddStaff.module.scss';
import { Formik, FormikValues } from 'formik';
import { EmployeeInformationFormValues, initialValues, validationSchema } from './config';
import { useStaffInviteMutation } from 'store/api/staff';
import { useAlert } from 'react-alert';
import { useHandleMutation } from 'utils/handleMutation';
import { AppRoutes } from 'config/routes';
import { useNavigate } from 'react-router-dom';

const Step = {
  EmployeeInformation: 1,
  RolesAndPowers: 2,
};

const StepLabels = {
  [Step.EmployeeInformation]: 'Employee Information',
  // [Step.RolesAndPowers]: 'Roles And Powers',
};

function AddStaff() {
  const alert = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(Step.EmployeeInformation);

  const [invite, mutation] = useStaffInviteMutation();

  const { Home, Staff, Invites, List } = AppRoutes.Dashboard.Organizations;

  const onSuccess = useCallback(
    (inviteId: string) => {
      navigate(Home + Staff + Invites + `/${inviteId}`);
      alert.success(t('Employee has been invited successfully'));
    },
    [alert, navigate, t],
  );

  const onError = useCallback(() => {
    alert.error(t('Error while inviting employee'));
  }, [alert]);

  useHandleMutation({ ...mutation, onSuccess, onError });

  const classesButton = cx(s['button']);
  const classesButtonBack = cx(s['button'], s['button-back']);

  const handleNextStep = () => {
    if (step < Step.RolesAndPowers) {
      setStep(step + 1);
      return true;
    }
  };

  const handlePrevStep = () => {
    navigate(Home + Staff + List);
  };

  const onSubmit = async (values: EmployeeInformationFormValues) => {
    invite({
      participantData: {
        INN: values.inn,
        SNILS: values.snils,
        FirstName: values.name,
        LastName: values.surname,
        MiddleName: values.patronymic,
        participantEmail: [values.email ?? ''],
      },
      role: values.role,
      type: 'general',
      position: values?.position,
    });
  };

  return (
    <div className={s['container']}>
      <HeaderPage title={t('Add Staff')} />
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema({ t, step })}
      >
        {({ errors, isSubmitting, isValid, submitForm, values, setFieldValue }) => (
          <div>
            <div className={s['wrapper-content']}>
              <SidenavSteps setStep={setStep} steps={1} step={step} labels={StepLabels} />
              <AddStaffContent step={step} />
            </div>

            <div className={s['footer']}>
              <Button
                displayType="outlined-secondary"
                className={classesButtonBack}
                onClick={handlePrevStep}
              >
                {t('Cancel')}
              </Button>

              <Button
                disabled={isSubmitting || !isValid || !!Object.values(errors)?.length}
                className={classesButton}
                onClick={submitForm}
              >
                {t('Invite')}
              </Button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default AddStaff;
