import { TimeStamp } from 'components/timestamp/timestamp';

export interface TimestampProps {
  id: number;
  start_time: string;
  end_time: string;
  contractNumber: string;
  status?: string;
  variant_status?: string;
  step?: string
}

const dataTimeStamp: TimestampProps[] = [
  {
    id: 0,
    start_time: '20.01.2021',
    end_time: '06.04.2023',
    contractNumber: '789',
    status: 'Completed',
    variant_status: 'blocked',
  },
  {
    id: 1,
    start_time: '20.01.2021',
    end_time: '06.04.2023',
    contractNumber: '789',
    step: 'Act signed',
  },
  {
    id: 2,
    start_time: '20.01.2021',
    end_time: '06.04.2023',
    contractNumber: '789',
    step: 'Act created',
  },
  {
    id: 3,
    start_time: '20.01.2021',
    end_time: '06.04.2023',
    contractNumber: '789',
    status: 'Signed',
    variant_status: 'active',
  },
  {
    id: 4,
    start_time: '20.01.2021',
    end_time: '06.04.2023',
    contractNumber: '789',
    step: 'Agreement created',
  },
]

export const Events = () => {
  return <TimeStamp data={dataTimeStamp} />;
};
