import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import s from './switch.module.scss';
import { ErrorLabel } from 'components/error-label';

export interface SwitchProps {
  id?: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  block?: boolean;
  checked?: boolean;
  onClick?: () => void;
  positionRightIcon?: boolean;
  transparent?: boolean;
  checkedIcon?: React.ReactNode;
  orientation?: boolean;
  error?: string;
}

export const Switch = ({
  id,
  label,
  error,
  onClick,
  checked,
  disabled,
  className,
  orientation,
  ...props
}: SwitchProps) => {
  const { t } = useTranslation();
  const switchId = id || label;

  const classesSlider = cx(s['switch-slider'], {
    [s['slider-on']]: checked,
    [s['slider-off']]: !checked,
  });

  const classesHandler = cx(s['switch-handler'], {
    [s['handler-on']]: checked,
    [s['handler-off']]: !checked,
  });

  const handleToggle = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <label className={cx(s['switch-label'], className)} tabIndex={disabled ? undefined : 0}>
      <div className={s['switch-container']}>
        <div className={classesSlider} />
        <div className={classesHandler} />

        <input
          id={switchId}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          className={cx(s['styled-checkbox'])}
          onChange={disabled ? undefined : handleToggle}
          {...props}
        />
      </div>

      {label && <div className={s['switch-text']}>{t(label)}</div>}

      {error && <ErrorLabel error={error} />}
    </label>
  );
};
