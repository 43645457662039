import { gql } from 'graphql-request';

export const GET_POA_SIGNED_XML = gql`
  query CorePOA($poaId: ID!) {
    corePOA {
      getSignedById(id: $poaId) {
        name
        contents
      }
    }
  }
`;
