import { useMemo, useState } from 'react';
import { AttorneyFormValues, model } from '../config';

import { DatePickerField, InputField } from 'components/common';

import { RadioGroupField } from 'components/fields/radio-group-field/select-filed';
import { SelectField } from 'components/fields/select-fields/select-filed';
import { SwitchField } from 'components/fields/switch-field/select-filed';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/loader/loader';
import { AsideAttorney } from 'components/modal';
import { Button } from 'components/ui/Button';
import { useGetPartnersOrganizationsQuery } from 'store/api/organizations';
import { TicketAttorney } from './ticket-attorney';

import styles from './information-attorney.module.scss';
import { useGetPoaListRetrustQuery } from 'store/api/poa';
import { parsePoaRetrust } from 'components/modal/aside-attorney/aside-attorney';

type Props = {
  isIndividual?: boolean;
  hasPoaForRetrust?: boolean;
  values: AttorneyFormValues;
};

type AttorneyOrderTransferProps = {
  checked: boolean;
};

const AttorneyOrderTransfer = ({ checked, values }: AttorneyOrderTransferProps & Props) => {
  const { t } = useTranslation();

  const { data: poaRetrustList = [] } = useGetPoaListRetrustQuery();

  const { data = [], isFetching } = useGetPartnersOrganizationsQuery({
    searchText: '',
  });

  const [isSelectAttorneyModal, setIsSelectAttorneyModal] = useState<boolean>(false);

  const receiverOptions = useMemo(() => {
    return data?.map((item) => {
      return {
        label: item?.name,
        value: item?.id,
      };
    });
  }, [data]);

  const handleOpenSelectAttorney = () => {
    setIsSelectAttorneyModal(true);
  };
  const handleCloseSelectAttorney = () => {
    setIsSelectAttorneyModal(false);
  };

  const selectedAttorney = useMemo(() => {
    if (values?.selectAttorney && values?.selectAttorney !== '') {
      const selectedAttorney = poaRetrustList?.find(
        (item) => item?.retrustPoaInfo?.number === values?.selectAttorney,
      );
      if (selectedAttorney) {
        return parsePoaRetrust(selectedAttorney);
      }
      return null;
    }
  }, [values]);

  const optionButton = {
    className: styles['button'],
    text: t('Choose a power of attorney'),
    onClick: handleOpenSelectAttorney,
  };

  if (checked) {
    return (
      <>
        {values?.selectAttorney ? (
          <TicketAttorney {...model.selectAttorney} values={selectedAttorney} />
        ) : (
          <Button {...optionButton} />
        )}

        {/* <UploadImageField {...model.xmlFile} /> */}

        <DatePickerField
          {...model.powerOfAttorneyPeriod}
          infoTitle={t(
            'The validity period of the power of attorney issued in the order of transfer may not exceed the validity period of the power of attorney on the basis of which it was issued',
          )}
        />
        <RadioGroupField {...model.lossOfAuthority} />
        <AsideAttorney
          {...model.selectAttorney}
          isOpen={isSelectAttorneyModal}
          handleClose={handleCloseSelectAttorney}
        />
      </>
    );
  } else {
    return (
      <>
        {/* <RadioGroupField {...model.isOneTimePowerOfAttorney} /> */}
        <DatePickerField {...model.powerOfAttorneyPeriod} />
        <SelectField
          {...model.possibilityTransferTrust}
          options={model.possibilityTransferTrust.options.map((item) => ({
            ...item,
            label: t(item.label),
          }))}
        />
        {values?.possibilityTransferTrust === 'single_retrust' ||
        values?.possibilityTransferTrust === 'multi_retrust' ? (
          <RadioGroupField variant="row" {...model.lossOfAuthority} />
        ) : null}
        {isFetching ? (
          <Loader size="medium" />
        ) : (
          <SelectField {...model.receiverOfPowerOfAttorney} options={receiverOptions} />
        )}
      </>
    );
  }
};

export const InformationAttorney = ({ values, isIndividual, hasPoaForRetrust }: Props) => {
  const prePowerOptions = useMemo(() => {
    if (hasPoaForRetrust) {
      if (isIndividual) {
        return { disabled: true };
      } else {
        return { disabled: false };
      }
    }

    return { disabled: true };
  }, [isIndividual, hasPoaForRetrust]);

  return (
    <div className={styles['container']}>
      <InputField {...model.attorneyNumber} />
      <SwitchField {...model.hasPrePowerOfAttorney} {...prePowerOptions} />
      <AttorneyOrderTransfer values={values} checked={values?.hasPrePowerOfAttorney} />
    </div>
  );
};
