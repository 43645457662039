import { useTranslation } from 'react-i18next';

import s from './Main.module.scss';

export const Main = () => {
  const { t } = useTranslation();

  return (
    <div className={s.container}>
      <span className={s.title}>{t('Events')}</span>
      <div className={s.line} />
      <div className={s.newEvents}>{t('There are no new events yet')}</div>
    </div>
  );
};
