import { gql } from 'graphql-request';

export const CREATE_CESSION_DOCUMENT = gql`
  mutation App1d1b2841_Document($input: app1d1b2841_DocumentMutationCession_createInputInput!) {
    app1d1b2841_Document {
      create(input: $input) {
        id
      }
    }
  }
`;
