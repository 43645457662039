import React, { useState, useCallback, Children } from 'react';
import cx from 'classnames';

import s from './accordion.module.scss';

const Accordion = ({
  accordion,
  collapsible,
  defaultActiveKey,
  onChange,
  children,
}: any) => {
  const [currentActiveKey, setCurrentActiveKey] = useState(defaultActiveKey || []);

  const handleClick = useCallback(
    (key: any) => {
      let newActiveKey = [...currentActiveKey];
      if (accordion) {
        if (newActiveKey[0] === key) {
          newActiveKey = [];
        } else {
          newActiveKey = [key];
        }
      } else {
        if (newActiveKey.includes(key)) {
          newActiveKey = newActiveKey.filter((k) => k !== key);
        } else {
          newActiveKey.push(key);
        }
      }
      setCurrentActiveKey(newActiveKey);
      if (onChange) {
        onChange(newActiveKey);
      }
    },
    [accordion, currentActiveKey, onChange]
  );

  return (
    <div className={cx(s['custom-accordion'])}>
      {Children.map(children, (child) =>
        React.cloneElement(child, {
          currentActiveKey,
          collapsible,
          handleClick,
        })
      )}
    </div>
  );
};

export default Accordion;
