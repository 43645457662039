import { createSelector, createSlice } from '@reduxjs/toolkit';
import { FormikValues } from 'formik';
import { RootState } from 'store/store';

const initialState: { formData: FormikValues } = {
  formData: {},
};

const powerAttorneySlice = createSlice({
  name: 'powerAttorney',
  initialState,
  reducers: {
    setDataPowerAttorney: (state, action) => {
      state.formData = action.payload;
    },
    clearDataPowerAttorney: (state) => {
      state.formData = {};
    },
  },
});

export const { setDataPowerAttorney, clearDataPowerAttorney } = powerAttorneySlice.actions;

export default powerAttorneySlice.reducer;

const selectStore = (store: RootState) => store.powerAttorney;

export const selectDataPowerAttorney = createSelector([selectStore], (store) => store.formData);
