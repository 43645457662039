import { gql } from 'graphql-request';

export const GET_DOCUMENT_ARGROPRODUCTION_SCHEME = gql`
  query App36c5da51_Document($documentId: String) {
    app36c5da51_Document {
      getTemplate(documentId: $documentId) {
        template {
          step
          name
          type
          required
          isSearchable
          placeholder
          fieldTransform
          fieldType
          stepLabel
          validation
          value
          label {
            ru
            en
          }
          options {
            label
            value
          }
        }
      }
    }
  }
`;
