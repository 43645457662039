import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'config/routes';

import { HeaderPage } from 'components/header-page/header-page';
import { Button } from 'components/ui/Button';

import useSigner from 'client/hooks/use-signer';
import useProcessXslt from 'hooks/use-process-xslt';
import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { usePoaSignMutation } from 'store/api/poa';
import { selectDocumentContent } from 'store/reducers/attorney.create';
import { clearDataForm } from 'store/reducers/data-form';
import { useHandleMutation } from 'utils/handleMutation';
import s from './styles.module.scss';

export const NewAttorneyPreview = () => {
  const { id: draftId } = useParams();

  const alert = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [signDraft, signDraftMutation] = usePoaSignMutation();

  const documentContent = useSelector(selectDocumentContent());

  const { data: xsltPreview } = useProcessXslt({ base64: documentContent });
  const { handleSign, isLoading: isSignerLoading } = useSigner();

  const handleBack = () => {
    navigate(-1);
  };

  const onSuccess = useCallback(() => {
    alert.success(t('Signed'));
    navigate(
      AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.PowersAttorney.Home +
        AppRoutes.Dashboard.PowersAttorney.Active,
    );
  }, [alert.success, navigate]);

  const onError = useCallback(() => {
    alert.error(t('Ошибка'));
  }, [alert, t]);

  useHandleMutation({ ...signDraftMutation, onSuccess, onError });

  const handleClick = async () => {
    await handleSign({
      signData: documentContent,
      onSubmit: async ({ signature, detachedSignature }) => {
        await signDraft({ id: draftId ?? '', signature, detachedSignature });
      },
    });
    dispatch(clearDataForm());
  };

  return (
    <div className={s['container']}>
      <HeaderPage title={t('Предпросмотр Доверенности')} />

      <div className={s['content']}>
        <div className={s['scroll']}>
          <div className={s['wrapper-main']}>
            {xsltPreview && (
              <span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: new XMLSerializer().serializeToString(xsltPreview as any),
                  }}
                />
              </span>
            )}
          </div>
        </div>
        <div className={s['wrapper-footer']}>
          <div className={s['wrapper-button']}>
            <Button
              displayType="outlined-secondary"
              className={cx(s['button'], s['button-back'])}
              onClick={handleBack}
            >
              {t('Back')}
            </Button>
            <Button
              onClick={handleClick}
              disabled={isSignerLoading}
              className={cx(s['button'], s['button-sign'])}
            >
              {t('Sign and send for approval')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
