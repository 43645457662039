import { gql } from 'graphql-request';

export const GET_BY_ID_POA_USER = gql`
  query CorePOAUsers($userId: ID!) {
    corePOAUsers {
      getData(userId: $userId) {
        id
        userType
        email
      }
    }
  }
`;
