import axios from 'axios';
import { parseCertificate } from 'modules/certificates';

/* eslint-disable */
export const LssConstants = {
  XmlCanonicalization: {
    C14N: 'http://www.w3.org/TR/2001/REC-xml-c14n-20010315',
    C14NC: 'http://www.w3.org/TR/2001/REC-xml-c14n-20010315#WithComments',
    EXSLUSIVE_C14N: 'http://www.w3.org/2001/10/xml-exc-c14n#',
    EXSLUSIVE_C14NC: 'http://www.w3.org/2001/10/xml-exc-c14n#WithComments',
    C14N11: 'http://www.w3.org/2006/12/xml-c14n11',
    C14NC11: 'http://www.w3.org/2006/12/xml-c14n11#WithComments',
  },
  XmlTransformation: {
    ENVELOPED: 'http://www.w3.org/2000/09/xmldsig#enveloped-signature',
    BASE64: 'http://www.w3.org/2000/09/xmldsig#base64',
    XPATH_FILTER2: 'http://www.w3.org/2002/06/xmldsig-filter2',
    XPATH: 'http://www.w3.org/TR/1999/REC-xpath-19991116',
    SMEV: 'urn://smev-gov-ru/xmldsig/transform',
  },
  KeyUsages: {
    SIGN_AND_ENCRYPT: 'signAndEncrypt',
    SIGN: 'sign',
    ENCRYPT: 'encrypt',
    DIGITAL_SIGNATURE: 'digitalSignature',
    NON_REPUDIATION: 'nonRepudiation',
    KEY_ENCIPHERMENT: 'keyEncipherment',
    DATA_ENCIPHERMENT: 'dataEncipherment',
    KEY_AGREEMENT: 'keyAgreement',
    KEY_CERT_SIGN: 'keyCertSign',
    CRL_SIGN: 'cRLSign',
    ENCIPHER_ONLY: 'encipherOnly',
    DECIPHER_ONLY: 'decipherOnly',
  },
};

const protocol = window.location.protocol === 'https:' ? 'https' : 'http';
const baseUrl = `${protocol}://127.0.0.1:${protocol === 'https' ? '61112' : '61111'}/webhost`;

const clientInstance = axios.create({
  baseUrl,
  headers: { 'Content-Type': 'application/json' },
});

clientInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export function VipnetApi() {
  var defaultExtension = '.doc';
  var usingByPass = false;
  var defaultTimeout = 0;
  var checkLssConnectivityTimeout = 5000;

  function ServiceClient() {
    var POST = 'POST';
    function post(type, data, timeout) {
      return clientInstance({
        method: 'post',
        data: JSON.stringify(data),
        url: createUrl(POST, type),
        headers: { 'Content-Type': 'application/json' },
        timeout: timeout ?? 1000 * 60,
      });
    }

    function createUrl(methodName, type) {
      return baseUrl + '/' + methodName + '?' + 'type' + '=' + type;
    }

    return {
      post: post,
      getBaseUrl: () => baseUrl,
    };
  }

  function HeartBeatRequest() {
    var self = this;
    self.taskType = 'SystemStateTask';
    self.Payload = 'CheckLssPresence';
  }

  function SignRequest(data, certificate) {
    var self = this;
    self.taskType = 'SignTask';
    self.DataToSign = data;
    self.SignCertificate = certificate;
  }

  function SignVerificationRequest(signedData, originalData) {
    var self = this;
    self.taskType = 'VerifyTask';
    self.SignedData = signedData;
    self.OriginalData = originalData;
  }

  function EncryptionRequest(data, certificates) {
    var self = this;
    self.taskType = 'EncryptTask';
    self.DataToEncrypt = data;
    self.Certificates = certificates;
  }

  function DecryptionRequest(data) {
    var self = this;
    self.taskType = 'DecryptTask';
    self.EncryptedData = data;
  }

  function SignAndEncryptionRequest(data, certificates, signCertificate) {
    var self = this;
    self.taskType = 'SignAndEncryptTask';
    self.DataToSignAndEncrypt = data;
    self.Certificates = certificates;
    self.SignCertificate = signCertificate;
  }

  function DecryptAndVerifySignRequest(data, certificate) {
    var self = this;
    self.taskType = 'DecryptAndVerifyTask';
    self.EnctyptedDataAndSign = data;
  }

  function SelectCertificateRequest() {
    var self = this;
    self.taskType = 'SelectCertificateTask';
  }

  function HashRequest() {
    var self = this;
    self.taskType = 'HashTask';
  }

  function SignedContentRequest() {
    var self = this;
    self.taskType = 'SignedContentTask';
  }

  function SpecialSign01Request() {
    var self = this;
    self.taskType = 'SpecialSign01Task';
  }

  function SignXmlRequest() {
    var self = this;
    self.taskType = 'SignXmlTask';
  }

  function CertificateListRequest() {
    var self = this;
    self.taskType = 'SelectCertificateListTask';
  }

  function CertificateRequestMessage(
    subject,
    extendedKeyUsages,
    identificationKind,
    keyUsages,
    pubkeyAlgorithmOid,
    pubkeyParamSet,
  ) {
    var self = this;
    self.taskType = 'CreateCertificateRequestTask';
    self.Subject = subject;
    self.ExtendedKeyUsages = extendedKeyUsages;
    self.IdentificationKind = identificationKind ? parseInt(identificationKind) : null;
    self.KeyUsages = keyUsages;
    self.PubkeyAlgorithmOid = pubkeyAlgorithmOid;
    self.PubkeyParamSet = pubkeyParamSet;
  }

  function InstallCertificateIssuedByRequestMessage(certificate) {
    var self = this;
    self.taskType = 'InstallCertificateIssuedByRequestTask';
    self.Certificate = certificate;
  }

  function VerifySignedXmlRequest() {
    var self = this;
    self.taskType = 'VerifySignedXmlTask';
  }

  function generateGuid() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
    return uuid;
  }

  function sendRequest(task, timeout) {
    var client = new ServiceClient();
    if (usingByPass) {
      task.ByPassVisualization = true;
    }
    return client.post(task.taskType, task, timeout);
  }

  function installCertificateIssuedByRequest(options) {
    var defaultOptions = {
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };

    var task = new InstallCertificateIssuedByRequestMessage(options.certificate);
    task.RequestId = options.requestId;
    return sendRequest({ ...task, ...options }, defaultTimeout);
  }

  function generateCertificateRequest(options) {
    var defaultOptions = {
      requestId: generateGuid(),
      identificationKind: null,
    };

    options = { ...defaultOptions, ...options };

    var task = new CertificateRequestMessage(
      options.subject,
      options.extendedKeyUsages,
      options.identificationKind,
      options.keyUsages,
      options.pubkeyAlgorithmOid,
      options.pubkeyParamSet,
    );
    task.RequestId = options.requestId;

    if (options.otherExtensions) {
      task.OtherExtensions = [];
      options.otherExtensions.forEach((e) => {
        task.OtherExtensions.push({
          Oid: e.oid,
          Critical: e.critical,
          Base64Data: e.base64Data,
        });
      });
    }
    return sendRequest({ ...task, ...options }, defaultTimeout);
  }

  function sign(options) {
    var defaultOptions = {
      base64Data: '-',
      description: 'Описание не задано',
      documentName: 'Подпись',
      fileExtension: defaultExtension,
      isAttached: true,
      base64Certificate: '-',
      disableCertificateVerification: false,
      requestId: generateGuid(),
    };

    const input = {
      base64Data: options.dataForSign,
      base64Certificate: options.certificate.Base64RawData,
    };

    options = { ...defaultOptions, ...input };

    var task = new SignRequest(options.base64Data, options.base64Certificate);
    task.IsAttached = options.isAttached;
    task.DocumentName = options.documentName;
    task.Description = options.description;
    task.ViewDescriptor = { FileExtension: options.fileExtension };
    task.RequestId = options.requestId;
    task.TspServerUrl = options.tspServerUrl;
    task.TspServerTimeoutInMilliseconds = parseInt(options.tspServerTimeout);
    task.DisableCertificateVerification = options.disableCertificateVerification;

    return sendRequest({ ...task, ...options }, defaultTimeout);
  }

  function verifySign(options) {
    var defaultOptions = {
      base64Data: '-',
      base64DataWithoutSign: '-',
      isAttached: true,
      description: 'Описание не задано',
      documentName: 'Проверка подписи',
      fileExtension: defaultExtension,
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };

    var task = new SignVerificationRequest(options.base64Data, options.base64DataWithoutSign);
    task.IsAttached = options.isAttached;
    task.DocumentName = options.documentName;
    task.Description = options.description;
    task.ViewDescriptor = { FileExtension: options.fileExtension };
    task.RequestId = options.requestId;

    return sendRequest({ ...task, ...options }, defaultTimeout);
  }

  function verifyFile(options) {
    var defaultOptions = {
      isAttached: true,
      description: 'Описание не задано',
      documentName: 'Проверка подписи',
      fileExtension: defaultExtension,
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };
    options.ViewDescriptor = { FileExtension: options.fileExtension };

    var client = new ServiceClient();
    var url = client.getBaseUrl() + '/VerifyFile/' + options.requestId;
    var data = new FormData();
    data.append('options', JSON.stringify(options));
    data.append('file', options.file);

    return clientInstance
      .post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error));
  }

  function encrypt(options) {
    var defaultOptions = {
      base64Data: '-',
      base64Certificates: [],
      description: 'Описание не задано',
      documentName: 'Шифрование',
      fileExtension: defaultExtension,
      disableCertificateVerification: false,
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };
    var task = new EncryptionRequest(options.base64Data, options.base64Certificates);
    task.DocumentName = options.documentName;
    task.Description = options.description;
    task.ViewDescriptor = { FileExtension: options.fileExtension };
    task.DisableCertificateVerification = options.disableCertificateVerification;
    task.RequestId = options.requestId;

    return sendRequest({ ...task, ...options }, defaultTimeout);
  }

  function decrypt(options) {
    var defaultOptions = {
      base64Data: '-',
      description: 'Описание не задано',
      documentName: 'Расшифрование',
      fileExtension: defaultExtension,
      disableCertificateVerification: false,
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };
    var task = new DecryptionRequest(options.base64Data);
    task.DocumentName = options.documentName;
    task.Description = options.description;
    task.ViewDescriptor = { FileExtension: options.fileExtension };
    task.DisableCertificateVerification = options.disableCertificateVerification;
    task.RequestId = options.requestId;

    return sendRequest({ ...task, ...options }, defaultTimeout);
  }

  function signAndEncrypt(options) {
    var defaultOptions = {
      base64Data: '-',
      base64Certificates: [],
      description: 'Описание не задано',
      documentName: 'Подпись и шифрование',
      fileExtension: defaultExtension,
      base64SignCertificate: '-',
      disableCertificateVerification: false,
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };
    var task = new SignAndEncryptionRequest(
      options.base64Data,
      options.base64Certificates,
      options.base64SignCertificate,
    );
    task.DocumentName = options.documentName;
    task.Description = options.description;
    task.ViewDescriptor = { FileExtension: options.fileExtension };
    task.TspServerUrl = options.tspServerUrl;
    task.TspServerTimeoutInMilliseconds = parseInt(options.tspServerTimeout);
    task.DisableCertificateVerification = options.disableCertificateVerification;
    task.RequestId = options.requestId;
    task.UseAttached = options.useAttached;

    return sendRequest({ ...task, ...options }, defaultTimeout);
  }

  function decryptAndVerifySign(options) {
    var defaultOptions = {
      base64Data: '-',
      description: 'Описание не задано',
      documentName: 'Расшифрование и проверка подписи',
      fileExtension: defaultExtension,
      disableCertificateVerification: false,
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };
    var task = new DecryptAndVerifySignRequest(options.base64Data);
    task.DocumentName = options.documentName;
    task.Description = options.description;
    task.ViewDescriptor = { FileExtension: options.fileExtension };
    task.DisableCertificateVerification = options.disableCertificateVerification;
    task.RequestId = options.requestId;

    return sendRequest({ ...task, ...options }, defaultTimeout);
  }

  function selectCertificate(options) {
    var defaultOptions = {
      disableCertificateVerification: false,
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };
    var task = new SelectCertificateRequest();
    task.DisableCertificateVerification = options.disableCertificateVerification;
    task.RequestId = options.requestId;

    return sendRequest({ ...task, ...options }, defaultTimeout);
  }

  function hash(options) {
    var defaultOptions = {
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };
    var task = new HashRequest();
    task.RequestId = options.requestId;
    task.DataToHash = options.base64Data;
    task.HashAlgorithmOid = options.hashAlgorithmOid;
    task.HashContext = options.hashContext;
    task.Final = options.final;

    return sendRequest(task, defaultTimeout);
  }

  function hashFile(options) {
    const defaultOptions = {
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };

    const url = `/hash/${options.requestId}`;

    const data = new FormData();
    data.append('options', JSON.stringify(options));
    data.append('file', options.file);

    return clientInstance
      .post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error));
  }

  function getSignedContent(options) {
    var defaultOptions = {
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };

    var task = new SignedContentRequest();
    task.RequestId = options.requestId;
    task.SignedData = options.base64Data;

    return sendRequest(task, defaultTimeout);
  }

  function specialSign01(options) {
    var defaultOptions = {
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };

    var task = new SpecialSign01Request();
    task.RequestId = options.requestId;
    task.DataToSign = options.base64Data;
    task.SignCertificate = options.base64Certificate;
    task.DocumentName = options.documentName;
    task.Description = options.description;
    task.ViewDescriptor = { FileExtension: options.fileExtension };
    task.DisableCertificateVerification = options.disableCertificateVerification;

    return sendRequest(task, defaultTimeout);
  }

  function specialSignFile(options) {
    var defaultOptions = {
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };

    var client = new ServiceClient();
    var url = client.getBaseUrl() + '/SpecialSign/' + options.requestId;
    var data = new FormData();

    data.append('options', JSON.stringify(options));
    data.append('file', options.file);

    return clientInstance
      .post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error));
  }

  async function getCertificates() {
    var defaultOptions = {
      requestId: generateGuid(),
    };

    var task = new CertificateListRequest();
    task.RequestId = defaultOptions.requestId;

    const data = await sendRequest(task, defaultTimeout);

    return data.Certificates.map((cert) => {
      const certificate = JSON.parse(cert.CertificateJson);

      return {
        FriendlyName: certificate.FriendlyName,
        Thumbprint: certificate.Thumbprint,
        Base64RawData: certificate.Base64RawData,
        Subject: parseCertificate(certificate.Subject),
      };
    });
  }

  function signFile(options) {
    var defaultOptions = {
      requestId: generateGuid(),
    };

    options = { ...defaultOptions, ...options };

    var client = new ServiceClient();
    var url = client.getBaseUrl() + '/SignFile/' + options.requestId;
    var data = new FormData();
    data.append('options', JSON.stringify(options));
    data.append('file', options.file);

    return clientInstance
      .post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error));
  }

  function signXml(options) {
    var defaultOptions = {
      requestId: generateGuid(),
      documentName: 'document.xml',
      fileExtension: '.xml',
      canonicalizationMethod: LssConstants.XmlCanonicalization.EXSLUSIVE_C14N,
      references: [
        {
          transforms: [
            { algorithm: LssConstants.XmlTransformation.ENVELOPED },
            { algorithm: LssConstants.XmlCanonicalization.EXSLUSIVE_C14N },
          ],
        },
      ],
    };

    options = { ...defaultOptions, ...options };
    var task = new SignXmlRequest();
    task.RequestId = options.requestId;
    task.Xml = options.xml;
    task.CanonicalizationMethod = options.canonicalizationMethod;
    task.References = options.references.map(mapReference);
    task.SignatureId = options.signatureId;
    task.SignatureLocationPath = options.signatureLocationPath;
    task.SignCertificate = options.base64Certificate;
    task.DocumentName = options.documentName;
    task.Description = options.description;
    task.ViewDescriptor = { FileExtension: options.fileExtension };
    task.DisableCertificateVerification = options.disableCertificateVerification;
    task.SignatureType = options.signatureType;
    task.SignatureTypeParam = options.signatureTypeParam;
    task.Base64Xml = options.base64Xml;

    return sendRequest({ ...task, ...options }, defaultTimeout);
  }

  function verifySignedXml(options) {
    var defaultOptions = {
      requestId: generateGuid(),
      documentName: 'Подписанный документ Xml',
      fileExtension: '.xml',
    };

    options = { ...defaultOptions, ...options };
    var task = new VerifySignedXmlRequest();
    task.RequestId = options.requestId;
    task.SignedXml = options.signedXml;
    task.Base64SignedXml = options.base64SignedXml;
    task.DocumentName = options.documentName;
    task.Description = options.description;
    task.ViewDescriptor = { FileExtension: options.fileExtension };

    return sendRequest({ ...task, ...options }, defaultTimeout);
  }

  function mapReference(reference) {
    return {
      Id: reference.id,
      Type: reference.type,
      Uri: reference.uri,
      Transforms: reference.transforms.map(function (transform) {
        return {
          Algorithm: transform.algorithm,
          TransformValue: transform.transformValue,
        };
      }),
    };
  }

  function checkLssConnectivity() {
    var task = new HeartBeatRequest();
    return sendRequest(task, checkLssConnectivityTimeout);
  }

  function withBypass() {
    usingByPass = true;
    return this;
  }

  return {
    signFile: signFile,
    verifyFile: verifyFile,
    sign: sign,
    verifySign: verifySign,
    encrypt: encrypt,
    decrypt: decrypt,
    signAndEncrypt: signAndEncrypt,
    decryptAndVerifySign: decryptAndVerifySign,
    selectCertificate: selectCertificate,
    checkConnection: checkLssConnectivity,
    withBypass: withBypass,
    generateCertificateRequest: generateCertificateRequest,
    installCertificateIssuedByRequest: installCertificateIssuedByRequest,
    hash: hash,
    hashFile: hashFile,
    getSignedContent: getSignedContent,
    specialSignFile: specialSignFile,
    signXml: signXml,
    verifySignedXml: verifySignedXml,
    getCertificates: getCertificates,
  };
}
