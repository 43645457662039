import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/breadcrumbs';
import { BreadcrumbsProps } from 'constants/routers-breadcrumbs';

import s from './header-page.module.scss';

interface HeaderPageProps {
  title: string;
  items?: BreadcrumbsProps[] | undefined;
}

export const HeaderPage = ({ title, items }: HeaderPageProps) => {
  const { t } = useTranslation();

  return (
    <div className={s['container']}>
      {items && <Breadcrumbs items={items} />}
      <span className={s['title']}>{t(title)}</span>
    </div>
  );
};
