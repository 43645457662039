export const formatFileSize = (size: number) => {
  if (size < 1000) {
    // File size is less than 1MB, display in KB
    return `${size.toFixed(2)} KB`;
  } else {
    // File size is 1MB or more, display in MB
    return `${(size / 1000).toFixed(2)} MB`;
  }
};

export const extractFilenameAndExtension = (fullFilename: string) => {
  const parts = fullFilename.split('.');

  if (parts.length < 2) {
    return { filename: fullFilename, extension: null };
  }

  const extension = parts.pop();
  const filename = parts.join('.');

  return { filename, extension };
};

export const stringSizeInKB = (str: string): number => {
  const byteSize = new Blob([str]).size;
  const sizeInKB = byteSize / 1024;

  return Number(sizeInKB.toFixed(2));
};
