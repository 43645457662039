import { DatePickerMode } from 'components/common/date-picker/date-picker';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { DefaultDateFormat } from 'utils/date';
import * as Yup from 'yup';

export interface EditProfileFormValues {
  phone: string;
  email: string;
  surname: string;
  name: string;
  patronymic: string;
  birthdate: string;
  citizenship: string;
  typeIdentityDocument: string;
  documentSeriesAndNumber: string;
  dateIssueDocument: string;
  nameAuthorityIssuedDocument: string;
}

export const model = {
  phone: {
    label: 'Phone',
    name: 'phone',
    type: 'text',
    placeholder: 'phone',
    required: true,
  },
  email: {
    type: 'email',
    label: 'E-mail',
    name: 'email',
    placeholder: 'Enter email',
    required: true,
  },
  surname: {
    type: 'text',
    required: true,
    placeholder: 'Иванов',
    name: 'surname',
    label: 'Surname',
  },
  name: {
    type: 'text',
    required: true,
    placeholder: 'Иван',
    name: 'name',
    label: 'Name',
  },
  patronymic: {
    type: 'text',
    required: true,
    placeholder: 'Иванович',
    name: 'patronymic',
    label: 'Patronymic',
  },
  birthdate: {
    type: 'date',
    required: true,
    mode: DatePickerMode.SINGLE,
    name: 'birthdate',
    label: 'Bithdate',
    placeholder: dayjs().format(DefaultDateFormat),
  },
  citizenship: {
    type: 'radio',
    required: true,
    name: 'citizenship',
    label: 'Citizenship',
    options: [
      { label: 'Citizen of the Russian Federation', value: 'RF' },
      { label: 'Foreign citizen', value: 'foreign' },
    ],
  },
  typeIdentityDocument: {
    label: 'Type of identity document',
    name: 'typeIdentityDocument',
    placeholder: 'Select from the list',
    options: ['Passport 1', 'Passport 2', 'Passport 3'].map((label) => ({
      label,
      value: label,
    })),
    required: true,
  },
  documentSeriesAndNumber: {
    type: 'text',
    required: true,
    placeholder: '1234567891',
    name: 'documentSeriesAndNumber',
    label: 'Document series and number',
  },
  dateIssueDocument: {
    type: 'date',
    required: true,
    mode: DatePickerMode.SINGLE,
    name: 'dateIssueDocument',
    label: 'Date of issue of the document',
    placeholder: dayjs().format(DefaultDateFormat),
  },
  nameAuthorityIssuedDocument: {
    label: 'Name of the authority that issued the document',
    name: 'nameAuthorityIssuedDocument',
    placeholder: 'Select from the list',
    options: ['Passport 1', 'Passport 2', 'Passport 3'].map((label) => ({
      label,
      value: label,
    })),
    required: true,
  },
};

export const validationSchema = (t: TFunction) => Yup.object().shape({});

export const initialValues = {
  [model.name.name]: '',
  [model.phone.name]: '',
  [model.email.name]: '',
  [model.surname.name]: '',
  [model.patronymic.name]: '',
  [model.documentSeriesAndNumber.name]: '',
} as unknown as EditProfileFormValues;
