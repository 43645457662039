import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'config/routes';

import { HeaderPage } from 'components/header-page/header-page';
import { Button } from 'components/ui/Button';

import useSigner from 'client/hooks/use-signer';
import { ConterpartyHeaderInfo } from 'components/counterparty-header-info';
import { BreadcrumbsProps } from 'constants/routers-breadcrumbs';
import useProcessXslt from 'hooks/use-process-xslt';
import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { useFinishInviteOrganizationMutation } from 'store/api/organizations-invitations';
import { clearDataForm, selectDataForm } from 'store/reducers/data-form';
import { selectDocumentContent, selectInviteEmail } from 'store/reducers/invite.conteragent';
import { prepareDayjs } from 'utils/date';
import { useHandleMutation } from 'utils/handleMutation';
import s from './styles.module.scss';

type Props = {
  title?: string;
  redirectUrl?: string;
  counterpartyId?: string;
  breadItems?: BreadcrumbsProps[];
};

export const NdaContractPreview = ({
  title,
  breadItems,
  redirectUrl,
  counterpartyId,
}: Props) => {
  const { id } = useParams();

  const alert = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Home, Counterparties } = AppRoutes.Dashboard;

  const { date, duration } = useSelector(selectDataForm);

  const [finishInvite, finishInviteMutation] = useFinishInviteOrganizationMutation();

  const documentContent = useSelector(selectDocumentContent());
  const inviteEmail = useSelector(selectInviteEmail());

  const { data: xsltPreview } = useProcessXslt({ base64: documentContent });
  const { handleSign, isLoading: isSignerLoading } = useSigner();

  const handleBack = () => {
    navigate(-1);
  };

  const onSuccess = useCallback(
    (inviteId: string) => {
      navigate(
        redirectUrl || Home + Counterparties.Home + Counterparties.Invitations + `/${inviteId}`,
      );
      alert.success(t('Приглашение отправлено'));
      dispatch(clearDataForm());
    },
    [alert, navigate, t],
  );

  const onError = useCallback(() => {
    alert.error(t('Ошибка'));
  }, [alert, t]);

  useHandleMutation({ ...finishInviteMutation, onSuccess, onError });

  const handleClick = async () => {
    await handleSign({
      validFrom: prepareDayjs(date).toISOString(),
      validUntil: prepareDayjs(date).add(duration, 'year').toISOString(),
      signData: documentContent,
      onSubmit: async ({ signature, detachedSignature }) => {
        await finishInvite({
          inviteId: id ?? '',
          signature,
          detachedSignature,
          sendInviteEmails: inviteEmail ? [inviteEmail] : [''],
        });
      },
    });
  };

  return (
    <div className={s['container']}>
      <HeaderPage title={t(title ?? 'Предпросмотр НДА')} items={breadItems} />

      <div className={s['content']}>
        {counterpartyId && <ConterpartyHeaderInfo id={counterpartyId} />}
        <div className={s['scroll']}>
          <div className={s['wrapper-main']}>
            {xsltPreview && (
              <span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: new XMLSerializer().serializeToString(xsltPreview as any),
                  }}
                />
              </span>
            )}
          </div>
        </div>
        <div className={s['wrapper-footer']}>
          <div className={s['wrapper-button']}>
            <Button
              displayType="outlined-secondary"
              className={cx(s['button'], s['button-back'])}
              onClick={handleBack}
            >
              {t('Back')}
            </Button>
            <Button
              onClick={handleClick}
              disabled={isSignerLoading}
              className={cx(s['button'], s['button-sign'])}
            >
              {t('Sign and send for approval')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
