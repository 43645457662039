import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

import s from './information-attorney.module.scss';
import { useField } from 'formik';

type Props = {
  name: string;
  values: any;
};

export const TicketAttorney = ({ values, name }: Props) => {
  const { t } = useTranslation();

  const [, , helpers] = useField(name);

  const handleDelete = () => {
    if (values) {
      helpers.setValue(null);
    }
  };

  if (!values || !values.content) {
    return null;
  }

  return (
    <div className={s['container-ticket']}>
      <div className={s['wrapper-ticket']}>
        <div className={s['ticket-header']}>
          <div className={s['header-left']}>
            <span className={s['values']}>{values.label}</span>
            <span className={s['date']}>
              {t('from-w')} {values.content.date}
            </span>
          </div>

          <button type="button" className={s['close']} onClick={handleDelete}>
            <CloseIcon />
          </button>
        </div>

        <span className={s['ticket-title']}>{values.content.title}</span>

        <div className={s['ticket-rangeDate']}>
          <span>с {values.content.rangeDate?.from}</span>

          <span>по {values.content.rangeDate?.to}</span>
        </div>

        <div className={s['ticket-text']}>
          <p className={s['text']}>{values.content.text}</p>
          <p className={s['text']}>{values.content.subText}</p>
        </div>
      </div>
    </div>
  );
};
