import { Status } from 'components/status/status';
import { AppRoutes } from 'config/routes';
import { useNavigate } from 'react-router-dom';

import { ListAttr } from 'components/list';

import s from './ListItem.module.scss';
import { Employee } from 'store/api/types/staff';
import { AttributesWhitelistStaff } from 'constants/attributes';

export const ListItem = ({ data }: { data: Employee }) => {
  const navigate = useNavigate();
  const { Home, Organizations } = AppRoutes.Dashboard;

  const handleDetail = (id: string) => {
    navigate(
      Home + Organizations.Home + Organizations.Staff + Organizations.Details + `/${id}`,
    );
  };

  return (
    <div className={s['container']}>
      <div className={s['wrapper']} onClick={() => handleDetail(data.id)}>
        <div className={s['header']}>
          <div className={s['wrapper-header']}>
            <span
              className={s['name']}
            >{`${data?.lastName} ${data?.firstName} ${data?.middleName}`}</span>
            <Status text={data.status as any} variant={data.status as any} />
          </div>

          {data.role && <span className={s['middle-attr']}>{data.role}</span>}
        </div>

        <ListAttr whitelist={AttributesWhitelistStaff} attrs={data.attributes} />
      </div>
    </div>
  );
};
