import { configureStore } from '@reduxjs/toolkit';

import { dictionariesApi } from './api/dictionaries';
import { documentsApi } from './api/documents';
import { organizationDocumentsApi } from './api/organizationDocuments';
import { organizationsApi } from './api/organizations';
import { organizationsInvitationsApi } from './api/organizations-invitations';
import { paymentsApi } from './api/payments';
import { poaApi } from './api/poa';
import { poaUsersApi } from './api/poaUsers';
import { profileApi } from './api/profile';
import { staffApi } from './api/staff';
import authMiddleware from './middleware/auth.middleware';
import certificateMiddleware from './middleware/certificate.middleware';
import rehydrateStore from './middleware/rehydrate-store';
import { rootReducer } from './rootReducer';
import { staffPowersApi } from './api/employePowers';

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: rehydrateStore(),
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authMiddleware,
      certificateMiddleware,
      poaApi.middleware,
      staffApi.middleware,
      profileApi.middleware,
      paymentsApi.middleware,
      poaUsersApi.middleware,
      documentsApi.middleware,
      staffPowersApi.middleware,
      dictionariesApi.middleware,
      organizationsApi.middleware,
      organizationDocumentsApi.middleware,
      organizationsInvitationsApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch: () => AppDispatch = useDispatch;
// export const useAppSelector = createSelectorHook<RootState>()

export type AppDispatch = typeof store.dispatch;
