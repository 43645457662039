import { gql } from 'graphql-request';

export const GET_STAFF_POWERS = gql`
  query CoreEmployeePowers {
    coreEmployeePowers {
      getPowersList {
        code
        name
      }
    }
  }
`;
