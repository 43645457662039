import { Input } from 'antd';
import cx from 'classnames';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button';
import { ModalMain } from '../main/main';

import { CounterpartyAttributesWhitelist } from 'constants/attributes';
import { useAlert } from 'react-alert';
import { useRejectInvitationMutation } from 'store/api/organizations-invitations';
import { PartyInfo } from 'store/api/types/organizations-invitations';
import { useHandleMutation } from 'utils/handleMutation';
import s from './reject.module.scss';

const { TextArea } = Input;

interface RejectModalProps {
  item: PartyInfo;
  inviteId: string;
  isVisible: boolean;
  setVisible: (value: boolean) => void;
}

export const RejectModal = ({ item, inviteId, isVisible, setVisible }: RejectModalProps) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const [rejectText, setRejectText] = useState<string>('');
  const [rejectInvite, rejectInviteMutation] = useRejectInvitationMutation();

  const classesBack = cx(s['button'], s['button-back']);

  const onSuccess = useCallback(() => {
    alert.success('Success!');
    setRejectText('');
  }, [alert, t]);

  const onError = useCallback(() => {
    alert.error(t('Error'));
  }, [alert, t]);

  const onRejectSubmit = async () => {
    await rejectInvite({ inviteId, rejectReason: rejectText });
    setVisible(false);
  };

  useHandleMutation({ ...rejectInviteMutation, onSuccess, onError });

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setRejectText(e.target.value);
  };

  return (
    <ModalMain
      title="Rejecting an invitation"
      isVisible={isVisible}
      closeOnClick={() => setVisible(false)}
      footer={
        <div className={s['button-wrapper']}>
          <Button
            text={t('Cancel')}
            className={classesBack}
            displayType="outlined-secondary"
            onClick={() => setVisible(false)}
          />
          <Button
            text={t('Decline the invitation')}
            className={s['button']}
            onClick={onRejectSubmit}
          />
        </div>
      }
    >
      <div>
        <span className={s['title']}>{t(item.partyOrganization.name)}</span>
        <div className={s['description-wrapper']}>
          {item?.partyOrganization?.attributes
            ?.filter((filterItem) =>
              CounterpartyAttributesWhitelist[item.partyOrganization.type].includes(
                filterItem?.name?.toString(),
              ),
            )
            ?.map((item) => (
              <div key={item.name.toString()} className={s['description']}>
                <span className={s['reduction']}>{t(item.name)}: </span>
                <span className={s['value']}>{item.value}</span>
              </div>
            ))}
        </div>
        <div className={s['input-wrapper']}>
          <span className={s['description-title']}>
            {t('Specify the reason for the rejection')}
          </span>
          <TextArea
            allowClear
            value={rejectText}
            onChange={onChange}
            style={{ minHeight: '79px' }}
            placeholder={t('Thank you, but your suggestion is not interesting')}
          />
        </div>
      </div>
    </ModalMain>
  );
};
