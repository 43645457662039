import { Input } from 'antd';
import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ReportIcon } from 'assets/images/report.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search-dashboard.svg';
import { Button } from 'components/ui/Button';
import { Counterparty } from '../counterparties/all-counterparties/AllCounterparties';

import Breadcrumbs from 'components/breadcrumbs';
import { Select } from 'components/common/select/select';
import { AppRoutes } from 'config/routes';
import { counterpartiesInvitatRoutes } from 'constants/routers-breadcrumbs';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import {
  useGetCoreOrganizationsQuery,
  useGetMyOrganizationsQuery,
  usePrepareOrganizationMutation,
} from 'store/api/organizations';
import { useDebounce } from 'usehooks-ts';
import { useHandleMutation } from 'utils/handleMutation';
import s from './invitation-search.module.scss';
import { Organization } from 'store/api/types';

interface TextNotFoundProps {
  hasSearch: string;
}

const TextNotFound = ({ hasSearch }: TextNotFoundProps) => {
  const { t } = useTranslation();

  return (
    <div className={s.notFound}>
      <ReportIcon />
      <span className={s.textNotFound}>
        {hasSearch ? (
          <span>{t('Organization not found')}</span>
        ) : (
          <>
            <span>{t('Here will be displayed')}</span>
            <span>{t('found counterparties')}</span>
          </>
        )}
      </span>
    </div>
  );
};

export const InvitationSearch = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({ label: '', value: '' });
  const debounceFilterValue = useDebounce(filter, 500);
  const [prepareOrganization, mutation] = usePrepareOrganizationMutation();
  const { data, isFetching, refetch } = useGetCoreOrganizationsQuery({
    searchName: debounceFilterValue,
  });
  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const onSuccess = useCallback(
    (preparedId: string) => {
      navigate(
        Dashboard.Home +
          Dashboard.Counterparties.Home +
          Dashboard.Counterparties.Invitation.Home +
          `/${preparedId}`,
      );
    },
    [alert, t],
  );

  const onError = useCallback(() => {
    alert.error(t('Error'));
  }, [alert, t]);

  useHandleMutation({ ...mutation, onSuccess, onError });

  const { Dashboard } = AppRoutes;

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilter(value);
  };

  const handlePrepareOrganization = async (index: number) => {
    const item = data?.[index];
    if (item?.attributes !== undefined) {
      await prepareOrganization({
        organizationAttributes: item?.attributes,
      });
    }
  };

  useEffect(() => {
    if (
      currentOrganization?.countryCode === 'kg' ||
      currentOrganization?.countryCode === 'kgz'
    ) {
      setSelectedCountry({ label: 'Кыргызстан', value: 'kg' });
    }

    if (
      currentOrganization?.countryCode === 'ru' ||
      currentOrganization?.countryCode === 'rus'
    ) {
      setSelectedCountry({ label: 'Россия', value: 'ru' });
    }
  }, [currentOrganization?.countryCode]);

  const noDataOrFound = data?.length === 0;
  const backgroudClasses = cx(s.wrapperBottom);

  return (
    <div className={s.container}>
      <div className={s.containerTop}>
        <Breadcrumbs items={counterpartiesInvitatRoutes} />
        <span className={s.title}>{t('Search and invitation of a counterparty')}</span>
      </div>

      <div className={s.content}>
        <div className={s.wrapperSearch}>
          <div style={{ flex: 2, display: 'flex', paddingBlock: '16px' }}>
            <Input
              value={filter ?? ''}
              placeholder={t(
                selectedCountry?.value === 'kg'
                  ? 'Enter the name, INN or OKPO'
                  : 'Enter the name, INN or OGRN',
              )}
              prefix={<SearchIcon />}
              style={{ borderRadius: '0px', height: '40px' }}
              onChange={handleFilterChange}
            />
          </div>
          <div style={{ marginRight: 24, flex: 0.5, marginLeft: 24 }}>
            <Select
              placeholder={t('Select')}
              options={[
                { label: t('Kyrgyzstan'), value: 'kg' },
                { label: t('Russia'), value: 'ru' },
              ]}
              value={selectedCountry}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(item) => setSelectedCountry(item)}
            />
          </div>
          <div className={s.wrapperButton}>
            <Button className={s.button} onClick={refetch}>
              {t('Search')}
            </Button>
          </div>
        </div>

        <div className={noDataOrFound ? backgroudClasses : s.wrapperRoot}>
          <div className={s.wrapperContent}>
            {noDataOrFound ? (
              <TextNotFound hasSearch={filter} />
            ) : (
              <div className={s.result}>
                {data?.map((user, index) => (
                  <Counterparty
                    name={user.name}
                    variant="invite"
                    type={user?.type}
                    highlightText={filter}
                    id={user?.id?.toString()}
                    attributes={user?.attributes}
                    key={user.id.toString() + index.toString()}
                    onInvite={() => handlePrepareOrganization(index)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
