import { useTranslation } from 'react-i18next';

import { Status } from 'components/status/status';

import s from './Payments.module.scss';

interface PaymentsProps {
  id: number;
  status: string;
  variantStatus: string;
  text: string;
  date: string;
  description: string;
}

const dataPayments: PaymentsProps[] = [
  {
    id: 0,
    variantStatus: 'secondary-expectation',
    status: 'In processing',
    text: 'Prepayment',
    date: '10.04.2023',
    description: 'Advance payment under the contract',
  },
  {
    id: 1,
    variantStatus: 'active',
    status: 'Executed',
    text: 'Payment',
    date: '10.04.2023',
    description: 'Advance payment under the contract',
  },
];

export const Payments = () => {
  const { t } = useTranslation();

  return (
    <>
      {dataPayments?.map((item) => (
        <div key={item.id.toString()} className={s['container']}>
          <div className={s['wrapper-payment']}>
            <div className={s['content-payment']}>
              <div className={s['info']}>
                <div className={s['title']}>
                  <span className={s['title-text']}>{t(item.text)}</span>
                  <span className={s['title-time']}>{t('from')} {item.date}</span>
                </div>

                <span className={s['description']}>{t(item.description)}</span>
              </div>
              <Status text={item.status} variant={item.variantStatus} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
