import cx from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button';

import { ModalMain } from 'components/modal/main/main';
import { useReviewStaffInvite } from 'hooks';
import { selectIsLoggedIn } from 'modules/auth';
import { useSelector } from 'react-redux';
import { useGetStaffIncomingInvitationsListQuery } from 'store/api/staff';
import { useAppDispatch } from 'store/hooks';
import { selectStaffInviteId, setStaffInviteId } from 'store/reducers/invite.conteragent';
import styles from './invite.module.scss';
import { useAlert } from 'react-alert';
import { CounterpartyAttributesWhitelist } from 'constants/attributes';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'config/routes';
import { useLazyGetMyOrganizationsQuery } from 'store/api/organizations';

export const InviteModal = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn());
  const staffInviteId = useSelector(selectStaffInviteId());

  const [refetchOrganizations] = useLazyGetMyOrganizationsQuery();
  const { data: incoming = [], isFetching } = useGetStaffIncomingInvitationsListQuery(
    undefined,
    {
      skip: !isLoggedIn,
    },
  );

  const { onConfirmSubmit, onRejectSubmit } = useReviewStaffInvite({ inviteId: staffInviteId });

  const { t } = useTranslation();
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (isLoggedIn && staffInviteId) {
      setVisible(true);
    }
  }, [isLoggedIn, staffInviteId]);

  const inviteItem = useMemo(() => {
    return incoming.find((item) => item.id === staffInviteId);
  }, [incoming, isFetching]);

  useEffect(() => {
    if (isLoggedIn && !isFetching && staffInviteId && !incoming?.length) {
      setVisible(false);
      dispatch(setStaffInviteId(''));
      alert.error('Данное приглашение адресовано другому пользователю');
    }
  }, [isFetching, staffInviteId]);

  return (
    <ModalMain
      title="Приглашение"
      isVisible={isVisible}
      closeOnClick={() => setVisible(false)}
      footer={
        <div className={styles['button-wrapper']}>
          <Button
            text={t('Decline')}
            className={cx(styles['button'], styles['button-back'])}
            displayType="outlined-secondary"
            onClick={() =>
              onRejectSubmit(() => {
                setVisible(false);
              })
            }
          />
          <Button
            text={t('Approve invite')}
            className={styles['button']}
            onClick={async () => {
              onConfirmSubmit(async () => {
                await refetchOrganizations();
                dispatch(setStaffInviteId(''));
                setVisible(false);
                navigate(AppRoutes.Dashboard.Home);
              });
            }}
          />
        </div>
      }
    >
      <div className={styles.container}>
        <span className={styles.title}>
          {`${inviteItem?.organization?.name ?? t('Loading...')}`}
        </span>

        <div className={styles.attributes}>
          {inviteItem?.organization?.attributes
            .filter((filterItem) =>
              [
                ...CounterpartyAttributesWhitelist[inviteItem.organization.type],
                'kpp',
              ].includes(filterItem?.name?.toString()),
            )
            ?.map((item) => (
              <div key={item.name.toString()} className={styles['description']}>
                <span className={styles['reduction']}>{t(item.name)}: </span>
                <span className={styles['value']}>{item.value}</span>
              </div>
            ))}
        </div>

        <span className={styles['description-title']}>добавил Вас в качестве сотрудника</span>
        <div className={styles['description-wrapper']}></div>
      </div>
    </ModalMain>
  );
};
