import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { controlError } from 'utils/controlError';

import { Switch } from 'components/common';

import { SwitchProps } from 'components/common/switch/switch';

export interface SwitchFieldProps extends SwitchProps {
  name: string;
  placeholder?: string;
}

export const SwitchField = ({ name, ...props }: SwitchFieldProps): JSX.Element => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);

  const error = t(controlError(meta, name) as string);

  return (
    <Switch
      error={error}
      checked={field.value}
      onClick={() => {
        helpers.setValue(!field.value);
      }}
      {...props}
    />
  );
};
