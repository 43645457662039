import { Input } from 'antd';
import cx from 'classnames';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ContractItem } from 'components/contracts/contract-item/contract-item';
import { AsideFilter } from 'components/filter/filter';
import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';

import { ReactComponent as FilterIcon } from 'assets/images/filter.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search-dashboard.svg';
import { ReactComponent as PlusIcon } from 'assets/images/second-plus.svg';

import { Loader } from 'components/loader/loader';
import { useGetDocumentsListQuery } from 'store/api/documents';

import s from './AllContract.module.scss';
import { useAppDispatch } from 'store/hooks';
import { resetFuelFormState } from 'store/reducers/contract.reducer';
import { useDebounce } from 'usehooks-ts';

export const Label = ({ text }: { text: string }) => {
  const { t } = useTranslation();

  return (
    <div className={s['label-container']}>
      <span className={s['label-text']}>{t(text)}</span>
    </div>
  );
};

function AllContracts() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { Home, Contracts } = AppRoutes.Dashboard;

  const [filter, setFilter] = useState<string>('');
  const filterDebounced = useDebounce(filter, 1000);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<string[]>();

  const { data, isFetching } = useGetDocumentsListQuery({
    statuses: filterStatus,
    searchText: filterDebounced,
  });

  const handleFilter = () => {
    setIsFilter((prev) => !prev);
  };

  const handleNewContract = () => {
    dispatch(resetFuelFormState());
    navigate(Home + Contracts.Home + Contracts.NewContract);
  };

  const handleFilterValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilter(value);
  };

  return (
    <>
      <span className={s['title']}>{t('Contracts')}</span>
      <div className={s['container-content']}>
        <div className={s['header']}>
          <div className={s['wrapper-search']}>
            <div className={s['search']}>
              <Input
                allowClear
                value={filter ?? ''}
                prefix={<SearchIcon />}
                placeholder={t('Search...')}
                onChange={handleFilterValue}
                style={{ borderRadius: '0px', height: '40px' }}
              />
            </div>
            <Button
              icon={<FilterIcon />}
              onClick={handleFilter}
              className={cx(s['button'])}
              displayType="outlined-tetriary"
            >
              {t('Filter')}
            </Button>
          </div>

          <div className={s['wrapper-button']}>
            <Button
              icon={<PlusIcon />}
              onClick={handleNewContract}
              className={cx(s['button'], s['button-contract'])}
            >
              {t('New contract')}
            </Button>
          </div>
        </div>
        <div className={s['main']}>
          {isFetching ? (
            <Loader size="big" />
          ) : (
            data?.map((item, index) => (
              <ContractItem key={item.id.toString()} data={item} url={Home + Contracts.Home} />
            ))
          )}
        </div>
      </div>
      <AsideFilter
        isOpen={isFilter}
        onClick={handleFilter}
        status={filterStatus}
        onChangeStatus={setFilterStatus}
      />
    </>
  );
}

export default AllContracts;
