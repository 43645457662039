import { gql } from 'graphql-request';

export const GET_LIST_RETRUST_POA = gql`
  query CorePOA {
    corePOA {
      listRetrustPOAs {
        retrustPoaInfo {
          id
          number
          issueDate
          expirationDate
          revocationDate
          createdAt
          updatedAt
          status
          content
          retrust
          authority {
            id
            userType
            email
            rawUserInfo
          }
          recipientOrganization {
            id
            name
            status
            type
            countryCode
            createdAt
            updatedAt
            attributes {
              name
              value
            }
          }
          representative {
            id
            userType
            email
            rawUserInfo
          }
          powerDomain {
            code
            textDescription
            content
          }
          parentPOA {
            id
            issueDate
            expirationDate
            retrustFlag
            authorityLossFlag
            retrust
          }
        }
        rootPOAInfo {
          id
          number
          issueDate
          expirationDate
          revocationDate
          createdAt
          updatedAt
          status
          content
          retrust
          authority {
            id
            userType
            email
            rawUserInfo
          }
          recipientOrganization {
            id
            name
            status
            type
            countryCode
            createdAt
            updatedAt
            attributes {
              name
              value
            }
          }
          representative {
            id
            userType
            email
            rawUserInfo
          }
          powerDomain {
            code
            textDescription
            content
          }
          parentPOA {
            id
            issueDate
            expirationDate
            retrustFlag
            authorityLossFlag
            retrust
          }
          delegatedPOAs {
            id
            issueDate
            expirationDate
            retrustFlag
            authorityLossFlag
            retrust
          }
        }
      }
    }
  }
`;
