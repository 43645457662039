import { removeExtraSymbolsFromBase64 } from 'helpers';
import TemplateSignerBase from './template-signer-base';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//  @ts-ignore
import ccpa from 'cadesplugin-crypto-pro-api';
import { Certificate, TemplateData } from 'client/models';

interface ICryptoProCertificate {
  certApi: any;
  issuerInfo: string;
  privateKey: any;
  serialNumber: string;
  thumbprint: string;
  subjectInfo: string;
  validPeriod: {
    from: string;
    to: string;
  };

  friendlyInfo(subjectIssuer: 'issuerInfo' | 'subjectInfo'): Array<{
    text: string;
    value: string;
  }>;

  friendlySubjectInfo(): Array<{
    text: string;
    value: string;
  }>;

  friendlyIssuerInfo(): Array<{
    text: string;
    value: string;
  }>;

  friendlyValidPeriod(): {
    from: string;
    to: string;
  };

  possibleInfo(subjectIssuer: 'issuerInfo' | 'subjectInfo'): { [key: string]: string };

  friendlyDate(date: string): {
    ddmmyy: string;
    hhmmss: string;
  };

  isValid(): Promise<boolean>;
}

interface CCPA {
  getCertsList: () => Promise<ICryptoProCertificate[]>;
  getCert: (thumbprint: string) => Promise<ICryptoProCertificate>;
  currentCadesCert: () => Promise<ICryptoProCertificate>;
  signBase64: (thumbprint: string, data: string, detached: boolean) => Promise<string>;
  signXml: (thumbprint: string, data: string) => Promise<string>;
  about: () => Promise<string>;
}

const CADESCOM_ENCODE_BASE64 = 0;

export default class CryptoProSigner extends TemplateSignerBase {
  constructor() {
    super();
  }

  formatTitle(title: string) {
    return title.replace(/""(.*?)""/g, '«$1»').replace(/^"|"$/g, '');
  }

  async getCertificates(): Promise<Certificate[]> {
    const certsApi = (await ccpa) as CCPA;
    const certsList = await certsApi.getCertsList();

    return Promise.all(
      certsList.map(async (cert: any) => {
        const friendlySubjectInfo = cert.friendlySubjectInfo();
        const surname = this.getFieldInfo(cert, 'Фамилия');
        const nameAndPatronymic = this.getFieldInfo(cert, 'Имя/Отчество');
        const owner = this.getFieldInfo(cert, 'Владелец');
        const company = this.getFieldInfo(cert, 'Компания');

        let title = '';
        if (surname && nameAndPatronymic) {
          title = `${surname} ${nameAndPatronymic}`;
        }
        if ((!surname || !nameAndPatronymic) && owner) {
          title = owner;
        }
        if (company) {
          title = company;
        }

        title = this.formatTitle(title);

        const subject = friendlySubjectInfo.reduce((acc: any, item: any) => {
          acc[item.value] = item.text;
          return acc;
        }, {});

        return {
          Subject: subject,
          FriendlyName: title,
          Thumbprint: cert.thumbprint,
          Base64RawData: await cert?.certApi?.Export(CADESCOM_ENCODE_BASE64),
        };
      }),
    );
  }

  getFieldInfo(cert: ICryptoProCertificate, field: string): string | undefined {
    const friendlySubjectInfo = cert.friendlySubjectInfo();

    return friendlySubjectInfo?.find((item) => item?.value === field)?.text;
  }

  getCustomAttribut(cert: ICryptoProCertificate, field: string): string | null {
    const customAttributes = new Map<string, string>([
      ['ИННЮЛ', 'ИННЮЛ=(\\d{10})'],
      ['INNLE', 'INNLE=(\\d{10})'],
    ]);

    const template = customAttributes.get(field);

    if (!template) {
      return null;
    }

    const expression = new RegExp(template);
    const matches = cert.subjectInfo.match(expression);

    return matches ? matches[1] : null;
  }

  async sign({
    certificate,
    dataForSign,
  }: {
    certificate: Certificate;
    dataForSign: string;
    signDate?: string;
  }): Promise<string> {
    const certsApi = (await ccpa) as CCPA;

    const isCertificateExist = await certsApi.getCert(certificate?.Thumbprint);

    if (!isCertificateExist) {
      throw new Error(`Сертификат с отпечатком ${certificate?.Thumbprint} не найден`);
    }

    return removeExtraSymbolsFromBase64(
      await certsApi.signBase64(certificate?.Thumbprint, dataForSign, true),
    );
  }

  async signXml({
    props,
    dataForSign,
    certificate,
  }: {
    dataForSign: string;
    certificate: Certificate;
    props?: Partial<TemplateData>;
  }): Promise<{ signature: string; detachedSignature: string }> {
    const certsApi = (await ccpa) as CCPA;

    const isCertificateExist = await certsApi.getCert(certificate?.Thumbprint);

    if (!isCertificateExist) {
      throw new Error(`Сертификат с отпечатком ${certificate?.Thumbprint} не найден`);
    }
    const xmlToSign = await this.addSignatureTemplate(dataForSign, certificate, props);

    return {
      signature: removeExtraSymbolsFromBase64(
        await certsApi.signXml(certificate?.Thumbprint, xmlToSign),
      ),
      detachedSignature: '',
    };
  }
}
