import { ReactNode, RefObject } from 'react';
import s from './Button.module.scss';
import cx from 'classnames';

export interface ButtonProps {
  removeRoot?: boolean;
  icon?: JSX.Element;
  size?: 'small' | 'default' | 'medium' | 'big';
  text?: string;
  displayType?: 'outlined-primary' | 'outlined-secondary' | 'outlined-tetriary';
  btnRef?: RefObject<HTMLButtonElement>;
  type?: 'primary' | 'secondary' | 'tetriary' | 'green' | 'icon' | 'null' | 'cancel';
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export function Button(props: ButtonProps) {
  const {
    text,
    size,
    icon,
    btnRef,
    onClick,
    disabled,
    children,
    className,
    removeRoot,
    displayType,
    type = 'primary',
  } = props;
  const classes = cx(
    {
      [s.root]: !removeRoot,
      [s.primary]: type === 'primary',
      [s.secondary]: type === 'secondary',
      [s.cancel]: type === 'cancel',
      [s.tetriary]: type === 'tetriary',
      [s.green]: type === 'green',
      [s.icon]: type === 'icon',
      [s.disabled]: disabled,
      [s.small]: size === 'small',
      [s.big]: size === 'big',
      [s?.['outlined-tetriary']]: displayType === 'outlined-tetriary',
      [s?.['outlined-primary']]: displayType === 'outlined-primary',
      [s?.['outlined-secondary']]: displayType === 'outlined-secondary',
    },
    className,
  );

  return (
    <button ref={btnRef} className={classes} disabled={disabled} onClick={onClick}>
      {icon && <span className={s.icon}>{icon}</span>}
      {children || text}
    </button>
  );
}
