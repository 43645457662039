import { useTranslation } from 'react-i18next';

import { HeaderPage } from 'components/header-page/header-page';

import useProcessXslt from 'hooks/use-process-xslt';
import { useSelector } from 'react-redux';
import { selectDocumentContent } from 'store/reducers/attorney.create';
import s from './styles.module.scss';

export const NewAttorneySidePreview = () => {
  const { t } = useTranslation();

  const documentContent = useSelector(selectDocumentContent());

  const { data: xsltPreview } = useProcessXslt({ base64: documentContent });

  return (
    <div className={s['container']}>
      <HeaderPage title={t('Предпросмотр Доверенности')} />

      <div className={s['content']}>
        <div className={s['scroll']}>
          <div className={s['wrapper-main']}>
            {xsltPreview && (
              <span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: new XMLSerializer().serializeToString(xsltPreview as any),
                  }}
                />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
