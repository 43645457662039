import { TFunction } from 'i18next';
import * as Yup from 'yup';

export interface OrganizationFormValues {
  status: string;
  role: string;
  email: string;
  settingAccess: string;
}

export const model = {
  status: {
    label: 'Status',
    name: 'status',
    placeholder: 'Select from the list',
    options: ['Status 1', 'Status 2', 'Status 3'].map((label) => ({
      label,
      value: label,
    })),
    required: true,
  },
  role: {
    label: 'Role',
    name: 'role',
    placeholder: 'Select from the list',
    options: [
      {
        label: 'Сотрудник',
        value: 'employee',
      },
      {
        label: 'Администратор',
        value: 'administrator',
      },
    ],
    required: true,
  },
};

export const validationSchema = (t: TFunction) => Yup.object().shape({});

export const initialValues = {
  [model.role.name]: '',
  [model.status.name]: '',
} as unknown as OrganizationFormValues;
