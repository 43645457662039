import { useTranslation } from 'react-i18next';
import { model } from 'components/attorney/forms/config';

import { InputField } from 'components/common';

import s from './individual-entrepreneur.module.scss';

export const IndividualEntrepreneurGroup = () => {
  const { t } = useTranslation();

  return (
    <div className={s['container']}>
      <span className={s['title']}>{t('Information about an individual entrepreneur')}</span>
      <div className={s['wrapper-content']}>
        <InputField {...model.nameCompanyAddingRepresentative} label="Наименование ИП" />
        <InputField {...model.ogrnip} />
      </div>
    </div>
  );
};
