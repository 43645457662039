import s from './highlight.module.scss'

interface HighlightTextProps {
  text: string;
  searchText?: string;
}

export const HighlightText = ({ text, searchText }: HighlightTextProps) => {
  if (searchText) {
    const searchRgx = new RegExp(`(${searchText})`, 'i');
    const texts = text.split(searchRgx);

    return (
      <span>
        {texts.map((value, index) => {
          const isMatch = searchRgx.test(value);

          if (isMatch) {
            return (
              <span key={`${value}-${index}`} className={s.highlight}>
                {value}
              </span>
            );
          }

          return <span key={`${value}-${index}`}>{value}</span>;
        })}
      </span>
    );
  }

  return <span>{text}</span>;
};