import { gql } from 'graphql-request';

export const GET_OPERATIONAL_REQUEST_DOCUMENT_SCHEME = gql`
  query App5182dd71_Document($documentId: String) {
    app5182dd71_Document {
      getTemplate(documentId: $documentId) {
        template {
          step
          name
          type
          value
          required
          isSearchable
          placeholder
          fieldTransform
          fieldType
          label {
            en
            ru
          }
          stepLabel
          validation
          options {
            label
            value
          }
        }
      }
    }
  }
`;
