import { DatePickerMode } from 'components/common/date-picker/date-picker';
import { InputMasks } from 'components/common/input-field/input-field';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export interface AttorneyAddRepresentiveFormValues {
  typeRepresentative: string;
  paymentProcedure: string;
  nameCompanyAddingRepresentative: string;
  inn: string;
  ogrnip: string;
  kppAddingRepresentative: string;
  ogrn: string;
  surname: string;
  name: string;
  middleName: string;
  birthdateRepresentative: string;
  citizenshipRepresentative: string;
  innFl: string;
  snils: string;
  typeIdentityDocument: string;
  documentSeriesAndNumber: string;
  dateIssueDocument: string;
  nameAuthorityIssuedDocument: string;
}

export const model = {
  typeRepresentative: {
    type: 'radio',
    required: true,
    name: 'typeRepresentative',
    label: 'Type of representative',
    options: [
      { label: 'Legal entity', value: 'rus_legal_entity' },
      { label: 'Individual entrepreneur', value: 'sole_proprietor' },
      { label: 'Physical person', value: 'natural_person' },
    ],
  },
  paymentProcedure: {
    label: 'Payment procedure',
    name: 'paymentProcedure',
    placeholder: 'Select from the list',
    options: ['Legal entity 1', 'Legal entity 2', 'Legal entity 3'].map((label) => ({
      label,
      value: label,
    })),
    required: true,
  },
  nameCompanyAddingRepresentative: {
    type: 'text',
    required: true,
    placeholder: '',
    name: 'nameCompanyAddingRepresentative',
    label: 'Name company',
  },
  inn: {
    type: 'text',
    required: true,
    placeholder: '',
    name: 'inn',
    label: 'INN',
    mask: InputMasks.inn10,
  },
  kppAddingRepresentative: {
    type: 'text',
    required: true,
    placeholder: '',
    name: 'kppAddingRepresentative',
    label: 'KPP',
    mask: InputMasks.org9,
  },
  ogrn: {
    type: 'text',
    required: true,
    placeholder: '',
    name: 'ogrn',
    label: 'OGRN',
    mask: InputMasks.org13,
  },
  ogrnip: {
    type: 'text',
    required: true,
    placeholder: '',
    name: 'ogrnip',
    label: 'OGRNIP',
    mask: InputMasks.org13,
  },
  surname: {
    type: 'text',
    required: true,
    placeholder: '',
    name: 'surname',
    label: 'Surname',
  },
  name: {
    type: 'text',
    required: true,
    placeholder: '',
    name: 'name',
    label: 'Name',
  },
  middleName: {
    type: 'text',
    required: true,
    placeholder: '',
    name: 'middleName',
    label: 'Middle name',
  },
  birthdateRepresentative: {
    type: 'date',
    required: true,
    mode: DatePickerMode.SINGLE,
    name: 'birthdateRepresentative',
    label: 'Bithdate',
  },
  citizenshipRepresentative: {
    type: 'radio',
    required: true,
    name: 'citizenship',
    label: 'Citizenship',
    options: [
      { label: 'Citizen of the Russian Federation', value: 'RF' },
      { label: 'Foreign citizen', value: 'foreign' },
    ],
  },
  innFl: {
    type: 'text',
    required: true,
    placeholder: '',
    name: 'innFl',
    label: 'INN FL',
    mask: InputMasks.org13,
  },
  snils: {
    type: 'text',
    required: true,
    placeholder: '',
    name: 'snils',
    label: 'SNILS',
    mask: InputMasks.org14,
  },
  typeIdentityDocument: {
    label: 'Type of identity document',
    name: 'typeIdentityDocument',
    placeholder: 'Select from the list',
    options: [
      { label: 'Passport of a citizen of the Russian Federation', value: '21' },
      { label: 'Passport of a foreign citizen', value: '10' },
      { label: 'Birth certificate', value: '03' },
      { label: 'Military ticket', value: '07' },
      {
        label:
          'Certificate of consideration of the application for recognition of a person as a refugee on the territory of the Russian Federation on the merits',
        value: '11',
      },
      { label: 'Residence permit in the Russian Federation', value: '12' },
      { label: 'Refugee certificate', value: '13' },
      { label: 'Temporary residence permit in the Russian Federation', value: '15' },
      {
        label: 'Certificate of temporary asylum in the territory of the Russian Federation',
        value: '19',
      },
      { label: 'Identity card of a serviceman of the Russian Federation', value: '24' },
    ],
    required: true,
  },
  documentSeriesAndNumber: {
    type: 'text',
    required: true,
    placeholder: '',
    name: 'documentSeriesAndNumber',
    label: 'Document series and number',
    mask: InputMasks.org9,
  },
  dateIssueDocument: {
    type: 'date',
    required: true,
    mode: DatePickerMode.SINGLE,
    name: 'dateIssueDocument',
    label: 'Date of issue of the document',
  },
  nameAuthorityIssuedDocument: {
    type: 'text',
    label: 'Name of the authority that issued the document',
    name: 'nameAuthorityIssuedDocument',
    placeholder: '',
    required: true,
  },
};

export const validationSchema = ({
  t,
  isLegalEntity,
}: {
  t: TFunction;
  isLegalEntity?: boolean;
}) =>
  Yup.object().shape({
    [model.name.name]: Yup.string().required('Name is required'),
    [model.surname.name]: Yup.string().required('Surname is required'),
    [model.middleName.name]: Yup.string().required('MiddleName is required'),
    [model.innFl.name]: Yup.number().required('This field is required'),
    [model.snils.name]: Yup.number().required('This field is required'),
    [model.dateIssueDocument.name]: Yup.string().required('This field is required'),
    [model.birthdateRepresentative.name]: Yup.string().required('This field is required'),
    [model.citizenshipRepresentative.name]: Yup.string().required('Citizenship is required'),
    [model.typeRepresentative.name]: Yup.string().required('Type representative is required'),
    [model.nameAuthorityIssuedDocument.name]: Yup.string().required('This field is required'),
    [model.documentSeriesAndNumber.name]: Yup.number().required(
      'Document series and number is required',
    ),
    // [model.paymentProcedure.name]: Yup.string().required('Payment procedure is required'),
    //nameCompanyAddingRepresentative: Yup.string().required('Name company is required'),

    ...(isLegalEntity
      ? {
          [model.inn.name]: Yup.number().required('This field is required'),
          [model.ogrn.name]: Yup.number().required('This field is required'),
          kppAddingRepresentative: Yup.number().required('KPP is required'),
        }
      : {}),
  });

export const initialValues = {
  [model.inn.name]: '',
  [model.name.name]: '',
  [model.ogrn.name]: '',
  [model.innFl.name]: '',
  [model.snils.name]: '',
  [model.surname.name]: '',
  [model.middleName.name]: '',
  [model.typeRepresentative.name]: '',
  [model.dateIssueDocument.name]: null,
  [model.documentSeriesAndNumber.name]: '',
  [model.kppAddingRepresentative.name]: '',
  [model.citizenshipRepresentative.name]: '',
  [model.birthdateRepresentative.name]: null,
  [model.nameAuthorityIssuedDocument.name]: '',
  [model.nameCompanyAddingRepresentative.name]: '',
  // [model.paymentProcedure.name]: '',
} as unknown as AttorneyAddRepresentiveFormValues;
