import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { getToken } from 'utils/token';

const token = getToken();

const link = createUploadLink({
  uri: process.env.REACT_APP_API_BASE_PATH + '/graphql',
  headers: {
    'apollo-require-preflight': 'true',
    authorization: `Bearer ${token}`,
  },
});

export const uploadClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});
