import { useField } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AttorneyFormValues, model } from '../config';

import { RadioGroupField } from 'components/fields/radio-group-field/select-filed';
import { SelectField } from 'components/fields/select-fields/select-filed';
import { PowerDomainsWizard } from 'components/forms/authority/authority';
import { AsideRepresentative } from 'components/modal/aside-representative/aside-representative';

import { Button } from 'components/ui/Button';

import { ReactComponent as InfoIcon } from 'assets/images/information.svg';
import { ReactComponent as PlusIcon } from 'assets/images/plus.svg';

import { useSearchPoausersQuery } from 'store/api/poaUsers';
import s from './information-representative.module.scss';
import { useStaffPermissionsData } from 'components/attorney/use-staff-permissions';
import { useGetStaffByIdQuery } from 'store/api/staff';
import { useCreateRepresentative } from 'components/attorney/use-create-representative';

type InformationRepresentativeProps = {
  values: AttorneyFormValues;
};

export const Authority = (props: InformationRepresentativeProps) => {
  const { t } = useTranslation();
  const { values } = props;

  const staffPermissionsData = useStaffPermissionsData();

  const [, , helpers] = useField(model.powerDomains);

  useEffect(() => {
    if (staffPermissionsData) {
      helpers.setValue(staffPermissionsData?.permissions?.map((item) => item.name));
    }
  }, [staffPermissionsData]);

  const lastOption = model.authority.options[model.authority.options.length - 1];

  return (
    <div className={s['container-authority']}>
      {!values?.hasPrePowerOfAttorney && (
        <RadioGroupField
          variant="column"
          {...model.authority}
          value={{
            label: t(lastOption.label),
            value: lastOption.value,
          }}
        />
      )}
      {values?.hasPrePowerOfAttorney && values?.powerDomains?.length ? null : (
        <PowerDomainsWizard
          values={values}
          field={model.powerDomains}
          disabled={values?.hasPrePowerOfAttorney}
        />
      )}
    </div>
  );
};

export const Representative = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const [, , helpers] = useField(model.representative.name);

  const { data, isLoading } = useSearchPoausersQuery();
  const { createRepresentativeByStaff } = useCreateRepresentative({});
  const staffPermissionsData = useStaffPermissionsData();
  const staffId = staffPermissionsData?.staffId;
  const { data: staff } = useGetStaffByIdQuery({ id: staffId ?? '' }, { skip: !staffId });

  const [inititalizatedLength, setInititalizatedLength] = useState<number | undefined>();

  const options = useMemo(() => {
    return data
      ?.filter((item) => item.name || item?.organizationName)
      ?.map((item) => ({
        label: item.organizationName?.length
          ? item?.organizationName
          : `${item.surname} ${item.name} ${item.parentalName}`,
        value: item.id,
      }));
  }, [data]);

  useEffect(() => {
    if (!isLoading && inititalizatedLength === undefined) {
      setInititalizatedLength(data?.length ?? 0);
    }
  }, [inititalizatedLength]);

  useEffect(() => {
    if ((data?.length ?? 0) > (inititalizatedLength ?? 0)) {
      helpers.setValue(data?.[data?.length - 1]?.id);
    }
  }, [data, inititalizatedLength]);

  useEffect(() => {
    if (!isLoading && staff) {
      const representative = data?.find(
        (item) =>
          item.inn === staff.attributes?.find((item) => item.name?.includes('inn'))?.value,
      );
      if (!representative) {
        createRepresentativeByStaff(staff);
      } else {
        helpers.setValue(representative.id);
      }
    }
  }, [staff, isLoading, inititalizatedLength]);

  const optionButton = {
    className: s['button'],
    text: t('Add representative'),
    icon: <PlusIcon width={24} height={24} />,
    onClick,
  };

  return (
    <div className={s['container-representative']}>
      <SelectField {...model.representative} options={options} />
      <div className={s['wrapper-info']}>
        <InfoIcon />
        <span className={s['text-info']}>
          {t('If you have not found the right representative, you can add one')}
        </span>
      </div>

      <Button {...optionButton} />
    </div>
  );
};

export const InformationRepresentative = (props: InformationRepresentativeProps) => {
  const { values } = props;

  const [isRepresentative, setIsRepresentative] = useState(false);

  const show = () => {
    setIsRepresentative(true);
  };

  const hide = () => {
    setIsRepresentative(false);
  };

  return (
    <div className={s['container']}>
      <Authority {...props} />
      <Representative onClick={show} />
      <AsideRepresentative mainValues={values} isOpen={isRepresentative} handleClose={hide} />
    </div>
  );
};
