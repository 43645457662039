import { createApi } from '@reduxjs/toolkit/query/react';

import { FINISH_REGISTRATION_BY_REPRESENTATIVE } from './mutations/organizations/finish.registration.by.representative';
import { CORE_ORGANIZATIONS_PREPARE } from './mutations/organizations/prepare.organization';
import { START_REGISTRATION_BY_REPRESENTATIVE } from './mutations/organizations/start.registration.by.representative';
import {
  CoreOrganizationsUpdateInput,
  CoreOrganizationsUpdateMyResponse,
  FinishRegistrationByRepresentativeInput,
  PrepareOrganizationInput,
  StartRegistrationByRepresentativeInput,
  StartRegistrationByRepresentativeResponse,
} from './mutations/organizations/types';
import { UPDATE_MY_ORGANIZATION } from './mutations/organizations/update.my.organizations';
import { GET_CORE_ORGANIZATIONS } from './queries/organizations/get.core.organizations';
import { GET_MY_ORGANIZATIONS } from './queries/organizations/get.my.organizations';
import { GET_ORGANIZATION } from './queries/organizations/get.organization';
import { GET_PARTNER_ORGANIZATION } from './queries/organizations/get.partner.organization';
import { GET_PARTNERS_ORGANIZATIONS } from './queries/organizations/get.partners.organizations';
import {
  CoreOrganizationsByIdResponse,
  CoreOrganizationsMyResponse,
  CoreOrganizationsPartnersResponse,
  CoreOrganizationsPrepareResponse,
  CoreOrganizationsQueryRequest,
  CoreOrganizationsQueryResponse,
  PartnerRequest,
  PartnersRequest,
} from './queries/organizations/types';
import { CoreOrganizationsPartnerResponse } from './queries/payments/types';
import { baseQuery } from './query';
import { Organization, Partner } from './types';

export const OrganizationsTags = {
  My: 'My Organization',
  List: 'List',
};
const tagTypes = [OrganizationsTags.My, OrganizationsTags.List];

export const organizationsApi = createApi({
  tagTypes,
  baseQuery: baseQuery,
  reducerPath: 'organizationsApi',
  endpoints: (builder) => ({
    getCoreOrganizations: builder.query<Organization[], CoreOrganizationsQueryRequest>({
      query: ({ searchName }) => ({
        document: GET_CORE_ORGANIZATIONS,
        variables: {
          searchText: searchName ?? null,
        },
      }),
      transformResponse: (rawResponse: CoreOrganizationsQueryResponse) =>
        rawResponse?.coreOrganizations?.query?.organizations,
    }),
    getMyOrganizations: builder.query<Organization[], void>({
      query: () => ({
        document: GET_MY_ORGANIZATIONS,
      }),
      transformResponse: (rawResponse: CoreOrganizationsMyResponse) =>
        rawResponse?.coreOrganizations?.my,
      providesTags: [OrganizationsTags.My],
    }),
    getMyOrganizationById: builder.query<Organization[], { id: string }>({
      query: ({ id }) => ({
        document: GET_ORGANIZATION,
        variables: { ids: [id] },
      }),
      transformResponse: (rawResponse: CoreOrganizationsByIdResponse) =>
        rawResponse?.coreOrganizations?.get,
    }),
    updateMyOrganization: builder.mutation<
      Organization,
      { input: CoreOrganizationsUpdateInput }
    >({
      query: ({ input }) => ({
        document: UPDATE_MY_ORGANIZATION,
        variables: { input },
      }),
      transformResponse: (rawResponse: CoreOrganizationsUpdateMyResponse) =>
        rawResponse?.coreOrganizations?.update,
      invalidatesTags: [OrganizationsTags.My],
    }),
    getPartnerOrganization: builder.query<Partner, PartnerRequest>({
      query: ({ id }) => ({
        document: GET_PARTNER_ORGANIZATION,
        variables: {
          id,
        },
      }),
      transformResponse: (rawResponse: CoreOrganizationsPartnerResponse) =>
        rawResponse?.coreOrganizations?.getPartner,
    }),
    getPartnersOrganizations: builder.query<Organization[], PartnersRequest>({
      query: ({ searchText }) => ({
        document: GET_PARTNERS_ORGANIZATIONS,
        variables: {
          searchText: searchText ?? null,
        },
      }),
      transformResponse: (rawResponse: CoreOrganizationsPartnersResponse) =>
        rawResponse?.coreOrganizations?.getPartners.organizations,
      providesTags: [OrganizationsTags.List],
    }),

    prepareOrganization: builder.mutation<string, PrepareOrganizationInput>({
      query: ({ organizationAttributes }) => ({
        document: CORE_ORGANIZATIONS_PREPARE,
        variables: { organizationAttributes },
      }),
      transformResponse: (rawResponse: CoreOrganizationsPrepareResponse) =>
        rawResponse?.coreOrganizations?.prepare.organization.id,
    }),
    startRegistrationByRepresentative: builder.mutation<
      string,
      StartRegistrationByRepresentativeInput
    >({
      query: ({ inviteId, poaId }) => ({
        document: START_REGISTRATION_BY_REPRESENTATIVE,
        variables: {
          poaId,
          inviteId,
        },
      }),
      transformResponse: (rawResponse: StartRegistrationByRepresentativeResponse) =>
        rawResponse?.coreOrganizations?.startRegistrationByRepresentative?.joinDocumentContent,
    }),
    finishRegistrationByRepresentative: builder.mutation<
      void,
      FinishRegistrationByRepresentativeInput
    >({
      query: ({ inviteId, poaId, signature, detachedSignature }) => ({
        document: FINISH_REGISTRATION_BY_REPRESENTATIVE,
        variables: {
          poaId,
          inviteId,
          signature,
          detachedSignature,
        },
      }),
    }),
  }),
});

export const {
  useGetMyOrganizationsQuery,
  useGetCoreOrganizationsQuery,
  useGetMyOrganizationByIdQuery,
  useGetPartnerOrganizationQuery,
  usePrepareOrganizationMutation,
  useLazyGetMyOrganizationsQuery,
  useUpdateMyOrganizationMutation,
  useGetPartnersOrganizationsQuery,
  useStartRegistrationByRepresentativeMutation,
  useFinishRegistrationByRepresentativeMutation,
} = organizationsApi;
