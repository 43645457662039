import './i18n';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { StoreProvider, store } from 'store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import { GlobalAlertProvider } from 'components/alert/alert';
import { App } from 'App';

import './styles/index.scss';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
dayjs.locale('ru');

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StoreProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <GlobalAlertProvider>
          <App />
        </GlobalAlertProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StoreProvider>,
);
