import { useMemo } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { getSelectValue } from 'utils/helpers';
import { controlError } from 'utils/controlError';
import { SelectProps } from 'components/common/select/select-types';

import { Select } from 'components/common/select/select';

import s from './select-field.module.scss';

export const InputMasks = {
  codeSecond: (value: string) => {
    const numericValue = value.replace(/\D/g, '');

    return `(${numericValue})`;
  },
};

export interface SelectFieldProps extends SelectProps {
  name: string;
  isColumn?: boolean;
  disabled?: boolean;
  onlyValue?: boolean;
  placeholder?: string;
  mask?: (value: string) => string;
}

export const SelectField = ({
  name,
  mask,
  options,
  onChange,
  disabled,
  onlyValue,
  placeholder,
  ...props
}: SelectFieldProps): JSX.Element => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);

  const value = useMemo(
    () => getSelectValue({ onlyValue, options, value: field.value }),
    [onlyValue, options, field.value],
  );

  const error = t(controlError(meta, name, t(placeholder ?? '') as string) as string);

  return (
    <Select
      {...field}
      {...props}
      error={error}
      value={value}
      options={options}
      isDisabled={disabled}
      placeholder={t(placeholder ?? '') as string}
      onChange={(option, action) => {
        const newValue = onlyValue
          ? Array.isArray(option)
            ? option.map((item) => item.value)
            : option?.values
          : option;
        onChange?.(option, action);
        helpers.setValue(newValue?.value);
      }}
    />
  );
};
