import { useTranslation } from 'react-i18next';

import { DatePickerField, InputField } from 'components/common';
import { RadioGroupField } from 'components/fields/radio-group-field/select-filed';
import { SelectField } from 'components/fields/select-fields/select-filed';

import { PoaCreateFromValues, model } from './config';

import { useCountriesOptions } from 'hooks/use-countires-options';
import s from './PoaCreatForm.module.scss';

export const PoaCreateForm = ({ values }: { values: PoaCreateFromValues }) => {
  const { t } = useTranslation();
  const { defaultCountry, options } = useCountriesOptions();

  return (
    <div className={s['wrapper-form']}>
      <div className={s['wrapper-group']}>
        <p className={s['label']}>{t('Information about the organization')}</p>
        <InputField {...model.nameOrganization} />
        <InputField {...model.inn} />
        <InputField {...model.kpp} />
      </div>

      <div className={s['wrapper-group']}>
        <p className={s['label']}>{t('Enter your personal information')}</p>
        <InputField {...model.surname} />
        <InputField {...model.name} />
        <InputField {...model.middleName} />
        <InputField {...model.innFl} />
        <InputField {...model.snils} />
        <DatePickerField {...model.birthdate} />
        <RadioGroupField {...model.citizenship} />
        {values?.citizenship === 'foreign' && (
          <SelectField {...model.country} options={options} defaultValue={defaultCountry} />
        )}
        <SelectField
          {...model.typeIdentityDocument}
          options={model.typeIdentityDocument?.options.map((item) => ({
            label: t(item.label),
            value: item.value,
          }))}
        />
        <InputField {...model.documentSeriesAndNumber} />
        <DatePickerField {...model.dateIssueDocument} />
        <InputField {...model.nameAuthorityIssuedDocument} />
        <InputField {...model.email} />
      </div>
    </div>
  );
};
