import { TFunction } from 'i18next';
import * as Yup from 'yup';

export interface StatusOption {
  label: string;
  value: string;
}

export interface AllContractsFormValues {
  typeContract: string;
  status: StatusOption[];
}

export const model = {
  typeContract: {
    label: 'Type contract',
    name: 'typeContract',
    placeholder: 'Select type contract',
    options: ['Russia', 'Belarus', 'Kazakhstan', 'Russia-1', 'Russia-2', 'Russia-3'].map(
      (label) => ({
        label,
        value: label,
      }),
    ),
  },
  status: {
    label: 'Status',
    name: 'status',
    placeholder: 'Select status',
  },
};

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    //
  });

export const initialValues = {
  [model.typeContract.name]: '',
  [model.status.name]: [],
} as unknown as AllContractsFormValues;
