import { gql } from 'graphql-request';

export const GET_ME = gql`
  query CoreEmployees {
    coreEmployees {
      me {
        id
        firstName
        middleName
        lastName
        status
        attributes {
          name
          value
        }
      }
    }
  }
`;
