import { gql } from 'graphql-request';

export const GET_STAFF_POAS_LIST = gql`
  query CorePOA($representativeEmployeeID: ID) {
    corePOA {
      query(
        filter: { representativeEmployeeID: $representativeEmployeeID, byEmployee: false }
      ) {
        poas {
          poaDraft {
            id
            number
            issueDate
            expirationDate
            revocationDate
            createdAt
            updatedAt
            status
            content
            retrust
            powerDomain {
              code
              textDescription
              content
            }
          }
        }
      }
    }
  }
`;
