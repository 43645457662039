import { DatePickerMode } from 'components/common/date-picker/date-picker';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export interface EDOOperationFormValues {
  email: string;
  edoOperation: string;
}

export const model = {
  email: {
    type: 'email',
    label: 'E-mail',
    name: 'email',
    placeholder: 'Enter email',
    required: true,
  },
  edoOperation: {
    label: 'Select EDO operation',
    name: 'edoOperation',
    placeholder: 'Select from the list',
    options: ['EDO 1', 'EDO 2', 'EDO 3'].map((label) => ({
      label,
      value: label,
    })),
    required: true,
  },
  token: {
    type: 'password',
    name: 'token',
    label: 'Token API',
  },
};

export const validationSchema = (t: TFunction) => Yup.object().shape({});

export const initialValues = {
  [model.email.name]: '',
  [model.token.name]: '',
  [model.edoOperation.name]: '',
} as unknown as EDOOperationFormValues;
