import cx from 'classnames';
import { AppRoutes } from 'config/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectInvitationsType, setStatus } from 'store/reducers/invite.list';

import { Status } from 'components/status/status';

import { CounterpartyAttributesWhitelist } from 'constants/attributes';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { PartyInfo } from 'store/api/types/organizations-invitations';
import s from './user.module.scss';
import { DefaultDateFormat } from 'utils/date';

type Props = {
  item: PartyInfo;
  isActive?: boolean;
  customAttributes?: string[];
  onClick?: (item: PartyInfo) => void;
};

export const UserCard = ({ item, customAttributes = [], onClick, isActive }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Home, Counterparties } = AppRoutes.Dashboard;
  const type = useSelector(selectInvitationsType);

  const { partyOrganization, createdAt, status } = item;

  const classesContent = cx({
    [s['line']]: 'test',
    [s['user-content']]: !'test',
    [s['active']]: isActive,
  });

  const handleUserClick = (id: string) => {
    if (onClick) {
      onClick(item);
      return;
    }

    dispatch(setStatus(status));
    navigate(Home + Counterparties.Home + Counterparties.Invitations + `/${id}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, id: string) => {
    if (event.key === 'Enter') {
      handleUserClick(id);
    }
  };

  return (
    <div
      className={s['user-container']}
      role="button"
      tabIndex={0}
      onClick={() => handleUserClick(item?.id)}
      onKeyDown={(e) => handleKeyDown(e, item?.id)}
    >
      <div className={classesContent}>
        <div className={s['user-header']}>
          <div className={s['date']}>
            <span>{dayjs(createdAt).format(DefaultDateFormat)}</span>
            <span>{dayjs(createdAt).format('HH:mm')}</span>
          </div>
          <Status text={item.status} type={type} />
        </div>
        <div className={s['user-main']}>{partyOrganization?.name}</div>
        <div className={s['user-footer']}>
          {partyOrganization?.attributes
            ?.filter((item) =>
              [
                ...CounterpartyAttributesWhitelist[partyOrganization?.type],
                ...customAttributes,
              ]?.includes(item?.name?.toString()),
            )
            ?.map((item) => (
              <div key={item?.name?.toString()} className={s['description']}>
                <span className={s['reduction']}>{t(item?.name)}:</span>
                <span className={s['value']}>{item.value}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
