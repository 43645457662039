import cx from 'classnames';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { controlError } from 'utils/controlError';

import { Radio } from 'antd';
import { SwitchProps } from 'components/common/switch/switch';
import { ErrorLabel } from 'components/error-label';
import { FieldLabel } from 'components/field-label';

export const variantRadioGroup = {
  row: 'row',
  column: 'column',
};
export interface RadioGroupProps extends SwitchProps {
  variant?: string;
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  isContent?: boolean;
  value?: {
    value: boolean | string;
    label: string;
  };
  options: Array<{
    value: boolean | string;
    label: string;
    content?:
      | {
          id: string;
          date?: string;
          title?: string;
          rangeDate?: {
            from?: string;
            to?: string;
          };
          text?: string;
          subText?: string;
        }
      | undefined;
  }>;
}

import s from './select-field.module.scss';

export const RadioGroupField = ({
  name,
  label,
  options,
  variant,
  disabled,
  required,
  isContent,
}: RadioGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);

  const wrapperClasses = cx(s['wrapper'], {
    [s['content-present']]: isContent,
  });

  const classesGroup = cx({
    [s['container-group']]: isContent,
    [s['row']]: variant === variantRadioGroup.row,
    [s['column']]: variant === variantRadioGroup.column,
  });

  const classesItemContainer = cx({
    [s['wrapper-radio']]: isContent,
  });

  const error = t(controlError(meta, name) as string);

  return (
    <div className={s['container']}>
      {label && <FieldLabel label={label} isRequired={required} />}
      <Radio.Group className={classesGroup} disabled={disabled} value={field.value}>
        {options?.map((option, index) => (
          <>
            <div className={wrapperClasses} key={index.toString()}>
              <div className={classesItemContainer}>
                <Radio
                  value={option.value}
                  onChange={() => {
                    helpers.setValue(option.value ?? false);
                  }}
                >
                  {option.content ? (
                    <>
                      <div
                        key={index?.toString()}
                        className={cx({
                          [s['content-wrapper']]: isContent,
                        })}
                      >
                        <span className={s['value']}>{option.value}</span>
                        {option.content?.title && (
                          <span className={s['content-title']}>{option.content?.title}</span>
                        )}
                        {option.content?.rangeDate && (
                          <div className={s['wrapper-rangeDate']}>
                            {option.content?.rangeDate?.from && (
                              <span>
                                {t('from')} {option.content?.rangeDate?.from}
                              </span>
                            )}
                            {option.content?.rangeDate?.to && (
                              <span>
                                {t('to')} {option.content?.rangeDate?.to}
                              </span>
                            )}
                          </div>
                        )}

                        <div className={s['wrapper-text']}>
                          <p className={s['text']}>{option.content?.text}</p>
                          <p className={s['text']}>{option.content?.subText}</p>
                        </div>

                        <span className={s['date']}>{option.content?.date}</span>
                      </div>
                    </>
                  ) : (
                    <span>{t(option.label)}</span>
                  )}
                </Radio>
              </div>
            </div>

            {isContent && index < options.length - 1 && <div className={s['line']} />}
          </>
        ))}
      </Radio.Group>

      {error && <ErrorLabel error={error} />}
    </div>
  );
};
