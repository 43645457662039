import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from 'assets/images/arrow-right.svg';

import s from './EventsInfo.module.scss';

const typeTable = {
  main: 'main',
  counterparties: 'counterparties'
}

interface dataAction {
  id: number;
  text: string;
  count: string;
}

interface TableInfoProps {
  title: string;
  data: dataAction[];
  type?: 'counterparties' | 'main';
}

const dataActions: dataAction[] = [
  {
    id: 0,
    text: 'Draft',
    count: '0',
  },
  {
    id: 1,
    text: 'Signed by one party',
    count: '0',
  },
  {
    id: 2,
    text: 'Completed',
    count: '0',
  },
  {
    id: 3,
    text: 'At the signing',
    count: '1',
  },
  {
    id: 4,
    text: 'It works',
    count: '6',
  },
  {
    id: 5,
    text: 'Completed',
    count: '0',
  },
];

const TableInfo = ({ title, data, type = 'main' }: TableInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className={s.containerTable}>
      <div className={s.wrapper}>
        <span className={s.title}>{t(title)}</span>
        <div className={s.line} />
        <div className={s.wrapperInfo}>
          <div className={s.column}>
            {data.slice(0, 3)?.map((item) => (
              <div key={item.id.toString()} className={s.info}>
                <span className={s.text}>{t(item.text)}</span>
                <span className={s.count}>{item.count}</span>
              </div>
            ))}
          </div>

          {type !== typeTable.counterparties && (
            <div className={s.column}>
              {data.slice(3)?.map((item) => (
                <div key={item.id.toString()} className={s.info}>
                  <span className={s.text}>{t(item.text)}</span>
                  <span className={s.count}>{item.count}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={s.icon}>
        <ArrowRightIcon />
      </div>
    </div>
  );
};

export const EventsInfo = () => {
  return (
    <div className={s.container}>
      <TableInfo title="Contracts" data={dataActions} />
      <TableInfo title="Powers of attorney" data={dataActions} />
      <TableInfo title="Counterparties" data={dataActions} type="counterparties" />
    </div>
  );
};
