import { FormikHelpers } from 'formik';

import { AttorneyFormValues } from '../forms/config';

import { InformationAttorney } from '../forms/information-attorney/information-attorney';
import { InformationPrincipal } from '../forms/information-principal/information-principal';
import { InformationRepresentative } from '../forms/information-representative/information-representative';

import { InformationPrincipalHasPrePower } from '../forms/information-principal-has-pre-power/information-principal';
import s from './Content.module.scss';
import { NewAttorneySidePreview } from '../new-attorney-side-preview/NewAttorneySidePreview';
import { NotFound } from 'components/not-found';

interface ContentProps {
  step: number;
  isIndividual: boolean;
  hasPoaForRetrust: boolean;
  values: AttorneyFormValues;
}

export const Content = ({ step, values, isIndividual, hasPoaForRetrust }: ContentProps) => {
  if (isIndividual && !hasPoaForRetrust) {
    return (
      <div className={s.notFoundContainer}>
        <NotFound
          notFoundTitle=""
          hasSearch={false}
          title="Добавление доверенности"
          subtitle="недоступно"
        />
      </div>
    );
  }
  return (
    <div className={s['container']}>
      {step === 1 && (
        <InformationAttorney
          values={values}
          isIndividual={isIndividual}
          hasPoaForRetrust={hasPoaForRetrust}
        />
      )}
      {step === 2 &&
        (values?.hasPrePowerOfAttorney ? (
          <InformationPrincipalHasPrePower values={values} isIndividual={isIndividual} />
        ) : (
          <InformationPrincipal values={values} />
        ))}
      {step === 3 && <InformationRepresentative values={values} />}
      {step === 4 && <NewAttorneySidePreview />}
    </div>
  );
};
