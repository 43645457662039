import { ContentProfile } from './content/Content';
import { HeaderProfile } from './header/HeaderProfile';

import { useMeQuery } from 'store/api/profile';
import s from './Profile.module.scss';

export function Profile() {
  return (
    <div className={s['container']}>
      <HeaderProfile />
      <ContentProfile />
    </div>
  );
}
