import { createApi } from '@reduxjs/toolkit/query/react';

import { GET_STAFF_POWERS } from './queries/staffPowers/getPowersList';
import { StaffPowersListResponse } from './queries/staffPowers/types';
import { baseQuery } from './query';
import { StaffPower } from './types/staffPowers';

export const staffPowersApi = createApi({
  tagTypes: [],
  baseQuery: baseQuery,
  reducerPath: 'staffPowersApi',
  endpoints: (builder) => ({
    getEmployeePowers: builder.query<StaffPower[], void>({
      query: () => ({
        document: GET_STAFF_POWERS,
      }),
      transformResponse: (rawResponse: StaffPowersListResponse) =>
        rawResponse?.coreEmployeePowers?.getPowersList,
      providesTags: [],
    }),
  }),
});

export const { useGetEmployeePowersQuery } = staffPowersApi;
