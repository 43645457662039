import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

import s from './main.module.scss';

interface ModalProps {
  title: string;
  width?: number;
  footer: React.ReactNode;
  isVisible: boolean;
  isInfo?: boolean;
  children: React.ReactNode;
  closeOnClick?: () => void;
}

export const ModalMain = ({
  width,
  title,
  isInfo,
  footer,
  children,
  isVisible,
  closeOnClick,
}: ModalProps) => {
  const { t } = useTranslation();

  const containerClasses = cx(s['container'], {
    [s['visible']]: isVisible,
  });
  const wrapperClasses = cx(s['wrapper'], {
    [s['visible']]: isVisible,
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (closeOnClick) {
        closeOnClick();
      }
    }
  };

  return (
    <div className={containerClasses}>
      <div className={wrapperClasses} style={{ flexBasis: width ?? 612 }}>
        <div className={s['wrapper-header']}>
          <span className={s['title']}>{t(title)}</span>
          <div
            role="button"
            tabIndex={0}
            className={s['close-icon']}
            onClick={closeOnClick}
            onKeyDown={handleKeyDown}
          >
            <CloseIcon width={24} height={24} />
          </div>
        </div>
        <div className={s['body']}>{children}</div>
        <div className={`footer ${isInfo ? 'info' : ''}`}>{footer}</div>
      </div>
    </div>
  );
};
