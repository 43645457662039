import { useMemo, useState } from 'react';

import { SelectOption } from 'components/common/select/select-types';

import { useGetCoreDictionariesCountriesQuery } from 'store/api/dictionaries';

export const useCountriesOptions = () => {
  const [defaultCountry, setDefaultCountry] = useState<SelectOption | null>(null);

  const { data: [countriesDictionary] = [], isFetching } =
    useGetCoreDictionariesCountriesQuery();

  const options = useMemo(() => {
    const original = countriesDictionary?.enums.map((item) => {
      return {
        label: item.values.find((it) => it.key === 'shortname')?.value ?? '',
        value: item.title ?? '',
      };
    }) as SelectOption[];

    if (original) {
      const dictionary = [...original]?.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });

      const russia = dictionary.find(
        (item) => item.value?.toLocaleLowerCase()?.trim() === 'rus',
      );
      if (russia) {
        setDefaultCountry(russia);
      }

      return [russia, ...dictionary]?.filter((item) => item !== undefined) as SelectOption[];
    }

    return [];
  }, [countriesDictionary]);

  return { isFetching, options, defaultCountry };
};
