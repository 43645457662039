import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetState } from 'modules/auth';
import { resetState as resetCertificatesState } from '../../reducers/certificates';
import { resetApiState } from 'store/rootReducer';

export const logout = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
  try {
    dispatch(resetApiState());
    dispatch(resetState());
    dispatch(resetCertificatesState());
  } catch (error) {
    console.error(error);
  }
});
