import { gql } from 'graphql-request';

export const CORE_ORGANIZATIONS_PREPARE = gql`
  mutation CoreOrganizations($organizationAttributes: [core_OrganizationAttributeInput!]!) {
    coreOrganizations {
      prepare(input: { organizationAttributes: $organizationAttributes }) {
        isRegistered
        inviteId
        organization {
          id
          name
          status
          type
          countryCode
          createdAt
          updatedAt
          attributes {
            name
            value
          }
        }
      }
    }
  }
`;

export const coreOrganizationsMutation = {
  mutation: CORE_ORGANIZATIONS_PREPARE,
};
