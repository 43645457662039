export const CounterpartyType = {
  General: 1,
  Entrepreneur: 2,
  Foreign: 3,
  LegalEntity: 4,
};

export const RepresentativeType = {
  NaturalPerson: 'natural_person', // Физ Лицо
  LegalEntity: 'rus_legal_entity', // ЮР Лицо
  SoleProprietor: 'sole_proprietor', // ИП
};

export const order = ['ogrn', 'ogrnip', 'inn', 'inn_legal_entity', 'kpp'];

export const AttributesEntrepreneurWhitelist = ['inn', 'ogrnip'];
export const AttributesLegalEntityWhitelist = ['okpo', 'ogrn', 'inn_legal_entity'];

export const AttributesForeignWhiteList = ['okpo', 'ogrn', 'inn_legal_entity', 'inn']

export const AttributesWhitelist = ['inn', 'ogrn', 'ogrnip', 'inn_legal_entity'];
export const AttributesWhitelistStaff = ['inn', 'inn_legal_entity', 'snils'];
export const AddressAttributesWhitelist = ['countryCode', 'address', 'postal_address'];
export const ContactInformationAttributesWhitelist = ['email', 'phone'];

export const CounterpartyAttributesWhitelist = {
  [CounterpartyType.General]: AttributesEntrepreneurWhitelist,
  [CounterpartyType.Entrepreneur]: AttributesEntrepreneurWhitelist,
  [CounterpartyType.Foreign]: AttributesForeignWhiteList,
  [CounterpartyType.LegalEntity]: AttributesLegalEntityWhitelist,
};
