export const handleDownload = async ({
  content,
  fileName = 'file',
  format = '.xml',
}: {
  content: string;
  fileName?: string;
  format?: string;
}) => {
  try {
    const blob = new Blob([content], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}${format}`;
    link.click();

    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Failed to download the XML:', error);
  }
};
