import { gql } from 'graphql-request';

export const GET_MY_OUTGOING_INVITATIONS = gql`
  query CoreOrganizations {
    coreOrganizationInvitations {
      query(filter: { isIncoming: false, statuses: [sent, approved, rejected] }) {
        invites {
          id
          status
          joinDocumentId
          createdAt
          updatedAt
          partyOrganization {
            id
            name
            status
            type
            countryCode
            createdAt
            updatedAt
            attributes {
              name
              value
            }
          }
          isIncoming
        }
      }
    }
  }
`;

export const coreOrganizationsQuery = {
  query: GET_MY_OUTGOING_INVITATIONS,
};
