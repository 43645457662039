import { useTranslation } from 'react-i18next';

import s from './organization-attributes.module.scss';
import { AttributesWhitelist } from 'constants/attributes';

interface DescriptionAbbreviationProps {
  text: string;
  value: string;
  whitelist?: typeof AttributesWhitelist;
}

export const OrganizationAttributes = ({
  whitelist,
  text,
  value,
}: DescriptionAbbreviationProps) => {
  const { t } = useTranslation();

  if (!value) {
    return null;
  }

  if ((whitelist ?? AttributesWhitelist).includes(text)) {
    return (
      <div className={s.wrapperDescription}>
        <span className={s.reduction}>{t(text)}:</span>
        <span className={s.value}>{value}</span>
      </div>
    );
  } else {
    return null;
  }
};
