import React from 'react';
import { SideLink } from './sidelink/sidelink';
import { LinkProps } from 'config/links';

export const SideLinks = ({ links }: { links: LinkProps[] }) => {
  return (
    <>
      {links.map((link) => (
        <React.Fragment key={link.id}>
          <SideLink
            id={link.id}
            path={link.path}
            icon={link?.icon}
            label={link.label}
            subLinks={link.subLinks}
            key={link.id.toString()}
          />
        </React.Fragment>
      ))}
    </>
  );
};
