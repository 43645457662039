import { createApi } from '@reduxjs/toolkit/query/react';

import { GET_ME } from './queries/profile/get.me';
import { ProfileResponse } from './queries/profile/types';
import { baseQuery } from './query';
import { Profile } from './types';

const ProfileTags = {
  ME: 'ME',
};
const tagTypes = [ProfileTags.ME];

export const profileApi = createApi({
  tagTypes,
  baseQuery: baseQuery,
  reducerPath: 'profileApi',
  endpoints: (builder) => ({
    me: builder.query<Profile, void>({
      query: () => ({
        document: GET_ME,
      }),
      transformResponse: (rawResponse: ProfileResponse) => {
        return rawResponse?.coreEmployees.me;
      },
    }),
  }),
});

export const { useMeQuery } = profileApi;
