import { gql } from 'graphql-request';

export const GET_CORE_DOCUMENTS_LIST = gql`
  query CoreOrganizations($statuses: [String!], $searchText: String) {
    coreDocuments {
      query(
        filter: {
          sortable: { field: "date", isOrderDesc: true }
          statuses: $statuses
          searchText: $searchText
        }
      ) {
        documents {
          id
          title
          number
          docDate
          version
          statusId
          createdAt
          updatedAt
          contractors {
            id
            name
          }
          template {
            id
          }
          orgOwner {
            id
            name
            status
            type
            countryCode
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
