import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

type InitialStateType = {
  documentContent?: string;
  inviteId?: string | null;
  staffInviteId?: string | null;
  email?: string | null;
  counterpartyId?: string | null;
};

const initialState: InitialStateType = {
  documentContent: '',
  inviteId: null,
  counterpartyId: null,
};

const inviteConteragentSlice = createSlice({
  name: 'inviteConteragent',
  initialState,
  reducers: {
    setInviteEmail(state, action) {
      state.email = action.payload;
    },
    setDocumentContent(state, action) {
      state.documentContent = action.payload;
    },
    setInviteId(state, action) {
      state.inviteId = action.payload;
    },
    setStaffInviteId(state, action) {
      state.staffInviteId = action.payload;
    },
    setCounterpartyId(state, action) {
      state.counterpartyId = action.payload;
    },
  },
});

export const {
  setDocumentContent,
  setStaffInviteId,
  setInviteEmail,
  setCounterpartyId,
  setInviteId,
} = inviteConteragentSlice.actions;

const inviteConteragentStore = (store: RootState) => {
  return store.inviteConteragentReducer;
};

export const selectDocumentContent = () =>
  createSelector([inviteConteragentStore], (store) => store?.documentContent ?? '');

export const selectInviteId = () =>
  createSelector([inviteConteragentStore], (store) => store?.inviteId ?? '');

export const selectStaffInviteId = () =>
  createSelector([inviteConteragentStore], (store) => store?.staffInviteId ?? '');

export const selectCounterpartyId = () =>
  createSelector([inviteConteragentStore], (store) => store?.counterpartyId ?? '');

export const selectInviteEmail = () =>
  createSelector([inviteConteragentStore], (store) => store?.email ?? '');

export default inviteConteragentSlice.reducer;
