function processXslt(rawXml) {
  let processor, xmlDoc;

  if (!rawXml) {
    return '';
  }

  try {
    xmlDoc = new DOMParser().parseFromString(rawXml, 'text/xml');
  } catch (error) {
    return 'Произошла ошибка при открытии документа';
  }

  const xmlErr = getParserError(xmlDoc);
  if (xmlErr) {
    return xmlErr;
  }

  let xslt = xmlDoc.getElementById('stylesheet');

  let firstPart = xslt?.outerHTML.slice(0, xslt.outerHTML.indexOf('>') + 1) ?? '';

  let inner = xslt?.innerHTML ?? '';
  let lastPart =
    xslt?.outerHTML.slice(xslt.outerHTML.lastIndexOf('<'), xslt.outerHTML.length) ?? '';
  let xsltText = firstPart + inner + lastPart;

  let xsltDoc = new DOMParser().parseFromString(xsltText, 'text/xml');
  const xsltError = getParserError(xsltDoc);
  if (xsltError) {
    return 'Произошла ошибка при открытии документа';
  }

  processor = new XSLTProcessor();
  processor.importStylesheet(xsltDoc);
  const ownerDocument = document.implementation.createDocument('', 'test', null);

  return processor.transformToFragment(xmlDoc, ownerDocument);
}

const generateUnexceptedErrorDOM = () => {
  const errorElement = document.createElement('div');
  errorElement.textContent = 'Неизвестная ошибка';
  return errorElement;
};

function getParserError(document) {
  try {
    var err = document?.getElementsByTagName('parsererror')?.[0];
    if (err) {
      return err;
    }
  } catch (error) {
    return generateUnexceptedErrorDOM();
  }
}

export { processXslt };
