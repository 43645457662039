import { gql } from 'graphql-request';

export const GET_STAFF_BY_ID = gql`
  query CoreEmployees($id: ID!) {
    coreEmployees {
      get(ids: [$id]) {
        id
        firstName
        middleName
        lastName
        status
        attributes {
          name
          value
        }
        organization_context {
          organizationID
          employeeID
          role
          type
          position
          status
          powers {
            code
            name
            basePOAID
            retrustable
          }
        }
      }
    }
  }
`;
