import { createApi } from '@reduxjs/toolkit/query/react';

import { DocumentsTags, documentsApi } from './documents';
import { CONFIRM_INVITATION } from './mutations/organizations-invitations/confirm.invite.from.organization';
import { FINISH_INVITE_ORGANIZATION } from './mutations/organizations-invitations/finish.invite.organization';
import { REJECT_INVITATION } from './mutations/organizations-invitations/reject.invite.from.organization';
import { START_INVITE_ORGANIZATION } from './mutations/organizations-invitations/start.invite.organization';
import {
  ConfirmInvitationInput,
  FinishInviteOrganizationInput,
  FinishInviteOrganizationResponse,
  InviteSignResponse,
  RejectInvitationInput,
  StartInviteOrganizationInput,
  StartInviteOrganizationResponse,
} from './mutations/organizations-invitations/types';
import { GET_MY_INCOMING_INVITATIONS } from './queries/organizations-invitations/get.my.incoming.invitations';
import { GET_MY_INCOMING_INVITATIONS_COUNT } from './queries/organizations-invitations/get.my.incoming.invitations.count';
import { GET_MY_OUTGOING_INVITATIONS } from './queries/organizations-invitations/get.my.outgoing.invitations';
import { GET_MY_OUTGOING_INVITATIONS_COUNT } from './queries/organizations-invitations/get.my.outgoing.invitations.count';
import { PartyInfo } from './types/organizations-invitations';
import {
  CoreOrganizationsCountInvitesResponse,
  CoreOrganizationsIncomingResponse,
  CoreOrganizationsOutgoingInvites,
} from './queries/organizations-invitations/types';
import { baseQuery } from './query';
import { OrganizationsTags, organizationsApi } from './organizations';

const OrganizationsInvitesTags = {
  Invites: 'OrganizationsInvites',
  List: 'OrganizationsInvitesList',
};
const tagTypes = [OrganizationsInvitesTags.Invites, OrganizationsInvitesTags.List];

export const organizationsInvitationsApi = createApi({
  tagTypes,
  baseQuery: baseQuery,
  reducerPath: 'organizationsInvitationsApi',
  endpoints: (builder) => ({
    getIncomingInvitationsList: builder.query<PartyInfo[], void>({
      query: () => ({
        document: GET_MY_INCOMING_INVITATIONS,
      }),
      transformResponse: (rawResponse: CoreOrganizationsIncomingResponse) =>
        rawResponse?.coreOrganizationInvitations?.query.invites,
      providesTags: [OrganizationsInvitesTags.Invites],
    }),
    getOutgoingInvitationsList: builder.query<PartyInfo[], void>({
      query: () => ({
        document: GET_MY_OUTGOING_INVITATIONS,
      }),
      transformResponse: (rawResponse: CoreOrganizationsOutgoingInvites) =>
        rawResponse?.coreOrganizationInvitations?.query.invites,
      providesTags: [OrganizationsInvitesTags.Invites],
    }),
    getOutgoingInvitationsCount: builder.query<number, void>({
      query: () => ({
        document: GET_MY_OUTGOING_INVITATIONS_COUNT,
      }),
      transformResponse: (rawResponse: CoreOrganizationsCountInvitesResponse) =>
        rawResponse?.coreOrganizationInvitations?.count,
      providesTags: [OrganizationsInvitesTags.Invites],
    }),
    getIncomingInvitationsCount: builder.query<number, void>({
      query: () => ({
        document: GET_MY_INCOMING_INVITATIONS_COUNT,
      }),
      transformResponse: (rawResponse: CoreOrganizationsCountInvitesResponse) =>
        rawResponse?.coreOrganizationInvitations?.count,
      providesTags: [OrganizationsInvitesTags.Invites],
    }),
    startInviteOrganization: builder.mutation<InviteSignResponse, StartInviteOrganizationInput>(
      {
        query: ({ partnerOrganizationId, joinDocument }) => ({
          document: START_INVITE_ORGANIZATION,
          variables: {
            partnerOrganizationId,
            joinDocument: joinDocument ?? null,
          },
        }),
        transformResponse: (rawResponse: StartInviteOrganizationResponse) =>
          rawResponse?.coreOrganizationInvitations?.startInvite,
      },
    ),
    finishInviteOrganization: builder.mutation<string, FinishInviteOrganizationInput>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(documentsApi.util.invalidateTags([DocumentsTags.List, DocumentsTags.ByIds]));
      },
      query: ({ sendInviteEmails, inviteId, signature, detachedSignature }) => ({
        document: FINISH_INVITE_ORGANIZATION,
        variables: {
          inviteId,
          signature,
          detachedSignature,
          sendInviteEmails,
        },
      }),
      transformResponse: (rawResponse: FinishInviteOrganizationResponse) =>
        rawResponse?.coreOrganizationInvitations?.finishInvite.inviteId,
      invalidatesTags: [OrganizationsInvitesTags.Invites],
    }),
    confirmInvitation: builder.mutation<void, ConfirmInvitationInput>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(documentsApi.util.invalidateTags([DocumentsTags.List, DocumentsTags.ByIds]));
        dispatch(organizationsApi.util.invalidateTags([OrganizationsTags.List]));
      },
      query: ({ inviteId, partnershipAgreement, signature, detachedSignature }) => ({
        document: CONFIRM_INVITATION,
        variables: {
          inviteId,
          partnershipAgreement,
          signature,
          detachedSignature
        },
      }),
      invalidatesTags: [OrganizationsInvitesTags.Invites, OrganizationsInvitesTags.List],
    }),
    rejectInvitation: builder.mutation<void, RejectInvitationInput>({
      query: ({ inviteId, rejectReason }) => ({
        document: REJECT_INVITATION,
        variables: {
          inviteId,
          partnershipAgreement: false,
          rejectReason: rejectReason ?? 'None',
        },
      }),
      invalidatesTags: [OrganizationsInvitesTags.Invites],
    }),
  }),
});

export const {
  useRejectInvitationMutation,
  useConfirmInvitationMutation,
  useStartInviteOrganizationMutation,
  useGetOutgoingInvitationsListQuery,
  useGetIncomingInvitationsListQuery,
  useGetIncomingInvitationsCountQuery,
  useGetOutgoingInvitationsCountQuery,
  useFinishInviteOrganizationMutation,
} = organizationsInvitationsApi;
