import { gql } from 'graphql-request';

export const GET_PARTNER_ORGANIZATION = gql`
  query CoreOrganizations($id: ID!) {
    coreOrganizations {
      getPartner(id: $id) {
        partnership_agreement_date
        organization {
          id
          name
          status
          type
          countryCode
          createdAt
          updatedAt
          attributes {
            name
            value
          }
        }
        default_payment_option {
          id
          name
          isDefault
          accountNumber
          corrNumber
          bic
          currency
          docId
          bank {
            id
            name
            status
            type
            countryCode
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
