import { StartAuthRequest } from 'models/api/auth/start-auth-request.model';
import { StartAuthResponse } from 'models/api/auth/start-auth-response';
import { RegisterRequest, RegisterResponse } from '../models/api';
import jsonApi from './json-api';

const API_SECTION = 'register';

function registerStart(cert_data: string): Promise<StartAuthResponse> {
  return jsonApi.post<StartAuthRequest, StartAuthResponse>(`${API_SECTION}/signup/pki/start`, {
    cert_data,
  });
}

function register({
  token,
  signature,
  detachedSignature,
}: RegisterRequest): Promise<RegisterResponse> {
  return jsonApi.post<RegisterRequest, RegisterResponse>(`${API_SECTION}/signup/pki`, {
    token,
    signature,
    detachedSignature,
  });
}

export { registerStart, register };
