import { useTranslation } from 'react-i18next';

import { Status } from 'components/status/status';
import { TimestampProps } from 'modules/dashboard/contracts/detail-contract/events/Events';

import s from './timestamp.module.scss';

export const TimeStamp = ({ data }: { data: TimestampProps[] }) => {
  const { t } = useTranslation();

  const renderStatus = (item: TimestampProps) => {
    if (item.step) {
      return <span className={s['status']}>{t(item.step)}</span>;
    } else {
      return (
        <Status
          text={item.status ?? 'Default Status'}
          variant={item.variant_status ?? 'active'}
        />
      );
    }
  };

  const renderText = (item: TimestampProps) => {
    if (item.step) {
      return (
        <span className={s['text']}>
          {t('By agreement')} №{item.contractNumber} {t('from')} {item.start_time}
        </span>
      );
    } else {
      return (
        <span className={s['text']}>
          {t('Agreement')} №{item.contractNumber} {t('from')} {item.start_time}
        </span>
      );
    }
  };

  return (
    <div className={s['wrapper']}>
      <ol className={s['timeline']}>
        {data?.map((item: TimestampProps) => (
          <li key={item.id.toString()} className={s['timeline-item']}>
            <div className={s['timeline-content']}>
              <div className={s['timeline-title']}>
                {renderStatus(item)}
                <span className={s['time']}>{item.end_time}</span>
              </div>
              <p className={s['timeline-desc']}>{renderText(item)}</p>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};
