export const Diploma = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11 9H4.5" stroke="#BECADC" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M19.5 6H4.5" stroke="#BECADC" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M13.5 20H2V3H22V20H18"
        stroke="#BECADC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M15.75 18C17.8211 18 19.5 16.3211 19.5 14.25C19.5 12.1789 17.8211 10.5 15.75 10.5C13.6789 10.5 12 12.1789 12 14.25C12 16.3211 13.6789 18 15.75 18Z"
        stroke="#BECADC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M18 17.25V22.3812L15.75 21.3812L13.5 22.3812V17.25"
        stroke="#BECADC"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
