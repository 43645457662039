import { createApi } from '@reduxjs/toolkit/query/react';

import { CREATE_POA_USER } from './mutations/poaUsers/create.poa.user';
import {
  CorePOAUsersCreateRepresentativeInput,
  CorePOAUsersCreateRepresentativeResponse,
  CorePOAUsersUpdateRepresentativeInput,
} from './mutations/poaUsers/types';
import { GET_BY_ID_POA_USER } from './queries/poaUsers/get.id.poa.user';
import { SEARCH_POA_USERS } from './queries/poaUsers/search.poa.users';
import {
  PoaUserIdRequest,
  CorePoaUserByIdResponse,
  CorePoaUsersSearchResponse,
  CheckCertificatesRequest,
  CheckCertificatesResponse,
} from './queries/poaUsers/types';
import { baseQuery } from './query';
import { PoaUser, PoaUserId } from './types';
import { UPDATE_POA_USER } from './mutations/poaUsers/update.poa.user';
import { CHECK_CERTIFICATES } from './queries/poaUsers/check.certificates';

const POAUsersTags = {
  List: 'List',
};
const tagTypes = [POAUsersTags.List];

export const poaUsersApi = createApi({
  tagTypes,
  baseQuery: baseQuery,
  reducerPath: 'poaUsersApi',
  endpoints: (builder) => ({
    searchPoausers: builder.query<PoaUser[], void>({
      query: () => ({
        document: SEARCH_POA_USERS,
      }),
      transformResponse: (rawResponse: CorePoaUsersSearchResponse) =>
        rawResponse?.corePOAUsers?.search || [],
      providesTags: [POAUsersTags.List],
    }),
    getByIdPoaUser: builder.query<PoaUserId, PoaUserIdRequest>({
      query: ({ userId }) => ({
        document: GET_BY_ID_POA_USER,
        variables: {
          userId,
        },
      }),
      transformResponse: (rawResponse: CorePoaUserByIdResponse) => {
        return rawResponse?.corePOAUsers?.getData;
      },
    }),
    checkCertificates: builder.query<string, CheckCertificatesRequest>({
      query: ({ poaId, certificates }) => ({
        document: CHECK_CERTIFICATES,
        variables: {
          poaId,
          certificates,
        },
      }),
      transformResponse: (rawResponse: CheckCertificatesResponse) => {
        return rawResponse?.corePOAUsers?.checkCertificates;
      },
    }),
    createPoaRepresentative: builder.mutation<
      CorePOAUsersCreateRepresentativeResponse,
      CorePOAUsersCreateRepresentativeInput
    >({
      query: ({ snils, request, userType }) => ({
        document: CREATE_POA_USER,
        variables: {
          snils,
          request,
          userType,
        },
      }),
      invalidatesTags: [POAUsersTags.List],
    }),
    updatePoaRepresentative: builder.mutation<void, CorePOAUsersUpdateRepresentativeInput>({
      query: ({ data, userId, userType }) => ({
        document: UPDATE_POA_USER,
        variables: {
          data,
          userId,
          userType,
        },
      }),
    }),
  }),
});

export const {
  useGetByIdPoaUserQuery,
  useSearchPoausersQuery,
  useLazyCheckCertificatesQuery,
  useCreatePoaRepresentativeMutation,
  useUpdatePoaRepresentativeMutation,
} = poaUsersApi;
