import { Route, Routes } from 'react-router-dom';
import { AppRoutes } from 'config/routes';

import { EDOOperator } from './edo-operator/EDOOperator';

import s from './Settings.module.scss';

function Settings() {
  const { Home, EDOOperator: EDOOperatorRoute } = AppRoutes.Dashboard.Settings;

  return (
    <div className={s['container']}>
      <EDOOperator />
      <Routes>
        <Route path="/*" element={<></>} />
        <Route path={EDOOperatorRoute} element={<EDOOperator />} />
      </Routes>
    </div>
  );
}

export default Settings;
