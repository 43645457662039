import { useMutation } from '@tanstack/react-query';

import { register } from 'api';
import { RegisterRequest } from '../models/api';

export default function useRegister() {
  return useMutation({
    mutationFn: (data: RegisterRequest) => register(data),
  });
}
