import cn from 'classnames';

import { OrganizationAttributes } from 'components/organization-attributes';
import { useTranslation } from 'react-i18next';
import { useGetMyOrganizationByIdQuery } from 'store/api/organizations';
import s from './invitation-organization.module.scss';

export const ConterpartyHeaderInfo = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const { data: [counterparty] = [] } = useGetMyOrganizationByIdQuery(
    { id: id ?? '' },
    { skip: !id },
  );

  const isCounterpartyConnected = counterparty?.status === 5;

  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.wrapperName}>
          <span className={s.name}>{counterparty.name}</span>
          {isCounterpartyConnected && (
            <div className={cn(s.status, counterparty?.status !== 5 && s?.['status-red'])}>
              {t(
                isCounterpartyConnected
                  ? 'Подключен к Контрактиуму'
                  : 'Не подключён к Контрактиуму',
              )}
            </div>
          )}
        </div>
      </div>

      <div className={s.description}>
        <OrganizationAttributes
          text="id"
          key={counterparty.id.toString()}
          value={counterparty.id.toString()}
        />
        {counterparty.attributes?.map((item: any) => (
          <OrganizationAttributes key={item.name} text={item.name} value={item.value} />
        ))}
      </div>
    </div>
  );
};
