import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

type InitialStateType = {
  documentContent?: string;
};

const initialState: InitialStateType = {
  documentContent: '',
};

const attorneyRevokeSlice = createSlice({
  name: 'attorneyRevoke',
  initialState,
  reducers: {
    setDocumentContent(state, action) {
      state.documentContent = action.payload;
    },
  },
});

export const { setDocumentContent } = attorneyRevokeSlice.actions;

const attorneyRevokeStore = (store: RootState) => {
  return store.attorneyRevokeReducer;
};

export const selectDocumentContent = () =>
  createSelector([attorneyRevokeStore], (store) => store?.documentContent ?? '');

export default attorneyRevokeSlice.reducer;
