import { ReactComponent as DownloadIcon } from 'assets/images/second-download.svg';
import cx from 'classnames';
import { Button } from 'components/ui/Button';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PoaDraft } from 'store/api/types';
import { SectionPowersRepresentative } from './section/powers-representative/powers-representative';
import { SectionPrincipal } from './section/principal/principal';
import { SectionRepresentative } from './section/representative/representative';
import { SectionSignaturePrincipal } from './section/signature-principal/signature-principal';
import { SectionTitle } from './section/title/section-title';

import { RevocationXsltModal } from 'components/modal/revocation-power-attorney-xslt/revocation-xslt';
import { RevocationPowerAttorneyModal } from 'components/modal/revocation-power-attorney/revocation-power-attorney';
import useProcessXslt from 'hooks/use-process-xslt';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetPoaDraftQuery, useGetPoaSignedRevocationQuery } from 'store/api/poa';
import { selectAttorneysType } from 'store/reducers/attorney.list';

import { Loader } from 'components/loader/loader';
import { fromBase64 } from 'helpers';
import { handleDownload } from 'utils/downloads';
import s from './AttorneyInfo.module.scss';

export const Variant = {
  active: 'active',
  revoked: 'revoked',
  archived: 'archived',
  drafts: 'drafts',
};

type AttorneyInfoHeaderProps = {
  modalXslt?: () => void;
  modalRecall?: () => void;
};

export const AttorneyInfoHeader = ({ modalRecall }: AttorneyInfoHeaderProps) => {
  const { id } = useParams();

  const { t } = useTranslation();
  const currentType = useSelector(selectAttorneysType);

  const { data: { content: documentContent = '' } = {} } = useGetPoaDraftQuery(
    { id: id ?? '' },
    { skip: !id },
  );
  const { data: { content: revocationContent = '' } = {} } = useGetPoaSignedRevocationQuery(
    { poaId: id ?? '' },
    { skip: !id || currentType !== Variant.revoked },
  );

  const classesButtonDownload = cx(s['button'], s['button-download']);
  const classesButtonRecall = cx(s['button'], s['button-recall']);

  const handleRecall = () => {
    if (modalRecall) {
      modalRecall();
    }
  };

  return (
    <div className={s['header']}>
      {currentType !== Variant.drafts && (
        <Button
          displayType="outlined-tetriary"
          className={classesButtonDownload}
          text={t('Download')}
          icon={<DownloadIcon />}
          onClick={() => handleDownload({ content: fromBase64(documentContent) })}
        />
      )}
      {currentType === Variant.revoked && (
        <Button
          displayType="outlined-tetriary"
          className={classesButtonDownload}
          text={t('Recall order')}
          icon={<DownloadIcon />}
          onClick={() => handleDownload({ content: fromBase64(revocationContent) })}
        />
      )}
      {currentType === Variant.active && (
        <Button
          type="cancel"
          className={classesButtonRecall}
          text={t('Recall')}
          onClick={handleRecall}
        />
      )}
    </div>
  );
};

export const AttorneyInfoContent = ({ hasRecalled }: { hasRecalled?: boolean }) => {
  return (
    <div className={s['content']}>
      <SectionTitle hasRecalled={hasRecalled} />
      <SectionPrincipal />
      <SectionRepresentative />
      <SectionPowersRepresentative hasRecalled={hasRecalled} />
      <SectionSignaturePrincipal hasRecalled={hasRecalled} />
    </div>
  );
};

type AttorneyMessageProps = {
  currentItem?: PoaDraft | null;
};

export const AttorneyInfo = ({ currentItem }: AttorneyMessageProps) => {
  const { id } = useParams();

  const { data, isFetching } = useGetPoaDraftQuery(
    { id: id ?? currentItem?.id ?? '' },
    { skip: !id && !currentItem?.id },
  );
  const { data: xsltPreview } = useProcessXslt({ base64: data?.content ?? '' });

  const [isRevocationXslt, setIsRevocationXslt] = useState(false);
  const [isRevocationPowerAttorney, setIsRevocationPowerAttorney] = useState(false);

  if (isFetching) {
    return <Loader size="medium" />;
  }

  if (!currentItem) {
    return null;
  }

  return (
    <div className={s['container']}>
      <AttorneyInfoHeader modalRecall={() => setIsRevocationPowerAttorney(true)} />

      <div
        style={{ background: 'white', width: '100%', overflow: 'hidden', overflowX: 'auto' }}
      >
        {data?.content && xsltPreview && (
          <span>
            <div
              style={{ zoom: `${100}%` }}
              dangerouslySetInnerHTML={{
                __html: new XMLSerializer().serializeToString(xsltPreview as any),
              }}
            />
          </span>
        )}
      </div>

      <RevocationPowerAttorneyModal
        isVisible={isRevocationPowerAttorney}
        onClose={() => setIsRevocationPowerAttorney(false)}
        onClick={() => {
          setIsRevocationPowerAttorney(false);
          setIsRevocationXslt(true);
        }}
      />

      <RevocationXsltModal
        isVisible={isRevocationXslt}
        onClose={() => setIsRevocationXslt(false)}
      />
    </div>
  );
};
