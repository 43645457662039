import { DatePickerMode } from 'components/common/date-picker/date-picker';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { PoaDraft, Retrust } from 'store/api/types';
import { DefaultDateFormat } from 'utils/date';
import * as Yup from 'yup';

export interface AttorneyInformationFormValues {
  attorneyNumber: string;
  hasPrePowerOfAttorney: boolean;
  isOneTimePowerOfAttorney: boolean;
  powerOfAttorneyPeriod: string;
  lossOfAuthority: boolean;
  possibilityTransferTrust: string;
  receiverOfPowerOfAttorney: string;
  xmlFile: string;
  selectPOA: string;
}

export const model = {
  attorneyNumber: {
    placeholder: '12574',
    name: 'attorneyNumber',
    label: 'Power attorney number',
    disabled: true,
  },
  hasPrePowerOfAttorney: {
    disabled: true,
    name: 'hasPrePowerOfAttorney',
    label: 'Power of attorney in the order of transfer',
  },
  isOneTimePowerOfAttorney: {
    required: true,
    type: 'radio',
    name: 'isOneTimePowerOfAttorney',
    label: 'Is one time power of attorney',
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },
  powerOfAttorneyPeriod: {
    type: 'date',
    info: true,
    required: true,
    mode: DatePickerMode.RANGE,
    name: 'powerOfAttorneyPeriod',
    label: 'Period of validity of the power of attorney',
    tomorrow: true,
  },
  lossOfAuthority: {
    type: 'radio',
    required: true,
    name: 'lossOfAuthority',
    label: 'Loss of authority when transferring',
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  },

  possibilityTransferTrust: {
    label: 'Possibility of transfer of trust',
    name: 'possibilityTransferTrust',
    placeholder: 'Select method of transfer of trust',
    options: [
      { label: 'Without the right of transfer', value: 'no_retrust' },
      { label: 'One-time transfer', value: 'single_retrust' },
      {
        label: 'Transfer of confidence followed by transfer of confidence',
        value: 'multi_retrust',
      },
    ] as Array<{ value: Retrust; label: string }>,
    required: true,
  },
  receiverOfPowerOfAttorney: {
    label: 'Receiver of power of attorney',
    name: 'receiverOfPowerOfAttorney',
    placeholder: 'Select receiver of power of attorney',
    options: ['Receiver 1', 'Receiver 2', 'Receiver 3', 'Receiver 4', 'Receiver 5'].map(
      (label) => ({
        label,
        value: label,
      }),
    ),
    isClearable: true,
  },
  xmlFile: {
    type: 'file',
    required: true,
    name: 'xmlFile',
    label: 'Select XML file',
    placeholder: 'Select XML file',
  },
  selectPOA: {
    label: 'Select POA',
    name: 'selectPOA',
    placeholder: 'Select POA',
    options: ['POA 1', 'POA 2', 'POA 3', 'POA 4', 'POA 5'].map((label) => ({
      label,
      value: label,
    })),
    required: true,
  },
};

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    [model.attorneyNumber.name]: Yup.string().required('Power attorney number is required'),
    [model.powerOfAttorneyPeriod.name]: Yup.string().required(
      t('Power of attorney period is required'),
    ),
    [model.possibilityTransferTrust.name]: Yup.string().when(
      model.hasPrePowerOfAttorney.name,
      (item) => {
        const [value] = item;
        if (value) {
          return Yup.string().optional();
        } else {
          return Yup.string().required(t('Possibility of transfer of trust required'));
        }
      },
    ),
    [model.selectPOA.name]: Yup.string().when(model.hasPrePowerOfAttorney.name, (item) => {
      const [value] = item;
      if (value) {
        return Yup.string().required();
      } else {
        return Yup.string().optional();
      }
    }),
    [model.lossOfAuthority.name]: Yup.boolean().required(
      t('The loss of authority during the transfer is mandatory'),
    ),
    [model.receiverOfPowerOfAttorney.name]: Yup.string().optional(),
  });

export const initialValues = ({
  draft,
  isIndividual,
  hasPoaForRetrust,
}: {
  draft?: PoaDraft;
  isIndividual: boolean;
  hasPoaForRetrust: boolean;
}) => {
  return {
    [model.xmlFile.name]: '',
    [model.selectPOA.name]: '',
    [model.attorneyNumber.name]: '',
    [model.lossOfAuthority.name]: false,
    [model.hasPrePowerOfAttorney.name]: false,
    [model.possibilityTransferTrust.name]: '',
    [model.receiverOfPowerOfAttorney.name]: '',
    [model.hasPrePowerOfAttorney.name]: hasPoaForRetrust && isIndividual,
    [model.powerOfAttorneyPeriod.name]: JSON.stringify(
      draft?.issueDate && draft?.expirationDate
        ? [
            dayjs(draft?.issueDate).format(DefaultDateFormat),
            dayjs(draft?.expirationDate).format(DefaultDateFormat),
          ]
        : [
            dayjs().add(1, 'day').format(DefaultDateFormat),
            dayjs().add(1, 'year').format(DefaultDateFormat),
          ],
    ),
    // [model.isOneTimePowerOfAttorney.name]: '',
  } as unknown as AttorneyInformationFormValues;
};
