import { gql } from 'graphql-request';

export const CREATE_AGRO_PODUCTION_DOCUMENT = gql`
  mutation App36c5da51_Document($input: app36c5da51_DocumentMutationNS_createInputInput!) {
    app36c5da51_Document {
      create(input: $input) {
        id
      }
    }
  }
`;
