import { combineReducers } from 'redux';
import attorneyCreateReducer from 'store/reducers/attorney.create';
import attorneyList from 'store/reducers/attorney.list';
import attorneyRevokeReducer from 'store/reducers/attorney.revoke';
import certificate from 'store/reducers/certificates';
import auth from 'store/reducers/auth.reducer';
import dataForm from 'store/reducers/data-form';
import inviteList from 'store/reducers/invite.list';
import { dictionariesApi } from './api/dictionaries';
import { documentsApi } from './api/documents';
import { organizationsApi } from './api/organizations';
import { paymentsApi } from './api/payments';
import { poaApi } from './api/poa';
import { poaUsersApi } from './api/poaUsers';
import { profileApi } from './api/profile';
import contractReducer from './reducers/contract.reducer';
import contractorSearchModalReducer from './reducers/contractor-search-modal.reducer';
import inviteConteragentReducer from './reducers/invite.conteragent';
import powerAttorney from './reducers/power-attorney';
import sublicenseContractReducer from './reducers/sublicense-contract.reducer';
import { organizationDocumentsApi } from './api/organizationDocuments';
import { staffApi } from './api/staff';
import { organizationsInvitationsApi } from './api/organizations-invitations';
import { staffPowersApi } from './api/employePowers';

export const rootReducer = combineReducers({
  auth,
  dataForm,
  inviteList,
  certificate,
  attorneyList,
  powerAttorney,
  contractReducer,
  attorneyCreateReducer,
  attorneyRevokeReducer,
  inviteConteragentReducer,
  sublicenseContractReducer,
  contractorSearchModalReducer,
  [poaApi.reducerPath]: poaApi.reducer,
  [staffApi.reducerPath]: staffApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  [poaUsersApi.reducerPath]: poaUsersApi.reducer,
  [documentsApi.reducerPath]: documentsApi.reducer,
  [staffPowersApi.reducerPath]: staffPowersApi.reducer,
  [dictionariesApi.reducerPath]: dictionariesApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [organizationDocumentsApi.reducerPath]: organizationDocumentsApi.reducer,
  [organizationsInvitationsApi.reducerPath]: organizationsInvitationsApi.reducer,
});

export const resetApiState = () => (dispatch: any) => {
  dispatch(poaApi.util.resetApiState());
  dispatch(staffApi.util.resetApiState());
  dispatch(profileApi.util.resetApiState());
  dispatch(paymentsApi.util.resetApiState());
  dispatch(poaUsersApi.util.resetApiState());
  dispatch(documentsApi.util.resetApiState());
  dispatch(organizationsApi.util.resetApiState());
  dispatch(organizationsInvitationsApi.util.resetApiState());
};
