import { gql } from 'graphql-request';

export const CONFIRM_INVITATION = gql`
  mutation CoreOrganizations(
    $inviteId: ID!
    $partnershipAgreement: Boolean!
    $signature: String
    $detachedSignature: String
  ) {
    coreOrganizationInvitations {
      confirmInvitation(input: {
        inviteId: $inviteId
        partnershipAgreement: $partnershipAgreement
        signature: $signature
        detachedSignature: $detachedSignature
      })
    }
  }
`;

export const coreOrganizationsQuery = {
  mutation: CONFIRM_INVITATION,
};
