import { useTranslation } from 'react-i18next';

import { HeaderPage } from 'components/header-page/header-page';

import { SidenavSteps } from 'components/sidenav-message/steps/steps';

import { BasicParameters } from '../forms/nda-invite/basic-parameters/BasicParameters';
import s from './styles.module.scss';

export const Step = {
  BasicParameters: 1,
};

const StepLabels = {
  [Step.BasicParameters]: 'Basic Parameters',
};

type Props = {
  step: number;
  withHeader?: boolean;
  withFooter?: boolean;
  handlePrevStep?: () => void;
  handleNextStep?: () => void;
  setStep: (value: number) => void;
};

export const NdaContractBase = ({ step, setStep, withHeader = true }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={s['container']}>
      {withHeader && <HeaderPage title={t('New contract')} />}
      <div className={s['main']}>
        <div className={s['content']}>
          <SidenavSteps
            step={step}
            setStep={setStep}
            labels={StepLabels}
            steps={Object.keys(Step).length}
          />
          <div className={s['container']}>{step === 1 && <BasicParameters />}</div>
        </div>
      </div>
    </div>
  );
};
