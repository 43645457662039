import { Result } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <section className="error-page">
      <Result
        status="404"
        title="404"
        subTitle={t("Couldn't find such a page")}
        extra={<Link to="/">{t('To the main page')}</Link>}
      />
    </section>
  );
}
