import * as Yup from 'yup';

export interface SelectAttorneyFormValues {
  selectAttorney: string;
}

export const model = {
  selectAttorney: {
    type: 'radio',
    name: 'selectAttorney',
  },
};

export const validationSchema = Yup.object().shape({
  [model.selectAttorney.name]: Yup.string().required('Select attorney is required'),
});

export const initialValues = {
  [model.selectAttorney.name]: '',
} as unknown as SelectAttorneyFormValues;
