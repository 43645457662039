import { gql } from 'graphql-request';

export const SIGN_DOCUMENT = gql`
  mutation CoreDocuments(
    $documentId: ID!
    $documentVersion: Int!
    $detachedSignature: String
    $signature: String!
  ) {
    coreDocuments {
      sign(
        input: {
          documentId: $documentId
          documentVersion: $documentVersion
          signature: $signature
          detachedSignature: $detachedSignature
        }
      ) {
        result
      }
    }
  }
`;
