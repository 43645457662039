import { gql } from 'graphql-request';

export const GET_PARTNERS_ORGANIZATIONS = gql`
  query CoreOrganizations($searchText: String) {
    coreOrganizations {
      getPartners(filter: { searchText: $searchText }) {
        organizations {
          id
          name
          status
          type
          countryCode
          createdAt
          updatedAt
          attributes {
            name
            value
          }
        }
      }
    }
  }
`;

export const coreOrganizationsQuery = {
  query: GET_PARTNERS_ORGANIZATIONS,
};
