import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button';

import { ModalMain } from 'components/modal/main/main';
import { CounterpartyAttributesWhitelist } from 'constants/attributes';
import { useReviewStaffInvite } from 'hooks';
import { PartyInfo } from 'store/api/types/organizations-invitations';
import styles from './review-invite.module.scss';

interface ReviewModalProps {
  item: PartyInfo;
  inviteId: string;
  isVisible: boolean;
  setVisible: (value: boolean) => void;
}

export const ReviewModal = ({ item, inviteId, isVisible, setVisible }: ReviewModalProps) => {
  const { t } = useTranslation();

  const { onConfirmSubmit, onRejectSubmit } = useReviewStaffInvite({ inviteId });

  return (
    <ModalMain
      title={t('Invitation review')}
      isVisible={isVisible}
      closeOnClick={() => setVisible(false)}
      footer={
        <div className={styles['button-wrapper']}>
          <Button
            text={t('Decline')}
            className={cx(styles['button'], styles['button-back'])}
            displayType="outlined-secondary"
            onClick={() =>
              onRejectSubmit(() => {
                setVisible(false);
              })
            }
          />
          <Button
            text={t('Approve invite')}
            className={styles['button']}
            onClick={() =>
              onConfirmSubmit(() => {
                setVisible(false);
              })
            }
          />
        </div>
      }
    >
      <div>
        <span
          className={styles['title']}
        >{`${item.partyOrganization?.name}, добавил Вас в качестве сотрудника`}</span>
        <div className={styles['description-wrapper']}>
          {item?.partyOrganization?.attributes
            .filter((filterItem) =>
              CounterpartyAttributesWhitelist[item.partyOrganization.type]?.includes(
                filterItem?.name?.toString(),
              ),
            )
            ?.map((item) => (
              <div key={item?.name?.toString()} className={styles['description']}>
                <span className={styles['reduction']}>{t(item?.name)}: </span>
                <span className={styles['value']}>{item.value}</span>
              </div>
            ))}
        </div>
      </div>
    </ModalMain>
  );
};
