import { useTranslation } from 'react-i18next';

import Dropdown from 'components/dropdown';
import { DropdownItemDefault } from 'components/dropdown/items/default';

import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';
import { ReactComponent as EyeIcon } from 'assets/images/eye.svg';
import { ReactComponent as FileIcon } from 'assets/images/file.svg';
import { ReactComponent as MoreIcon } from 'assets/images/more.svg';
import { ReactComponent as RemoveIcon } from 'assets/images/remove.svg';

import { downloadDocument } from 'api';
import { DeleteDocumentModal } from 'components/modal/delete-document';
import { useState } from 'react';
import { OrganizationDocument } from 'store/api/types';

import s from './styles.module.scss';
import dayjs from 'dayjs';
import { DefaultDateFormat } from 'utils/date';
import { extractFilenameAndExtension, formatFileSize } from 'utils/files';

export const DocumentOrganization = ({
  data,
  hasDelete = true,
}: {
  data: OrganizationDocument;
  hasDelete?: boolean;
}) => {
  const { t } = useTranslation();

  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <div className={s['container-document']}>
      <div className={s['container-content']}>
        <div className={s['icon']}>
          <FileIcon />
        </div>
        <div className={s['wrapper-content']}>
          <div className={s['wrapper-title']}>
            <span className={s['title']}>
              {extractFilenameAndExtension(data?.name ?? '')?.filename}
            </span>
            {data?.createdAt && false && (
              <span className={s['date']}>
                {t('from')} {dayjs(data?.createdAt).format(DefaultDateFormat)}
              </span>
            )}
          </div>
          <span className={s['size']}>
            {extractFilenameAndExtension(data?.name ?? '')?.extension} /{' '}
            {formatFileSize(data?.size ?? 0)}
          </span>
        </div>
      </div>

      <Dropdown
        button={
          <div className={s['more']}>
            <MoreIcon />
          </div>
        }
      >
        {false && (
          <DropdownItemDefault
            icon={<EyeIcon width={16} height={16} />}
            onClick={() => {
              //
            }}
          >
            {t('View')}
          </DropdownItemDefault>
        )}

        <DropdownItemDefault
          icon={<DownloadIcon width={16} height={16} />}
          onClick={async () => {
            await downloadDocument(data?.id, data?.name);
          }}
        >
          {t('Download')}
        </DropdownItemDefault>
        {hasDelete && (
          <DropdownItemDefault
            icon={<RemoveIcon width={16} height={16} />}
            onClick={() => {
              setModalVisible(true);
            }}
          >
            {t('Delete')}
          </DropdownItemDefault>
        )}
      </Dropdown>

      <DeleteDocumentModal
        documentId={data?.id}
        isVisible={isModalVisible}
        setVisible={setModalVisible}
      />
    </div>
  );
};
