import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'config/routes';

import { AttorneyFormValues, Step } from './forms/config';

import useSigner from 'client/hooks/use-signer';
import { TemplateData } from 'client/models';
import { useEmployeePowers, useNavigateWithCurrentParams } from 'hooks';
import { useAlert } from 'react-alert';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';
import {
  useCreatePoaDraftMutation,
  useGetPoaDraftQuery,
  useGetPoaListRetrustQuery,
  useLazyGetPoaDraftQuery,
  usePoaSignMutation,
  useUpdatePoaDraftMutation,
} from 'store/api/poa';
import { Organization, OrganizationAttribute, Retrust } from 'store/api/types';
import { setDocumentContent } from 'store/reducers/attorney.create';
import { selectCurrentCertificate } from 'store/reducers/certificates';
import { isCertificatePerson } from 'utils/certificate';
import { prepareDate, prepareDayjs } from 'utils/date';
import { useHandleMutation } from 'utils/handleMutation';

export const useNewPoa = () => {
  const alert = useAlert();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateWithCurrentParams = useNavigateWithCurrentParams();
  const dispatch = useDispatch();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const { data: poaRetrustList = [] } = useGetPoaListRetrustQuery();

  const { id: draftId } = useParams<{ id: string }>();
  const staffPermissionsParams = urlParams.get('staffPermissionsData');

  const [step, setStep] = useState<number>(Step.InformationAttorney);

  const [xmlTemplateData, setXmlTemplateData] = useState<Partial<TemplateData> | null>(null);

  const { handleSign, isLoading: isSignerLoading } = useSigner();
  const currentCertificate = useSelector(selectCurrentCertificate());
  const [signDraft, signDraftMutation] = usePoaSignMutation();
  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const [createDraft, createDraftMutation] = useCreatePoaDraftMutation();
  const [updateDraft, updateDraftMutation] = useUpdatePoaDraftMutation();
  const [getXmlDraft, { data: xmlDraft }] = useLazyGetPoaDraftQuery();
  const { getPowerDomainCode } = useEmployeePowers();
  const { data: draft } = useGetPoaDraftQuery({ id: draftId ?? '' }, { skip: !draftId });

  const findKppAttribute = (attributes: OrganizationAttribute[] | undefined) => {
    const kppAttribute = attributes?.find((attribute) => attribute.name === 'kpp');
    return kppAttribute?.value;
  };

  const currentOrganization: Organization | undefined = myOrganizations?.[0];
  const kpp = findKppAttribute(currentOrganization?.attributes);

  const onSuccess = useCallback((id: string) => {
    navigateWithCurrentParams(
      AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.PowersAttorney.Home +
        AppRoutes.Dashboard.PowersAttorney.NewAttorney +
        `/${id}`,
    );
  }, []);

  const onSuccessUpdate = useCallback(() => {
    alert.success(t('Power of attorney successfully updated'));
  }, [alert.success, navigate]);

  const onSuccessSign = useCallback(() => {
    alert.success(t('Power of attorney has been successfully signed'));
    navigate(
      AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.PowersAttorney.Home +
        AppRoutes.Dashboard.PowersAttorney.Active,
    );
  }, [alert.success, navigate]);

  const onErrorSign = useCallback(() => {
    alert.error(t('Ошибка'));
  }, [alert, t]);

  useHandleMutation({ ...createDraftMutation, onSuccess });
  useHandleMutation({ ...updateDraftMutation, onSuccess: onSuccessUpdate });
  useHandleMutation({ ...signDraftMutation, onSuccess: onSuccessSign, onError: onErrorSign });

  useEffect(() => {
    if (!draftId) {
      createDraft({});
    }
  }, []);

  useEffect(() => {
    if (xmlDraft) {
      dispatch(setDocumentContent(xmlDraft?.content));
      setStep(Step.Sign);
    }
  }, [xmlDraft]);

  const handleSignDocument = async () => {
    await handleSign({
      addTemplate: true,
      signData: draft?.content ?? '',
      validFrom: prepareDayjs(xmlTemplateData?.validFrom).toISOString(),
      validUntil: prepareDayjs(xmlTemplateData?.validUntil).toISOString(),
      onSubmit: async ({ signature, detachedSignature }) => {
        await signDraft({ id: draftId ?? '', signature, detachedSignature });
      },
    });
  };

  const handlePrevStep = () => {
    if (step > Step.InformationAttorney) {
      setStep(step - 1);
    }
  };

  const handleNextStep = async (values: AttorneyFormValues) => {
    if (step < Step.InformationRepresentative) {
      setStep(step + 1);
    } else {
      const { kpp, attorneyNumber, authority, powerDomains } = values;
      const {
        type,
        country,
        citizenship,
        powerOfAttorneyPeriod,
        dateOfBirth,
        representative,
        lossOfAuthority,
        hasPrePowerOfAttorney,
        isOneTimePowerOfAttorney,
        possibilityTransferTrust,
        receiverOfPowerOfAttorney,
        nameDocumentConfirmingAuthority,
      } = values;

      const [issueDate, expirationDate] = JSON.parse(powerOfAttorneyPeriod);
      const recipientOrganizationID = receiverOfPowerOfAttorney?.length
        ? receiverOfPowerOfAttorney
        : undefined;

      setXmlTemplateData({
        validFrom: issueDate,
        validUntil: expirationDate,
      });

      const retrustValue: Retrust = hasPrePowerOfAttorney
        ? 'no_retrust'
        : (possibilityTransferTrust as Retrust);

      await updateDraft({
        input: {
          poaInfo: {
            id: attorneyNumber,
            issueDate: prepareDate(issueDate),
            expirationDate: prepareDate(expirationDate),
            retrust: retrustValue,
            authorityLossFlag: lossOfAuthority,
          },
          recipientOrganizationID,
          representativeId: representative,
          authorityBirthday: prepareDate(dateOfBirth),
          authorityCitizenship: citizenship,
          legalEntity: {
            kpp,
            foundation: nameDocumentConfirmingAuthority,
          },
          powerDomain: powerDomains?.map((item, index) => ({
            code: getPowerDomainCode(item?.toString()) ?? (index + 1)?.toString() ?? '',
            textDescription: item?.toString() ?? '',
            content: item?.toString() ?? '',
          })),
        },
      });

      await getXmlDraft({ id: draftId ?? '' });
    }
  };

  const { hasPoaForRetrust, isIndividual } = useMemo(() => {
    const isIndividual = isCertificatePerson();
    const hasPoaForRetrust = !!poaRetrustList?.length; // Наличие МЧД для передоверия
    return { hasPoaForRetrust, isIndividual };
  }, [currentCertificate, poaRetrustList?.length]);

  return {
    kpp,
    step,
    draft,
    setStep,
    draftId,
    isIndividual,
    handleNextStep,
    handlePrevStep,
    isSignerLoading,
    hasPoaForRetrust,
    handleSignDocument,
    currentOrganization,
  };
};
