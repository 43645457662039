function toBase64(str: string, urlSafe = false) {
  const base64 = window.btoa(unescape(encodeURIComponent(str)));

  return urlSafe ? toUrlSafeBase64(base64) : base64;
}

function fromBase64(base64Str: string, urlSafe = false) {
  const _base64Str = urlSafe ? fromUrlSafeBase64(base64Str) : base64Str;

  return decodeURIComponent(escape(window.atob(_base64Str)));
}

function fromUrlSafeBase64(base64Str: string) {
  return base64Str.replaceAll('-', '+').replaceAll('_', '/');
}

function toUrlSafeBase64(base64UrlSafeStr: string) {
  return base64UrlSafeStr.replaceAll('+', '-').replaceAll('/', '_');
}

function unicodeSafeToBase64(str: string) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    }),
  );
}

function unicodeSafeFromBase64(b64Encoded: string) {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(b64Encoded), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
}

function removeExtraSymbolsFromBase64(base64String: string) {
  const pattern = /[^A-Za-z0-9+/=]/g;
  const cleanedBase64 = base64String.replace(pattern, '');

  return cleanedBase64;
}

export {
  toBase64,
  fromBase64,
  toUrlSafeBase64,
  fromUrlSafeBase64,
  unicodeSafeToBase64,
  unicodeSafeFromBase64,
  removeExtraSymbolsFromBase64,
};
