import { gql } from 'graphql-request';

export const GET_MY_DOCUMENTS = gql`
  query CoreOrganizationDocuments {
    coreOrganizationDocuments {
      myDocuments {
        documents {
          id
          createdAt
          updatedAt
          name
          extension
          size
        }
      }
    }
  }
`;
