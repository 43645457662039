import { Field, FieldArray, Formik, FormikValues } from 'formik';

import { useTranslation } from 'react-i18next';

import { Checkbox } from 'components/common';
import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';
import { toUrlSafeBase64 } from 'helpers';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './StaffPermissions.module.scss';
import { initialValues, model, validationSchema } from './config';
import { useGetEmployeePowersQuery } from 'store/api/employePowers';
import { StaffPower } from 'store/api/types/staffPowers';
import { Loader } from 'components/loader/loader';

type Props = {
  activePowers: string[];
  onCancel: () => void;
};

export const StaffPermissionsForm = ({ onCancel, activePowers = [] }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: powers, isFetching: isFetchingPowers } = useGetEmployeePowersQuery();

  const onSubmit = (values: FormikValues) => {
    const permissions = values?.permissions?.filter((item: any) => item.checked);
    navigate(
      AppRoutes.Dashboard.Home +
        AppRoutes.Dashboard.PowersAttorney.Home +
        AppRoutes.Dashboard.PowersAttorney.NewAttorney +
        `?staffPermissionsData=${toUrlSafeBase64(
          JSON.stringify({
            permissions: permissions?.filter((item: any) => !activePowers.includes(item.code)),
            staffId: id,
          }),
        )}`,
    );
  };

  const isAllChecked = (permissions: any) => {
    return permissions.every((permission: any) => permission.checked);
  };

  if (isFetchingPowers) return <Loader />;

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues({ powers: powers ?? [], activePowers })}
    >
      {({ dirty, isSubmitting, submitForm, values, setFieldValue }) => (
        <div>
          <div className={styles['main']}>
            <div className={styles['wrapper-group']}>
              <div className={styles.permissionsActions}>
                <span className={styles['main-attr']}>{t('Authority')}</span>

                <div className={styles.cancelContaienr}>
                  <Button
                    displayType="outlined-tetriary"
                    className={styles.button}
                    text="Отменить"
                    onClick={() => {
                      onCancel();
                    }}
                  />

                  <Button
                    className={styles.button}
                    disabled={
                      !dirty ||
                      isSubmitting ||
                      !values.permissions?.filter((item) => item.checked)?.length
                    }
                    text="Выдать доверенность"
                    onClick={() => {
                      submitForm();
                    }}
                  />
                </div>
              </div>

              <Checkbox
                checked={isAllChecked(values.permissions)}
                label={t('Select all')}
                onChange={() => {
                  const allChecked = isAllChecked(values.permissions);

                  const newPermissions = values.permissions.map((permission: any) => {
                    return {
                      ...permission,
                      checked: !allChecked,
                    };
                  });
                  setFieldValue(model.permissions.name, newPermissions);
                }}
              />

              <FieldArray name={model.permissions.name}>
                {({ form, push, remove }) => {
                  return form?.values?.permissions?.map((option: StaffPower, index: number) => (
                    <div key={option.code} className={styles['modal-wrapper-checkbox']}>
                      <Field name={`${model.permissions.name}.${index}.checked`}>
                        {({ field }: any) => (
                          <Checkbox
                            disabled={activePowers.includes(option.code)}
                            label={option.name}
                            checked={field.value}
                            onChange={field.onChange}
                            name={field.name}
                          />
                        )}
                      </Field>
                    </div>
                  ));
                }}
              </FieldArray>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
