import { useTranslation } from 'react-i18next';

import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { toBase64 } from 'helpers';

import dayjs from 'dayjs';
import { SignType } from 'enums';
import { selectCurrentCertificate, setSignLoading } from 'store/reducers/certificates';
import { useClient } from './useClient';
import { useAlert } from 'react-alert';

export default function useSigner() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alert = useAlert();

  const { client, sign, signXml } = useClient();

  const certificate = useSelector(selectCurrentCertificate());

  const handleSign = async ({
    signData,
    onSubmit,
    validFrom,
    validUntil,
    addTemplate,
    tbsType = SignType.XMLDSIG,
  }: {
    signData: string;
    tbsType?: string;
    validFrom?: string;
    validUntil?: string;
    addTemplate?: boolean;
    onSubmit?: ({
      signature,
      detachedSignature,
    }: {
      signature: string;
      detachedSignature: string;
    }) => void;
  }) => {
    try {
      if (!certificate || !signData) {
        return;
      }

      dispatch(setSignLoading(true));
      let signature = '';
      let detachedSignature = '';

      if (tbsType === SignType.XMLDSIG) {
        const { signature: a, detachedSignature: b } = await signXml({
          certificate,
          dataForSign: signData,
          props: {
            validFrom: validFrom ?? dayjs().toISOString(),
            validUntil: validUntil ?? dayjs().toISOString(),
          },
        });
        signature = a;
        detachedSignature = b;
      } else {
        signature = await sign({ dataForSign: toBase64(signData), certificate });
      }
      dispatch(setSignLoading(false));

      try {
        if (signature?.length && onSubmit) {
          await onSubmit({ signature, detachedSignature });
        }
      } catch (error: any) {
        if (error?.message) {
          message.error(error?.message as string);
        } else {
          throw t('Something went wrong...');
        }
      }
    } catch (error: any) {
      if (error.message === `Cannot read properties of undefined (reading 'signXml')`) {
        alert.error(
          'Криптоклиент инициализируется, пожалуйста, подождите немного и попробуйте ещё раз',
        );
      }
      if (error.message && error.message === 'register certificate first') {
        message.error(t('The organization is not connected'));
      } else {
        message.error(t('Something went wrong...'));
      }
    } finally {
      dispatch(setSignLoading(false));
    }
  };

  return { handleSign, isLoading: !client };
}
