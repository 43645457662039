import { Form } from 'antd';
import { CertificateSelector } from 'modules/certificates';
import { useTriggerButton } from 'modules/auth';

import useRegister from './use-register';

export default function Register() {
  const { register } = useRegister();

  useTriggerButton('register', register);

  return (
    <>
      <Form onFinish={register}>
        <Form.Item style={{ marginTop: '40px' }}>
          <CertificateSelector />
        </Form.Item>
      </Form>
    </>
  );
}
