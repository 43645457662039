import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, ButtonProps } from 'components/ui/Button';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { setAttorneysType } from 'store/reducers/attorney.list';
import styles from './header-tabs.module.scss';

export interface Tab {
  id: number;
  label: string;
  path: string;
  disabled?: boolean;
}

interface HeaderTabsProps {
  tabs: Tab[];
  type?: 'detail' | 'counterparty';
  isButton?: boolean;
  invitesCount?: number;
  optionButton?: ButtonProps;
}

const typeHeaderTabs = {
  detail: 'detail',
  counterparty: 'counterparty',
};

export const HeaderTabs = ({
  tabs,
  invitesCount,
  optionButton,
  isButton = false,
  type = 'counterparty',
}: HeaderTabsProps) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleTabClick = (item: Tab) => {
    if (item.disabled) {
      return;
    }

    const type = item?.path?.split('/');
    dispatch(setAttorneysType(type[type?.length - 1]));
    navigate(item?.path);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.tabs}>
            {tabs?.map((item: Tab) => (
              <React.Fragment key={item.id}>
                <div
                  key={item.id.toString()}
                  role="button"
                  tabIndex={0}
                  className={cx(styles.tab, {
                    [styles.activeTab]: pathname.includes(`${item.path}`),
                    [styles.disabledTab]: item.disabled,
                  })}
                  onClick={() => handleTabClick(item)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Space') {
                      handleTabClick(item);
                    }
                  }}
                >
                  <span className={styles.label}>{t(item.label)}</span>
                  {(invitesCount ?? 0) > 0 &&
                    type === typeHeaderTabs.counterparty &&
                    item.path === tabs[1].path && (
                      <span className={styles.count}>{invitesCount}</span>
                    )}
                </div>
              </React.Fragment>
            ))}
            {isButton && (
              <div className={styles.wrapperButton}>
                <Button {...optionButton}>{t(optionButton?.text ?? 'Block')}</Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
