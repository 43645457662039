export const CERT_KEYS = {
  INN: 'ИНН',
  CN: 'Владелец',
  L: 'Город',
  S: 'Регион',
  O: 'Компания',
  OGRN: 'ОГРН',
  INNLE: 'ИННЮЛ',
  SNILS: 'СНИЛС',
  STREET: 'Улица',
  SN: 'Фамилия',
  G: 'Имя/Отчество',
  T: 'Должность',
};
