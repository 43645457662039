import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectDataPowerAttorney, setDataPowerAttorney } from 'store/reducers/power-attorney';

import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import {
  AttorneyFormValues,
  Step,
  StepLabels,
  StepLabelsRetrust,
  initialValues,
  validationSchema,
} from './forms/config';

import { HeaderPage } from 'components/header-page/header-page';
import { SidenavSteps } from 'components/sidenav-message/steps/steps';
import { Button } from 'components/ui/Button';
import { Content } from './content/Content';

import { Loader } from 'components/loader/loader';
import s from './NewAttorney.module.scss';
import { useNewPoa } from './use-new-poa';

function CreateNewAttorney() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectPowerAttorney = useSelector(selectDataPowerAttorney);
  const {
    kpp,
    step,
    draft,
    setStep,
    draftId,
    isIndividual,
    handleNextStep,
    handlePrevStep,
    isSignerLoading,
    hasPoaForRetrust,
    handleSignDocument,
    currentOrganization,
  } = useNewPoa();

  const onSubmit = async (values: AttorneyFormValues) => {
    await handleNextStep(values);
    dispatch(setDataPowerAttorney(values));
  };

  const isBlocked = isIndividual && !hasPoaForRetrust; // Запрещено для физ лица без доверенностей для преддоверия

  if (!draftId) return <Loader size="big" />;

  return (
    <div className={s['container']}>
      <HeaderPage title={t('New power attorney')} />
      <Formik
        onSubmit={onSubmit}
        enableReinitialize
        validate={(values) => {
          try {
            validateYupSchema(
              values,
              validationSchema({ t, step, values, hasPoaForRetrust }),
              true,
              values,
            );
          } catch (err) {
            return yupToFormErrors(err);
          }

          return {};
        }}
        initialValues={initialValues({
          draft,
          isIndividual,
          hasPoaForRetrust,
          selectPowerAttorney,
          currentOrganization,
          organizationKpp: kpp ?? '',
          currentDraftNumber: draftId,
          organizationType: currentOrganization?.type ?? 0,
        })}
      >
        {({ dirty, isSubmitting, errors, submitForm, values, setFieldValue }) => (
          <>
            <div className={s['main']}>
              <div className={s['content']}>
                <SidenavSteps
                  step={step}
                  setStep={setStep}
                  steps={Object.keys(StepLabels)?.length}
                  labels={values?.hasPrePowerOfAttorney ? StepLabelsRetrust : StepLabels}
                />
                <Content
                  step={step}
                  values={values}
                  isIndividual={isIndividual}
                  hasPoaForRetrust={hasPoaForRetrust}
                />
              </div>
            </div>

            <div className={s['footer']}>
              {step > 1 && (
                <Button
                  onClick={handlePrevStep}
                  displayType="outlined-secondary"
                  className={cx(s['button'], s['button-back'])}
                >
                  {t('Back')}
                </Button>
              )}

              <Button
                className={cx(s['button'])}
                disabled={
                  (step === Step.Sign && isSignerLoading) ||
                  (step !== Step.Sign && (isSubmitting || !dirty || isBlocked))
                }
                onClick={() => {
                  if (step === Step.Sign) {
                    handleSignDocument();
                    return;
                  }
                  submitForm();
                }}
              >
                {step === Step.InformationAttorney && t('Next')}
                {step === Step.InformationPrincipal && t('Next')}
                {step === Step.InformationRepresentative && t('Create')}
                {step === Step.Sign && t('Sign')}
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}

export default CreateNewAttorney;
