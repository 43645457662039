/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cx from 'classnames';

import { Status } from 'components/status/status';

import { ReactComponent as CheckIcon } from 'assets/images/check-second.svg';
import { ReactComponent as FileIcon } from 'assets/images/file.svg';

import s from './accordion-item.module.scss';

const AccordionItem = ({
  item,
  currentActiveKey,
  collapsible,
  handleClick,
}: any) => (
  <div
    className={cx(
      s['accordion-panel'],
      { [s['active']]: currentActiveKey.includes(item.key) },
      { [s['collapsible-header']]: collapsible === 'header' },
      { [s['disabled']]: item.isDisabled },
    )}
  >
    <div
      className={cx(s['panel-header'])}
      onClick={() => handleClick(item.key)}
    >
      <div className={s['icon']}>
        <CheckIcon />
        {item.title}
      </div>
      {collapsible === 'header' && item.status && (
        <Status text={item.status} variant={item.variant} />
      )}
    </div>
    <div
      className={cx(s['panel-content'], {
        [s['hidden']]: collapsible === 'header' && !currentActiveKey.includes(item.key),
      })}
    >
      <FileIcon />
      <span className={s['content']}>{item.content}</span>
      <div className={s['wrapper-info']}>
        <span className={s['size']}>5.7MB</span>
        <span className={s['view']}>Просмотреть</span>
      </div>
    </div>
  </div>
);

export default AccordionItem;
