import { useCallback } from 'react';
import { useGetEmployeePowersQuery } from 'store/api/employePowers';

export const useEmployeePowers = () => {
  const { data } = useGetEmployeePowersQuery();

  const getPowerDomainCode = useCallback(
    (name: string) => {
      const power = data?.find((power) => power.name === name);
      return power?.code ?? null;
    },
    [data],
  );

  return { getPowerDomainCode, powers: data };
};
