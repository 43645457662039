import React from 'react';
import s from './ItemDefault.module.scss';

interface DropdownItemProps {
  children: React.ReactNode;
  onClick: () => void;
  icon?: React.ReactNode;
}

export const DropdownItemDefault: React.FC<DropdownItemProps> = ({
  children,
  onClick,
  icon,
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <div className={s['container']}>
      {icon && <div className={s['icon']}>{icon}</div>}

      <div
        tabIndex={0}
        role="button"
        onClick={onClick}
        onKeyDown={handleKeyDown}
        className={s.dropdownItem}
      >
        {children}
      </div>
    </div>
  );
};
