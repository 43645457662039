import { Step } from 'components/contracts/forms/nda-invite/config';
import { useState } from 'react';

export const useFooterButtons = () => {
  const [step, setStep] = useState<number>(Step.BasicParameters);

  const handleNextStep = () => {
    if (step < Step.BasicParameters) {
      setStep(step + 1);
    }
  };

  const handlePrevStep = () => {
    if (step > Step.BasicParameters) {
      setStep(step - 1);
    }
  };

  return { step, setStep, handleNextStep, handlePrevStep };
};
