/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Input } from 'antd';
import cx from 'classnames';
import { useField } from 'formik';
import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { controlError } from 'utils/controlError';
import { InputProps } from './types';

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import TextArea from 'antd/es/input/TextArea';
import { ErrorLabel } from 'components/error-label';
import { FieldLabel } from 'components/field-label';
import s from './input-field.module.scss';

export const InputMasks = {
  kpp: (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.slice(0, 9);
  },
  okpo: (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.slice(0, 12);
  },
  org20: (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.slice(0, 20);
  },
  org9: (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.slice(0, 9);
  },
  inn10: (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.slice(0, 10);
  },
  org13: (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.slice(0, 13);
  },
  org14: (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    return numericValue.slice(0, 14);
  },
  codeSecond: (value: string) => {
    const numericValue = value.replace(/\D/g, '');

    return `(${numericValue})`;
  },
  phone: (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    const numericValueLength = numericValue.length;

    let formattedValue = '';

    if (numericValueLength >= 1) {
      formattedValue += `+${numericValue.slice(0, 1)}`;
    }

    if (numericValueLength > 1) {
      formattedValue += ` (${numericValue.slice(1, 4)}`;
    }

    if (numericValueLength > 4) {
      formattedValue += `) ${numericValue.slice(4, 7)}`;
    }

    if (numericValueLength > 7) {
      formattedValue += `-${numericValue.slice(7, 9)}`;
    }

    if (numericValueLength > 9) {
      formattedValue += `-${numericValue.slice(9, 11)}`;
    }

    return formattedValue;
  },
  kgPhone: (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    const numericValueLength = numericValue.length;

    let formattedValue = '';

    if (numericValueLength >= 1) {
      formattedValue += `+${numericValue.slice(0, 3)}`;
    }

    if (numericValueLength > 3) {
      formattedValue += ` (${numericValue.slice(3, 6)}`;
    }

    if (numericValueLength > 6) {
      formattedValue += `) ${numericValue.slice(6, 9)}`;
    }

    if (numericValueLength > 9) {
      formattedValue += `-${numericValue.slice(9, 11)}`;
    }

    if (numericValueLength > 11) {
      formattedValue += `-${numericValue.slice(11, 13)}`;
    }

    return formattedValue;
  },
};

export type FormikField<T> = T & { name: string };

interface InputFieldProps extends FormikField<InputProps> {
  mask?: (value: string) => string;
}

export const InputField = ({
  name,
  mask,
  label,
  style,
  type,
  disabled,
  onChange,
  required,
  placeholder,
  variant = 'medium',
  ...props
}: InputFieldProps) => {
  const { t } = useTranslation();

  if (!name) {
    throw new Error('InputField requires a "name" prop.');
  }

  const [field, meta, helpers] = useField<string>(name);
  const error = t(controlError(meta, name, t(placeholder ?? '') as string) as string);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const inputType = useMemo(() => {
    if (type === 'password') {
      return isPasswordVisible ? 'text' : 'password';
    }
    return type;
  }, [type, isPasswordVisible]);

  const inputSuffix =
    type === 'password' ? (
      <div onClick={togglePasswordVisibility} style={{ cursor: 'pointer', color: '#4F6281' }}>
        {isPasswordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
      </div>
    ) : undefined;

  const classesInput = cx(s['input'], {
    [s['error-input']]: error,
    [s['medium']]: variant === 'medium',
  });

  const Element = type === 'textarea' ? TextArea : Input;

  return (
    <div className={s['container']} {...props}>
      {label && <FieldLabel label={label} isRequired={required} />}

      <div className={s['wrapper-input']}>
        <Element
          {...field}
          rows={4}
          status={error ? 'error' : ''}
          type={inputType}
          disabled={disabled}
          placeholder={placeholder ? t(placeholder) : ''}
          className={classesInput}
          suffix={inputSuffix}
          style={style}
          onChange={(e: any) => {
            if (onChange) {
              onChange(e);
            } else {
              if (mask) {
                helpers.setValue(mask(e?.target?.value));
              } else {
                if (type === 'file') {
                  helpers.setValue(e?.target?.files[0]);
                } else {
                  helpers.setValue(e?.target?.value);
                }
              }
            }
          }}
        />
        {error && <ErrorLabel error={error} />}
      </div>
    </div>
  );
};
