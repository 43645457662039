import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'config/routes';

import { HeaderPage } from 'components/header-page/header-page';
import { Button } from 'components/ui/Button';

import { Loader } from 'components/loader/loader';
import { toBase64 } from 'helpers';
import useSigner from 'client/hooks/use-signer';
import useProcessXslt from 'hooks/use-process-xslt';
import { useCallback, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useSelector } from 'react-redux';
import { useGetCoreDocumentsQuery, useSignDocumentMutation } from 'store/api/documents';
import { useAppDispatch } from 'store/hooks';
import { selectIsSignLoading } from 'store/reducers/certificates';
import { clearDataForm } from 'store/reducers/data-form';
import { useHandleMutation } from 'utils/handleMutation';
import s from './NewContractPreview.module.scss';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';
import { Organization } from 'store/api/types';

type Props = {
  withFooter?: boolean;
  hideSignButton?: boolean;
};

export const NewContractPreview = ({ withFooter = true, hideSignButton }: Props) => {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Home, Contracts } = AppRoutes.Dashboard;

  const { data: [document] = [], isFetching } = useGetCoreDocumentsQuery(
    { ids: [id ?? ''] },
    { skip: !id },
  );

  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const isOwner = currentOrganization?.id === document?.orgOwner?.id;

  const fetchedContent = document?.content ? toBase64(document?.content) : null;
  const currentContent = fetchedContent ?? '';

  const { data: xsltPreview } = useProcessXslt({
    base64: currentContent,
  });

  const [signDocument, signDocumentMutation] = useSignDocumentMutation();
  const { handleSign, isLoading: isSignerLoading } = useSigner();
  const isSignLoading = useSelector(selectIsSignLoading());
  const isLoading = signDocumentMutation.isLoading;

  const handleBack = () => {
    navigate(-1);
  };

  const handleClick = async () => {
    await handleSign({
      signData: currentContent,
      onSubmit: async ({ signature, detachedSignature }) => {
        await signDocument({
          signature,
          detachedSignature,
          documentId: id ?? '',
          documentVersion: document?.version ?? 0,
        });
      },
    });
    dispatch(clearDataForm());
  };

  const onSuccess = useCallback(() => {
    alert.success(t('Document Signed'));
    navigate(Home + Contracts.Home);
  }, [navigate, alert.success]);

  const onError = useCallback(() => {
    alert.error(t('Error'));
  }, [alert, t]);

  useHandleMutation({
    ...signDocumentMutation,
    onSuccess: onSuccess,
    onError: onError,
  });

  const signText = useMemo(() => {
    if (isOwner) {
      return 'Sign and send for approval';
    }

    return 'Sign';
  }, [isOwner]);

  if (isFetching) {
    return <Loader size="medium" />;
  }

  return (
    <div className={s['container']}>
      <HeaderPage title="Предпросмотр" />

      <div className={s['content']}>
        <div className={s['scroll']}>
          <div className={s['wrapper-main']}>
            {xsltPreview && (
              <span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: new XMLSerializer().serializeToString(xsltPreview as any),
                  }}
                />
              </span>
            )}
          </div>
        </div>
        {withFooter && (
          <div className={s['wrapper-footer']}>
            <div className={s['wrapper-button']}>
              <Button className={cx(s['button'], s['button-back'])} onClick={handleBack}>
                {t('Back')}
              </Button>
              {!hideSignButton && (
                <Button
                  disabled={isSignerLoading}
                  className={cx(s['button'], s['button-sign'])}
                  onClick={handleClick}
                >
                  {t(isSignLoading || isLoading ? 'Loading...' : signText)}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
