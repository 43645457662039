import api from './api';

const jsonApi = {
  async post<T, R>(path: string, payload: T): Promise<R> {
    const init: RequestInit = {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
      method: 'POST',
    };

    const response = await api(path, init);
    return (await response.json()) as R;
  },
  async get<R>(path: string, init?: RequestInit): Promise<R> {
    const response = await api(path, { ...init, method: 'GET' });
    return (await response.json()) as R;
  },
  async patch<T, R>(path: string, payload: T): Promise<R> {
    const init: RequestInit = {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
      method: 'PATCH',
    };

    const response = await api(path, init);
    return (await response.json()) as R;
  },
  async put<T, R>(path: string, payload: T): Promise<R> {
    const init: RequestInit = {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
      method: 'PUT',
    };

    const response = await api(path, init);
    return (await response.json()) as R;
  },
};

export default jsonApi;
