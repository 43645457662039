import { createApi } from '@reduxjs/toolkit/query/react';

import { CREATE_DOCUMENT } from './mutations/documents/create.document';
import { SIGN_DOCUMENT } from './mutations/documents/sign.document';
import {
  CreateAgroProductionDocumentResponse,
  CreateDocumentInput,
  CreateDocumentResponse,
  CreateLicenseDocumentResponse,
  CreateSupplyDocumentResponse,
  CreateOperationalRequestDocumentResponse,
  SignDocumentInput,
  UpdateDocumentInput,
  UpdateDocumentResponse,
  CreateCessionDocumentResponse,
} from './mutations/documents/types';
import { UPDATE_DOCUMENT } from './mutations/documents/update.document';
import { GET_CORE_DOCUMENT_STATUSES } from './queries/documents/get.core.document.statuses';
import { GET_CORE_DOCUMENTS } from './queries/documents/get.core.documents';
import { GET_CORE_DOCUMENTS_LIST } from './queries/documents/get.core.documents.list';
import {
  DocumentStatusesResponse,
  DocumentsListResponse,
  DocumentsRequest,
  DocumentsResponse,
} from './queries/documents/types';
import { baseQuery } from './query';
import { AppDocument, DocumentStatus } from './types/documents';
import { GET_FUEL_DOCUMENT_SCHEME } from './queries/documents/get.document.scheme';
import {
  GET_DOCUMENT_LICECES_SCHEME,
  LICENCE_TEMPLATE_ID,
} from './queries/documents/get.document.licences.scheme';
import { CREATE_LICENSE_DOCUMENT } from './mutations/documents/create.license.document';
import { GET_DOCUMENT_ARGROPRODUCTION_SCHEME } from './queries/documents/get.document.agroproduction.scheme';
import { CREATE_AGRO_PODUCTION_DOCUMENT } from './mutations/documents/create.agro.document';
import { GET_SUPPLY_DOCUMENT_SCHEME } from './queries/documents/get.document.supply.scheme';
import { CREATE_SUPPLY_DOCUMENT } from './mutations/documents/create.supply.document';
import { GET_OPERATIONAL_REQUEST_DOCUMENT_SCHEME } from './queries/documents/get.document.operational-request.scheme';
import { CREATE_OPERATIONAL_REQUEST_DOCUMENT } from './mutations/documents/create.operational-request.document';
import { GET_CESSION_DOCUMENT_SCHEME } from './queries/documents/get.document.cession.scheme';
import { CREATE_CESSION_DOCUMENT } from './mutations/documents/create.cession.document';

export const DocumentsTags = {
  List: 'List',
  ByIds: 'ByIds',
};
const tagTypes = [DocumentsTags.List, DocumentsTags.ByIds];

export const documentsApi = createApi({
  tagTypes,
  baseQuery: baseQuery,
  reducerPath: 'documentsApi',
  endpoints: (builder) => ({
    getCoreDocuments: builder.query<AppDocument[], DocumentsRequest>({
      query: ({ ids }) => ({
        document: GET_CORE_DOCUMENTS,
        variables: {
          documentIds: ids,
          outputFormat: 'xml',
        },
      }),
      transformResponse: (rawResponse: DocumentsResponse) => {
        return rawResponse?.coreDocuments.get;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    getFuelDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_FUEL_DOCUMENT_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app70e9b8a9_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    getLicenseDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId = '' }) => ({
        document: GET_DOCUMENT_LICECES_SCHEME,
        variables: {
          documentId,
          templateId: LICENCE_TEMPLATE_ID.Sublicense,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5705572d_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    getPartnerDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId = '' }) => ({
        document: GET_DOCUMENT_LICECES_SCHEME,
        variables: {
          documentId,
          templateId: LICENCE_TEMPLATE_ID.Partner,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5705572d_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    getAgroProductionDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId = '' }) => ({
        document: GET_DOCUMENT_ARGROPRODUCTION_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app36c5da51_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    getCoreDocumentByIds: builder.mutation<AppDocument[], DocumentsRequest>({
      query: ({ ids }) => ({
        document: GET_CORE_DOCUMENTS,
        variables: {
          documentIds: ids,
          outputFormat: 'xml',
        },
      }),
      transformResponse: (rawResponse: DocumentsResponse) => {
        return rawResponse?.coreDocuments.get;
      },
    }),
    getDocumentsList: builder.query<
      AppDocument[],
      { searchText?: string; statuses?: Array<string> }
    >({
      query: ({ statuses, searchText }) => ({
        document: GET_CORE_DOCUMENTS_LIST,
        variables: {
          searchText,
          statuses: statuses ?? [
            '22b6be53-ac81-4371-a1ed-5a0066ec4846',
            '6a905bb4-8c62-4b7a-bcd3-55a8c761fa2d',
            'e622b09f-9905-465d-8053-733fa24c6953',
          ],
        },
      }),
      transformResponse: (rawResponse: DocumentsListResponse) => {
        return rawResponse?.coreDocuments.query.documents;
      },
      providesTags: [DocumentsTags.List],
    }),

    getDocumentStatuses: builder.query<DocumentStatus[], void>({
      query: () => ({
        document: GET_CORE_DOCUMENT_STATUSES,
      }),
      transformResponse: (rawResponse: DocumentStatusesResponse) => {
        return rawResponse?.coreDocuments.getStatuses;
      },
    }),

    createFuelDocument: builder.mutation<string, { input: any }>({
      query: ({ input }) => ({
        document: CREATE_DOCUMENT,
        variables: { input },
      }),
      transformResponse: (rawResponse: CreateDocumentResponse) => {
        return rawResponse?.app70e9b8a9_Document?.create?.id;
      },
    }),
    createLicenseDocument: builder.mutation<string, { input: any }>({
      query: ({ input }) => ({
        document: CREATE_LICENSE_DOCUMENT,
        variables: { input },
      }),
      transformResponse: (rawResponse: CreateLicenseDocumentResponse) => {
        return rawResponse?.app5705572d_Document?.create?.id;
      },
    }),
    createAgroProductionDocument: builder.mutation<string, { input: any }>({
      query: ({ input }) => ({
        document: CREATE_AGRO_PODUCTION_DOCUMENT,
        variables: { input },
      }),
      transformResponse: (rawResponse: CreateAgroProductionDocumentResponse) => {
        return rawResponse?.app36c5da51_Document?.create?.id;
      },
    }),

    updateDocument: builder.mutation<string, UpdateDocumentInput>({
      query: (input) => ({
        document: UPDATE_DOCUMENT,
        variables: input,
      }),
      transformResponse: (rawResponse: UpdateDocumentResponse) => {
        return rawResponse?.app70e9b8a9_Document?.update?.id;
      },
    }),
    signDocument: builder.mutation<string, SignDocumentInput>({
      query: ({ documentId, documentVersion, signature, detachedSignature }) => ({
        document: SIGN_DOCUMENT,
        variables: {
          documentId,
          documentVersion,
          signature,
          detachedSignature,
        },
      }),
      transformResponse: (rawResponse: UpdateDocumentResponse) => {
        return rawResponse?.app70e9b8a9_Document?.update?.id;
      },
      invalidatesTags: [DocumentsTags.List, DocumentsTags.ByIds],
    }),
    getSupplyDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_SUPPLY_DOCUMENT_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app12d801b5_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    createSupplyProductionDocument: builder.mutation<string, { input: any }>({
      query: ({ input }) => ({
        document: CREATE_SUPPLY_DOCUMENT,
        variables: { input },
      }),
      transformResponse: (rawResponse: CreateSupplyDocumentResponse) => {
        return rawResponse?.app12d801b5_Document?.create?.id;
      },
    }),
    getOperationalRequestDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_OPERATIONAL_REQUEST_DOCUMENT_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app5182dd71_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    createOperationalRequestProductionDocument: builder.mutation<string, { input: any }>({
      query: ({ input }) => ({
        document: CREATE_OPERATIONAL_REQUEST_DOCUMENT,
        variables: { input },
      }),
      transformResponse: (rawResponse: CreateOperationalRequestDocumentResponse) => {
        return rawResponse?.app5182dd71_Document?.create?.id;
      },
    }),
    getCessionDocumentScheme: builder.query<any, { documentId?: string }>({
      query: ({ documentId }) => ({
        document: GET_CESSION_DOCUMENT_SCHEME,
        variables: {
          documentId,
        },
      }),
      transformResponse: (rawResponse: any) => {
        return rawResponse?.app1d1b2841_Document?.getTemplate?.template;
      },
      providesTags: [DocumentsTags.ByIds],
    }),
    createCessionProductionDocument: builder.mutation<string, { input: any }>({
      query: ({ input }) => ({
        document: CREATE_CESSION_DOCUMENT,
        variables: { input },
      }),
      transformResponse: (rawResponse: CreateCessionDocumentResponse) => {
        return rawResponse?.app1d1b2841_Document?.create?.id;
      },
    }),
  }),
});

export const {
  useSignDocumentMutation,
  useGetCoreDocumentsQuery,
  useGetDocumentsListQuery,
  useUpdateDocumentMutation,
  useGetDocumentStatusesQuery,
  useCreateFuelDocumentMutation,
  useGetFuelDocumentSchemeQuery,
  useGetCoreDocumentByIdsMutation,
  useCreateLicenseDocumentMutation,
  useGetLicenseDocumentSchemeQuery,
  useGetPartnerDocumentSchemeQuery,
  useCreateAgroProductionDocumentMutation,
  useGetAgroProductionDocumentSchemeQuery,
  useGetSupplyDocumentSchemeQuery,
  useCreateSupplyProductionDocumentMutation,
  useCreateOperationalRequestProductionDocumentMutation,
  useGetOperationalRequestDocumentSchemeQuery,
  useCreateCessionProductionDocumentMutation,
  useGetCessionDocumentSchemeQuery,
} = documentsApi;
