import { useTranslation } from 'react-i18next';

import { useMeQuery } from 'store/api/profile';
import { ProfileAttribute } from 'store/api/types';
import s from './PersonalData.module.scss';

export const LeftInfo = ({ attributes }: { attributes?: ProfileAttribute[] }) => {
  const { t } = useTranslation();

  return (
    <div className={s['left-container']}>
      {attributes
        ?.filter((item) => item.value)
        ?.map((item) => (
          <div className={s['attribute-item']} key={item?.name}>
            <span>{t(item.name)}: </span>
            <span>{item.value}</span>
          </div>
        ))}
    </div>
  );
};

export const RightInfo = ({ attributes }: { attributes?: ProfileAttribute[] }) => {
  const { t } = useTranslation();

  return (
    <div className={s['right-content']}>
      {attributes
        ?.filter((item) => item.value)
        ?.map((item) => (
          <div className={s['attribute-item']} key={item?.name}>
            <span>{t(item.name)}: </span>
            <span>{item.value}</span>
          </div>
        ))}
    </div>
  );
};

const leftInfoWhitelist = ['country'];
const rightInfoWhitelist = ['phone', 'email'];

export const PersonalData = () => {
  const { data: userInfo, isFetching } = useMeQuery();

  if (isFetching) {
    return null;
  }

  return (
    <div className={s['container']}>
      <div className={s['wrapper-content']}>
        <LeftInfo
          attributes={userInfo?.attributes?.filter((item) =>
            leftInfoWhitelist.includes(item.name),
          )}
        />
        <RightInfo
          attributes={userInfo?.attributes.filter((item) =>
            rightInfoWhitelist.includes(item.name),
          )}
        />
      </div>
    </div>
  );
};
