import { useMemo } from 'react';

import { Select } from 'components/common/select/select';

import s from './header.module.scss';

export type VariantOption = {
  value: string;
  label: string;
  scheme: any;
  templateId: string;
};

export const Header = ({
  options,
  disabled,
  currentSelect,
  setCurrentSelect,
}: {
  disabled?: boolean;
  options: VariantOption[];
  currentSelect?: VariantOption | undefined;
  setCurrentSelect?: (newValue: VariantOption | undefined) => void | undefined;
}) => {
  const customOptions = useMemo(
    () =>
      options.map((option) => ({
        value: option.value,
        label: option.label,
      })),
    [options],
  );

  const handleOptionChange = (newOption: VariantOption) => {
    if (setCurrentSelect) {
      setCurrentSelect(
        options.find((option) => option.value === newOption.value) ?? options[0],
      );
    }
  };

  return (
    <div className={s['main-header']}>
      <div className={s['custom-select']}>
        <Select
          isDisabled={disabled}
          options={customOptions}
          defaultValue={currentSelect ?? customOptions[0]}
          onChange={handleOptionChange as any}
        />
      </div>
    </div>
  );
};
