import dayjs from 'dayjs';

export const DefaultDateFormat = 'DD.MM.YYYY';
export const ServerDateFormat = 'YYYY-MM-DD';

export const prepareDate = (date: string) => {
  try {
    const [day, month, year] = date?.split('.') ?? [];

    return dayjs(
      new Date(Number(year) ?? 0, Number(month) - 1 ?? 0, Number(day) ?? 0).toISOString(),
    ).format(ServerDateFormat);
  } catch (error) {
    return '';
  }
};

export const prepareDayjs = (date: string | undefined) => {
  const [day, month, year] = date?.split('.') ?? [];

  return dayjs(
    new Date(Number(year) ?? 0, Number(month) - 1 ?? 0, Number(day) ?? 0).toISOString(),
  );
};
