import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { FormikField } from 'components/common/input-field/input-field';
import { FormikHelpers, FormikValues, FieldArray } from 'formik';

import { model } from 'components/attorney/forms/config';

import { Button } from 'components/ui/Button';
import { InputField } from 'components/common';

import { ReactComponent as RemoveIcon } from 'assets/images/close.svg';
import { ReactComponent as PlusIcon } from 'assets/images/plus.svg';

import s from './authority.module.scss';

type PowerDomainsWizardProps = {
  disabled?: boolean;
  field?: FormikField<any>;
  fieldArrayName?: string;
  values: FormikValues;
};

export const PowerDomainsWizard = (props: PowerDomainsWizardProps) => {
  const { t } = useTranslation();
  const { field, disabled } = props;

  const classesButton = cx(s['button']);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      //
    }
  };

  return (
    <div className={s['container']}>
      <FieldArray name={field?.name}>
        {({ form, push, remove }) => {
          if (!form.values[field?.name]?.length) {
            push('');
          }

          return (
            <>
              <div className={s['container-authority']}>
                {(form.values[field?.name] || []).map((_: any, index: number) => (
                  <div key={`${field?.name}[${index}]`} className={s['container-field']}>
                    <div className={s['select-field']}>
                      <InputField
                        disabled={disabled}
                        {...model.powerDomains}
                        name={`${field?.name}[${index}]`}
                      />
                    </div>
                    {!disabled && (
                      <div
                        role="button"
                        tabIndex={0}
                        className={s['remove']}
                        onClick={() => remove(index)}
                        onKeyDown={handleKeyDown}
                      >
                        <RemoveIcon />
                      </div>
                    )}
                  </div>
                ))}

                {!disabled && (
                  <div className={s['wrapper-footer']}>
                    <Button
                      icon={<PlusIcon width={24} height={24} />}
                      className={classesButton}
                      onClick={() => push('')}
                    >
                      {t('Add')}
                    </Button>
                  </div>
                )}
              </div>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};
