import { DocumentOrganization } from 'components/list-items/document-organization';
import { Loader } from 'components/loader/loader';
import { NotFound, NotFoundContainer } from 'components/not-found';
import { useMyDocumentsQuery } from 'store/api/organizationDocuments';
import s from './Documents.module.scss';

export const DocumentsContent = () => {
  const { data, isFetching } = useMyDocumentsQuery();

  if (isFetching) {
    return <Loader size="medium" />;
  }

  if (!isFetching && !data?.length) {
    return (
      <NotFoundContainer>
        <NotFound
          hasSearch={false}
          title="Here will be displayed"
          subtitle="organization documents"
          notFoundTitle="No documents found"
        />
      </NotFoundContainer>
    );
  }

  return (
    <div className={s['container-documents']}>
      <div className={s.grid}>
        {data?.map((item) => (
          <DocumentOrganization key={item.id.toString()} data={item} />
        ))}
      </div>
    </div>
  );
};
