/* eslint-disable no-undef */

import * as x509 from '@peculiar/x509';

export default class JCWebclientApi {
  client;

  constructor() {
    this.getScript();
  }

  checkJCWebClientVersion() {
    let failureCount = 0;

    checkVersion();

    function checkVersion() {
      JCWebClient2.getJCWebClientVersion({
        async: true,
        onResult: function (version, error) {
          if (typeof error !== 'undefined') {
            console.error('Ошибка получения версии JC-WebClient: ' + error);
          }

          if (version === undefined) {
            ++failureCount;

            if (failureCount === 1) {
              console.warn('Обновите JC-WebClient !!!');
            }

            setTimeout(function () {
              checkVersion();
            }, 2000);
            return;
          }

          if (failureCount !== 0) {
            window.location.reload();
            return;
          }

          return version;
        },
      });
    }
  }

  utf8Encode(str) {
    let utf8Bytes = [];

    for (let i = 0; i < str.length; i++) {
      let codePoint = str.codePointAt(i);

      if (codePoint < 0x80) {
        utf8Bytes.push(codePoint);
      } else if (codePoint < 0x800) {
        utf8Bytes.push(((codePoint >> 6) & 0x1f) | 0xc0);
        utf8Bytes.push((codePoint & 0x3f) | 0x80);
      } else if (codePoint < 0x10000) {
        utf8Bytes.push(((codePoint >> 12) & 0x0f) | 0xe0);
        utf8Bytes.push(((codePoint >> 6) & 0x3f) | 0x80);
        utf8Bytes.push((codePoint & 0x3f) | 0x80);
      } else {
        utf8Bytes.push(((codePoint >> 18) & 0x07) | 0xf0);
        utf8Bytes.push(((codePoint >> 12) & 0x3f) | 0x80);
        utf8Bytes.push(((codePoint >> 6) & 0x3f) | 0x80);
        utf8Bytes.push((codePoint & 0x3f) | 0x80);
      }
    }

    return new Uint8Array(utf8Bytes);
  }

  utf8Decode(bytes) {
    let str = '';

    for (let i = 0; i < bytes.length; i++) {
      let byte = bytes[i];
      let codePoint = 0;

      if ((byte & 0x80) === 0) {
        codePoint = byte;
      } else if ((byte & 0xe0) === 0xc0) {
        codePoint = ((byte & 0x1f) << 6) | (bytes[++i] & 0x3f);
      } else if ((byte & 0xf0) === 0xe0) {
        codePoint = ((byte & 0x0f) << 12) | ((bytes[++i] & 0x3f) << 6) | (bytes[++i] & 0x3f);
      } else if ((byte & 0xf8) === 0xf0) {
        codePoint =
          ((byte & 0x07) << 18) |
          ((bytes[++i] & 0x3f) << 12) |
          ((bytes[++i] & 0x3f) << 6) |
          (bytes[++i] & 0x3f);
      }

      str += String.fromCodePoint(codePoint);
    }

    return str;
  }

  decodeCertificate(base64) {
    try {
      const cert = new x509.X509Certificate(base64);

      return cert;
    } catch (error) {
      console.error('Error decoding certificate:', error);
    }
  }

  base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
  }

  getJCWebClient() {
    if (typeof JCWebClient2 !== 'undefined') {
      JCWebClient2.initialize();

      this.client = JCWebClient2;

      return JCWebClient2;
    }
  }

  getJCWebClientVersion() {
    return JCWebClient2.getJCWebClientVersion();
  }

  getScript() {
    if (typeof JCWebClient2 !== 'undefined') {
      return;
    }

    const parent = document.getElementsByTagName('body')[0];

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://localhost:24738/JCWebClient.js';

    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          if (typeof JCWebClient2 === 'undefined') {
            onFail('JCWebClient is invalid');
          } else {
            this.getJCWebClient();
          }
        } else if (script.readyState !== 'loading') {
          onFail("JCWebClient hasn't been loaded");
        }
      };
    } else {
      script.onload = () => {
        this.getJCWebClient();
      };
      script.onerror = function () {
        onFail("JCWebClient hasn't been loaded");
      };
    }

    parent.appendChild(script);

    function onFail(errorMsg) {
      parent.removeChild(script);
      fail(errorMsg);
    }
  }

  getTokens() {
    const TokenPRO = [];
    const TokenGOST = [];
    const TokenGOST2 = [];
    const TokenPKI = [];
    const AntifraudWithoutSmartCard = [];

    const slots = JCWebClient2.getAllSlots();

    for (let i = 0; i < slots.length; i++) {
      const slot = slots[i];

      if (
        typeof slot.reader !== 'undefined' &&
        slot.reader.type === JCWebClient2.Vars.ReaderType.antifraud &&
        slot.tokenExists === false
      ) {
        AntifraudWithoutSmartCard.push(slot);
        continue;
      }

      try {
        const tokenInfo = JCWebClient2.getTokenInfo({
          args: {
            tokenID: slot.id,
          },
        });

        switch (tokenInfo.type) {
          case JCWebClient2.Vars.TokenType.pro: {
            TokenPRO.push(slot);
            break;
          }
          case JCWebClient2.Vars.TokenType.gost: {
            TokenGOST.push(slot);
            break;
          }
          case JCWebClient2.Vars.TokenType.gost2: {
            TokenGOST2.push(slot);
            break;
          }
          case JCWebClient2.Vars.TokenType.pki: {
            TokenPKI.push(slot);
            break;
          }
        }
      } catch (error) {
        console.error('Error: ' + error);
      }
    }

    return { TokenPRO, TokenGOST, TokenGOST2, TokenPKI, AntifraudWithoutSmartCard };
  }

  getSlots() {
    const slots = JCWebClient2.getAllSlots();
    return slots;
  }

  getCertificatesListWithContainers() {
    const tokens = this.getTokens();
    const [token] = tokens.TokenGOST2;

    const containerList = JCWebClient2.getContainerList({
      args: {
        tokenID: token?.id,
      },
    });

    let certificates = [];

    containerList.forEach((container) => {
      const certificateBytes = JCWebClient2.getCertificateBody({
        args: {
          id: container?.id,
          tokenID: token?.id,
        },
      });

      const base64Body = btoa(String.fromCharCode.apply(null, certificateBytes));
      const pemCert = `-----BEGIN CERTIFICATE-----\n${base64Body}\n-----END CERTIFICATE-----`;

      const certificate = this.decodeCertificate(pemCert);
      if (certificate) {
        certificates.push({ container, certificate });
      }
    });

    return certificates;
  }

  getCertificatesList() {
    const tokens = this.getTokens();
    const [token] = tokens.TokenGOST2;

    const containerList = JCWebClient2.getContainerList({
      args: {
        tokenID: token?.id,
      },
    });

    let certificates = [];

    containerList.forEach((item) => {
      const certificateBytes = JCWebClient2.getCertificateBody({
        args: {
          id: item?.id,
          tokenID: token?.id,
        },
      });

      const base64Body = btoa(String.fromCharCode.apply(null, certificateBytes));
      const pemCert = `-----BEGIN CERTIFICATE-----\n${base64Body}\n-----END CERTIFICATE-----`;

      const certificate = this.decodeCertificate(pemCert);
      if (certificate) {
        certificates.push({base64Body, certificate});
      }
    });

    return certificates;
  }

  getCertificatesInfoList() {
    const tokens = this.getTokens();
    const [token] = tokens.TokenGOST2;

    const containerList = JCWebClient2.getContainerList({
      args: {
        tokenID: token?.id,
      },
    });

    let certificates = [];

    containerList.forEach((item) => {
      const certificateBytes = JCWebClient2.getCertificateInfo({
        args: {
          id: item?.id,
          tokenID: token?.id,
        },
      });

      const base64Info = btoa(String.fromCharCode.apply(null, certificateBytes));
      const pemCert = `-----BEGIN CERTIFICATE-----\n${base64Info}\n-----END CERTIFICATE-----`;

      const certificate = this.decodeCertificate(pemCert);
      if (certificate) {
        certificates.push(certificate);
      }
    });

    return certificates;
  }

  setupSecuredConnection(contID) {
    const tokens = this.getTokens();
    const [token] = tokens.TokenGOST2;

    if (JCWebClient2.getLoggedInState().state != JCWebClient2.Vars.AuthState.notBinded) {
      JCWebClient2.unbindToken();
    }

    JCWebClient2.bindToken({
      args: {
        tokenID: token?.id,
        useUI: true,
      },
    });

    JCWebClient2.establishSChannelBegin({
      args: {
        contID: contID,
      },
    });
  }

  sign({ dataForSign, contID }) {
    this.setupSecuredConnection(contID);

    const decoder = new TextDecoder('utf-8');

    const data = this.utf8Encode(dataForSign);
    const dataArray = Array.from(data);

    const result = JCWebClient2.signData({
      args: {
        contID,
        data: dataArray,
        attachedSignature: true,
      },
    });

    const decodedString = decoder.decode(new Uint8Array(result));

    return decodedString;
  }

  signBase64EncodedData({ dataForSignBase64, contID }) {
    this.setupSecuredConnection(contID);

    const result = JCWebClient2.signBase64EncodedData({
      args: {
        contID,
        data: dataForSignBase64,
        attachedSignature: false,
      },
    });

    return { signature: result, data: dataForSignBase64 };
  }
}
