import { AUTH_STATE_KEY } from 'modules/auth';
import { getCertificateState } from 'utils/certificate';

export default function () {
  try {
    const authState = JSON.parse(localStorage.getItem(AUTH_STATE_KEY) ?? '{}');
    const certificateState = getCertificateState();

    const appState = { auth: authState, certificate: certificateState };
    return appState;
  } catch (error) {
    return {};
  }
}
