import { message } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CERT_KEYS } from '../constants';

import { Select } from 'components/common/select/select';
import { SelectOption } from 'components/common/select/select-types';
import {
  selectCurrentCertificate,
  setCertificate,
  setCertificateInfo,
} from 'store/reducers/certificates';
import { useCertificates } from 'client/hooks/useCertificates';

export function CertificateSelector() {
  const { t } = useTranslation();
  const { data: certificates, isLoading: isCertificatesLoading } = useCertificates();
  const selectedCertificate = useSelector(selectCurrentCertificate());
  const certificateThumbprint = selectedCertificate?.Thumbprint;

  const dispatch = useDispatch();

  const options = useMemo(
    () =>
      certificates?.map((certificate) => ({
        frendlyName: certificate.FriendlyName,
        thumbprint: certificate.Thumbprint,
        directorName:
          [certificate.Subject[CERT_KEYS.SN], certificate.Subject[CERT_KEYS.G]].join(' ') ??
          '-',
      })),
    [certificates],
  );

  const customOptions = useMemo(
    () =>
      options?.map((option) => ({
        value: `${option.thumbprint}`,
        label: `${option.frendlyName}`,
      })),
    [options],
  );

  const selected = useMemo(() => {
    if (selectedCertificate) {
      const selectedCert = certificates?.find(
        (certificate) => certificate.Thumbprint === certificateThumbprint,
      );

      if (selectedCert) {
        return {
          value: selectedCert.Thumbprint,
          label: `${selectedCert.FriendlyName}`,
        };
      }
    }
    return null;
  }, [selectedCertificate, certificates]);

  const onCertificateSelect = (thumbprint: string) => {
    const certificate = certificates?.find((item) => item.Thumbprint === thumbprint);

    if (!certificate) {
      const errorMessage = `${t('Certificate with a fingerprint')} "${thumbprint}" не найден`;

      message.error(errorMessage);
      throw new Error(errorMessage);
    }

    dispatch(setCertificate(certificate));
    dispatch(setCertificateInfo(certificate.Subject));
  };

  return (
    <Select
      placeholder=""
      value={selected}
      isLoading={isCertificatesLoading}
      isDisabled={isCertificatesLoading}
      label={t('Select the certificate to sign in')}
      onChange={(option) => {
        onCertificateSelect((option as SelectOption).value);
      }}
      options={customOptions}
    />
  );
}
