import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { InputField, Checkbox } from 'components/common';
import { SelectField } from 'components/fields/select-fields/select-filed';

import { model } from '../../config';

import s from './RolesPowers.module.scss';

interface CheckboxItemProps {
  index: number;
  checked?: boolean;
  onRowChecked?: (index: number) => void;
}

export const CheckboxItem = ({ index, checked, onRowChecked }: CheckboxItemProps) => {
  const { t } = useTranslation();

  return (
    <div className={s['container-checkbox']}>
      <Checkbox
        label="Text"
        checked={checked}
        onClick={() => onRowChecked && onRowChecked(index)}
      />
      <div className={s['wrapper-desc']}>
        <span className={s['info']}>
          {t('Confirmation with the help of the Ministry of Emergency Situations is required')}
        </span>
        <span className={s['action']}>{t('Release the MCHD')}</span>
      </div>
    </div>
  );
};

export const RolesPowers = () => {
  const { t } = useTranslation();

  const [checked, setChecked] = useState<number[]>([]);
  const [checkdeAll, setCheckdeAll] = useState<boolean>(false);

  const handleCheckboxChange = (index: number) => {
    const isChecked = checked.includes(index);
    if (isChecked) {
      const updatedChecked = checked.filter((item) => item !== index);
      setChecked(updatedChecked);
    } else {
      const updatedChecked = [...checked, index];
      setChecked(updatedChecked);
    }
  };

  const handleAllChecked = () => {
    if (checkdeAll) {
      setChecked([]);
    } else {
      const allIndexes = Array.from({ length: 5 }).map((_, index) => index);
      setChecked(allIndexes);
    }
    setCheckdeAll(!checkdeAll);
  };

  return (
    <div className={s['container']}>
      <SelectField {...model.role} />
      <InputField {...model.posistion} />
      {/*<div className={s['wrapper-checkbox']}>
        <div className={s['header-checkbox']}>
          <span>{t('Authority')}</span>
          <Checkbox checked={checkdeAll} label="Select all" onClick={handleAllChecked} />

          <div className={s['lists-checkbox']}>
            {Array.from({ length: 5 }).map((_, index: number) => (
              <CheckboxItem
                key={index.toString()}
                index={index}
                checked={checked.includes(index)}
                onRowChecked={() => handleCheckboxChange(index)}
              />
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
};
