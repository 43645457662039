import { AppRoutes } from 'config/routes';

export interface BreadcrumbsProps {
  id: number;
  path: string;
  title: string;
}

export interface IRoute {
  title: string;
  icon?: JSX.Element;
}

export type ItemRender = (
  route: IRoute,
  params: Record<string, any>,
  routes: IRoute[],
  paths: string[],
) => JSX.Element;

const { Home, Counterparties } = AppRoutes.Dashboard;

export const counterpartiesRoutes: BreadcrumbsProps[] = [
  {
    id: 0,
    path: Home + Counterparties.Home,
    title: 'Counterparties',
  },
  {
    id: 1,
    path: Counterparties.Invitation.Home,
    title: 'Search and invitation of a counterparty',
  },
];

export const counterpartiesInvitatRoutes: BreadcrumbsProps[] = [
  {
    id: 0,
    path: Home + Counterparties.Home,
    title: 'Counterparties',
  },
];
