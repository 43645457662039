import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { HeaderTabs, Tab } from 'components/header-tabs/header-tabs';
import { AllCounterparties } from './all-counterparties/AllCounterparties';
import { Invitations } from './invitations/Invitations';

import styles from './Counterparties.module.scss';

import { AppRoutes } from 'config/routes';
import { useGetIncomingInvitationsCountQuery } from 'store/api/organizations-invitations';

const { Home, Counterparties: CounterpartiesRoute } = AppRoutes.Dashboard;

const tabsContent: Tab[] = [
  {
    id: 0,
    label: 'All counterparties',
    path: Home + CounterpartiesRoute.Home + CounterpartiesRoute.CounterpatyDetail.Home,
  },
  {
    id: 1,
    label: 'Invitations',
    path: Home + CounterpartiesRoute.Home + CounterpartiesRoute.Invitations,
  },
];

function Counterparties() {
  const { t } = useTranslation();

  const { data: incomingCount = 0 } = useGetIncomingInvitationsCountQuery();

  return (
    <>
      <span className={styles.title}>{t('Counterparties')}</span>
      <div className={styles.headerContainer}>
        <HeaderTabs tabs={tabsContent} invitesCount={incomingCount} />
      </div>
      <Routes>
        <Route path="/*" element={<AllCounterparties />} />
        <Route path={CounterpartiesRoute.Invitations} element={<Invitations />} />
        <Route path={CounterpartiesRoute.Invitations + '/:id'} element={<Invitations />} />
      </Routes>
    </>
  );
}

export default Counterparties;
