import { gql } from 'graphql-request';

export const SEARCH_POA_USERS = gql`
  query CorePOAUsers {
    corePOAUsers {
      search(selector: "") {
        id
        name
        surname
        parentalName
        inn
        organizationName
      }
    }
  }
`;
