/* eslint-disable import/namespace */
import cx from 'classnames';
import { Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/ui/Button';
import { InputField, Image } from 'components/common';
import { AsideModal } from 'components/modal/aside/aside-modal';
import { SelectField } from 'components/fields/select-fields/select-filed';
import { createButtonSettings } from 'modules/dashboard/counterparties/counterparty-detail/CounterpatyDetail';

import { model, initialValues, validationSchema } from './config';

import { ReactComponent as EditIcon } from 'assets/images/edit.svg';

import Diadok1 from 'assets/images/diadok-1.jpg';
import Diadok2 from 'assets/images/diadok-2.jpg';
import Diadok3 from 'assets/images/diadok-3.jpg';
import Diadok4 from 'assets/images/diadok-4.jpg';
import Diadok5 from 'assets/images/diadok-5.jpg';

import s from './EDOOperator.module.scss';

interface EDOOperatorParams {
  id: number;
  icon: string;
  name: string;
  token: string;
}

const iconImages = {
  contour: Diadok1,
  takskom: Diadok2,
  sbils: Diadok3,
  kalugaAstral: Diadok4,
  sberKorus: Diadok5,
};

const dataEDOOperator: EDOOperatorParams[] = [
  {
    id: 0,
    icon: 'contour',
    name: 'Контур.Диадок',
    token: '39392881cw2',
  },
  {
    id: 1,
    icon: 'takskom',
    name: 'Такском',
    token: '39392881cw2',
  },
  {
    id: 2,
    icon: 'sbils',
    name: 'СБИС',
    token: '39392881cw2',
  },
  {
    id: 3,
    icon: 'kalugaAstral',
    name: 'Калуга Астрал',
    token: '39392881cw2',
  },
  {
    id: 4,
    icon: 'sberKorus',
    name: 'Сбер Корус',
    token: '39392881cw2',
  },
];

const ItemEDOOperator = ({
  data,
  onClick,
}: {
  data: EDOOperatorParams;
  onClick: (item: EDOOperatorParams) => void;
}) => {
  const { t } = useTranslation();

  const classesButton = cx(s['button']);

  const buttonSettings = createButtonSettings({
    text: 'Edit',
    icon: <EditIcon width={16} height={16} />,
    disabled: false,
    className: classesButton,
    onClick: () => onClick(data),
  });

  return (
    <div className={s['container-edo']}>
      <div className={s['content-edo']}>
        <div className={s['wrapper-header']}>
          <div className={s['header-edo']}>
            <Image
              src={iconImages[data.icon as keyof typeof iconImages]}
              width={32}
              height={32}
              alt={data.icon}
            />
            <span className={s['name']}>{data.name}</span>
          </div>

          <Button {...buttonSettings} />
        </div>

        <div className={s['wrapper-field']}>
          <InputField value={data.token} {...model.token} name={model.token.name + data.name} />
        </div>
      </div>
    </div>
  );
};

export const EDOOperator = () => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedOperator, setSelectedOperator] = useState<EDOOperatorParams | null>(null);

  const handleOpenEdit = (item: EDOOperatorParams) => {
    setSelectedOperator(item);
    setIsEdit(true);
  };

  const handleCloseEdit = () => {
    setIsEdit(false);
  };

  const onSubmit = () => {
    //
  };

  return (
    <div className={s['container']}>
      <span className={s['title']}>{t('EDO Operators')}</span>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
      >
        {({ dirty, isSubmitting, validateForm, submitForm }) => (
          <>
            <div className={s['wrapper-content']}>
              {dataEDOOperator?.map((item) => (
                <ItemEDOOperator
                  key={item.id.toString()}
                  data={item}
                  onClick={() => handleOpenEdit(item)}
                />
              ))}
            </div>
            <AsideModal
              title="Editing"
              isOpen={isEdit}
              onClick={handleCloseEdit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              <div className={s['content-modal']}>
                <SelectField
                  {...model.edoOperation}
                  value={
                    selectedOperator && {
                      value: selectedOperator.name,
                      label: selectedOperator.name,
                    }
                  }
                />
                <InputField
                  defaultValue={selectedOperator?.token}
                  value={selectedOperator?.token}
                  {...model.token}
                />
              </div>
            </AsideModal>
          </>
        )}
      </Formik>
    </div>
  );
};
