import { gql } from 'graphql-request';

export const GET_ORGANIZATION = gql`
  query CoreOrganizations($ids: [ID!]!) {
    coreOrganizations {
      get(ids: $ids) {
        id
        name
        status
        type
        countryCode
        createdAt
        updatedAt
        attributes {
          name
          value
        }
      }
    }
  }
`;

export const coreOrganizationsQuery = {
  query: GET_ORGANIZATION,
};
