import { ReactComponent as LoginIcon } from 'assets/images/login.svg';
import { Button } from 'components/ui/Button';
import { useTranslation } from 'react-i18next';
import s from './LoginPage.module.scss';

import { CheckClientModal } from 'components/modal/checkClient';
import useLogin from 'hooks/use-login';
import { CertificateSelector } from 'modules/certificates';
import { useSelector } from 'react-redux';
import { selectCurrentCertificate, selectIsSignLoading } from 'store/reducers/certificates';
import { HeaderTabs, Tab } from 'components/header-tabs/header-tabs';

const tabsContent: Tab[] = [
  {
    id: 0,
    label: 'LOGIN (E-MAIL)',
    path: '/email',
    disabled: true,
  },
  {
    id: 1,
    label: 'PHONE',
    path: '/phone',
    disabled: true,
  },
  {
    id: 2,
    label: 'certificate',
    path: '/',
  },
];

export function LoginPage() {
  const { t } = useTranslation();
  const { login, isClientLoading } = useLogin();
  const currentCertificate = useSelector(selectCurrentCertificate());
  const isSignLoading = useSelector(selectIsSignLoading());

  const handleSubmitClick = async () => {
    await login();
  };

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.firstBlock}>
          <div className={s.header}>
            {t('Connects')} <br /> {t('Business')}
          </div>
          <div className={s.subtitle}>
            {t(
              'Welcome to Contracteum, a platform for secure digital transactions under your full control',
            )}
          </div>

          <div className={s.entranceContainer}>
            <h1 className={s.entrance}>
              <div>{t('entrance')} </div>
              <LoginIcon />
            </h1>
          </div>
        </div>

        <div className={s.secondBlock}>
          <div>
            <HeaderTabs type="detail" tabs={tabsContent} />
          </div>

          {false && (
            <span className={s.description}>
              {t('To register a company, log in with your digital signature certificate.')}
            </span>
          )}

          <div className={s?.['full-width']}>
            <CertificateSelector />
          </div>

          <div className={s.buttonContainer}>
            <Button
              type="primary"
              className={s.button}
              onClick={handleSubmitClick}
              disabled={!currentCertificate || isSignLoading || isClientLoading}
            >
              {t(isSignLoading ? 'Loading...' : 'enter')}
            </Button>
          </div>
        </div>
      </div>

      <div className={s.bottom}></div>

      <CheckClientModal />
    </div>
  );
}
