import { gql } from 'graphql-request';

export const GET_POA_DRAFT_FOR_AUTHORITY = gql`
  query CorePOA($cert: String!, $id: ID!) {
    corePOA {
      getDraft(cert: $cert, id: $id) {
        id
        number
        issueDate
        expirationDate
        revocationDate
        createdAt
        updatedAt
        status
        content
        retrust
        authority {
          id
          userType
          email
          rawUserInfo
        }
        recipientOrganization {
          id
          name
          status
          type
          countryCode
          createdAt
          updatedAt
          attributes {
            name
            value
          }
        }
        representative {
          id
          userType
          email
          rawUserInfo
        }
        powerDomain {
          code
          textDescription
          content
        }
        parentPOA {
          retrust
          authorityLossFlag
          retrustFlag
        }
      }
    }
  }
`;
