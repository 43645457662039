import { gql } from 'graphql-request';

export const FINISH_REGISTRATION_BY_REPRESENTATIVE = gql`
  mutation CoreOrganizations(
    $poaId: ID!
    $inviteId: ID!
    $signature: String!
    $detachedSignature: String
  ) {
    coreOrganizations {
      finishRegistrationByRepresentative(
        input: {
          poaId: $poaId
          inviteId: $inviteId
          signature: $signature
          detachedSignature: $detachedSignature
        }
      ) {
        organizationId
      }
    }
  }
`;
