import { TFunction } from 'i18next';
import * as Yup from 'yup';

export interface OrganizationFormValues {
  status: string;
  role: string;
  email: string;
  settingAccess: string;
}

export const model = {
  status: {
    label: 'Status',
    name: 'status',
    placeholder: 'Select from the list',
    options: ['Status 1', 'Status 2', 'Status 3'].map((label) => ({
      label,
      value: label,
    })),
    required: true,
  },
  role: {
    label: 'Role',
    name: 'role',
    placeholder: 'Select from the list',
    options: [
      {
        label: 'Сотрудник',
        value: 'employee',
      },
      {
        label: 'Администратор',
        value: 'administrator',
      },
    ],
    required: true,
  },
  email: {
    type: 'email',
    label: 'E-mail',
    name: 'email',
    placeholder: 'Enter email',
    required: true,
  },
  settingAccess: {
    type: 'text',
    label: 'Setting access',
    name: 'settingAccess',
    placeholder: 'To all documents and sections',
    required: true,
  },
  permissions: {
    name: 'permissions',
  },
};

export const validationSchema = (t: TFunction) => Yup.object().shape({});

export const initialValues = {
  [model.role.name]: '',
  [model.email.name]: '',
  [model.status.name]: '',
  [model.settingAccess.name]: '',
  [model.permissions.name]: [
    { id: 0, label: 'Приглашение контрагентов', checked: false, poaRequired: true },
    {
      id: 1,
      label: 'Рассмотрение приглашений от контрагентов',
      checked: false,
      poaRequired: true,
    },
    {
      id: 2,
      label: 'Подписание соглашения о порядке обработки данных ',
      checked: false,
      poaRequired: true,
    },
    {
      id: 3,
      label: 'Подписание договорных документов организации',
      checked: false,
      poaRequired: true,
    },
    { id: 4, label: 'Управление профилем организации', checked: false, poaRequired: true },
    {
      id: 5,
      label: 'Управление сотрудниками и их полномочиями',
      checked: false,
      poaRequired: true,
    },
  ],
} as unknown as OrganizationFormValues;
