import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, DatePickerField } from 'components/common';
import { RadioGroupField } from 'components/fields/radio-group-field/select-filed';
import { SelectField } from 'components/fields/select-fields/select-filed';

import { PoaAcceptFromValues, model } from './config';

import { CheckboxField } from 'components/fields/checkbox-field';
import { useCountriesOptions } from 'hooks/use-countires-options';
import { Retrust } from 'store/api/types';
import s from './PoaAcceptForm.module.scss';

interface CheckboxListParams {
  id: number;
  label: string;
  checked: boolean;
  disabled?: boolean;
}

const checkboxes: CheckboxListParams[] = [
  {
    id: 0,
    label: `Managing the company's profile in Contracteum`,
    checked: true,
    disabled: true,
  },
  {
    id: 1,
    label: `Signing of a non-disclosure agreement and data processing procedure`,
    checked: true,
    disabled: true,
  },
];

export const PoaAcceptForm = ({ values }: { values: PoaAcceptFromValues }) => {
  const { t } = useTranslation();
  const { defaultCountry, options } = useCountriesOptions();

  const [checked, setChecked] = useState<number[]>(checkboxes.map((checkbox) => checkbox.id));

  const handleCheckboxChange = (index: number) => {
    const isChecked = checked.includes(index);

    if (isChecked) {
      const updatedChecked = checked.filter((item) => item !== index);
      setChecked(updatedChecked);
    } else {
      const updatedChecked = [...checked, index];
      setChecked(updatedChecked);
    }
  };

  return (
    <div className={s['wrapper-form']}>
      <div className={s['wrapper-group']}>
        <p className={s['label']}>{t('Authority')}</p>

        {checkboxes?.map((checkbox) => (
          <Checkbox
            key={checkbox?.id?.toString()}
            label={t(checkbox?.label)}
            className={s['check-label']}
            disabled={checkbox?.disabled}
            checked={checked.includes(checkbox?.id)}
            onClick={() => handleCheckboxChange(checkbox?.id)}
          />
        ))}

        <CheckboxField className={s['check-label']} {...model.managingEmployes} />
      </div>

      <div className={s['wrapper-group']}>
        <p className={s['label']}>{t('Additional information')}</p>
        <DatePickerField {...model.powerOfAttorneyPeriod} />
        <SelectField
          {...model.possibilityTransferTrust}
          options={model.possibilityTransferTrust?.options.map((item) => ({
            label: t(item.label),
            value: item.value,
          }))}
        />
        {((values?.possibilityTransferTrust as Retrust) === 'multi_retrust' ||
          (values?.possibilityTransferTrust as Retrust) === 'single_retrust') && (
          <RadioGroupField {...model.lossOfAuthority} />
        )}
      </div>

      <div className={s['wrapper-group']}>
        <p className={s['label']}>{t('Information about the signatory')}</p>
        <DatePickerField {...model.dateOfBirth} />
        <RadioGroupField {...model.citizenship} />
        {values?.citizenship === 'foreign' && (
          <SelectField {...model.country} options={options} defaultValue={defaultCountry} />
        )}
      </div>
    </div>
  );
};
