import { Button } from 'components/ui/Button';
import { useTranslation } from 'react-i18next';
import styles from './UserRestricted.module.scss';
import { logout } from 'store/thunks/auth/auth';
import { useAppDispatch } from 'store/hooks';
import { useNavigate } from 'react-router-dom';

export function UserRestricted() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleConfirmLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.firstBlock}>
          <div className={styles.header}>
            {t('Connects')} <br /> {t('Business')}
          </div>
          <div className={styles.subtitle}>
            {t(
              'Welcome to Contracteum, a platform for secure digital transactions under your full control',
            )}
          </div>
        </div>

        <div className={styles.secondBlock}>
          <span className={styles.title}>{t('Вы не связаны ни с одной организацией.')}</span>
          <span className={styles.restrictedText}>
            {t(
              'Чтобы продолжить использовать сервис "Контрактиум" вам надо быть сотрудником минимум одной организации',
            )}
          </span>

          <div className={styles.buttonContainer}>
            <Button type="cancel" className={styles.button} onClick={handleConfirmLogout}>
              {t('Выйти')}
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.bottom}></div>
    </div>
  );
}
