import { model } from './config';

import { DatePickerField, InputField } from 'components/common';
import s from './BasicParameters.module.scss';

export const BasicParameters = () => {
  return (
    <div className={s['container']}>
      <InputField {...model.number} />
      <DatePickerField {...model.date} />
      <InputField {...model.location} />
      <InputField {...model.duration} />
    </div>
  );
};
