import React, { FunctionComponent, SVGProps, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LinkProps as LinkParams } from 'config/links';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-bottom.svg';

import cx from 'classnames';

import styles from './sidelink.module.scss';

export interface LinkProps {
  path: string;
  label: string;
  icon?: JSX.Element;
  id: number | string;
  subLinks?: LinkParams[];
}

export const redirectPath = {
  dashboard: '/dashboard/infopanel',
};

export const SideLink = ({ label, path, icon, subLinks }: LinkProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const shouldAddLineClass = subLinks && subLinks.length > 1;

  const isActive = useMemo(() => {
    return pathname.includes(`${path}`);
  }, [pathname, path]);

  const classesLink = cx(styles.rootLink, {
    [styles.link]: true,
    [styles.activeLink]: isActive,
  });

  const classesSubLink = cx(styles.subLink, {
    [styles.activeSubLink]: false,
  });

  const classesContent = cx({
    [styles.contentHidden]: !isSubMenuOpen,
    [styles.contentVisibled]: isSubMenuOpen,
  });

  const classesArrowIcon = cx(styles.arrow, {
    [styles.rotateArrow]: isSubMenuOpen,
  });

  let linkProps = {};

  switch (true) {
    case subLinks && subLinks.length > 0:
      linkProps = {
        onClick: handleSubMenuToggle,
      };
      break;
    default:
      linkProps = {
        onClick: () => navigate(path || redirectPath.dashboard),
      };
      break;
  }

  return (
    <>
      <div
        className={cx({
          [styles.wrapper]: true,
          [styles.active]: isActive,
          [styles[`wrapper_${label?.replace(/ /g, '')?.toLocaleLowerCase()}`]]: true,
        })}
      >
        {icon &&
          React.cloneElement(icon, {
            className: cx(styles.icon, {
              [styles?.['icon-selected']]: isActive,
              [styles?.['icon-inactive']]: !isActive,
            }),
          })}
        <span
          {...linkProps}
          className={cx(classesLink, {
            [styles.inactive]: !isActive,
          })}
        >
          {t(label)}
        </span>

        {shouldAddLineClass && (
          <div
            {...linkProps}
            className={classesArrowIcon}
            style={{
              transform: `rotate(${isSubMenuOpen ? '180deg' : '0deg'})`,
              transition: 'transform 0.2s ease-in-out',
            }}
          >
            <ArrowIcon />
          </div>
        )}
      </div>

      {subLinks && subLinks.length > 0 && (
        <div className={classesContent}>
          <ul className={styles.subMenu}>
            {subLinks.map((subLink, index) => (
              <li key={subLink.id} className={styles.wrapperSubLink}>
                <Link
                  to={subLink.path}
                  className={cx(classesSubLink, {
                    [styles.line]: shouldAddLineClass && index !== subLinks.length - 1,
                    [styles.active]: subLink.path === pathname,
                    [styles.inactive]: subLink.path !== pathname,
                  })}
                >
                  {t(subLink.label)}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
