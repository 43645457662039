import { useEffect, useState } from 'react';
import { useClient } from './useClient';
import { Certificate } from 'client/models';

export const useCertificates = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { client, clientType, getCertificates } = useClient();
  const [certificates, setCertificates] = useState<Certificate[] | undefined>();

  const handle = async () => {
    setIsLoading(true);
    const data = await getCertificates();
    setIsLoading(false);
    setCertificates(data);
  };

  useEffect(() => {
    if (client) {
      handle();
    }
  }, [clientType]);

  return { data: certificates, isLoading: !certificates?.length && isLoading };
};
