import { SublicenseContract } from "../../models/contracts/sublicenses-contract/sublicense-contract.model";

export const sublicenseContract: SublicenseContract = {
  general: {
    contractNumber: '',
    contractDate: '',
    contractLocation: '',
    contractorId: '',
    currencyCode: '',
    isPrepayed: true,
    partnerContract: { contractId: '', licensorName: '' },
  },
  specification: {
    specificationItems: [{ amount: 1, description: '', name: '', partNumber: '', price: 0, vat: 0 }],
  },
  firstParty: {
    email: '',
    phone: '',
    signee: { name: '', surname: '' }
  },
  secondParty: {
    email: '',
    phone: '',
    signee: { name: '', surname: '' }
  },
};
