import { isTokenExpired } from 'helpers/token.helpers';
import { useEffect } from 'react';
import { getToken } from 'utils/token';

export const buttonFunctionMap: Map<string, () => void> = new Map();

export function useAuth(): boolean {
  const token = getToken();

  return !!token && !isTokenExpired(token);
}

export function useTriggerButton(type: string, fn: any) {
  useEffect(() => {
    if (type) buttonFunctionMap.set(type, fn);
  });
}
