import { useTranslation } from 'react-i18next';

import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { toBase64 } from 'helpers';

import { handleAuth } from 'store/reducers/auth.reducer';

import useLoginRequest from 'hooks/use-login-request';
import { selectCurrentCertificate, setSignLoading } from 'store/reducers/certificates';

import { AppRoutes } from 'config/routes';
import { SignType } from 'enums';
import useLoginStart from 'hooks/use-start-login';
import { useClient } from 'client/hooks/useClient';

export default function useLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { mutateAsync: initHandshake } = useLoginStart();

  const { mutateAsync: confirm } = useLoginRequest();

  const { sign, signXml, client } = useClient();

  const certificate = useSelector(selectCurrentCertificate());

  const login = async () => {
    if (!certificate) {
      return;
    }

    const { Auth } = AppRoutes;

    try {
      const data = await initHandshake({ data: certificate?.Base64RawData });
      const { tbs_type, tbs_data: secret, token, identity_exists } = data;

      if (!identity_exists) {
        navigate(`${Auth.Home + Auth.Offer}`);
        return;
      }

      if (!certificate || !secret || !token) {
        return;
      }

      dispatch(setSignLoading(true));
      let signature = '';
      let detachedSignature = '';

      if (tbs_type === SignType.XMLDSIG) {
        const { signature: a, detachedSignature: b } = await signXml({
          certificate,
          dataForSign: secret,
        });

        signature = a;
        detachedSignature = b;
      } else {
        signature = await sign({ dataForSign: toBase64(secret), certificate });
      }

      const payload = {
        token,
        signature,
        detachedSignature,
      };

      try {
        const result = await confirm(payload);
        dispatch(handleAuth(result));
      } catch (error: any) {
        if (error?.message) {
          navigate(`${Auth.Home}${Auth.Register}`);
          message.error(error?.message as string);
        } else {
          throw t('Something went wrong...');
        }
      }

      !state ? navigate('/') : navigate(state.from);
    } catch (error: any) {
      message.error(t('Something went wrong...'));
    } finally {
      dispatch(setSignLoading(false));
    }
  };

  return { login, isClientLoading: !client };
}
