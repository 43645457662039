import { Modal } from 'antd';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import user from 'assets/images/user.jpg';
import Dropdown from 'components/dropdown';
import { DropdownItemDefault } from 'components/dropdown/items/default';
import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/hooks';
import { logout } from 'store/thunks/auth/auth';

import { ReactComponent as ArrowIcon } from 'assets/images/arrow-bottom.svg';
import { ReactComponent as CheckIcon } from 'assets/images/check-second.svg';
import { ReactComponent as LogoutIcon } from 'assets/images/logout.svg';
import { ReactComponent as PlusIcon } from 'assets/images/plus.svg';
import { ReactComponent as UserIcon } from 'assets/images/user.svg';

import { selectCurrentCertificateInfo } from 'store/reducers/certificates';
import s from './navbar.module.scss';

export const User = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userInfo = useSelector(selectCurrentCertificateInfo());
  const [isLogoutModalVisible, setLogoutModalVisible] = useState(false);

  const classesButtonAdd = cx(s['button'], s['button-add']);

  const { Home, Profile, Organizations } = AppRoutes.Dashboard;

  const handleLogout = () => {
    setLogoutModalVisible(true);
  };

  const handleConfirmLogout = () => {
    dispatch(logout());
    setLogoutModalVisible(false);
  };

  const handleCancelLogout = () => {
    setLogoutModalVisible(false);
  };

  return (
    <div className={s.container}>
      <div className={s.info}>
        <Dropdown
          button={
            <div className={s.changeUser}>
              <span className={s.organization}>
                {userInfo?.['Имя/Отчество'] || userInfo?.['Владелец'] || userInfo?.['Компания']}
              </span>

              <ArrowIcon />
            </div>
          }
          footer={
            <Button
              icon={<PlusIcon />}
              className={classesButtonAdd}
              displayType="outlined-primary"
              onClick={() => navigate(Home + Organizations.Home + Organizations.Requisites)}
            >
              {t('Add')}
            </Button>
          }
        >
          <DropdownItemDefault
            onClick={() => {
              navigate(Home + Profile.Home + Profile.PersonalData);
            }}
          >
            <div className={s.item}>
              <span>{userInfo?.['Имя/Отчество'] ?? userInfo?.['Компания']}</span>

              <CheckIcon />
            </div>
          </DropdownItemDefault>
        </Dropdown>

        <span className={s.position}>
          {userInfo?.['Должность'] ?? userInfo?.['T'] ?? 'Администратор'}
        </span>
      </div>

      <Dropdown button={<img src={user} alt="user" width={40} height={40} />}>
        <DropdownItemDefault
          onClick={() => {
            navigate(Home + Profile.Home + Profile.PersonalData);
          }}
        >
          <div className={s.itemProfile}>
            <UserIcon />
            {t('Profile')}
          </div>
        </DropdownItemDefault>
        {/* <DropdownItemDefault onClick={handleLogout}>
          <div className={s.itemProfile}>
            <RefreshIcon />
            {t('Change user')}
          </div>
        </DropdownItemDefault> */}
        <DropdownItemDefault
          onClick={() => {
            handleLogout();
          }}
        >
          <div className={s.itemProfile}>
            <LogoutIcon />
            {t('Logout')}
          </div>
        </DropdownItemDefault>
      </Dropdown>

      <Modal
        title={t('Confirm Logout')}
        open={isLogoutModalVisible}
        onOk={handleConfirmLogout}
        onCancel={handleCancelLogout}
        okText={t('Logout')}
        cancelText={t('Cancel')}
      >
        {t('Are you sure you want to logout?')}
      </Modal>
    </div>
  );
};
