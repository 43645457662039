import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { controlError } from 'utils/controlError';

import { Checkbox } from 'components/common';
import { ErrorLabel } from 'components/error-label';
import { FieldLabel } from 'components/field-label';

import s from './styles.module.scss';
import { useState } from 'react';

export const variantRadioGroup = {
  row: 'row',
  column: 'column',
};
export interface Props {
  name: string;
  label?: string;
  className?: string;
  placeholder?: string;
  required?: boolean;
}

export const CheckboxField = ({ name, label, className }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);

  const error = t(controlError(meta, name) as string);

  return (
    <div className={s['container']}>
      <Checkbox
        label={label}
        preventLabelClick
        className={className}
        checked={field?.value}
        onClick={() => {
          helpers?.setValue(!field?.value);
        }}
      />
      {error && <ErrorLabel error={error} />}
    </div>
  );
};
