import { useNavigate } from 'react-router-dom';

export const useNavigateWithCurrentParams = () => {
  const navigate = useNavigate();

  const navigateWithCurrentParams = (path: string) => {
    const currentParams = new URLSearchParams(window.location.search);
    navigate(path + '?' + currentParams.toString());
  };

  return navigateWithCurrentParams;
};
