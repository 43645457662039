import { saveCertificateState } from 'utils/certificate';

export default function ({ getState }: { getState: () => any }) {
  return (next: any) => (action: any) => {
    const result = next(action);

    if (action.type?.startsWith('certificates/setCertificate')) {
      const state = getState().certificate;
      saveCertificateState(state);
    }

    return result;
  };
}
