import { useMemo } from "react"

import {
  OptionProps,
  ControlProps,
  SingleValueProps,
  CSSObjectWithLabel,
} from 'react-select';

import { SelectOption } from "./select-types";

export const useStyles = (
  large?: boolean,
  error?: boolean,
): any => {

  const customStyles = useMemo(() => ({
    menuPortal: (css: CSSObjectWithLabel) => ({
      ...css,
      zIndex: 9999,
    }),

    container: (css: CSSObjectWithLabel) => ({
      ...css,

      '.rs-option-icon, .rs-search-icon, .rs-searchable-option-icon': {
        lineHeight: 0,
      },
    }),

    valueContainer: (css: CSSObjectWithLabel) => ({
      ...css,
    }),

    placeholder: (css: CSSObjectWithLabel) => ({
      ...css,
      color: 'black',
      fontFamily: 'Gilroy',
      fontWeight: 400,
      fontSize: '16px',
      fontStyle: 'normal',
      lineHeight: '24px',
    }),

    control: (
      css: CSSObjectWithLabel,
      state: ControlProps<SelectOption>
    ) => ({
      ...css,
      minHeight: '40px',
      padding: '0px 12px 0px 0px',
      borderRadius: '0px',
      transition: 'border-color 0.2s ease-in-out',
      cursor: 'pointer',

      '.rs-search-icon': {
        display: state.menuIsOpen ? 'block' : 'none',
        transform: state.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
        marginRight: '8px',

        '& path': {
          fill: 'grey',
        },
      },

      '.rs-searchable-option-icon': {
        display: state.menuIsOpen ? 'none' : 'inherit',
      },
    }),

    indicatorSeparator: (css: CSSObjectWithLabel) => {
      return { ...css, display: 'none' };
    },

    dropdownIndicator: (
      css: CSSObjectWithLabel,
      state: ControlProps<SelectOption>
    ) => {
      const { isDisabled, menuIsOpen } = state.selectProps;

      return {
        ...css,
        padding: '0',
        svg: {
          transform: `rotate(${menuIsOpen ? '180deg' : '0deg'})`,
          path: {
            fill: '#4F6281',

            ...(isDisabled && { fill: '#4F6281' }),
          },
          transition: 'transform 0.3s ease-out',
        },
      };
    },

    menu: (css: CSSObjectWithLabel, state: ControlProps<SelectOption>) => {
      const { menuIsOpen } = state.selectProps;

      return {
        ...css,
        marginBottom: 0,
        borderRadius: '0px',
        overflow: 'hidden',
        opacity: menuIsOpen ? '1' : '0',
        zIndex: 2,
        transition: 'opacity 2s ease-out',
      };
    },

  }), [large, error]) 

  return customStyles;
}