import React from 'react';
import cx from 'classnames'; // Import classnames as cx
import { Icons } from 'components/icons';
import styles from './StaffPermission.module.scss';

export const StaffPermission = ({ title, disabled }: { title: string; disabled?: boolean }) => {
  const containerClasses = cx(styles.container, { [styles.disabledContainer]: disabled });
  const textClasses = cx(styles.text, { [styles.disabledText]: disabled });
  const iconWrapperClasses = cx(styles.iconWrapper, { [styles.disabledIcon]: disabled });

  return (
    <div className={containerClasses}>
      <div className={styles.leftSection}>
        <div className={iconWrapperClasses}>
          <Icons.SuccessMark />
        </div>
        <div className={textClasses}>{title}</div>
      </div>
      {false && (
        <div className={styles.rightSection}>
          <div className={styles.alertBox}>
            <div className={styles.alertText}>Истек срок доверенности</div>
          </div>
          <div className={styles.imageWrapper}>
            <div className={styles.innerImageWrapper}>
              <img className={styles.image} src="https://via.placeholder.com/13x13" alt="" />
            </div>
          </div>
        </div>
      )}{' '}
    </div>
  );
};
