import cx from 'classnames';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import s from './status.module.scss';
import { InviteStatus } from 'store/api/types/organizations-invitations';

export const variantStatus = {
  sent: 'sent',
  rejected: 'rejected',
  expectation: 'expectation',
  secondaryExpectation: 'secondary-expectation',
  completed: 'completed',
  delete: 'delete',
};

export const Status = ({
  type,
  text,
  variant,
  style,
}: {
  type?: string;
  variant?: string;
  style?: CSSProperties;
  text?: InviteStatus | string;
}) => {
  const { t } = useTranslation();

  let statusText = '';

  if (type === 'incoming') {
    if (text === 'sent') {
      statusText = t('Приглашение');
    } else if (text === 'rejected') {
      statusText = t('Отклонено');
    } else if (text === 'approved') {
      statusText = t('Принято');
    }
  } else if (type === 'outgoing') {
    if (text === 'sent') {
      statusText = t('Отправлено');
    } else if (text === 'rejected') {
      statusText = t('Отклонено');
    } else if (text === 'approved') {
      statusText = t('Принято');
    }
  } else if (type === 'document') {
    if (text === 'draft') {
      statusText = t('Черновик');
    } else if (text === 'sent') {
      statusText = t('Отправлен');
    } else if (text === 'approved') {
      statusText = t('Направлен на подписание');
    } else if (text === 'signed') {
      statusText = t('Подписан');
    }
  } else if (type === 'status') {
    statusText = t(`${text}`);
  }

  const statusClasses = cx(s['root'], {
    [s['expectation']]: text === 'sent',
    [s['blocked']]: text === 'rejected' || type === 'status',
    [s['active']]: text === 'approved' || text === 'signed',
    [s['delete']]: variant === variantStatus.delete,
    [s['secondary-expectation']]:
      text === 'draft' || variant === variantStatus.secondaryExpectation,

    [s['completed']]: variant === variantStatus.completed,
  });

  return (
    <span className={statusClasses} style={style}>
      {t(statusText ?? '')}
    </span>
  );
};
