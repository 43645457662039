import { gql } from 'graphql-request';

export const GET_STAFF_OUTGOING_INVITES = gql`
  query CoreEmployeeInvitations {
    coreEmployeeInvitations {
      query(filter: { isIncoming: false, statuses: [sent, approved, rejected] }) {
        invites {
          id
          type
          status
          createdAt
          updatedAt
          employee {
            id
            firstName
            middleName
            lastName
            status
            attributes {
              name
              value
            }
          }
          organization {
            id
            name
            status
            type
            countryCode
            createdAt
            updatedAt
            attributes {
              name
              value
            }
          }
          inviteeEmployee {
            id
            firstName
            middleName
            lastName
            status
            attributes {
              name
              value
            }
          }
        }
      }
    }
  }
`;
