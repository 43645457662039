import { createApi } from '@reduxjs/toolkit/query/react';

import { PaymentOption } from 'models/api/payments/payment-option';
import { CREATE_PAYMENT_OPTIONS } from './mutations/payments/create.payment.options';
import { UPDATE_PAYMENT_OPTIONS } from './mutations/payments/update.payment.options';
import { GET_MY_PAYMENT_OPTIONS } from './queries/payments/get.my.PaymentOptions';
import { MyPaymentOptionsQueryResponse } from './queries/payments/types';
import { baseQuery } from './query';
import { SET_PAYMENT_OPTIONS } from './mutations/payments/set.payment.options copy';
import { DefaultPaymentOption } from './types';

const PaymentTags = {
  MyPaymentOptions: 'MyPaymentOptions',
};

const tagTypes = [PaymentTags.MyPaymentOptions];

export const paymentsApi = createApi({
  tagTypes,
  baseQuery: baseQuery,
  reducerPath: 'paymentsApi',
  endpoints: (builder) => ({
    getMyPaymentOptions: builder.query<DefaultPaymentOption[], void>({
      query: () => ({
        document: GET_MY_PAYMENT_OPTIONS,
      }),
      transformResponse: (rawResponse: MyPaymentOptionsQueryResponse) =>
        rawResponse?.coreOrganizationPaymentOptions.my,
      providesTags: [PaymentTags.MyPaymentOptions],
    }),
    createPaymentOption: builder.mutation<void, { input: PaymentOption }>({
      query: ({ input }) => ({
        document: CREATE_PAYMENT_OPTIONS,
        variables: {
          input,
        },
      }),
      invalidatesTags: [PaymentTags.MyPaymentOptions],
    }),
    updatePaymentOption: builder.mutation<void, { id: string; input: Partial<PaymentOption> }>({
      query: ({ input, id }) => ({
        document: UPDATE_PAYMENT_OPTIONS,
        variables: {
          id,
          input,
        },
      }),
      invalidatesTags: [PaymentTags.MyPaymentOptions],
    }),
    setPaymentOption: builder.mutation<void, { id: string; input: Partial<PaymentOption> }>({
      query: ({ input, id }) => ({
        document: SET_PAYMENT_OPTIONS,
        variables: {
          id,
          input,
        },
      }),
      invalidatesTags: [PaymentTags.MyPaymentOptions],
    }),
  }),
});

export const {
  useSetPaymentOptionMutation,
  useGetMyPaymentOptionsQuery,
  useCreatePaymentOptionMutation,
  useUpdatePaymentOptionMutation,
} = paymentsApi;
