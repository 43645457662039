import { gql } from 'graphql-request';

export const GET_STAFF_LIST = gql`
  query CoreEmployees($searchText: String, $roles: [core_EmployeeRole!]) {
    coreEmployees {
      query(filter: { searchText: $searchText, roles: $roles }) {
        employees {
          id
          firstName
          middleName
          lastName
          status
          attributes {
            name
            value
          }
        }
      }
    }
  }
`;
