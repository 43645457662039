import { TFunction } from 'i18next';
import * as Yup from 'yup';

export interface OrganizationUploadDocumentFormValues {
  document?: File;
}

export const model = {
  document: {
    isModal: true,
    name: 'document',
    multiple: false,
  },
};

export const validationSchema = (t: TFunction) => Yup.object().shape({});

export const initialValues = {};
