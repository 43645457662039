import { gql } from 'graphql-request';

export const GET_CONTRACTOR_ORGANIZATION_DOCUMENTS = gql`
  query CoreOrganizationDocuments($contractorId: ID!) {
    coreOrganizationDocuments {
      contractorDocuments(contractorId: $contractorId) {
        documents {
          id
          createdAt
          updatedAt
          name
          extension
          size
        }
      }
    }
  }
`;
