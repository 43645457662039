import { useEffect } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';

interface HandleMutationProps {
  data?: any;
  error?: any;
  status?: QueryStatus;
  onSuccess?: (data?: any) => void;
  onError?: (error: any) => void;
}

export const useHandleMutation = ({
  data,
  error,
  status,
  onError,
  onSuccess,
}: HandleMutationProps) => {
  useEffect(() => {
    if (error) {
      if (onError) {
        onError(error);
      }
    }
  }, [error]);

  useEffect(() => {
    if (status === QueryStatus.fulfilled && onSuccess) {
      onSuccess(data);
    }
  }, [status, data, onSuccess]);
};
