import { DatePickerMode } from 'components/common/date-picker/date-picker';
import { InputMasks } from 'components/common/input-field/input-field';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

export interface AttorneyPrincipalHasPrePowerFormValues {
  nameCompany: string;
  kpp: string;
  dateOfBirth: string;
  citizenship: string;
  country: string;
}

export const model = {
  nameCompany: {
    type: 'text',
    required: true,
    placeholder: 'АО “Поставщик-ПК”',
    name: 'nameCompany',
    label: 'Name company',
    disabled: false,
  },
  kpp: {
    type: 'text',
    required: true,
    placeholder: '411154484',
    name: 'kpp',
    label: 'KPP',
    mask: InputMasks.org9,
    disabled: false,
  },
  dateOfBirth: {
    type: 'date',
    required: true,
    mode: DatePickerMode.SINGLE,
    name: 'dateOfBirth',
    label: 'Date of birth',
  },
  citizenship: {
    type: 'radio',
    required: true,
    name: 'citizenship',
    label: 'Citizenship',
    options: [
      { label: 'Citizen of the Russian Federation', value: 'ru' },
      { label: 'Foreign citizen', value: 'foreign' },
    ],
  },
  country: {
    required: true,
    name: 'country',
    label: 'Country',
    placeholder: 'Select from the list',
  },
};

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    [model.nameCompany.name]: Yup.string().required(t('Name company is required')),
    [model.kpp.name]: Yup.string().matches(
      /^[0-9]{9}$/,
      t('This field should be a 9-digit number'),
    ),
    [model.dateOfBirth.name]: Yup.string().required('Date of birth is required'),
    [model.citizenship.name]: Yup.string().required('Citizenship is required'),
    [model.country.name]: Yup.string(),
  });

export const initialValues = {
  [model.kpp.name]: '',
  [model.country.name]: '',
  [model.nameCompany.name]: '',
  [model.citizenship.name]: '',
  [model.dateOfBirth.name]: null,
} as unknown as AttorneyPrincipalHasPrePowerFormValues;
