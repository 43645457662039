import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import styles from './loader.module.scss';

interface LoaderConfig {
  size?: 'small' | 'medium' | 'big' | 'ultra';
  text?: string;
  fontSize?: number;
}

export function Loader({ size, text, fontSize }: LoaderConfig) {
  const getLoaderFontSize = () => {
    if (size === 'medium') {
      return 32;
    }

    if (size === 'big') {
      return 48;
    }

    if (size === 'ultra') {
      return 128;
    }

    return 16;
  };

  return (
    <div className={styles.loader}>
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: fontSize ?? getLoaderFontSize() }} spin />
        }
      />
      <p>{text}</p>
    </div>
  );
}
