import { gql } from 'graphql-request';

export const GET_POA_DRAFTS_LIST = gql`
  query CorePOA($representativeEmployeeID: ID, $status: core_POAQueryNS_queryInputUserStatus) {
    corePOA {
      query(
        filter: {
          status: $status
          byEmployee: false
          pagination: { limit: 1000 }
          representativeEmployeeID: $representativeEmployeeID
        }
      ) {
        poas {
          poaDraft {
            id
            number
            issueDate
            status
            updatedAt
            createdAt
            expirationDate
            revocationDate
            representative {
              id
              userType
              email
              rawUserInfo
            }
            authority {
              id
              userType
              email
              rawUserInfo
            }
            recipientOrganization {
              id
              name
              status
              type
              countryCode
              createdAt
              updatedAt
            }
            powerDomain {
              code
              textDescription
              content
            }
          }
          authority {
            id
            userType
            email
            rawUserInfo
          }
          representative {
            id
            userType
            email
            rawUserInfo
          }
        }
      }
    }
  }
`;
