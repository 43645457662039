import { useTranslation } from 'react-i18next';

import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { toBase64 } from 'helpers';

import { SignType } from 'enums';
import useRegisterApi from 'hooks/use-register';
import useRegisterStart from 'hooks/use-start-register';
import { useState } from 'react';
import { handleAuth } from 'store/reducers/auth.reducer';
import { selectCurrentCertificate, setSignLoading } from 'store/reducers/certificates';
import { useClient } from 'client/hooks/useClient';

export default function useRegister() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const { mutateAsync: initHandshake } = useRegisterStart();

  const { sign, signXml, client } = useClient();

  const { mutateAsync: confirmRegistration } = useRegisterApi();

  const certificate = useSelector(selectCurrentCertificate());

  const startRegister = async () => {
    try {
      if (!certificate) {
        return null;
      }

      setLoading(true);

      const data = await initHandshake(certificate.Base64RawData);

      setLoading(false);

      return data;
    } catch (error: any) {
      message.error(error?.message as string);
      navigate('/');
      return null;
    }
  };

  const register = async ({
    token,
    secret,
    tbsType,
  }: {
    token: string;
    secret: string;
    tbsType: string;
  }) => {
    try {
      if (!certificate || !secret || !token) {
        return;
      }

      dispatch(setSignLoading(true));
      let signature = '';
      let detachedSignature = '';

      if (tbsType === SignType.XMLDSIG) {
        const { signature: a, detachedSignature: b } = await signXml({
          certificate,
          dataForSign: secret,
        });
        signature = a;
        detachedSignature = b;
      } else {
        signature = await sign({ dataForSign: toBase64(secret), certificate });
      }
      dispatch(setSignLoading(false));

      const payload = {
        token,
        signature,
        detachedSignature
      };

      try {
        const result = await confirmRegistration(payload);
        dispatch(handleAuth(result));
      } catch (error: any) {
        if (error?.message) {
          message.error(error?.message as string);
        } else {
          throw t('Something went wrong...');
        }
      }

      !state ? navigate('/') : navigate(state.from);
    } catch (error: any) {
      if (error.message && error.message === 'register certificate first') {
        message.error(t('The organization is not connected'));
      } else {
        message.error(t('Something went wrong...'));
      }
    } finally {
      dispatch(setSignLoading(false));
    }
  };

  return { register, startRegister, isLoading, isClientLoading: !client };
}
