import { Tooltip } from 'antd';
import { CSSProperties, ReactNode } from 'react';

import s from './custom-tooltip.module.scss';

interface CustomTooltipProps {
  isArrow?: boolean;
  classname?: string;
  widthInner?: string;
  innerStyle?: CSSProperties;
  title: string;
  children?: ReactNode;
}

export const CustomTooltip = ({
  title,
  isArrow,
  children,
  classname,
  innerStyle,
  widthInner,
}: CustomTooltipProps) => {
  return (
    <Tooltip
      title={title}
      arrow={isArrow ?? false}
      className={classname}
      overlayInnerStyle={{
        fontSize: '14px',
        borderRadius: '0px',
        backgroundColor: '#4F6281',
        width: `${widthInner ?? ''}px`,
        ...innerStyle,
      }}
    >
      {children}
    </Tooltip>
  );
};
