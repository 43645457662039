const Auth = {
  Home: '/authorization',
  EULA: '/eula',
  Login: '/login',
  Offer: '/offer',
  Register: '/register',
  PoaCreate: '/poa-create',
  PoaAccept: '/poa-accept',
  PoaRequest: '/poa-request',
  PoaPreview: '/poa-preview',
  PoaSigned: '/poa-signed',
  UserRestricted: '/user-restricted',
  RegisterByRepresentative: '/register-by-representative',
};

const Profile = {
  Home: '/profile',
  PersonalData: '/personal-data',
  Authority: '/authority',
};

const Settings = {
  Home: '/settings',
  EDOOperator: '/edo-operator',
  SettingsCommon: '/settings-common',
};

const Organizations = {
  Home: '/organization',
  Requisites: '/requisites',
  Documents: '/documents',
  Staff: '/staff',
  List: '/list',
  Details: '/details',
  Invites: '/invites',
  AddStaff: '/add-staff',
};

const Contracts = {
  Home: '/contracts',
  NewContract: '/new-contract',
  PreviewNewContract: '/preview',
  BasicInformation: '/basic-information',
  Events: '/events',
  Payments: '/payments',
  Documents: '/documents',
  ContractApproval: '/contract-approval',
};

const Invitation = {
  Home: '/invitation',
  NDA: '/nda',
  Confirm: '/confirm',
  Agreement: '/agreement',
};

const CounterpatyDetail = {
  Home: '/all-counterparties',
  Documents: '/documents',
  Requisites: '/requisites',
};

const Counterparties = {
  Invitation,
  CounterpatyDetail,
  Home: '/counterparties',
  Invitations: '/invitations',
  Agreement: '/agreement',
};

const PowersAttorney = {
  Home: '/powers-attorney',
  Active: '/active',
  Revoked: '/revoked',
  Archived: '/archived',
  Drafts: '/drafts',
  NewAttorney: '/new-attorney',
  Preview: '/preview',
};

const Dashboard = {
  Profile,
  Contracts,
  Organizations,
  Counterparties,
  PowersAttorney,
  Settings,
  Home: '',
  Infopanel: '/infopanel',
};

export const AppRoutes = {
  Auth,
  Dashboard,
  NotFound: '/not-found',
};
