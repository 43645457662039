import { types } from 'react-alert';

import { ReactComponent as SuccessIcon } from 'assets/images/mark.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/close.svg';

export const Icon = {
  [types.SUCCESS]: <SuccessIcon width={24} height={24} />,
  [types.ERROR]: null,
  [types.INFO]: null,
};

export const Title = {
  [types.SUCCESS]: 'Success',
  [types.ERROR]: 'Error',
  [types.INFO]: 'Info',
};
