import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useCreatePoaRepresentativeMutation } from 'store/api/poaUsers';
import { Employee } from 'store/api/types/staff';
import { DefaultDateFormat, prepareDate } from 'utils/date';
import { useHandleMutation } from 'utils/handleMutation';

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useCreateRepresentative = ({ onSuccess, onError }: Props) => {
  const [createRepresentative, mutation] = useCreatePoaRepresentativeMutation();
  const alert = useAlert();

  const handleSuccess = useCallback(() => {
    alert.success('Representative added');
    if (onSuccess) {
      onSuccess();
    }
  }, [alert.success, onSuccess]);

  const handleError = useCallback(() => {
    alert.error('Representative not added');
    if (onError) {
      onError();
    }
  }, [alert.error, onError]);

  useHandleMutation({ ...mutation, onSuccess: handleSuccess, onError: handleError });

  const createRepresentativeByStaff = async (staff: Employee) => {
    const inn = staff.attributes?.find((item) => item.name === 'inn')?.value;
    const snils = staff.attributes?.find((item) => item.name === 'snils')?.value;
    const ogrn = staff.attributes?.find((item) => item.name === 'ogrn')?.value;
    const kpp = staff.attributes?.find((item) => item.name === 'kpp')?.value;

    await createRepresentative({
      snils: staff.attributes?.find((item) => item.name === 'snils')?.value ?? '000',
      userType: 'natural_person',
      request: JSON.stringify({
        surname: staff.lastName ?? ' ',
        name: staff.firstName ?? ' ',
        parental_name: staff.middleName?.length ? staff.middleName : ' ',
        inn: inn?.length ? inn : '0000000000',
        inn_natural_person: inn?.length ? inn : '0000000000',
        snils: snils?.length ? snils : '00000000000',
        birth_date: prepareDate(dayjs().format(DefaultDateFormat)),
        citizenship_flag: 'ru' ?? 'foreign',
        doc_type: '21',
        doc_number: '000',
        doc_issue_date: prepareDate(dayjs().format(DefaultDateFormat)),
        doc_issuer: '000',
        doc_code: '000000',
        ogrn: ogrn?.length ? ogrn : '000',
        kpp: kpp?.length ? kpp : '000',
      }),
    });
  };

  return { createRepresentativeByStaff };
};
