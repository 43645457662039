import { gql } from 'graphql-request';

export const LICENCE_TEMPLATE_ID = {
  Sublicense: 'cc9d6276-8e60-4acf-a243-2c9b40e02155',
  Partner: 'd0f0d31f-7ca5-4af6-a1d3-808ce2b08e1f',
};

export const GET_DOCUMENT_LICECES_SCHEME = gql`
  query App5705572d_Document($templateId: String!, $documentId: String) {
    app5705572d_Document {
      getTemplate(templateId: $templateId, documentId: $documentId) {
        template {
          step
          name
          type
          value
          required
          isSearchable
          placeholder
          fieldType
          fieldTransform
          label {
            en
            ru
          }
          stepLabel
          validation
          options {
            label
            value
          }
        }
      }
    }
  }
`;
