import { gql } from 'graphql-request';

export const UPDATE_DOCUMENT = gql`
  mutation App70e9b8a9_Document($input: app70e9b8a9_DocumentMutationNS_updateInputInput!) {
    app70e9b8a9_Document {
      update(input: $input) {
        id
      }
    }
  }
`;
