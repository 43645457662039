import { TFunction } from 'i18next';
import * as Yup from 'yup';

import {
  initialValues as ndaInitialValues,
  model as ndaModel,
  validationSchema as ndaValidationSchema,
} from 'components/contracts/forms/nda-invite/basic-parameters/config';

export const model = {
  email: {
    label: '',
    type: 'text',
    name: 'email',
    placeholder: 'E-mail',
    ...ndaModel,
  },
};

export const validationSchema = (t: TFunction, isNdaRequired: boolean) =>
  Yup.object()
    .shape({
      [model.email.name]: Yup.string().email(t('Incorrect e-mail') as string),
    })
    .concat(isNdaRequired ? ndaValidationSchema(t) : Yup.object());

export const initialValues = (data: any) => {
  return {
    [model.email.name]: '',
    ...ndaInitialValues(data),
  };
};
