import { DatePickerField, InputField } from 'components/common';

import { AttorneyFormValues, model } from '../config';

import { RadioGroupField } from 'components/fields/radio-group-field/select-filed';
import { SelectField } from 'components/fields/select-fields/select-filed';
import { Loader } from 'components/loader/loader';
import { useCountriesOptions } from 'hooks/use-countires-options';
import styles from './information-principal.module.scss';

type Props = {
  isIndividual?: boolean;
  values: AttorneyFormValues;
};

export const InformationPrincipalHasPrePower = ({ values, isIndividual }: Props) => {
  const { defaultCountry, isFetching, options } = useCountriesOptions();

  return (
    <div className={styles['container']}>
      {!isIndividual && <InputField {...model.nameCompany} />}
      {!isIndividual && <InputField {...model.kpp} />}
      <DatePickerField {...model.dateOfBirth} />
      <RadioGroupField {...model.citizenship} />
      {values.citizenship === 'foreign' && (
        <>
          {isFetching ? (
            <Loader size="medium" />
          ) : (
            <SelectField {...model.country} options={options} defaultValue={defaultCountry} />
          )}
        </>
      )}
    </div>
  );
};
