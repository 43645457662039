import { useCallback } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useConfirmStaffInviteMutation, useRejectStaffInviteMutation } from 'store/api/staff';
import { useHandleMutation } from 'utils/handleMutation';

type Props = {
  inviteId: string;
};

export const useReviewStaffInvite = ({ inviteId }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const [rejectInvite, rejectInviteMutation] = useRejectStaffInviteMutation();
  const [confirmInvite, confirmInviteMutation] = useConfirmStaffInviteMutation();

  const onRejectSuccess = useCallback(() => {
    alert.success(t('Invite Rejected'));
  }, [alert, t]);

  const onRejectError = useCallback(() => {
    alert.error(t('Error'));
  }, [alert, t]);

  const onConfirmSuccess = useCallback(() => {
    alert.success(t('Invite Accepted'));
  }, [alert, t]);

  const onConfirmError = useCallback(() => {
    alert.error(t('Error'));
  }, [alert, t]);

  const onRejectSubmit = async (callBack: () => void) => {
    await rejectInvite({ id: inviteId });
    callBack();
  };

  const onConfirmSubmit = async (callBack: () => void) => {
    await confirmInvite({ id: inviteId });
    callBack();
  };

  useHandleMutation({
    ...rejectInviteMutation,
    onSuccess: onRejectSuccess,
    onError: onRejectError,
  });

  useHandleMutation({
    ...confirmInviteMutation,
    onSuccess: onConfirmSuccess,
    onError: onConfirmError,
  });

  return {
    onRejectSubmit,
    onConfirmSubmit,
  };
};
