import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckIcon } from 'assets/images/check.svg';

import s from './steps.module.scss';

interface SidenavStepsProps {
  step?: number;
  steps?: number;
  labels?: Record<number, string>;
  setStep?: (step: number) => void;
}

export const Step = ({
  step,
  label,
  checked,
  current,
  onClick,
}: {
  step: number;
  label?: string;
  checked?: boolean;
  current?: boolean;
  onClick?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div
      tabIndex={0}
      role="button"
      onClick={() => {
        onClick && onClick();
      }}
      onKeyDown={() => onClick && onClick()}
      className={cx(s['wrapper-step'], { [s['current']]: current })}
    >
      <div className={cx(s['checkbox-mark'], { [s['checked']]: checked })}>
        <div className={s['checkbox-icon-container']}>{<CheckIcon />}</div>
      </div>
      <div className={s['wrapper-content']}>
        <span className={s['step']}>
          {t('Step')} {step}
        </span>
        <span className={s['name']}>{t(label!) ?? 'name'}</span>
      </div>
    </div>
  );
};

export const SidenavSteps = ({ step = 1, steps = 3, labels, setStep }: SidenavStepsProps) => {
  return (
    <div className={s['container']}>
      {[...Array(steps)].map((_, i) => (
        <div key={i.toString()} className={s['step-container']}>
          <Step
            step={i + 1}
            checked={i + 1 < step}
            current={i + 1 === step}
            label={labels && labels[i + 1]}
          />
        </div>
      ))}
    </div>
  );
};
