import cx from 'classnames';
import { Formik } from 'formik';

import { SelectTagsField } from 'components/fields/select-fields-tags/select-fields-tags';
import { useTranslation } from 'react-i18next';

import {
  model,
  StatusOption,
  initialValues,
  validationSchema,
  AllContractsFormValues,
} from './config';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import { Button } from 'components/ui/Button';

import { useMemo, useState, useEffect } from 'react';
import s from './filter.module.scss';
import { useGetDocumentStatusesQuery } from 'store/api/documents';

export const AsideFilter = ({
  isOpen,
  status: initialStatus,
  onClick,
  onChangeStatus,
}: {
  isOpen: boolean;
  status: string[] | undefined;
  onClick: () => void;
  onChangeStatus: (status: string[]) => void;
}) => {
  const { t } = useTranslation();

  const { data: statuses } = useGetDocumentStatusesQuery();

  const [selectedStatus, setSelectedStatus] = useState<StatusOption[]>(
    initialStatus?.map((status) => ({ label: status, value: status })) || [],
  );

  const options = useMemo(() => {
    return statuses
      ?.filter((item) => !['initiated', 'purged', 'cancelled', 'pending'].includes(item.alias))
      ?.map((item) => ({ label: t(item?.alias), value: item?.id }));
  }, [statuses]);

  const currentStatuses = useMemo(() => {
    return statuses
      ?.filter((option) => selectedStatus.some((selected) => selected.value === option.id))
      .map((item) => ({ label: t(item?.alias), value: item?.id }));
  }, [selectedStatus]);

  const containerClasses = cx(s['container'], {
    [s['open']]: isOpen,
  });
  const wrapperClasses = cx(s['wrapper'], {
    [s['open']]: isOpen,
  });
  const classesButtonBack = cx(s['button'], s['button-back']);
  const classesButtonSave = cx(s['button'], s['button-save']);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (onClick) {
        onClick();
      }
    }
  };

  const handleStatusChange = (selectedOptions: any) => {
    const optionsArray = Array.isArray(selectedOptions)
      ? selectedOptions
      : [selectedOptions || []];
    setSelectedStatus(optionsArray);
  };

  const onSubmit = (values: AllContractsFormValues) => {
    const { status } = values;

    const selectedStatus = status?.map((item) => item.value) || [];
    onChangeStatus(selectedStatus);

    if (onClick) {
      onClick();
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting, submitForm, setFieldValue }) => (
        <div className={containerClasses}>
          <div className={wrapperClasses}>
            <div className={s['header']}>
              <span className={s['title']}>{t('Filter')}</span>

              <div
                role="button"
                tabIndex={0}
                className={s['close']}
                onClick={onClick}
                onKeyDown={handleKeyDown}
              >
                <CloseIcon />
              </div>
            </div>

            <div className={s['main']}>
              {false && (
                <div className={s['wrapper-select']}>
                  <SelectTagsField {...model.typeContract} />
                </div>
              )}

              <div className={s['wrapper-select']}>
                <div className={s['wrapper-select']}>
                  <SelectTagsField
                    {...model.status}
                    options={options}
                    value={currentStatuses}
                    onChange={handleStatusChange}
                  />
                </div>
              </div>
            </div>

            <div className={s['footer']}>
              <Button className={classesButtonBack} onClick={onClick}>
                {t('Back')}
              </Button>
              <Button className={classesButtonSave} onClick={submitForm}>
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
