import { UsersList } from 'components/sidenav-message/invitations/UsersList';
import { InviteInfo } from './InviteInfo/InviteInfo';

import { selectInvitationsType, setInvitationType } from 'store/reducers/invite.list';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useGetIncomingInvitationsListQuery,
  useGetOutgoingInvitationsListQuery,
} from 'store/api/organizations-invitations';
import { PartyInfo } from 'store/api/types/organizations-invitations';
import { useAppDispatch } from 'store/hooks';
import s from './Invitations.module.scss';

export const Invitations = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [currentInvite, setCurrentInvite] = useState<PartyInfo | undefined>();
  const type = useSelector(selectInvitationsType);
  const { data: outgoing = [] } = useGetOutgoingInvitationsListQuery();
  const { data: incoming = [] } = useGetIncomingInvitationsListQuery();

  useEffect(() => {
    setCurrentInvite(undefined);
  }, [type]);

  useEffect(() => {
    let currentType;
    let current;

    if (incoming?.find((item) => item.id === id)) {
      current = incoming?.find((item) => item.id === id);
      currentType = 'incoming';
    }

    if (outgoing?.find((item) => item.id === id)) {
      current = outgoing?.find((item) => item.id === id);
      currentType = 'outgoing';
    }

    if (currentType) {
      dispatch(setInvitationType(currentType));
    }

    if (type === 'incoming' && incoming.length > 0 && !id) {
      current = incoming[incoming.length - 1];
      setCurrentInvite(current);
    }
    if (type === 'outgoing' && outgoing.length > 0 && !id) {
      current = outgoing[outgoing.length - 1];
      setCurrentInvite(current);
    }

    setCurrentInvite(current);
  }, [id, outgoing, incoming, type, dispatch]);

  const currentData = useMemo(() => {
    return type === 'incoming' ? incoming : outgoing;
  }, [type, outgoing, incoming]);

  return (
    <div className={s['container']}>
      <UsersList
        type={type}
        data={currentData}
        leftCount={incoming.length}
        rightCount={outgoing.length}
        selected={currentInvite}
      />
      <InviteInfo currentInvite={currentInvite} />
    </div>
  );
};
