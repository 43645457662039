import { isPlainObject } from '@reduxjs/toolkit';
import type { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { DocumentNode } from 'graphql';
import { ClientError, GraphQLClient, RequestDocument, RequestOptions } from 'graphql-request';
import { RefreshResponse } from 'models/api/auth/refresh-response.model';
import { refreshAccessToken } from 'modules/auth';
import { RootState, store } from 'store/store';

export const graphqlRequestBaseQuery = <E = ErrorResponse>(
  options: GraphqlRequestBaseQueryArgs<E>,
): BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  E,
  Partial<Pick<ClientError, 'request' | 'response'>>
> => {
  const client =
    'client' in options
      ? options.client
      : new GraphQLClient(process.env.REACT_APP_API_BASE_PATH + '/graphql');
  const requestHeaders: RequestHeaders =
    'requestHeaders' in options ? options.requestHeaders : {};

  return async (
    { document, variables },
    { getState, endpoint, forced, type, signal, extra },
  ) => {
    let preparedHeaders;
    try {
      const prepareHeaders: PrepareHeaders = options.prepareHeaders ?? ((x) => x);
      const headers = new Headers(stripUndefined(requestHeaders));

      const token = (getState() as RootState).auth?.authData?.access_token;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      preparedHeaders = await prepareHeaders(headers, {
        getState,
        endpoint,
        forced,
        type,
        extra,
      });

      const result = await client.request({
        document,
        variables,
        signal,
        requestHeaders: preparedHeaders,
      });

      // I can handle result here

      return {
        data: result,
        meta: {},
      };
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, message, stack, request, response } = error;

        const customErrors = options.customErrors ?? (() => ({ name, message, stack }));

        const customizedErrors = customErrors(error) as E;

        //I can handle Error here

        if (message?.includes('Unauthorized')) {
          const refresh_token = (getState() as RootState).auth?.authData?.refresh_token;
          if (refresh_token) {
            const refreshResult = await store.dispatch(refreshAccessToken({ refresh_token }));

            await client.request({
              document,
              variables,
              signal,
              requestHeaders: {
                Authorization: `Bearer ${
                  (refreshResult.payload as RefreshResponse).access_token
                }`,
              },
            });
          }
        }

        return { error: customizedErrors, meta: { request, response } };
      }
      throw error;
    }
  };
};

export const baseQuery = graphqlRequestBaseQuery({
  url: process.env.REACT_APP_API_BASE_PATH + '/graphql',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth?.authData?.access_token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

function stripUndefined(obj: any) {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const copy: Record<string, any> = { ...obj };
  for (const [k, v] of Object.entries(copy)) {
    if (typeof v === 'undefined') delete copy[k];
  }
  return copy;
}

export type Document = RequestDocument;
export type RequestHeaders = RequestOptions['requestHeaders'];
export type PrepareHeaders = (
  headers: Headers,
  api: Pick<BaseQueryApi, 'getState' | 'endpoint' | 'type' | 'forced' | 'extra'>,
) => MaybePromise<Headers>;

export type ErrorResponse = {
  message: string;
  stack: string;
  name: string;
};

export type GraphqlRequestBaseQueryArgs<E = ErrorResponse> = (
  | {
      url: string;
    }
  | { client: GraphQLClient }
) & {
  requestHeaders?: RequestHeaders;
  prepareHeaders?: PrepareHeaders;
  customErrors?: (args: ClientError) => E;
};

export type QueryReturnValue<T = unknown, E = unknown, M = unknown> =
  | {
      error: E;
      data?: undefined;
      meta?: M;
    }
  | {
      error?: undefined;
      data: T;
      meta?: M;
    };
export type MaybePromise<T> = T | PromiseLike<T>;
