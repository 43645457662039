import menu from 'assets/images/menu.svg';
import { Button } from 'components/ui/Button';
import { Link } from 'react-router-dom';
import s from './Header.module.scss';

import logo from 'dynamic/assets/logo-login.svg';

import { selectIsLoggedIn } from 'modules/auth';
import { useSelector } from 'react-redux';
import { ContracteumLogo } from 'components/contracteum-logo';

export function Header() {
  const isLoggedIn = useSelector(selectIsLoggedIn());

  function handleMenuClick() {
    //
  }

  return (
    <div className={s.root}>
      <Link style={{ textDecoration: 'none' }} to="/" className={s.logo}>
        <ContracteumLogo variant="auth" />
      </Link>

      {false && isLoggedIn && (
        <Button type="icon" className={s.menu} onClick={handleMenuClick}>
          <img src={menu} alt="Menu" />
        </Button>
      )}
    </div>
  );
}
