
import { EmployeeInformation } from './employee-information/EmployeeInformation';
import { RolesPowers } from './roles-powers/RolesPowers';

import styles from './Content.module.scss';

export const AddStaffContent = ({ step }: { step: number }) => {
  return (
    <div className={styles['container']}>
      {step === 1 && <EmployeeInformation />}
      {step === 2 && <RolesPowers />}
    </div>
  );
};
