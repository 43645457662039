import { createApi } from '@reduxjs/toolkit/query/react';

import { GET_CORE_DICTIONARIES_BANKS } from './queries/dictionaries/get.core.dictionaries.banks';
import { GET_CORE_DICTIONARIES_COUNTRIES } from './queries/dictionaries/get.core.dictionaries.countries';
import { CoreDictionariesCodeResponse } from './queries/dictionaries/types';
import { CoreOrganizationsBanksResponse } from './queries/organizations/types';
import { baseQuery } from './query';
import { Country, Organization } from './types';

export const dictionariesApi = createApi({
  tagTypes: [],
  baseQuery: baseQuery,
  reducerPath: 'dictionariesApi',
  endpoints: (builder) => ({
    getCoreDictionariesCountries: builder.query<Country[], void>({
      query: () => ({
        document: GET_CORE_DICTIONARIES_COUNTRIES,
      }),
      transformResponse: (rawResponse: CoreDictionariesCodeResponse) =>
        rawResponse?.coreDictionaries.get,
    }),
    getCoreDictionariesBanks: builder.query<Organization[], void>({
      query: () => ({
        document: GET_CORE_DICTIONARIES_BANKS,
      }),
      transformResponse: (rawResponse: CoreOrganizationsBanksResponse) =>
        rawResponse?.coreOrganizations.queryBanks.organizations,
    }),
  }),
});

export const { useGetCoreDictionariesCountriesQuery, useGetCoreDictionariesBanksQuery } =
  dictionariesApi;
