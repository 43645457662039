import { message } from 'antd';
import { ReactComponent as FileIcon } from 'assets/images/file.svg';
import { Checkbox } from 'components/common';
import { Loader } from 'components/loader/loader';
import useRegister from 'components/register/use-register';
import { Status } from 'components/status/status';
import { Button } from 'components/ui/Button';
import { AppRoutes } from 'config/routes';
import { SignType } from 'enums/sign-type';
import { fromBase64 } from 'helpers';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentCertificate, selectIsSignLoading } from 'store/reducers/certificates';
import { processXslt } from 'utils/xslt-transform.helper';

import { formatFileSize, stringSizeInKB } from 'utils/files';
import styles from './SignOfferPage.module.scss';

const Step = {
  Info: 0,
  Document: 1,
};

export function SignOfferPage() {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [token, setToken] = useState('');
  const [tbsType, setTbsType] = useState('');
  const [tbsData, setTbsData] = useState('');
  const [isChecked, setChecked] = useState(false);
  const [tbsDataDecoded, setTbsDataDecoded] = useState<DocumentFragment | string | null>(null);

  const { register, startRegister, isLoading, isClientLoading } = useRegister();
  const isSignLoading = useSelector(selectIsSignLoading());
  const selectedCertificate = useSelector(selectCurrentCertificate());

  const { EULA } = AppRoutes.Auth;
  const isSignRequired = !!tbsDataDecoded;

  const buttonText = useMemo(() => {
    if (isSignLoading) {
      return `${t('Loading')}...`;
    }

    if (!isSignRequired && step === Step.Document && !isChecked) {
      return t('Back');
    }

    if (!isSignRequired) {
      return t('Sign');
    }

    if (isSignRequired && step === Step.Document) {
      return t('Sign');
    }

    return t('Next');
  }, [isChecked, isSignLoading, isLoading, step, isChecked, isSignRequired]);

  const startRegisterFlow = async () => {
    const result = await startRegister();

    setToken(result?.token ?? '');
    if (result?.tbs_type === SignType.XMLDSIG) {
      setTbsDataDecoded(processXslt(fromBase64(result?.tbs_data)));
    }
    setTbsData(result?.tbs_data ?? '');
    setTbsType(result?.tbs_type ?? '');
  };

  useEffect(() => {
    startRegisterFlow();
  }, []);

  const handleButtonClick = async () => {
    if (step < 1 && isSignRequired) {
      setStep(step + 1);
    }

    if (!isSignRequired) {
      if (isChecked) {
        await register({ tbsType, token, secret: tbsData });
      } else {
        message.error(t('You have not accepted the User Agreement'));
      }
      setStep(Step.Info);
      return;
    }

    if (step >= 1) {
      await register({ tbsType, token, secret: tbsData });
    }
  };

  return (
    <div className={styles.root}>
      {isLoading ? (
        <div className={styles.content}>
          <div className={styles.loadingBlock}>
            <Loader size="ultra" />
          </div>
        </div>
      ) : (
        step === Step.Info && (
          <div className={styles.content}>
            <div className={styles.firstBlock}>
              <div className={styles.header}>
                {t('Connects')} <br /> {t('Business')}
              </div>
            </div>

            <div className={styles.secondBlock}>
              <div className={styles.info}>
                {isSignRequired ? (
                  <div className={styles.wrapperCompany}>
                    <div className={styles.headerOffer}>
                      <p className={styles.nameCompany}>{selectedCertificate?.FriendlyName}</p>
                      <Status text="Not signed" type="status" variant="rejected" />
                    </div>

                    <p className={styles.infoSign}>
                      {t('To work in the system, you need to sign a Accession Agreement')}
                    </p>

                    <div className={styles.wrapperFile}>
                      <FileIcon />

                      <div className={styles.wrapperContent}>
                        <span className={styles.content}>{t('Agreement')}</span>
                        <div className={styles.wrapperInfo}>
                          <span className={styles.size}>
                            {formatFileSize(stringSizeInKB(tbsData) ?? 0)}
                          </span>
                          <div className={styles.dot} />
                          <span className={styles.view} onClick={handleButtonClick}>
                            {t('Browse')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.wrapperUser}>
                    <p className={styles.username}>{selectedCertificate?.FriendlyName}</p>
                    <span className={styles.terms}>
                      {t('To work in the system, you need to accept a Terms of Conditions')}
                    </span>
                    <div className={styles.checkboxContainer}>
                      <Checkbox
                        link={EULA}
                        preventLabelClick
                        checked={isChecked}
                        linkStyle={styles.checkbox}
                        label={t('I accept the Terms of Conditions')}
                        onClick={() => {
                          setChecked((prev) => !prev);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.bottom}>
                <Button
                  size="big"
                  type="green"
                  className={styles.button}
                  onClick={handleButtonClick}
                  disabled={(!isSignRequired && !isChecked) || isClientLoading}
                >
                  {buttonText}
                </Button>
              </div>
            </div>
          </div>
        )
      )}

      {step === Step.Document && (
        <>
          <div className={styles.thirBlock}>
            <div className={styles.document}>
              <span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: new XMLSerializer().serializeToString(tbsDataDecoded as any),
                  }}
                />
              </span>
            </div>
          </div>
        </>
      )}

      {step === Step.Document && (
        <div className={styles.bottomSigned}>
          <Button
            size="big"
            type="green"
            className={styles.button}
            onClick={handleButtonClick}
            disabled={(!isSignRequired && !isChecked) || isClientLoading}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
}
