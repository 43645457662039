import { Loader } from 'components/loader/loader';
import { Button } from 'components/ui/Button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsSignLoading } from 'store/reducers/certificates';

import useSigner from 'client/hooks/use-signer';
import useProcessXslt from 'hooks/use-process-xslt';
import {
  useFinishRegistrationByRepresentativeMutation,
  useStartRegistrationByRepresentativeMutation,
} from 'store/api/organizations';
import { useHandleMutation } from 'utils/handleMutation';
import s from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'config/routes';
import { useAppDispatch } from 'store/hooks';
import { setInviteId } from 'store/reducers/invite.conteragent';

export function RegisterByRepresentative() {
  const inviteId = new URLSearchParams(window.location?.search || '').get('inviteId');
  const poaId = new URLSearchParams(window.location?.search || '').get('poaId');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [documentContent, setDocumentContent] = useState<string>('');

  const [startRegister, startMutation] = useStartRegistrationByRepresentativeMutation();
  const [finishRegister, finishMutation] = useFinishRegistrationByRepresentativeMutation();
  const isSignLoading = useSelector(selectIsSignLoading());
  const isLoading = startMutation?.isLoading;

  const { data: xsltPreview } = useProcessXslt({ base64: documentContent });
  const { handleSign, isLoading: isSignerLoading } = useSigner();

  const { Home, Counterparties: CounterpartiesRoute } = AppRoutes.Dashboard;

  const onSuccessStart = useCallback((data: string) => {
    if (!data?.length) {
      navigate('/');
      dispatch(setInviteId(null));
      return;
    }
    setDocumentContent(data ?? '');
  }, []);

  const onErrorStart = useCallback(() => {
    navigate('/');
    dispatch(setInviteId(null));
    localStorage.setItem(
      'redirectTo',
      `${AppRoutes.Auth.Home}${AppRoutes.Auth.RegisterByRepresentative}?inviteId=${inviteId}&poaId=${poaId}`,
    );
  }, [navigate]);

  const onSuccessFinish = useCallback(() => {
    navigate(
      Home + CounterpartiesRoute.Home + CounterpartiesRoute.Invitations + `/${inviteId}`,
    );
    dispatch(setInviteId(null));
  }, [navigate]);

  useHandleMutation({ ...startMutation, onSuccess: onSuccessStart, onError: onErrorStart });
  useHandleMutation({ ...finishMutation, onSuccess: onSuccessFinish });

  const buttonText = useMemo(() => {
    if (isSignLoading) {
      return `${t('Loading')}...`;
    }

    return t('Sign');
  }, [isSignLoading, isSignLoading]);

  useEffect(() => {
    if (inviteId && poaId) {
      startRegister({ inviteId, poaId });
    }
  }, []);

  const handleClick = async () => {
    await handleSign({
      signData: documentContent,
      onSubmit: async ({ signature, detachedSignature }) => {
        if (inviteId && poaId) {
          await finishRegister({ inviteId, poaId, signature, detachedSignature });
        }
      },
    });
  };

  return (
    <div className={s.root}>
      {isLoading ? (
        <div className={s.content}>
          <div className={s.loadingBlock}>
            <Loader size="ultra" />
          </div>
        </div>
      ) : (
        <>
          <div className={s.thirBlock}>
            <div className={s.document}>
              {xsltPreview && (
                <span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: new XMLSerializer().serializeToString(xsltPreview as any),
                    }}
                  />
                </span>
              )}
            </div>
          </div>

          <div className={s.bottomSigned}>
            <Button
              size="big"
              type="green"
              className={s.button}
              onClick={handleClick}
              disabled={isSignerLoading}
            >
              {buttonText}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
