/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from 'assets/images/check.svg';

import s from './checkbox.module.scss';

interface CheckboxProps {
  id?: string;
  link?: string;
  linkStyle?: string;
  icon?: React.ReactNode;
  label?: string;
  disabled?: boolean;
  className?: string;
  block?: boolean;
  checked?: boolean;
  preventLabelClick?: boolean;
  onClick?: () => void;
  onClickLabel?: () => void;
  positionRightIcon?: boolean;
  transparent?: boolean;
  checkedIcon?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
}

export const Checkbox = ({
  id,
  icon,
  link,
  label,
  onClick,
  disabled,
  linkStyle,
  className,
  onClickLabel,
  block = false,
  checked = false,
  transparent = false,
  preventLabelClick = false,
  checkedIcon = <CheckIcon />,
  ...props
}: CheckboxProps) => {
  const { t } = useTranslation();
  const checkboxId = id || label;

  const classesDisabled = cx({
    [s['disabled']]: disabled,
  });

  const handleToggle = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <label
      className={cx(s['checkbox-label'], classesDisabled, className)}
      tabIndex={disabled ? undefined : 0}
      onClick={preventLabelClick || disabled ? undefined : onClick}
    >
      <input
        className={cx(s['styled-checkbox'])}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        id={checkboxId}
        onChange={handleToggle}
        {...props}
      />

      <div className={cx(s['checkbox-mark'], { [s.checked]: checked })}>
        <div className={s['checkbox-icon-container']}>{checkedIcon}</div>
      </div>

      {label && !link && (
        <div
          onClick={
            disabled
              ? undefined
              : (e) => {
                  e.preventDefault();
                  if (onClickLabel) {
                    onClickLabel();
                  }
                }
          }
          className={s['checkbox-text']}
        >
          {t(label)}
        </div>
      )}

      {link && (
        <a
          href={link}
          target="_blank"
          className={linkStyle ? linkStyle : 'checkbox-text'}
          rel="noreferrer"
        >
          {t(label ?? '')}
        </a>
      )}
    </label>
  );
};
