import { TFunction } from 'i18next';
import * as Yup from 'yup';

export interface NewContractBasicParametersFromValues {
  number: string;
  date: string;
  location: string;
  duration: string;
}

export const model = {
  number: {
    type: 'number',
    required: true,
    placeholder: '',
    name: 'number',
    label: 'Contract field',
  },
  date: {
    required: true,
    name: 'date',
    label: 'Contract date',
  },
  location: {
    label: 'Place of conclusion of the contract',
    name: 'location',
    placeholder: '',
    required: true,
  },
  duration: {
    label: 'Term of the contract, year',
    name: 'duration',
    placeholder: '',
    required: true,
  },
};

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    [model.number.name]: Yup.string()
      .required('Contract field is required')
      .matches(/^[0-9]+$/, 'Contract field must contain only numbers'),
    [model.date.name]: Yup.string().required('Date field is required'),
    [model.location.name]: Yup.string().required(
      'The place of conclusion of the contract field is required',
    ),
    [model.duration.name]: Yup.string()
      .required('Term of the contract is required')
      .max(4, 'Maximum 9999 years')
      .matches(/^[0-9]+$/, 'Specify the number of years (digits)'),
  });

export const initialValues = (data: any) => {
  return {
    [model.number.name]: data.number || '',
    [model.location.name]: data.location || '',
    [model.duration.name]: data.duration || '',
    [model.date.name]: data.date || null,
  };
};
