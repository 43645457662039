import { gql } from 'graphql-request';

export const FINISH_INVITE_ORGANIZATION = gql`
  mutation CoreOrganizations(
    $inviteId: ID!
    $signature: String
    $detachedSignature: String
    $sendInviteEmails: [String!]
  ) {
    coreOrganizationInvitations {
      finishInvite(
        input: {
          inviteId: $inviteId
          signature: $signature
          detachedSignature: $detachedSignature
          sendInviteEmails: $sendInviteEmails
        }
      ) {
        inviteId
      }
    }
  }
`;

export const coreOrganizationsQuery = {
  mutation: FINISH_INVITE_ORGANIZATION,
};
