import { gql } from 'graphql-request';

export const CREATE_PAYMENT_OPTIONS = gql`
  mutation CoreOrganizationPaymentOptions(
    $input: core_OrganizationPaymentOptionsMutationNS_createInputInput!
  ) {
    coreOrganizationPaymentOptions {
      create(input: $input) {
        id
        name
        isDefault
        accountNumber
        corrNumber
        bic
        currency
        docId
        bank {
          id
          name
          status
          type
          countryCode
          createdAt
          updatedAt
          attributes {
            name
            value
          }
        }
      }
    }
  }
`;
