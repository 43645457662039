import { gql } from 'graphql-request';

export const CREATE_SUPPLY_DOCUMENT = gql`
  mutation App12d801b5_Document($input: app12d801b5_DocumentMutationNPSupplyAgreement_createInputInput!) {
    app12d801b5_Document {
      create(input: $input) {
        id
      }
    }
  }
`;
