import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { TFunction } from 'i18next';
import { Organization, PoaDraft } from 'store/api/types';
import {
  AttorneyAddRepresentiveFormValues,
  initialValues as addRepresentativeInitialValues,
  model as addRepresentativeModel,
} from '../../modal/aside-representative/config';
import {
  AttorneyInformationFormValues,
  initialValues as informationInitialValues,
  model as informationModel,
  validationSchema as informationValidationSchema,
} from './information-attorney/config';
import {
  AttorneyPrincipalHasPrePowerFormValues,
  model as principalHasPrePowerModel,
  validationSchema as principalHasPrePowerSchema,
  initialValues as principalHasPrePowerValues,
} from './information-principal-has-pre-power/config';
import {
  AttorneyPrincipalFormValues,
  initialValues as principalInitialValues,
  model as principalModel,
  validationSchema as principalValidationSchema,
} from './information-principal/config';
import {
  AttorneyRepresentiveFormValues,
  initialValues as representativeInitialValues,
  model as representativeModel,
  validationSchema as representativeValidationSchema,
  validationSchemaHasPrePower as representativeValidationSchemaHasPrePower,
} from './information-representative/config';
import {
  SelectAttorneyFormValues,
  initialValues as selectAttorneyInitialValues,
  model as selectAttorneyModel,
  validationSchema as selectAttorneyValidationSchema,
} from 'components/modal/aside-attorney/config';

export type {
  AttorneyAddRepresentiveFormValues,
  AttorneyInformationFormValues,
  AttorneyPrincipalFormValues,
  AttorneyPrincipalHasPrePowerFormValues,
  AttorneyRepresentiveFormValues,
  SelectAttorneyFormValues,
};

export const modelFields = {
  informationModel,
  principalModel,
  principalHasPrePowerModel,
  representativeModel,
  addRepresentativeModel,
  selectAttorneyModel,
};

export interface AttorneyFormValues
  extends AttorneyInformationFormValues,
    AttorneyPrincipalFormValues,
    AttorneyPrincipalHasPrePowerFormValues,
    AttorneyAddRepresentiveFormValues,
    AttorneyRepresentiveFormValues,
    SelectAttorneyFormValues {
  type: number;
  isKppDisabled: boolean;
}

export const model = {
  ...informationModel,
  ...principalModel,
  ...principalHasPrePowerModel,
  ...representativeModel,
  ...addRepresentativeModel,
  ...selectAttorneyModel,
};

export const validationSchema = ({
  t,
  step,
  values,
  hasPoaForRetrust,
}: {
  step: number;
  t: TFunction;
  hasPoaForRetrust: boolean;
  values: AttorneyFormValues;
}) => {
  if (step === Step.InformationAttorney) {
    return {
      ...informationValidationSchema(t),
      ...selectAttorneyValidationSchema,
    };
  }
  if (step === Step.InformationPrincipal) {
    if (values?.hasPrePowerOfAttorney) {
      return principalHasPrePowerSchema(t);
    }
    return principalValidationSchema(t);
  }
  if (step === Step.InformationRepresentative) {
    if (hasPoaForRetrust) {
      return representativeValidationSchemaHasPrePower;
    }
    return representativeValidationSchema(t);
  }
  if (step === Step.Sign) {
    return Yup.object({});
  }

  return Yup.object({});
};

export const initialValues = (data: {
  isIndividual: boolean;
  organizationKpp: string;
  organizationType: number;
  hasPoaForRetrust: boolean;
  currentDraftNumber: string;
  draft?: PoaDraft | undefined;
  selectPowerAttorney: FormikValues;
  currentOrganization: Organization | undefined;
}) => {
  const {
    draft,
    isIndividual,
    organizationKpp,
    hasPoaForRetrust,
    organizationType,
    currentDraftNumber,
    selectPowerAttorney,
    currentOrganization,
  } = data;

  const initial = {
    ...informationInitialValues({ draft, isIndividual, hasPoaForRetrust }),
    ...principalInitialValues,
    ...principalHasPrePowerValues,
    ...representativeInitialValues(draft),
    ...addRepresentativeInitialValues,
    ...selectAttorneyInitialValues,

    [model.kpp.name]: organizationKpp,
    [model.nameCompany.name]: currentOrganization?.name,
    [informationModel.attorneyNumber.name]: currentDraftNumber,

    isKppDisabled: !!organizationKpp?.length,

    type: organizationType,
  } as unknown as AttorneyFormValues;

  return initial;
};

export const Step = {
  InformationAttorney: 1,
  InformationPrincipal: 2,
  InformationRepresentative: 3,
  Sign: 4,
};

export const StepLabels = {
  [Step.InformationAttorney]: 'Information Attorney',
  [Step.InformationPrincipal]: 'Information Principal',
  [Step.InformationRepresentative]: 'Information Representative',
  [Step.Sign]: 'Sign',
};

export const StepLabelsRetrust = {
  [Step.InformationAttorney]: 'Information Attorney',
  [Step.InformationPrincipal]: 'Information about the transferor',
  [Step.InformationRepresentative]: 'Information Representative',
  [Step.Sign]: 'Sign',
};
