import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

interface ContractState {
  fuelFormData: object;
}

const initialState: ContractState = {
  fuelFormData: {},
};

const contractSlice = createSlice({
  name: 'partnershipContract',
  initialState,
  reducers: {
    setFuelFormData: (state, action) => {
      state.fuelFormData = action.payload;
    },
    resetFuelFormState: () => initialState,
  },
});

export default contractSlice.reducer;
export const { resetFuelFormState, setFuelFormData } = contractSlice.actions;

const contractStore = (store: RootState) => store.contractReducer;

export const selectFuelContractFormData = () =>
  createSelector([contractStore], (store) => store?.fuelFormData ?? {});
