import s from './EULA.module.scss';

const eulaText = `End-User License Agreement (EULA) for Crypto Keys Login This End-User License
Agreement ("Agreement") is a legal agreement between you (referred to as "User,"
"you," or "your") and [Your Company Name] (referred to as "Company," "we," "us," or
"our") for the use of the Crypto Keys Login software ("Software") and any
accompanying services. By using the Software, you agree to be bound by the terms and
conditions of this Agreement. If you do not agree to these terms, you may not use
the Software. License Grant: Subject to the terms of this Agreement, Company grants
you a non-exclusive, non-transferable license to use the Software solely for the
purpose of secure login using crypto keys. This license is limited to your personal
or business use and does not grant you any rights to modify, distribute, sublicense,
or reverse engineer the Software. Crypto Keys and Security: You acknowledge that the
Software facilitates login using cryptographic keys for enhanced security. You are
solely responsible for the generation, storage, and protection of your cryptographic
keys. Company does not have access to your keys and cannot be held responsible for
any loss, theft, or misuse of your cryptographic keys. User Responsibilities: You
agree to use the Software in compliance with all applicable laws and regulations.
You are responsible for maintaining the confidentiality of your login credentials,
including your cryptographic keys. You must promptly notify Company of any
unauthorized use or breach of security. Updates and Maintenance: Company may release
updates, patches, or new versions of the Software to improve functionality or
security. You agree to promptly install and use the latest version provided by
Company. Company is not obligated to provide maintenance, support, or updates beyond
what is explicitly mentioned in this Agreement. Intellectual Property: The Software
and all related intellectual property rights are owned by Company. This Agreement
does not transfer ownership of the Software or any intellectual property to you. You
agree not to remove, alter, or obscure any proprietary notices on the Software.
Limitation of Liability: To the extent permitted by law, Company shall not be liable
for any direct, indirect, incidental, special, or consequential damages arising out
of or in connection with the use of the Software, including but not limited to loss
of data, loss of profits, or business interruption. Termination: This Agreement is
effective until terminated. Company may terminate this Agreement at any time if you
fail to comply with its terms. Upon termination, you must cease all use of the
Software and destroy any copies in your possession. Governing Law: This Agreement
shall be governed by and construed in accordance with the laws of [Your
Jurisdiction]. Any disputes arising out of or in connection with this Agreement
shall be subject to the exclusive jurisdiction of the courts of [Your Jurisdiction].
Entire Agreement: This Agreement constitutes the entire agreement between you and
Company regarding the Software and supersedes all prior or contemporaneous
agreements, understandings, or representations, whether oral or written. By using
the Software, you acknowledge that you have read, understood, and agreed to be bound
by the terms and conditions of this Agreement. If you have any questions about this
Agreement, please contact us at [Contact Email].`;

export function EULA() {
  return (
    <div className={s.root}>
      <>
        <div className={s.thirBlock}>
          <div className={s.document}>
            <span>{eulaText}</span>
          </div>
        </div>
      </>
    </div>
  );
}
