import s from './image.module.scss';

export const Image = ({
  src,
  alt,
  width,
  height,
  objectFit,
  borderRadius,
}: {
  src: any;
  alt: string;
  width?: number;
  height?: number;
  borderRadius?: number | undefined;
  objectFit?: 'none' | 'fill' | 'cover';
}) => (
  <img
    src={src}
    alt={alt}
    width={width}
    height={height}
    style={{
      objectFit: objectFit,
      borderRadius: borderRadius ? `${borderRadius}px` : '0',
    }}
  />
);
