import * as Yup from 'yup';

export interface PoaSignedFormValues {
  email?: string;
}

export const model = {
  email: {
    name: 'email',
    placeholder: 'Новый e-mail',
  },
};

export const validationSchema = Yup.object().shape({
  [model.email.name]: Yup.string().email().optional(),
});

export const initialValues = {
  [model.email.name]: '',
};
