import { gql } from 'graphql-request';

export const GET_CORE_ORGANIZATIONS = gql`
  query CoreOrganizations($searchText: String) {
    coreOrganizations {
      query(filter: { searchText: $searchText }) {
        organizations {
          id
          name
          status
          type
          countryCode
          createdAt
          updatedAt
          attributes {
            value
            name
          }
        }
      }
    }
  }
`;

export const coreOrganizationsQuery = {
  query: GET_CORE_ORGANIZATIONS,
};
