import cx from 'classnames';
import { Formik, useField } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectAttorneyFormValues, initialValues, model, validationSchema } from './config';

import { ReactComponent as CloseIcon } from 'assets/images/close.svg';

import { RadioGroupField } from 'components/fields/radio-group-field/select-filed';
import { Button } from 'components/ui/Button';

import s from './aside-attorney.module.scss';
import { useGetPoaListRetrustQuery } from 'store/api/poa';
import { PoaRetrust } from 'store/api/types';

export const parsePoaRetrust = (poaRetrust: PoaRetrust) => {
  return {
    label: poaRetrust?.retrustPoaInfo?.number ?? '',
    value: poaRetrust?.retrustPoaInfo?.number ?? '',
    content: {
      id: poaRetrust?.retrustPoaInfo?.number,
      date: poaRetrust?.retrustPoaInfo?.issueDate ?? '',
      title: poaRetrust?.retrustPoaInfo?.recipientOrganization?.name ?? '',
      rangeDate: {
        from: poaRetrust?.retrustPoaInfo?.issueDate ?? '',
        to: poaRetrust?.retrustPoaInfo?.expirationDate ?? '',
      },
      text:
        poaRetrust?.retrustPoaInfo?.powerDomain
          ?.map((item) => item?.content ?? '')
          ?.toString() ?? '',
      subText: '',
    },
  };
};

export const AsideAttorney = ({
  name,
  isOpen,
  handleClose,
}: {
  name: string;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const { t } = useTranslation();
  const [, , helpers] = useField(name);

  const { data: poaRetrustList = [] } = useGetPoaListRetrustQuery();

  const containerClasses = cx(s['container'], {
    [s['open']]: isOpen,
  });
  const wrapperClasses = cx(s['wrapper'], {
    [s['open']]: isOpen,
  });
  const classesButtonBack = cx(s['button'], s['button-back']);
  const classesButtonSave = cx(s['button'], s['button-save']);

  const optionConfig = useMemo(() => {
    return poaRetrustList?.map((item: PoaRetrust) => parsePoaRetrust(item));
  }, [poaRetrustList?.length]);

  const onSubmit = (values: SelectAttorneyFormValues) => {
    helpers.setValue(values.selectAttorney);
    handleClose();
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ dirty, submitForm }) => (
        <div className={containerClasses}>
          <div className={wrapperClasses}>
            <div className={s['header']}>
              <span className={s['title']}>{t('Choosing a power of attorney')}</span>

              <button type="button" className={s['close']} onClick={handleClose}>
                <CloseIcon />
              </button>
            </div>

            <div className={s['main']}>
              <div className={s['scroll']}>
                <RadioGroupField
                  isContent
                  variant="column"
                  options={optionConfig}
                  {...model.selectAttorney}
                />
              </div>
            </div>

            <div className={s['footer']}>
              <Button
                displayType="outlined-secondary"
                className={classesButtonBack}
                onClick={handleClose}
              >
                {t('Cancel')}
              </Button>
              <Button disabled={!dirty} className={classesButtonSave} onClick={submitForm}>
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
