import { Navbar } from './navbar';
import { SideNav } from './sidenav';

import s from './Dashboard.module.scss';

interface LayoutMainProps {
  children: React.ReactNode;
}

function LayoutDashboard({ children }: LayoutMainProps) {
  return (
    <div className={s.container}>
      <SideNav />
      <Navbar />
      <div className={s.main}>{children}</div>
    </div>
  );
}

export default LayoutDashboard;
