import cx from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import s from './RegisterPage.module.scss';

import { ReactComponent as ArrowRightIcon } from 'assets/images/arrow-right.svg';
import Register from 'components/register/register';
import { Button } from 'components/ui/Button';
import { useAuth } from '../../hooks';

import { CheckClientModal } from 'components/modal/checkClient';
import { AppRoutes } from 'config/routes';

export function RegisterPage() {
  const isToken = useAuth();
  const { t } = useTranslation();

  const { Home, Infopanel } = AppRoutes.Dashboard;

  const navigate = useNavigate();

  function handleSubmitClick() {
    navigate('/offer');
  }

  useEffect(() => {
    if (isToken) {
      navigate(Home + Infopanel);
    }
  }, [isToken, navigate]);

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.firstBlock}>
          <div className={s.header}>
            {t('Connects')} <br /> {t('Business')}
          </div>

          <h1 className={s.title}>{t('Registration')}</h1>
        </div>

        <div className={s.secondBlock}>
          <StepIndicator step={1} />

          <Register />
        </div>
      </div>

      <div className={s.bottom}>
        <Button type="primary" className={s.button} onClick={handleSubmitClick}>
          {t('Next')}
        </Button>
      </div>

      <CheckClientModal />
    </div>
  );
}

export function StepIndicator(props: { step?: number; steps?: number }) {
  const { step = 1, steps = 3 } = props;

  return (
    <div className={s.stepIndicator}>
      {[...Array(steps)].map((_, i) => (
        <span key={i} className={cx(s.step, { [s.stepFilled]: step > i })}>
          {i > 0 && <ArrowRightIcon />}
          <span>{i + 1}</span>
        </span>
      ))}
    </div>
  );
}
