import { gql } from 'graphql-request';

export const GET_MY_PAYMENT_OPTIONS = gql`
  query CoreOrganizationPaymentOptions {
    coreOrganizationPaymentOptions {
      my {
        id
        name
        isDefault
        accountNumber
        corrNumber
        bic
        currency
        docId
        bank {
          id
          name
          status
          type
          countryCode
          createdAt
          updatedAt
          attributes {
            name
            value
          }
        }
      }
    }
  }
`;
