import { TFunction } from 'i18next';
import { StaffPower } from 'store/api/types/staffPowers';
import * as Yup from 'yup';

export interface OrganizationFormValues {
  permissions: {
    id: number;
    label: string;
    checked: boolean;
    poaRequired: boolean;
  }[];
}

export const model = {
  permissions: {
    name: 'permissions',
  },
};

export const validationSchema = (t: TFunction) => Yup.object().shape({});

export const initialValues = ({
  powers,
  activePowers,
}: {
  powers: StaffPower[];
  activePowers: string[];
}) => {
  return {
    [model.permissions.name]: powers?.map((power) => ({
      id: power.code,
      label: power.name,
      name: power.name,
      code: power.code,
      checked: activePowers.includes(power.code),
    })),
  } as unknown as OrganizationFormValues;
};
