import { TableRequisites } from 'components/table/requisites/requisites';
import { useTranslation } from 'react-i18next';

import {
  AddressAttributesWhitelist,
  AttributesWhitelist,
  ContactInformationAttributesWhitelist,
  CounterpartyAttributesWhitelist,
  CounterpartyType,
} from 'constants/attributes';

import { sortAttributes, sortAddressAttributes } from 'utils/attributes-order';

import { OrganizationAttributes } from 'components/organization-attributes';
import { DefaultPaymentOption, Organization } from 'store/api/types';

import s from './RequisitesDetail.module.scss';

interface RequisitesDetailProps {
  data: Organization | undefined;
  payment?: DefaultPaymentOption | undefined;
}

export const RequisitesDetail = ({ data, payment }: RequisitesDetailProps) => {
  const { t } = useTranslation();

  const addressAttributes = sortAddressAttributes(
    (data?.attributes ?? [])?.filter((item) => {
      return AddressAttributesWhitelist?.includes(item?.name);
    }),
  );

  const contactInformationAttributes = data?.attributes?.filter((item) => {
    return ContactInformationAttributesWhitelist?.includes(item?.name);
  });

  const postalAddressColumn = {
    title: t('Address'),
    attributes: addressAttributes,
  };

  const contactInformationColumn = {
    title: t('Contact information'),
    attributes: contactInformationAttributes,
  };

  const columns = [postalAddressColumn, contactInformationColumn];

  const sortedAttributes = data?.attributes ? [...data.attributes] : [];

  return (
    <div>
      <div className={s.wrapperDescription}>
        {sortAttributes(sortedAttributes)?.map((item) => (
          <>
            <OrganizationAttributes
              whitelist={[
                ...(CounterpartyAttributesWhitelist?.[
                  data?.type ?? CounterpartyType.LegalEntity
                ] ?? CounterpartyAttributesWhitelist[CounterpartyType.LegalEntity]),
                'kpp',
              ]}
              key={item.name}
              text={item.name}
              value={item.value}
            />
          </>
        ))}
      </div>
      <div className={s['container-column']}>
        {columns.map((column, index) => (
          <TableRequisites
            max={340}
            id={index}
            organization={data}
            key={index.toString()}
            title={column.title}
            data={column.attributes || []}
          />
        ))}

        {payment && (
          <TableRequisites
            max={340}
            id={2}
            title={t('Bank requisites')}
            data={[
              { name: 'bankName', value: payment.bank?.name },
              { name: 'departmentName', value: payment.name },
              { name: 'accountNumber', value: payment.accountNumber },
              { name: 'corrNumber', value: payment.corrNumber },
              { name: 'bic', value: payment.bic },
            ].filter((item) => item.value !== undefined)}
          />
        )}
      </div>
    </div>
  );
};
