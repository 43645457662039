import { useTranslation } from 'react-i18next';

import { model } from 'components/modal/aside-organization/config';

import { InputField } from 'components/common';
import { FieldLabel } from 'components/field-label';
import { SelectField } from 'components/fields/select-fields/select-filed';

import { Loader } from 'components/loader/loader';

import { SelectOption } from 'components/common/select/select-types';
import { useMemo } from 'react';
import {
  useGetCoreDictionariesBanksQuery,
  useGetCoreDictionariesCountriesQuery,
} from 'store/api/dictionaries';
import s from './styles.module.scss';
import { InputMasks } from 'components/common/input-field/input-field';

export const OKPOForm = () => {
  return (
    <div className={s['container-legal']}>
      <InputField {...model.okpo} />
    </div>
  );
};

export const KppForm = () => {
  return (
    <div className={s['container-legal']}>
      <InputField {...model.kpp} />
    </div>
  );
};

export const LegalAddressForm = () => {
  const { t } = useTranslation();

  const { data: [countriesDictionary] = [], isFetching } =
    useGetCoreDictionariesCountriesQuery();

  const options = useMemo(() => {
    const original = countriesDictionary?.enums.map((item) => {
      return {
        label: item.values.find((it) => it.key === 'shortname')?.value ?? '',
        value: item.title ?? '',
      };
    }) as SelectOption[];

    if (original) {
      const dictionary = [...original]?.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });

      const russia = dictionary.find(
        (item) => item.value?.toLocaleLowerCase()?.trim() === 'rus',
      );

      const kg = dictionary.find((item) => item.value?.toLocaleLowerCase()?.trim() === 'kgz');

      return [russia, kg, ...dictionary]?.filter(
        (item) => item !== undefined,
      ) as SelectOption[];
    }

    return [];
  }, [countriesDictionary]);

  if (isFetching) {
    return <Loader size="medium" />;
  }

  return (
    <div className={s['container-legal']}>
      <span className={s['title']}>{t('Legal address')}</span>
      <div className={s['wrapper-form']}>
        <SelectField {...model.country} options={options} />
        <InputField {...model.address} />
      </div>
    </div>
  );
};

export const PostalAddressForm = () => {
  const { t } = useTranslation();

  const { data: [countriesDictionary] = [], isFetching } =
    useGetCoreDictionariesCountriesQuery();

  const options = useMemo(() => {
    const original = countriesDictionary?.enums.map((item) => {
      return {
        label: item.values.find((it) => it.key === 'shortname')?.value ?? '',
        value: item.title ?? '',
      };
    }) as SelectOption[];

    if (original) {
      const dictionary = [...original]?.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });

      const russia = dictionary.find(
        (item) => item.value?.toLocaleLowerCase()?.trim() === 'rus',
      );

      return [russia, ...dictionary]?.filter((item) => item !== undefined) as SelectOption[];
    }

    return [];
  }, [countriesDictionary]);

  if (isFetching) {
    return <Loader size="medium" />;
  }

  return (
    <div className={s['container-legal']}>
      <span className={s['title']}>{t('Postal address')}</span>
      <div className={s['wrapper-form']}>
        <SelectField {...model.postalCountry} options={options} />
        <InputField {...model.postalAddress} />
      </div>
    </div>
  );
};

export const ContactInformationForm = ({ country }: { country: string }) => {
  const { t } = useTranslation();

  return (
    <div className={s['container-legal']}>
      <span className={s['title']}>{t('Contact information')}</span>
      <div className={s['wrapper-form']}>
        <div className={s['wraper-phone']}>
          <FieldLabel label="Phone" isRequired={true} />
          <div>
            <InputField
              {...model.phone}
              mask={
                country === 'kg' || country === 'kgz' ? InputMasks.kgPhone : InputMasks.phone
              }
            />
          </div>
        </div>
        <InputField {...model.email} />
      </div>
    </div>
  );
};

export const BankRequisitesForm = () => {
  const { t } = useTranslation();

  const { data: banks } = useGetCoreDictionariesBanksQuery();

  const options = useMemo(() => {
    return banks?.map((item) => {
      return {
        label: item.name ?? '',
        value: item.id ?? '',
      };
    }) as SelectOption[];
  }, [banks]);

  return (
    <div className={s['container-legal']}>
      <span className={s['title']}>{t('Bank requisites')}</span>
      <div className={s['wrapper-form']}>
        <SelectField {...model.bankId} options={options} />
        <InputField {...model.bankDepartment} />
        <InputField {...model.paymentAccount} />
        <InputField {...model.correspondentAccount} />
        <InputField {...model.bic} />
      </div>
    </div>
  );
};
