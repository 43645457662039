import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { SingleValue, MultiValue, ActionMeta } from 'react-select';

import { controlError } from 'utils/controlError';
import { SelectFieldProps } from '../select-fields/select-filed';
import { SelectOption } from 'components/common/select/select-types';

import { SelectTags } from '../select-tags/select-tags';

import s from './select-fields-tags.module.scss';

export const SelectTagsField = ({
  name,
  options,
  isColumn,
  onChange,
  onlyValue,
  isCreatable,
  placeholder,
  ...props
}: SelectFieldProps) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const error = t(controlError(meta, name, t(placeholder ?? '') as string) as string);

  const handleChange = (
    values: SingleValue<SelectOption> | MultiValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>,
  ) => {
    helpers.setValue(values);
    if (onChange) {
      onChange(values, actionMeta);
    }
  };

  return (
    <>
      <SelectTags
        {...field}
        error={error}
        isColumn={isColumn}
        items={field.value}
        placeholder={t(placeholder ?? '')}
        onClose={(item: SelectOption) =>
          helpers.setValue(field.value?.filter((i: SelectOption) => i.value !== item.value))
        }
        onChange={handleChange}
        options={options}
        {...props}
      />
    </>
  );
};

// export const SelectTagsField = ({
//   name,
//   options,
//   isColumn,
//   onChange,
//   onlyValue,
//   isCreatable,
//   placeholder,
//   ...props
// }: SelectFieldProps) => {
//   const { t } = useTranslation();

//   const [field, meta, helpers] = useField(name);

//   const error = t(controlError(meta, name, t(placeholder ?? '') as string) as string);
//   return (
//     <>
//       <SelectTags
//         {...field}
//         error={error}
//         isColumn={isColumn}
//         items={field.value}
//         onClose={(item: SelectOption) =>
//           helpers.setValue(field.value?.filter((i: SelectOption) => i.value !== item.value))
//         }
//         onChange={(values) => {
//           helpers.setValue(values);
//         }}
//         options={options}
//         {...props}
//       />
//     </>
//   );
// };
