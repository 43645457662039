import { gql } from 'graphql-request';

export const GET_POA_SIGNED_REVOCATION = gql`
  query CorePOA($poaId: ID!) {
    corePOA {
      getSignedRevocation(poaId: $poaId) {
        revocation {
          poaID
          content
          filename
          createdAt
        }
      }
    }
  }
`;
