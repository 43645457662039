import cn from 'classnames';
import { AppRoutes } from 'config/routes';
import { Formik, FormikValues } from 'formik';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { InputField, Switch } from 'components/common';
import { HeaderPage } from 'components/header-page/header-page';
import { Button } from 'components/ui/Button';

import { ReactComponent as InformationIcon } from 'assets/images/information.svg';

import { initialValues, model, validationSchema } from './config';

import { useAlert } from 'react-alert';
import { useGetMyOrganizationByIdQuery } from 'store/api/organizations';
import { useHandleMutation } from 'utils/handleMutation';
import s from './invitation-organization.module.scss';

import { NdaContractBase } from 'components/contracts/nda-contract';
import { Step } from 'components/contracts/forms/nda-invite/config';
import { selectDataForm, setDataForm } from 'store/reducers/data-form';
import { useSelector } from 'react-redux';
import {
  InviteSignResponse,
  NdaDocumentInput,
} from 'store/api/mutations/organizations-invitations/types';
import { useAppDispatch } from 'store/hooks';
import {
  setCounterpartyId,
  setDocumentContent,
  setInviteEmail,
} from 'store/reducers/invite.conteragent';
import { useFooterButtons } from './useFooterButtons';

import { ConterpartyHeaderInfo } from 'components/counterparty-header-info';
import { counterpartiesRoutes } from 'constants/routers-breadcrumbs';
import { prepareDate } from 'utils/date';
import {
  useFinishInviteOrganizationMutation,
  useStartInviteOrganizationMutation,
} from 'store/api/organizations-invitations';

const varinatForm = {
  date: 'date',
  number: 'number',
  location: 'location',
  duration: 'duration',
};

export const Information = ({ text }: { text: string }) => {
  const { t } = useTranslation();

  return (
    <div className={s.inforamtion}>
      <div className={s.iconInformation}>
        <InformationIcon />
      </div>
      <span className={s.textInformation}>{t(text)}</span>
    </div>
  );
};

export const InvitationOrganization = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const alert = useAlert();

  const dispatch = useAppDispatch();

  const formData = useSelector(selectDataForm);

  const { data: [counterparty] = [], isFetching } = useGetMyOrganizationByIdQuery(
    { id: id ?? '' },
    { skip: !id },
  );
  const [startInvite, startInviteMutation] = useStartInviteOrganizationMutation();
  const [finishInvite, finishInviteMutation] = useFinishInviteOrganizationMutation();

  const isLoading = startInviteMutation.isLoading || finishInviteMutation.isLoading;

  const isCounterpartyConnected = counterparty?.status === 5;

  const { Home, Counterparties } = AppRoutes.Dashboard;

  const [checked, setChecked] = useState<boolean>(formData?.checked ?? false);

  const { step, setStep, handleNextStep, handlePrevStep } = useFooterButtons();

  const classesWrapperButton = cn(s.wrapperButton, {
    [s.line]: checked,
  });

  const handleCheked = () => {
    setChecked((prev) => !prev);
  };

  const handleInvitation = async ({
    email,
    joinDocument,
  }: {
    email?: string;
    joinDocument?: NdaDocumentInput;
  }) => {
    if (checked) {
      const { data: { joinDocumentContent, inviteId } = {} } = (await startInvite({
        partnerOrganizationId: counterparty?.id ?? '',
        joinDocument,
      })) as { data: InviteSignResponse };

      dispatch(setInviteEmail(email));
      dispatch(setDocumentContent(joinDocumentContent));
      dispatch(setCounterpartyId(id));

      navigate(
        Home +
          Counterparties.Home +
          Counterparties.Invitation.Home +
          Counterparties.Invitation.Agreement +
          `/${inviteId}`,
      );
    } else {
      const { data: { inviteId } = {} } = (await startInvite({
        partnerOrganizationId: counterparty?.id ?? '',
      })) as { data: InviteSignResponse };

      if (inviteId) {
        await finishInvite({
          inviteId: (inviteId as string) ?? '',
          sendInviteEmails: email ? [email] : undefined,
        });
      }
    }
  };

  const onSubmit = async (values: FormikValues) => {
    const document = {
      location: values.location,
      date: prepareDate(values.date),
      durationYears: Number(values.duration),
      number: values.number?.toString() ?? '',
    } as NdaDocumentInput;

    const isDocumentValid = Object.keys(document).every(
      (key) => (document as any)[key] !== null,
    );

    dispatch(setDataForm({ checked, ...values }));
    await handleInvitation({
      email: values.email,
      joinDocument: isDocumentValid ? document : undefined,
    });
  };

  const isFormFilled = ({
    values,
    errors,
  }: {
    values: FormikValues;
    errors: FormikValues;
  }): boolean => {
    if (checked) {
      const formFields = Object.keys(values).filter((fieldName) =>
        Object.prototype.hasOwnProperty.call(varinatForm, fieldName),
      );

      const isAllFieldsFilled = formFields.every((fieldName) => {
        const value = values[fieldName];
        const stringValue = String(value);

        return stringValue.trim() !== '';
      });

      return formFields.length === 4 && isAllFieldsFilled;
    } else {
      return isCounterpartyConnected
        ? true
        : values?.['email']?.length && errors?.email === undefined;
    }
  };

  const onSuccessStart = useCallback(() => {
    //
  }, [alert, navigate, t]);

  const onSuccessFinish = useCallback(
    (inviteId: string) => {
      navigate(Home + Counterparties.Home + Counterparties.Invitations + `/${inviteId}`);
      alert.success(t('The invitation has been sent'));
    },
    [alert, navigate, t],
  );

  const onErrorStart = useCallback(() => {
    alert.error(t('Error'));
  }, [alert, t]);

  const onErrorFinish = useCallback(() => {
    alert.error(t('Error'));
  }, [alert, t]);

  useHandleMutation({
    ...finishInviteMutation,
    onSuccess: onSuccessFinish,
    onError: onErrorFinish,
  });
  useHandleMutation({
    ...startInviteMutation,
    onSuccess: onSuccessStart,
    onError: onErrorStart,
  });

  if (!counterparty) {
    return <div>{isFetching ? 'Loading' : 'Not found counterparty'}</div>;
  }

  return (
    <div className={s.container}>
      <HeaderPage title="Invitation" items={counterpartiesRoutes} />

      <div className={s.wrapperBottom}>
        {id && <ConterpartyHeaderInfo id={id} />}

        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues(formData)}
          validationSchema={validationSchema(t, checked)}
        >
          {({ errors, isSubmitting, submitForm, values }) => (
            <div className={s.wrapperForm}>
              <div className={s.wrapperInvitation}>
                <span className={s.titleInvitation}>
                  {t(
                    isCounterpartyConnected
                      ? 'The invitation will be sent to the administrator of the organization'
                      : 'Specify the e-mail of the contact person to send the invitation',
                  )}
                </span>

                <div className={s.wrapperInput}>
                  {isCounterpartyConnected && (
                    <span className={s.label}>
                      {t(
                        'If necessary, specify an additional e-mail address for sending the invitation',
                      )}
                    </span>
                  )}
                  <InputField
                    style={{ borderRadius: '0px', height: '40px' }}
                    {...model.email}
                  />
                </div>
              </div>

              <div className={s.wrapperAgreement}>
                <Information text="To work within the framework of the service, a mutual non-disclosure agreement must be concluded between the parties" />
                <Switch
                  checked={checked}
                  onClick={handleCheked}
                  label="Add a non-disclosure agreement"
                />
              </div>

              {checked && (
                <NdaContractBase
                  step={step}
                  setStep={setStep}
                  withHeader={false}
                  withFooter={false}
                />
              )}

              <div className={classesWrapperButton}>
                {checked && step > Step.BasicParameters && (
                  <Button
                    disabled={isSubmitting}
                    className={s.button_back}
                    onClick={handlePrevStep}
                  >
                    {t('Back')}
                  </Button>
                )}
                <Button
                  type="primary"
                  className={s.button}
                  disabled={!isFormFilled({ values, errors })}
                  onClick={
                    checked && step !== Step.BasicParameters ? handleNextStep : submitForm
                  }
                >
                  {t(isLoading ? 'Loading...' : 'Next')}
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
