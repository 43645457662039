import { order, AddressAttributesWhitelist } from 'constants/attributes';
import { OrganizationAttribute } from 'store/api/types';

export const sortAttributes = (attributes: OrganizationAttribute[]) => {
  return [...attributes].sort((a, b) => {
    const orderA = order.indexOf(a.name);
    const orderB = order.indexOf(b.name);

    return orderA - orderB || attributes.indexOf(a) - attributes.indexOf(b);
  });
};

export const sortAddressAttributes = (attributes: OrganizationAttribute[]) => {
  return [...attributes].sort((a, b) => {
    const orderA = AddressAttributesWhitelist.indexOf(a.name);
    const orderB = AddressAttributesWhitelist.indexOf(b.name);

    return orderA - orderB || attributes.indexOf(a) - attributes.indexOf(b);
  });
};

export const sortCustomAttributes = (attributes: OrganizationAttribute[], order: string[]) => {
  return [...attributes].sort((a, b) => {
    const orderA = order.indexOf(a.name);
    const orderB = order.indexOf(b.name);

    return orderA - orderB || attributes.indexOf(a) - attributes.indexOf(b);
  });
};
