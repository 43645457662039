import { CERT_KEYS } from './constants';

export function parseCertificate(subjectString: string) {
  return {
    [CERT_KEYS.INN]: extract(/INN=(.*?),/, subjectString),
    [CERT_KEYS.CN]: extractMultiple(subjectString, [/CN="(.*?)",/, /CN=(.*?),/]),
    [CERT_KEYS.L]: extract(/L=(.*?),/, subjectString),
    [CERT_KEYS.S]: extract(/S=(.*?),/, subjectString),
    [CERT_KEYS.O]: extractMultiple(subjectString, [/O="(.*?)",/, /O=(.*?),/]),
    [CERT_KEYS.OGRN]: extractMultiple(subjectString, [/OGRN=(.*?),/, /ОГРН=(.*?),/]),
    [CERT_KEYS.INNLE]: extractMultiple(subjectString, [
      /INNLE=(.*?),/,
      /ИНН ЮЛ=(.*?),/,
      /INNLE=(.*?)$/,
      /ИНН ЮЛ=(.*?)$/,
    ]),
    [CERT_KEYS.SNILS]: extract(/SNILS=(.*?),/, subjectString),
    [CERT_KEYS.STREET]: extract(/STREET="(.*?)"/, subjectString),
    [CERT_KEYS.SN]: extract(/SN=(.*?),/, subjectString),
    [CERT_KEYS.G]: extract(/G=(.*?),/, subjectString),
    [CERT_KEYS.T]: extract(/\sT=(.*?),/, subjectString),
  };
}

function extract(whatRegExp: RegExp, from: string): string {
  const matchResult = from.match(whatRegExp);

  if (!matchResult) {
    return '';
  }

  const [, match] = matchResult;

  return match.replaceAll('""', '"');
}

function extractMultiple(subject: string, regexps: RegExp[]): string {
  try {
    const items = regexps
      .reduce((result, item) => [...result, extract(item, subject)], [] as string[])
      .filter((item) => !!item);

    return items[0];
  } catch (error) {
    console.error(error);
    return '';
  }
}
