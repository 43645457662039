import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'config/routes';

import { StatusDocument } from 'components/status/status-document';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useGetDocumentStatusesQuery } from 'store/api/documents';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';
import { Organization } from 'store/api/types';
import { AppDocument } from 'store/api/types/documents';
import { DefaultDateFormat } from 'utils/date';
import s from './contract-item.module.scss';

export const ContractItem = ({
  data,
  url,
  ref,
}: {
  data: AppDocument;
  url: string;
  ref?: React.Ref<HTMLDivElement> | ((node: Element | null) => void) | null;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, title, number, statusId, contractors, orgOwner, template, createdAt } = data;
  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const { data: statuses } = useGetDocumentStatusesQuery();
  const status = statuses?.find((item: any) => item.id === statusId);

  const isOwner = currentOrganization?.id === data?.orgOwner?.id;

  const { Home, Contracts } = AppRoutes.Dashboard;

  const handleDetailContract = (id: string) => {
    if (statusId === statuses?.find((item) => item?.alias === 'approved')?.id && !isOwner) {
      navigate(
        Home + Contracts.Home + Contracts.ContractApproval + `/${id}?templateId=${template?.id}`,
      );
      return;
    }
    navigate(Home + Contracts.Home + `/${id}` + Contracts.BasicInformation);
  };

  const handleKeyDown = (id: string, event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleDetailContract(id);
    }
  };

  const conteragentName = useMemo(() => {
    return contractors?.[0]?.name ?? orgOwner?.name;
  }, [contractors?.[0]?.name, orgOwner?.name]);

  return (
    <div
      ref={ref}
      className={s['contract-container']}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => handleKeyDown(id, e)}
      onClick={() => handleDetailContract(id)}
    >
      <div className={s['contract-content']}>
        <div className={s['contract-header']}>
          {/* <Label text="Partnership agreement" /> */}
          <StatusDocument text={status?.alias} isOwner={isOwner} />
        </div>
        <div className={s['contract-main']}>
          <div className={s['main-top']}>
            <div className={s['wrapper-name']}>
              <span className={s['name']}>
                {title} <span className={s['number']}>№ {number}</span>
                <span className={s['date']}>{` от ${dayjs(createdAt)?.format(
                  DefaultDateFormat,
                )}`}</span>
              </span>
            </div>
          </div>
          {conteragentName && <div className={s['organization']}>{conteragentName}</div>}
          {false && (
            <div className={s['wrapper-payment']}>
              <span className={s['payment-text']}>{t('Amount')}:</span>
              <span className={s['payment']}>{'payment'} ₽</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
