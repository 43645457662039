import { DatePickerMode } from 'components/common/date-picker/date-picker';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { InputMasks } from 'components/common/input-field/input-field';
import { DefaultDateFormat } from 'utils/date';
export interface AttorneyPrincipalFormValues {
  typePrincipal: string;
  nameCompany: string;
  kpp: string;
  nameDocumentConfirmingAuthority: string;
  dateOfBirth: string;
  citizenship: string;
  country: string;
}

export const model = {
  // information principal
  typePrincipal: {
    label: 'Type principal',
    name: 'typePrincipal',
    placeholder: 'Legal entity',
    options: ['Legal entity 1', 'Legal entity 2', 'Legal entity 3'].map((label) => ({
      label,
      value: label,
    })),
    required: true,
    disabled: true,
  },
  nameCompany: {
    type: 'text',
    required: true,
    placeholder: 'АО “Поставщик-ПК”',
    name: 'nameCompany',
    label: 'Name company',
    disabled: false,
  },
  kpp: {
    type: 'text',
    required: true,
    placeholder: '411154484',
    name: 'kpp',
    label: 'KPP',
    mask: InputMasks.org9,
    disabled: false,
  },
  nameDocumentConfirmingAuthority: {
    type: 'text',
    required: true,
    placeholder: 'Order',
    name: 'nameDocumentConfirmingAuthority',
    label: 'Name of the document confirming the authority',
  },
  dateOfBirth: {
    type: 'date',
    required: true,
    mode: DatePickerMode.SINGLE,
    name: 'dateOfBirth',
    label: 'Date of birth',
  },
  citizenship: {
    type: 'radio',
    required: true,
    name: 'citizenship',
    label: 'Citizenship',
    options: [
      { label: 'Citizen of the Russian Federation', value: 'ru' },
      { label: 'Foreign citizen', value: 'foreign' },
    ],
  },
  country: {
    required: true,
    name: 'country',
    label: 'Country',
    placeholder: 'Select from the list',
  },
};

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    [model.nameCompany.name]: Yup.string().required(t('Name company is required')),
    [model.nameDocumentConfirmingAuthority.name]: Yup.string().required(
      t('Name of the document confirming the authority is required'),
    ),
    [model.kpp.name]: Yup.string().matches(
      /^[0-9]{9}$/,
      t('This field should be a 9-digit number'),
    ),
    [model.citizenship.name]: Yup.string().required('Citizenship is required'),
    [model.country.name]: Yup.string(),
    [model.dateOfBirth.name]: Yup.string().required('Date of birth is required'),
  });

export const initialValues = {
  [model.kpp.name]: '',
  [model.country.name]: '',
  [model.nameCompany.name]: '',
  [model.citizenship.name]: '',
  [model.typePrincipal.name]: '',
  [model.nameDocumentConfirmingAuthority.name]: 'Устав',
  [model.dateOfBirth.name]: null,
} as unknown as AttorneyPrincipalFormValues;
