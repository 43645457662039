import { createApi } from '@reduxjs/toolkit/query/react';

import { CREATE_POA_DRAFT } from './mutations/poa/create.poa.draft';
import { POA_REVOKE } from './mutations/poa/poa.revoke';
import { POA_SIGN } from './mutations/poa/poa.sign';
import {
  CorePOACreateDraftResponse,
  CorePOACreateLinkForAuthorityInput,
  CorePOACreateDraftForAuthorityResponse,
  CorePOARevokeInput,
  CorePOASignInput,
  CorePOAUpdateDraftInput,
  CorePoaPreviewRevocationInput,
  CorePoaPreviewRevocationResponse,
  CorePOACreateLinkForAuthorityResponse,
} from './mutations/poa/types';
import { UPDATE_POA_DRAFT } from './mutations/poa/update.poa.draft';
import { GET_LINK_FOR_AUTHORITY } from './queries/poa/get.link.for.authority';
import { GET_POA_DRAFT_AND_XML } from './queries/poa/get.poa.drafts.and.xml';
import { GET_POA_DRAFTS_LIST } from './queries/poa/get.poa.drafts.list';
import { GET_POA_SIGNED_REVOCATION } from './queries/poa/get.poa.signed.revocation';
import { GET_POA_SIGNED_XML } from './queries/poa/get.poa.signed.xml';
import { POA_PREVIEW_REVOCATION } from './queries/poa/poa.review.revocation';
import {
  CorePoaGetDraftForAuthorityInput,
  CorePoaGetDraftResponse,
  CorePoaGetDraftsResponse,
  CorePoaGetRetrustListResponse,
  CorePoaGetSignedResponse,
  CorePoaSignedRevocationResponse,
} from './queries/poa/types';
import { baseQuery } from './query';
import { PoaDraft, PoaRetrust, PoaSigned, PoaSignedRevocation } from './types';
import { GET_POA_DRAFT_FOR_AUTHORITY } from './queries/poa/get.poa.draft.for.authority';
import { SEND_EMAIL_TO_AUTHORITY } from './mutations/poa/send.email.to.authority';
import { GET_LIST_RETRUST_POA } from './queries/poa/get.list.retrust.poa';
import { StaffTags, staffApi } from './staff';

const POATags = {
  List: 'List',
  DraftById: 'DraftById',
};
const tagTypes = [POATags.List];

export const poaApi = createApi({
  tagTypes,
  baseQuery: baseQuery,
  reducerPath: 'poaApi',
  endpoints: (builder) => ({
    getPoaActiveList: builder.query<PoaDraft[], { representativeEmployeeID?: string | null }>({
      query: ({ representativeEmployeeID }) => ({
        document: GET_POA_DRAFTS_LIST,
        variables: { status: 'active', representativeEmployeeID },
      }),
      transformResponse: (rawResponse: CorePoaGetDraftsResponse) =>
        rawResponse?.corePOA?.query?.poas?.map((item) => ({
          ...item.poaDraft,
          authority: item.authority,
          representative: item.representative,
        })),
      providesTags: [POATags.List],
    }),
    getPoaArchiveList: builder.query<PoaDraft[], void>({
      query: () => ({
        document: GET_POA_DRAFTS_LIST,
        variables: { status: 'archive' },
      }),
      transformResponse: (rawResponse: CorePoaGetDraftsResponse) =>
        rawResponse?.corePOA?.query?.poas?.map((item) => ({
          ...item.poaDraft,
          authority: item.authority,
          representative: item.representative,
        })),
      providesTags: [POATags.List],
    }),
    getPoaRevokedList: builder.query<PoaDraft[], void>({
      query: () => ({
        document: GET_POA_DRAFTS_LIST,
        variables: { status: 'revoked' },
      }),
      transformResponse: (rawResponse: CorePoaGetDraftsResponse) =>
        rawResponse?.corePOA?.query?.poas?.map((item) => ({
          ...item.poaDraft,
          authority: item.authority,
          representative: item.representative,
        })),
      providesTags: [POATags.List],
    }),
    getPoaDraftsList: builder.query<PoaDraft[], void>({
      query: () => ({
        document: GET_POA_DRAFTS_LIST,
        variables: { status: 'draft' },
      }),
      transformResponse: (rawResponse: CorePoaGetDraftsResponse) =>
        rawResponse?.corePOA?.query?.poas?.map((item) => ({
          ...item.poaDraft,
          authority: item.authority,
          representative: item.representative,
        })),
      providesTags: [POATags.List],
    }),
    getPoaDraft: builder.query<PoaDraft, { id: string; format?: string | null }>({
      query: ({ id, format = 'xml' }) => ({
        document: GET_POA_DRAFT_AND_XML,
        variables: { id, format },
      }),
      transformResponse: (rawResponse: CorePoaGetDraftResponse) =>
        rawResponse?.corePOA?.getDraft,
      providesTags: [POATags.DraftById],
    }),
    getPoaSigned: builder.query<PoaSigned, { poaId: string }>({
      query: ({ poaId }) => ({
        document: GET_POA_SIGNED_XML,
        variables: { poaId },
      }),
      transformResponse: (rawResponse: CorePoaGetSignedResponse) =>
        rawResponse?.corePOA?.getSignedById,
    }),
    getPoaListRetrust: builder.query<PoaRetrust[], void>({
      query: () => ({
        document: GET_LIST_RETRUST_POA,
      }),
      transformResponse: (rawResponse: CorePoaGetRetrustListResponse) =>
        rawResponse?.corePOA?.listRetrustPOAs,
    }),
    getPoaSignedRevocation: builder.query<PoaSignedRevocation, { poaId: string }>({
      query: ({ poaId }) => ({
        document: GET_POA_SIGNED_REVOCATION,
        variables: { poaId },
      }),
      transformResponse: (rawResponse: CorePoaSignedRevocationResponse) =>
        rawResponse?.corePOA?.getSignedRevocation?.revocation,
    }),
    createPoaDraft: builder.mutation<string, { inviteId?: string }>({
      query: ({ inviteId }) => ({
        document: CREATE_POA_DRAFT,
        variables: { inviteId },
      }),
      transformResponse: (rawResponse: CorePOACreateDraftResponse) =>
        rawResponse?.corePOA?.createDraft,
    }),
    updatePoaDraft: builder.mutation<void, CorePOAUpdateDraftInput>({
      query: ({ input }) => ({
        document: UPDATE_POA_DRAFT,
        variables: {
          input,
        },
      }),
    }),
    poaSign: builder.mutation<void, CorePOASignInput>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(staffApi.util.invalidateTags([StaffTags.List, StaffTags.Staff]));
      },
      query: ({ id, signature, detachedSignature, cert }) => ({
        document: POA_SIGN,
        variables: {
          id,
          cert,
          signature,
          detachedSignature,
        },
      }),
      invalidatesTags: [POATags.List, POATags.DraftById],
    }),
    poaPreviewRevocation: builder.mutation<string, CorePoaPreviewRevocationInput>({
      query: ({ poaId, revokeDate, revokeReason }) => ({
        document: POA_PREVIEW_REVOCATION,
        variables: {
          poaId,
          revokeDate,
          revokeReason,
        },
      }),
      transformResponse: (rawResponse: CorePoaPreviewRevocationResponse) =>
        rawResponse?.corePOA?.previewRevocation?.content,
    }),
    poaRevoke: builder.mutation<void, CorePOARevokeInput>({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(staffApi.util.invalidateTags([StaffTags.List, StaffTags.Staff]));
      },
      query: ({ id, signature, detachedSignature }) => ({
        document: POA_REVOKE,
        variables: {
          id,
          signature,
          detachedSignature,
        },
      }),
      invalidatesTags: [POATags.List],
    }),
    poaGetLinkForAuthority: builder.query<string, CorePOACreateLinkForAuthorityInput>({
      query: ({ poaId, inviteId }) => ({
        document: GET_LINK_FOR_AUTHORITY,
        variables: {
          poaId,
          inviteId,
        },
      }),
      transformResponse: (rawResponse: CorePOACreateLinkForAuthorityResponse) =>
        rawResponse?.corePOA?.getLinkForAuthority,
    }),
    poaGetDraftForAuthority: builder.query<PoaDraft, CorePoaGetDraftForAuthorityInput>({
      query: ({ cert, id }) => ({
        document: GET_POA_DRAFT_FOR_AUTHORITY,
        variables: {
          id,
          cert,
        },
      }),
      transformResponse: (rawResponse: CorePOACreateDraftForAuthorityResponse) =>
        rawResponse?.corePOA?.getDraft,
    }),
    poaSendEmailToAuthority: builder.mutation<void, { email: string; cert: string }>({
      query: ({ email, cert }) => ({
        document: SEND_EMAIL_TO_AUTHORITY,
        variables: {
          email,
          cert,
        },
      }),
    }),
  }),
});

export const {
  usePoaSignMutation,
  useGetPoaDraftQuery,
  usePoaRevokeMutation,
  useGetPoaSignedQuery,
  useLazyGetPoaDraftQuery,
  useGetPoaActiveListQuery,
  useGetPoaDraftsListQuery,
  useGetPoaArchiveListQuery,
  useCreatePoaDraftMutation,
  useUpdatePoaDraftMutation,
  useGetPoaRevokedListQuery,
  useGetPoaListRetrustQuery,
  useGetPoaSignedRevocationQuery,
  usePoaGetLinkForAuthorityQuery,
  usePoaPreviewRevocationMutation,
  usePoaSendEmailToAuthorityMutation,
  useLazyPoaGetDraftForAuthorityQuery,
} = poaApi;
