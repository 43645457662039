import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

type InitialStateType = {
  documentContent?: string;
};

const initialState: InitialStateType = {
  documentContent: '',
};

const attorneyCreateSlice = createSlice({
  name: 'attorneyCreate',
  initialState,
  reducers: {
    setDocumentContent(state, action) {
      state.documentContent = action.payload;
    },
  },
});

export const { setDocumentContent } = attorneyCreateSlice.actions;

const attorneyCreateStore = (store: RootState) => {
  return store.attorneyCreateReducer;
};

export const selectDocumentContent = () =>
  createSelector([attorneyCreateStore], (store) => store?.documentContent ?? '');

export default attorneyCreateSlice.reducer;
