import cx from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectInvitationsType } from 'store/reducers/invite.list';

import { UserCard } from 'components/card';
import { Button } from 'components/ui/Button';

import { useAlert } from 'react-alert';
import { StaffInvite } from 'store/api/types/staff';
import s from './InviteInfo.module.scss';
import { ReviewModal } from './review-invite/review-invite';

const VarinatMessage = {
  Incoming: 'incoming',
  Outgoing: 'outgoing',
};

const OutgoingMessageButtons = ({ inviteId }: { inviteId: string }) => {
  const alert = useAlert();
  const { t } = useTranslation();

  const onCopy = async () => {
    try {
      const link = `${window.location.origin}?staffInviteId=${inviteId}`;
      await navigator.clipboard.writeText(link ?? '');
      alert.success(t('Link Copied'));
    } catch (err) {
      alert.error(t('Error!'));
    }
  };

  return (
    <>
      <Button className={cx(s['button'], s['button-primary'])} onClick={onCopy}>
        {t('Copy Link')}
      </Button>
    </>
  );
};

const IncomingMessageButtons = ({
  inviteId,
  setModalVisible,
}: {
  inviteId: string;
  setModalVisible: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        className={cx(s['button'], s['button-back'])}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        {t('Рассмотреть')}
      </Button>
    </>
  );
};

export const InviteInfo = ({ currentInvite }: { currentInvite: StaffInvite | undefined }) => {
  const type = useSelector(selectInvitationsType);
  const [isModalVisible, setModalVisible] = useState(false);

  if (!currentInvite?.id) {
    return null;
  }

  return (
    <div className={s['container']}>
      <div className={s['wrapper']}>
        <UserCard item={currentInvite as any} customAttributes={['snils']}/>
        {currentInvite.status !== 'approved' && (
          <div className={s['footer']}>
            {type === VarinatMessage.Outgoing && (
              <OutgoingMessageButtons inviteId={currentInvite.id} />
            )}
            {type === VarinatMessage.Incoming && (
              <IncomingMessageButtons
                inviteId={currentInvite.id}
                setModalVisible={setModalVisible}
              />
            )}
          </div>
        )}
      </div>

      <ReviewModal
        item={currentInvite as any}
        inviteId={currentInvite.id}
        isVisible={isModalVisible}
        setVisible={setModalVisible}
      />
    </div>
  );
};
