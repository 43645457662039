type Props = {
  width?: number;
  height?: number;
};

export const SuccessMark = ({ width = 101, height = 101 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.8867 9.65234C42.8519 9.65234 34.9974 12.035 28.3167 16.4989C21.6359 20.9628 16.4289 27.3076 13.3541 34.7308C10.2793 42.1541 9.47481 50.3224 11.0423 58.2029C12.6099 66.0834 16.479 73.3221 22.1605 79.0036C27.842 84.6851 35.0807 88.5542 42.9612 90.1217C50.8417 91.6893 59.01 90.8848 66.4333 87.81C73.8565 84.7351 80.2013 79.5281 84.6652 72.8474C89.1291 66.1666 91.5117 58.3122 91.5117 50.2773C91.4911 39.5093 87.2043 29.1881 79.5901 21.5739C71.976 13.9597 61.6548 9.67299 50.8867 9.65234ZM70.2227 43.168L47.3321 65.043C46.7403 65.5997 45.957 65.9074 45.1446 65.9023C44.7474 65.908 44.3531 65.835 43.9844 65.6875C43.6156 65.54 43.2798 65.321 42.9961 65.043L31.5508 54.1055C31.2333 53.8285 30.9752 53.4902 30.7918 53.1109C30.6084 52.7317 30.5036 52.3192 30.4837 51.8984C30.4638 51.4776 30.5292 51.0571 30.6759 50.6622C30.8227 50.2673 31.0478 49.9061 31.3377 49.6004C31.6275 49.2947 31.9762 49.0508 32.3628 48.8833C32.7494 48.7158 33.1658 48.6282 33.5871 48.6258C34.0084 48.6233 34.4258 48.7061 34.8143 48.8691C35.2027 49.0321 35.5543 49.2719 35.8477 49.5742L45.1446 58.4414L65.9258 38.6367C66.5334 38.1066 67.3225 37.8319 68.1279 37.87C68.9334 37.9081 69.693 38.2561 70.2478 38.8412C70.8027 39.4263 71.1099 40.2033 71.1052 41.0096C71.1005 41.816 70.7843 42.5893 70.2227 43.168Z"
        fill="#44AC21"
      />
    </svg>
  );
};
