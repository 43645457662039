export const variantStatus = {
  invite: 'invite',
  active: 'active',
  blocked: 'blocked',
};

interface useCounterpartyStatusProps {
  variant: string | number;
}

export const useCounterpartyStatus = (variant: useCounterpartyStatusProps['variant']) => {
  const getStatusClass = () => {
    switch (variant) {
      case variantStatus.active:
        return {
          className: 'active',
          text: 'Active',
        };
      case variantStatus.blocked:
        return {
          className: 'blocked',
          text: 'Blocked',
        };
      case variantStatus.invite:
        return {
          className: 'invite',
          text: 'Invite',
        };
      case 5:
        return {
          className: 'active',
          text: 'Active',
        };
      default:
        return {
          className: '',
          text: '',
        };
    }
  };

  return {
    statusClass: getStatusClass(),
  };
};
