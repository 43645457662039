
import { fromBase64 } from 'helpers';
import { useEffect, useState } from 'react';
import { processXslt } from 'utils/xslt-transform.helper';

export default function useProcessXslt({ base64 }: { base64: string }) {
  const [data, setData] = useState<DocumentFragment | string | null>(null);

  useEffect(() => {
    setData(processXslt(fromBase64(base64)));
  }, [base64]);

  return { data };
}
