import { Input } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as PlusIcon } from 'assets/images/plus.svg';
import { ReactComponent as ReportIcon } from 'assets/images/report.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search-dashboard.svg';
import { HighlightText } from 'components/common';
import { Button } from 'components/ui/Button';
import { useCounterpartyStatus } from 'hooks/use-counterparty-status';

import { AppRoutes } from 'config/routes';

import { Loader } from 'components/loader/loader';
import { CounterpartyAttributesWhitelist } from 'constants/attributes';
import { useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useGetPartnersOrganizationsQuery } from 'store/api/organizations';
import { OrganizationAttribute } from 'store/api/types';
import { useDebounce } from 'usehooks-ts';
import { sortAttributes } from 'utils/attributes-order';
import s from './AllCounterparties.module.scss';

export const variantStatus = {
  invite: 'invite',
  active: 'active',
  blocked: 'blocked',
};

export interface CounterpartyProps {
  name: string;
  type?: number;
  id: number | string;
  highlightText?: string;
  variant?: string | number;
  onInvite?: () => void;
  attributes?: OrganizationAttribute[];
}

interface TextNotFoundProps {
  hasSearch: string;
}

export const Counterparty = ({
  id,
  name,
  type = 2,
  onInvite,
  attributes,
  highlightText,
  variant = 'active',
}: CounterpartyProps) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const { statusClass } = useCounterpartyStatus(variant);

  const navigate = useNavigate();

  const { Dashboard } = AppRoutes;

  const handleClick = (id: number | string) => {
    if (variant !== variantStatus.blocked) {
      if (onInvite) {
        onInvite();
        return;
      }
      navigate(
        Dashboard.Home +
          Dashboard.Counterparties.Home +
          Dashboard.Counterparties.CounterpatyDetail.Home +
          `/${id}` +
          Dashboard.Counterparties.CounterpatyDetail.Requisites,
      );
    }
  };

  const classesContainer = cx(s.wrapperCounterparty, {
    [s.blockedCounterparty]: variant === variantStatus.blocked,
  });

  const hendleDetailCounterparty = () => {
    if (variant !== variantStatus.blocked) {
      handleClick(id);
    } else {
      alert.show(t('You cannot view the details of a blocked counterparty'));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      hendleDetailCounterparty();
    }
  };

  const filteredAttributes = sortAttributes(
    (attributes ?? [])?.filter((item) =>
      CounterpartyAttributesWhitelist[type].includes(item.name),
    ),
  );

  return (
    <div className={classesContainer}>
      <div
        tabIndex={0}
        role="button"
        className={cx(s.info, {
          [s.cursorDefault]: variant === variantStatus.invite,
        })}
        onKeyDown={handleKeyDown}
        onClick={hendleDetailCounterparty}
      >
        <div className={s.wrapperTop}>
          <span className={s.name}>
            <HighlightText text={name} searchText={highlightText} />
          </span>
          {variant !== variantStatus.active && variant !== 5 && (
            <Button
              type="green"
              size="small"
              displayType="outlined-primary"
              onClick={() => handleClick(id)}
            >
              {t(statusClass.text)}
            </Button>
          )}
        </div>
        <div className={s.wrapperDescription}>
          {filteredAttributes?.map((item) => {
            if (!item.value) {
              return null;
            }
            return (
              <div key={item.name} className={s.description}>
                <span className={s.reduction}>{t(item.name)}:</span>
                <span className={s.value}>{item.value}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const TextNotFound = ({ hasSearch }: TextNotFoundProps) => {
  const { t } = useTranslation();

  return (
    <div className={s.notFound}>
      <ReportIcon />
      <span className={s.textNotFound}>
        {hasSearch ? (
          <span>{t('Organization not found')}</span>
        ) : (
          <>
            <span>{t('Here will be displayed')}</span>
            <span>{t('contractors of the organization')}</span>
          </>
        )}
      </span>
    </div>
  );
};

export const AllCounterparties = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [filter, setFilter] = useState<string>('');
  const debounceFilterValue = useDebounce(filter, 1500);

  const { data = [], isFetching } = useGetPartnersOrganizationsQuery({
    searchText: debounceFilterValue,
  });

  const { Home, Counterparties } = AppRoutes.Dashboard;

  const noDataOrFound = data?.length === 0;

  const filterData = useMemo(() => {
    if (!filter) {
      return data;
    }

    return data.filter((row) => {
      return row?.name?.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
    });
  }, [filter]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilter(value);
  };

  const handleInvitation = () => {
    navigate(Home + Counterparties.Home + Counterparties.Invitation.Home);
  };

  return (
    <div>
      <div className={s.top}>
        <div className={s['wrapper-input']}>
          <Input
            allowClear
            value={filter ?? ''}
            placeholder={t('Enter the name, INN, OGRN or OKPO')}
            prefix={<SearchIcon />}
            style={{ borderRadius: '0px', height: '40px' }}
            onChange={handleFilterChange}
          />
        </div>
        <Button
          className={s['button']}
          icon={<PlusIcon className={s['icon']} />}
          onClick={handleInvitation}
        >
          {t('Invite counterparty')}
        </Button>
      </div>

      {isFetching && <Loader size="big" />}

      {!isFetching && (
        <div className={noDataOrFound && filterData.length === 0 ? s.wrapperBottom : s.content}>
          {noDataOrFound && filterData.length === 0 ? (
            <TextNotFound hasSearch={filter} />
          ) : (
            data?.map((user) => (
              <Counterparty
                id={user.id}
                name={user.name}
                type={user?.type}
                variant={user.status}
                key={user.id.toString()}
                attributes={user.attributes}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
};
