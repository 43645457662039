import { Loader } from 'components/loader/loader';
import useProcessXslt from 'hooks/use-process-xslt';
import s from './styles.module.scss';

type Props = {
  documentContent?: string | null;
  isLoading?: boolean;
};

export const NdaContractPreviewAcceptInvite = ({ documentContent, isLoading }: Props) => {
  const { data: xsltPreview } = useProcessXslt({ base64: documentContent ?? '' });

  return (
    <div className={s['container']}>
      <div className={s['wrapper-main']}>
        {isLoading && <Loader size="big" />}
        {xsltPreview && (
          <span>
            <div
              dangerouslySetInnerHTML={{
                __html: new XMLSerializer().serializeToString(xsltPreview as any),
              }}
            />
          </span>
        )}
      </div>
    </div>
  );
};
