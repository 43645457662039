import { gql } from 'graphql-request';

export const START_INVITE_ORGANIZATION = gql`
  mutation CoreOrganizations($partnerOrganizationId: ID!, $joinDocument: core_OrganizationMutationNS_joinDocumentInput) {
    coreOrganizationInvitations {
      startInvite(
        input: { partnerOrganizationId: $partnerOrganizationId, joinDocument: $joinDocument }
      ) {
        joinDocumentContent
        inviteId
      }
    }
  }
`;

export const coreOrganizationsQuery = {
  mutation: START_INVITE_ORGANIZATION,
};
